import React from "react";
import "./ProductsSec.css";
import { ProductCard } from "../../components";

const ProductsSec = ({ products, handleModalOpened }) => {
  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp">
        <div className="product_cards_wrapp">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              imgSrc={product.img}
              productName={product.name}
              handleModalOpened={handleModalOpened}
              sizeproduct={product.size}
              subImages={product.subImages}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductsSec;
