import React, { useEffect, useState } from "react";
import "./ProductFilter.css";
import { GoChevronDown } from "react-icons/go";
import { FaSearch } from "react-icons/fa";

const ProductFilter = ({
  data,
  currentCategory,
  categoryFilter,
  sizeFilter,
  finishFilter,
  searchTerm,
  handleCategoryChange,
  handleSizeChange,
  handleFinishChange,
  handleSearchChange,
  isSizeDisabled,
  isFinishDisabled,
  availableSizes,
  availableFinishes
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [showMoreCategories, setShowMoreCategories] = useState(false);

  const categories = data
    ? ["All Categories", ...new Set(data.map((item) => item.name))]
    : [];

  const visibleCategories = showMoreCategories
    ? categories
    : categories.slice(0, 8);

  useEffect(() => {
    console.log("FilterData", data);
  }, [data]);

  useEffect(() => {
    if (categoryFilter === "All Categories") {
      setShowMoreCategories(false);
    }
  }, [categoryFilter]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen(false);
    setIsOpen3(false);
  };

  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen(false);
    setIsOpen2(false);
  };

  const handleCategorySelect = (category) => {
    handleCategoryChange(category);
    setIsOpen(false);
    if (category === "All Categories") {
      setShowMoreCategories(false);
    }
  };

  const handleSizeSelect = (size) => {
    handleSizeChange(size);
    setIsOpen2(false);
  };

  const handleFinishSelect = (finish) => {
    handleFinishChange(finish);
    setIsOpen3(false);
  };

  return (
    <div className="product_filter_mw">
      <div className="product_filter_bw">
        <p className="product_filter_heading">Filter By.</p>
        <div className="product_filter_dw">
          {/* Filter by Category */}
          <div className="dropdown-wrapper">
            {data && (
              <div className="dropdown-wrapper">
                <div className="dropdown" onClick={toggleDropdown}>
                  <span className="dropdown-selected">
                    {categoryFilter || "All Categories"}
                  </span>
                  <GoChevronDown className="dropdown-icon" width={20} />
                </div>
                {isOpen && (
                  <ul className="dropdown-options">
                    {visibleCategories.map((category, index) => (
                      <li
                        key={index}
                        className="dropdown-option"
                        onClick={() => handleCategorySelect(category)}
                      >
                        {category}
                      </li>
                    ))}
                    {!showMoreCategories && categories.length > 8 && (
                      <li
                        className="dropdown-option show-more"
                        onClick={() => setShowMoreCategories(true)}
                      >
                        Show More
                      </li>
                    )}
                  </ul>
                )}
              </div>
            )}
          </div>

          {/* Filter by Size */}
          <div className="dropdown-wrapper">
            <div className="dropdown-wrapper">
              <div className={`dropdown ${isSizeDisabled ? "disabled" : ""}`} onClick={toggleDropdown2}>
                <span className="dropdown-selected">
                  {sizeFilter || "All Sizes"}
                </span>
                <GoChevronDown className="dropdown-icon" width={20} />
              </div>
              {isOpen2 && !isSizeDisabled && (
                <ul className="dropdown-options">
                  {availableSizes.map((size, index) => (
                    <li
                      key={index}
                      className="dropdown-option"
                      onClick={() => handleSizeSelect(size)}
                    >
                      {size}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Filter by Finish */}
          <div className="dropdown-wrapper">
            <div className="dropdown-wrapper">
              <div className={`dropdown ${isFinishDisabled ? "disabled" : ""}`} onClick={toggleDropdown3}>
                <span className="dropdown-selected">
                  {finishFilter || "All Finishes"}
                </span>
                <GoChevronDown className="dropdown-icon" width={20} />
              </div>
              {isOpen3 && !isFinishDisabled && (
                <ul className="dropdown-options">
                  {availableFinishes.map((finish, index) => (
                    <li
                      key={index}
                      className="dropdown-option"
                      onClick={() => handleFinishSelect(finish)}
                    >
                      {finish}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Search Bar */}
          <div className="p_f_srarch_wrapp">
            <input
              type="text"
              placeholder="Search ..."
              value={searchTerm}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <FaSearch size={16} color="#010101" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
