import React from "react";
import "./HeroSection.css";
import { Parallax } from "react-parallax";

const HeroSection = ({ img, title }) => {
  return (
    <>
      {/* <div
      className="hero_section_main_wrapp_2"
      style={{ maxWidth: "100vw", overflow: "hidden" }}
    >
      <div className="hero-slider-wrapp-shadow_2">
        <div
          className="hero-slider-wrapp-shadow-inner"
          style={{ alignItems: "center", width: "auto" }}
        >
          <div className="hero-slider-wrapp-shadow-vl"></div>
          <div
            className="hero-slider-wrapp-shadow-text-wrapp"
            style={{ width: "100%" }}
          >
            <p>{title}</p>
          </div>
        </div>
      </div>

      <img
        src={img}
        alt="hero"
        className="slide-image"
        style={{ height: "70vh", objectPosition: "center" }}
      />
    </div> */}

      <Parallax
        bgImage={img} // Path to the background image
        strength={300} // Control the depth effect
        bgImageAlt="Parallax Background"
        bgImageStyle={{ objectPosition: "end" }}
        style={{
          height: "70vh",

          maxWidth: "100vw",
          overflow: "hidden",
          position: "relative",
        }} // Height of the parallax section
      >
        <div
          style={{
            // border: "1px solid red",
            height: "70vh",
            // backgroundColor: "#0000008b",
            background:
              "linear-gradient(289.52deg,rgba(0, 0, 0, 0.15) 20.83%,rgba(1, 1, 1, 0.51) 100%)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className="hero-slider-wrapp-shadow-inner"
            style={{ alignItems: "center", width: "auto" }}
          >
            <div className="hero-slider-wrapp-shadow-vl"></div>
            <div
              className="hero-slider-wrapp-shadow-text-wrapp"
              style={{ width: "100%" }}
            >
              <p>{title}</p>
            </div>
          </div>
        </div>
      </Parallax>
    </>
  );
};

export default HeroSection;
