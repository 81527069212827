import React from "react";
import "./HomeAbout.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp">
        <div className="home_about_wrapp">
          <div className="home_about_wrapp_1st_sec_wrapp">
            <h4 className="sec_light_heading mb-1">ABOUT</h4>
            <h2 className="sec_heading2 mb-2">Teknika</h2>
            <p className="sec_description mb-3">
              Teknika Industries, a rising star in the global tile market
              founded in 2021, prioritizes quality, sustainability, and
              innovation. They craft beautiful and durable tiles using
              cutting-edge technology to elevate your space. Believing in strong
              partnerships and environmental responsibility, Teknika recently
              launched a state-of-the-art production unit on Halvad Road (Morbi)
              boasting a dedicated production line for the popular 60x120 tile
              size. This impressive facility utilizes a fully automated process
              featuring advanced technology like an HLT press YP209, high-end
              digital machines by System (Italy), and a remarkable 84 Polishing
              Heads by KEDA. This ensures exceptional consistency, precision,
              and a truly premium finish in every tile, reflecting Teknika's
              dedication to offering the best possible product.
            </p>
            <Link to={'/about'} class="custom-button">KNOW MORE</Link>
          </div>

          <div className="home_about_wrapp_2nd_sec_wrapp">
            <img src={images.home_img_3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
