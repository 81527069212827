import React, { useEffect, useState } from "react";
import "./BlogDetailBanner.css";
import { Parallax } from "react-parallax";
import images from "../../constants/images";

const BlogDetailBanner = ({ blogData }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 5 seconds delay

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  if (loading || !blogData) {
    return (
      <div className="loading-container">
        <div className="loading-text">
          {"Loading".split("").map((char, index) => (
            <span key={index} className="loading-letter">
              {char}
            </span>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="blog_detail_banner_main_wrapp">
      <Parallax
        bgImage={blogData.blog_image[0].image} // Path to the background image
        strength={300} // Control the depth effect
        bgImageAlt="Parallax Background"
        style={{ height: "100%" }} // Height of the parallax section
      >
        <div className="blog_detail_banner_shadow">
          <div className="blog_detail_banner_shadow_inner">

            <div className="blog_detail_banner_shadow_tags_wrapp">
              {blogData.blog_key_words.slice(0, 3).map((blog_key_words, index) => (
                <div className="blog_detail_banner_shadow_tag" key={index}>
                  {blog_key_words.blog_key_words}
                </div>
              ))}
            </div>

            <h4 className="blog_detail_banner_heading">
              {blogData.blog_title}
            </h4>
            <p className="blog_detail_banner_description">{blogData.date}</p>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default BlogDetailBanner;
