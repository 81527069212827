import React, { useEffect } from "react";
import { HeroSection } from "../common";
import images from "../constants/images";
import { ContectUsForm, ContectUsMap, ContectUsTimeline, HomeInsta } from "../container";

const ContectUsPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' 
    });
  }, []);
  return (
    <div>
      <HeroSection img={images.contact_hero_img} title="Contact us" />
      <ContectUsForm />
      <ContectUsMap />
      <HomeInsta />
    </div>
  );
};

export default ContectUsPage;
