import React from "react";
import "./TechInnovation.css";
import images from "../../constants/images";

const TechInnovation = () => {
  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp tech_sec_main_wrapp">
        <h2 className="sec_heading">INNOVATION MEETS TECHNOLOGY</h2>
        <div className="tech_sec_first_wrapp">
          <div>
            <p className="sec_description_2 mb-1_5 mv_sec">
              As leaders in the tiles industry, Teknika Industries stands as a
              beacon of innovation and excellence, continuously pushing the
              boundaries of what's achievable. Our relentless pursuit of
              perfection is driven by cutting-edge technology and a commitment
              to setting new standards of quality and ingenuity worldwide.
            </p>
            <p className="sec_description_2 mb-1_5 mv_sec">
              In an era defined by rapid technological advancement, Teknika
              Industries leads the charge, harnessing the latest breakthroughs
              to craft surfaces that not only meet but exceed expectations. Our
              dedication to upholding the highest ethical and sustainability
              standards underscores every aspect of our operations, earning the
              unwavering trust of customers across the globe.
            </p>
          </div>
          <img src={images.tech_img} alt="" />
        </div>
        <p className="sec_description_2 mb-1 mv_sec">
          Within our state-of-the-art facilities, innovation thrives. Equipped
          with advanced scanning, printing, and manufacturing capabilities, we
          transform raw materials into works of art, setting the stage for a
          future where imagination knows no bounds.
        </p>
        <p className="sec_description_2 mb-1 mv_sec">
          Embracing diversity and fostering a culture of collaboration, Teknika
          Industries transcends borders and cultures to unite minds in pursuit
          of a common goal: reshaping the landscape of the tiles industry.
          Together, we're rewriting the rules and opening doors to endless
          possibilities.
        </p>
        <p className="sec_description_2 mb-1 mv_sec">
          Join us on this extraordinary journey as we redefine what's possible,
          leaving an indelible mark on the world stage with solutions that not
          only inspire awe but also shape the future of design and innovation.
          At Teknika Industries, the future is limitless, and together, we're
          building it one tile at a time.
        </p>
      </div>
    </div>
  );
};

export default TechInnovation;
