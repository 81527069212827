import React from "react";
import "./HomeSeamless.css";
import images from "../../constants/images";

const HomeSeamless = () => {
  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp">
        <div className="home_seamless_sec_wrapp">
          <h4 className="sec_heading mb-2">OUR SEAMLESS EXPERIENCE</h4>
          <p className="sec_description mb-1">
            Tenika specializes in alleviating the stress associated with
            bringing your dream space to life. Whether you're perusing designs
            or seeking expert installation, we ensure a seamless and hassle-free
            journey. Our website offers an empowering platform for exploring
            various options and conveniently ordering samples, while our
            dedicated and knowledgeable team stands ready to support you at
            every turn. With Tenika, you'll effortlessly transition from
            inspiration to flawless execution, experiencing unparalleled
            elegance every step of the way.
          </p>

          <div className="home_seamless_sec_points_wrapp">
            <div className="home_seamless_sec_single_point">
              <img src={images.ose_logo_1} alt="" />
              <p>Versatile Customization</p>
            </div>
            <div className="home_seamless_sec_single_point">
              <img src={images.ose_logo_2} alt="" />
              <p>Cutting-Edge Designs</p>
            </div>
            <div className="home_seamless_sec_single_point">
              <img src={images.ose_logo_3} alt="" />
              <p>Superior Durability</p>
            </div>
            <div className="home_seamless_sec_single_point">
              <img src={images.ose_logo_4} alt="" />
              <p>Exceptional Service</p>
            </div>
            <div className="home_seamless_sec_single_point">
              <img src={images.ose_logo_5} alt="" />
              <p>Transformative Expertise</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSeamless;
