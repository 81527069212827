import React from "react";
import "./AboutWeAre.css";
import images from "../../constants/images";
import { FaCheck } from "react-icons/fa";

const AboutWeAre = () => {
  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp">
        <div className="about_weare_main_wrapp">
          <div className="about_weare_sec1">
            <h2 className="sec_heading mb-2" style={{ lineHeight: "38px" }}>
              Who we are
            </h2>
            <p className="about_weare_sec1_first_peregraph_head">
              Teknika Industries: Redefining Tile, One Project at a Time
            </p>
            <p className="about_weare_sec1_first_peregraph_des">
              Teknika Industries is a consortium of passionate tile producers
              founded in 2021. We are united by a shared vision: to export
              exceptional tiles that elevate spaces worldwide. Our brand
              represents quality, innovation, and a deep commitment to
              sustainability.
            </p>
            <div className="about_weare_sec1_inner_sec">
              <div className="about_weare_sec1_inner_sec_rectangle">
                <h2>10</h2>
                <p>Years</p>
                <p>of</p>
                <p>Expertise</p>
              </div>
              <div className="about_weare_sec1_inner_sec_missin_wrapp">
                <div className="mission_sec_1">
                  <div className="mission_sec_1_first">
                    <FaCheck size={16} color="var(--color-ocher)" />
                    <p>Mission</p>
                  </div>
                  <p className="mission_sec_1_sec">
                    Our mission is to prioritise fostering long-lasting
                    relationships with both partners and customers. We achieve
                    this by creating a reliable distribution network that brings
                    our vision to life, ensuring exceptional service and on-time
                    delivery for every project.
                  </p>
                </div>
                <div className="mission_sec_1">
                  <div className="mission_sec_1_first">
                    <FaCheck size={16} color="var(--color-ocher)" />
                    <p>Vision</p>
                  </div>
                  <p className="mission_sec_1_sec">
                    To establish a sustainable business model built on crafting
                    top-notch, durable tiles that are not only aesthetically
                    pleasing but also environmentally responsible. We envision a
                    future where Teknika Industries is synonymous with
                    exceptional quality, cutting-edge innovation, and a
                    commitment to eco-friendly practices in the global tile
                    market.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="about_weare_sec2">
            <img src={images.about_weare_img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWeAre;
