// ---------------- logos ------------------
import logo from "../assets/logos/logo.png";
import btn_img from "../assets/logos/btn_img.webp";
import play_btn from "../assets/logos/play_btn.webp";
import news_send_icon from "../assets/logos/news_send_icon.webp";

import right_arrow from "../assets/logos/right_arrow.webp";

import ose_logo_1 from "../assets/logos/ose_logo_1.webp";
import ose_logo_2 from "../assets/logos/ose_logo_2.webp";
import ose_logo_3 from "../assets/logos/ose_logo_3.webp";
import ose_logo_4 from "../assets/logos/ose_logo_4.webp";
import ose_logo_5 from "../assets/logos/ose_logo_5.webp";

import email_icon from "../assets/logos/email_icon.webp";
import location_icon from "../assets/logos/location_icon.webp";
import map_pin from "../assets/logos/map-pin.png";
import map_pin_white from "../assets/logos/map-pin-white.png";
import phone_icon from "../assets/logos/phone_icon.webp";

import email_icon_white from "../assets/logos/email_icon_white.webp";
import location_icon_white from "../assets/logos/location_icon_white.webp";
import phone_icon_white from "../assets/logos/phone_icon_white.webp";

import home_advantage_logo_1 from "../assets/logos/home_advantage_logo_1.webp";
import home_advantage_logo_2 from "../assets/logos/home_advantage_logo_2.webp";
import home_advantage_logo_3 from "../assets/logos/home_advantage_logo_3.webp";
import home_advantage_logo_4 from "../assets/logos/home_advantage_logo_4.webp";
import home_advantage_logo_5 from "../assets/logos/home_advantage_logo_5.webp";
import home_advantage_logo_6 from "../assets/logos/home_advantage_logo_6.webp";

import tech_partner_logo_1 from "../assets/logos/tech_partner_logo_1.webp";
import tech_partner_logo_2 from "../assets/logos/tech_partner_logo_2.webp";
import tech_partner_logo_3 from "../assets/logos/tech_partner_logo_3.webp";
import tech_partner_logo_4 from "../assets/logos/tech_partner_logo_4.webp";
import tech_partner_logo_5 from "../assets/logos/tech_partner_logo_5.webp";

import build_logo from "../assets/logos/build_logo.webp";

// ------------- images -----------------
import home_slider_img1 from "../assets/images/Home_slider_img1.webp";
import home_slider_img2 from "../assets/images/Home_slider_img2.webp";
import home_slider_img3 from "../assets/images/Home_slider_img3.webp";
import home_slider_img4 from "../assets/images/Home_slider_img4.webp";
import home_slider_img5 from "../assets/images/Home_slider_5.webp";
import home_slider_img6 from "../assets/images/Home_slider_6.webp";

import home_slider_1 from "../assets/images/home_slider_1.webp";
import about_image from "../assets/images/about_image.webp";

import home_img_1 from "../assets/images/home_img_1.webp";
import home_img_2 from "../assets/images/home_img_2.webp";
import home_img_3 from "../assets/images/home_img_3.webp";
import home_img_4 from "../assets/images/home_img_4.webp";

import about_weare_img from "../assets/images/about_weare_img.webp";

import tech_img from "../assets/images/tech_img.webp";

import img1 from "../assets/images/Image.webp";
import img2 from "../assets/images/Rectangle 25 (1).webp";
import img3 from "../assets/images/Rectangle 26 (2).webp";
// product card images
import roccia_gris from "../assets/images/roccia_gris.webp";
import roccia_metal from "../assets/images/roccia_metal.webp";
import roccia_nero from "../assets/images/roccia_nero.webp";
import roccia_taupe from "../assets/images/roccia_taupe.webp";
import norway_cerma from "../assets/images/norway_cerma.webp";
import norway_cloud from "../assets/images/norway_cloud.webp";
import norway_gris from "../assets/images/norway_gris.webp";
import norway_nero from "../assets/images/norway_nero.webp";

import insta_img3 from "../assets/images/insta_img3.webp";
import insta_img4 from "../assets/images/insta_img4.webp";
import insta_img5 from "../assets/images/insta_img5.webp";

import about_timeline from "../assets/images/about_timeline.webp";
import about_timeline2 from "../assets/images/blog_img1.webp";
import about_timeline3 from "../assets/images/blog_img2.webp";

import blog_img1 from "../assets/images/blog_img1.webp";
import blog_img2 from "../assets/images/blog_img2.webp";
import blog_img3 from "../assets/images/blog_img3.webp";

import about_hero_img from "../assets/images/About_hero_img.webp";
import product_hero_img from "../assets/images/product_hero_img.webp";
import techinnovation_hero_img_hero_img from "../assets/images/techinnovation_hero_img.webp";
import contact_hero_img from "../assets/images/contact_hero_img.webp";

import blog_hero_img from "../assets/images/blog_hero_img.webp";
import blog_detail_1 from "../assets/images/blog_detail_1.webp";
import blog_detail_2 from "../assets/images/blog_detail2.webp";

// -------------- videos ------------------------------
import video2 from "../assets/videos/video2.mp4";
import video1 from "../assets/videos/video1.mp4";
import teknikaa from "../assets/videos/teknikaa.mp4";

// -------------- teknika_product ------------------------------
import teknika_product from "../assets/logos/teknika_product.png";


// -------------- XL Slab ------------------------------
import CALACUTTA_BORGHINI_BM from "../assets/xlslab/CALACUTTA BORGHINI BM.png";
import CALACUTTA_BORGHINI_BM_2 from "../assets/xlslab/CALACUTTA BORGHINI_BM.jpg";
import CALACUTTA_LUXE01 from "../assets/xlslab/CALACUTTA LUXE01.jpg";
import CALACUTTA_LUXE02 from "../assets/xlslab/CALACUTTA LUXE02.jpg";
import CARRARA_ULTIMO from "../assets/xlslab/CARRARA ULTIMO.jpg";
import CELIO01 from "../assets/xlslab/CELIO01.jpg";
import CELIO02 from "../assets/xlslab/CELIO02.jpg";
import DIOR_GOLD from "../assets/xlslab/DIOR GOLD.jpg";
import DOLCE_GREY from "../assets/xlslab/DOLCE GREY.jpg";
import EVEREST_WHITE_1 from "../assets/xlslab/EVEREST WHITE 01.jpg";
import SAINT_LAURENT_2 from "../assets/xlslab/SAINT LAURENT 02.jpg";
import SILVER_RIVER_Calacutta_Bianco_Oro from "../assets/xlslab/SILVER RIVER & Calacutta Bianco Oro.jpg";
import SILVER_RIVER from "../assets/xlslab/SILVER RIVER.png";
import STATUARIO_NORWELL_1 from "../assets/xlslab/STATUARIO NORWELL 1.jpg";
import STATUARIO_NORWELL_2 from "../assets/xlslab/STATUARIO NORWELL 2.jpg";
import STATUARIO_NORWELL_3 from "../assets/xlslab/STATUARIO NORWELL 3.jpg";
import STATUARIO_PLATINA_1 from "../assets/xlslab/STATUARIO PLATINA  1.jpg";
import STATUARIO_PLATINA_2 from "../assets/xlslab/STATUARIO PLATINA 2.jpg";
import STATUARIO_VENETIAN_1 from "../assets/xlslab/STATUARIO VENETIAN 1.jpg";
import STATUARIO_VENETIAN_2 from "../assets/xlslab/STATUARIO VENETIAN 2.jpg";
import STATUARIO_VENETIAN_BM from "../assets/xlslab/STATUARIO VENETIAN_BM.jpg";
import STRATUM_PORTORO_1 from "../assets/xlslab/STRATUM PORTORO 1.jpg";
import STRATUM_PORTORO_2 from "../assets/xlslab/STRATUM PORTORO 2.jpg";
import STRATUM_PORTORO from "../assets/xlslab/STRATUM PORTORO.jpg";
import teknika_bg from "../assets/logos/teknika_bg.png";

import ADONIS1 from "../assets/images/Glossy/ADONIS DARK P1.jpeg";
import ADONIS2 from "../assets/images/Glossy/ADONIS DARK P2.jpeg";

import AIZA_COBALT_P1 from "../assets/images/Glossy/AIZA COBALT P1.jpeg";
import AIZA_COBALT_P2 from "../assets/images/Glossy/AIZA COBALT P2.jpeg";
import AIZA_COBALT_P3 from "../assets/images/Glossy/AIZA COBALT P3.jpeg";
import AIZA_COBALT_P4 from "../assets/images/Glossy/AIZA COBALT P4.jpeg";

import AIZA_ONYX_P1 from "../assets/images/Glossy/AIZA ONYX P1.jpeg";
import AIZA_ONYX_P2 from "../assets/images/Glossy/AIZA ONYX P2.jpeg";
import AIZA_ONYX_P3 from "../assets/images/Glossy/AIZA ONYX P3.jpeg";
import AIZA_ONYX_P4 from "../assets/images/Glossy/AIZA ONYX P4.jpeg";

import ALBASTO_BIANCO_P1 from "../assets/images/Glossy/ALBASTO BIANCO  P1.jpeg";
import ALBASTO_BIANCO_P2 from "../assets/images/Glossy/ALBASTO BIANCO  P2.jpeg";
import ALBASTO_BIANCO_P3 from "../assets/images/Glossy/ALBASTO BIANCO  P3.jpeg";
import ALBASTO_BIANCO_P4 from "../assets/images/Glossy/ALBASTO BIANCO  P4.jpeg";

import ALBASTO_BROWN_P1 from "../assets/images/Glossy/ALBASTO_BROWN_P1.jpeg";
import ALBASTO_BROWN_P2 from "../assets/images/Glossy/ALBASTO_BROWN_P2.jpeg";
import ALBASTO_BROWN_P3 from "../assets/images/Glossy/ALBASTO_BROWN_P3.jpeg";
import ALBASTO_BROWN_P4 from "../assets/images/Glossy/ALBASTO_BROWN_P4.jpeg";
import ALBASTO_BROWN_P5 from "../assets/images/Glossy/ALBASTO_BROWN_P5.jpeg";

import ALPINE_STATURIO_P1 from "../assets/images/Glossy/ALPINE_STATURIO_P1.jpeg";
import ALPINE_STATURIO_P2 from "../assets/images/Glossy/ALPINE_STATURIO_P2.jpeg";
import ALPINE_STATURIO_P3 from "../assets/images/Glossy/ALPINE_STATURIO_P3.jpeg";
import ALPINE_STATURIO_P4 from "../assets/images/Glossy/ALPINE_STATURIO_P4.jpeg";
import ALPINE_STATURIO_P5 from "../assets/images/Glossy/ALPINE_STATURIO_P5.jpeg";

import AMAZON_CREMA_P1 from "../assets/images/Glossy/AMAZON_CREMA_P1.jpeg";
import AMAZON_CREMA_P2 from "../assets/images/Glossy/AMAZON_CREMA_P2.jpeg";
import AMAZON_CREMA_P3 from "../assets/images/Glossy/AMAZON_CREMA_P3.jpeg";
import AMAZON_CREMA_P4 from "../assets/images/Glossy/AMAZON_CREMA_P4.jpeg";

import AMAZON_GREY_P1 from "../assets/images/Glossy/AMAZON_GREY_P1.jpeg";
import AMAZON_GREY_P2 from "../assets/images/Glossy/AMAZON_GREY_P2.jpeg";
import AMAZON_GREY_P3 from "../assets/images/Glossy/AMAZON_GREY_P3.jpeg";
import AMAZON_GREY_P4 from "../assets/images/Glossy/AMAZON_GREY_P4.jpeg";

import AMBROSIA_BIANCO_P1 from "../assets/images/Glossy/AMBROSIA_BIANCO_P1.jpeg";
import AMBROSIA_BIANCO_P2 from "../assets/images/Glossy/AMBROSIA_BIANCO_P2.jpeg";
import AMBROSIA_BIANCO_P3 from "../assets/images/Glossy/AMBROSIA_BIANCO_P3.jpeg";
import AMBROSIA_BIANCO_P4 from "../assets/images/Glossy/AMBROSIA_BIANCO_P4.jpeg";

import AMBROSIA_BROWN_P1 from "../assets/images/Glossy/AMBROSIA_BROWN_P1.jpeg";
import AMBROSIA_BROWN_P2 from "../assets/images/Glossy/AMBROSIA_BROWN_P2.jpeg";
import AMBROSIA_BROWN_P3 from "../assets/images/Glossy/AMBROSIA_BROWN_P3.jpeg";
import AMBROSIA_BROWN_P4 from "../assets/images/Glossy/AMBROSIA_BROWN_P4.jpeg";


import ANGEL_SATVARIYO_P1 from "../assets/images/Glossy/ANGEL_SATVARIYO_P1.jpeg";
import ANGEL_SATVARIYO_P2 from "../assets/images/Glossy/ANGEL_SATVARIYO_P2.jpeg";
import ANGEL_SATVARIYO_P3 from "../assets/images/Glossy/ANGEL_SATVARIYO_P3.jpeg";
import ANGEL_SATVARIYO_P4 from "../assets/images/Glossy/ANGEL_SATVARIYO_P4.jpeg";

import ANTI_QUITY_BEIGE_P1 from "../assets/images/Glossy/ANTI_QUITY_BEIGE_P1.jpeg";
import ANTI_QUITY_BEIGE_P2 from "../assets/images/Glossy/ANTI_QUITY_BEIGE_P2.jpeg";
import ANTI_QUITY_BEIGE_P3 from "../assets/images/Glossy/ANTI_QUITY_BEIGE_P3.jpeg";
import ANTI_QUITY_BEIGE_P4 from "../assets/images/Glossy/ANTI_QUITY_BEIGE_P4.jpeg";


import ANTICA_COBALT_P1 from "../assets/images/Glossy/ANTICA_COBALT_P1.jpeg";
import ANTICA_COBALT_P2 from "../assets/images/Glossy/ANTICA_COBALT_P2.jpeg";

import ANTICA_MAROON_P1 from "../assets/images/Glossy/ANTICA_MAROON_P1.jpeg";
import ANTICA_MAROON_P2 from "../assets/images/Glossy/ANTICA_MAROON_P2.jpeg";

import ANTRACITA_ICE_P1 from "../assets/images/Glossy/ANTRACITA_ICE_P1.jpeg";
import ANTRACITA_ICE_P2 from "../assets/images/Glossy/ANTRACITA_ICE_P2.jpeg";
import ANTRACITA_ICE_P3 from "../assets/images/Glossy/ANTRACITA_ICE_P3.jpeg";
import ANTRACITA_ICE_P4 from "../assets/images/Glossy/ANTRACITA_ICE_P4.jpeg";

import APUANE_SATUARIO_P1 from "../assets/images/Glossy/APUANE_SATUARIO_P1.jpeg";
import APUANE_SATUARIO_P2 from "../assets/images/Glossy/APUANE_SATUARIO_P2.jpeg";
import APUANE_SATUARIO_P3 from "../assets/images/Glossy/APUANE_SATUARIO_P3.jpeg";
import APUANE_SATUARIO_P4 from "../assets/images/Glossy/APUANE_SATUARIO_P4.jpeg";

import ARABIC_BROWN_LIGHT_P1 from "../assets/images/Glossy/ARABIC_BROWN_LIGHT_P1.jpeg";
import ARABIC_BROWN_LIGHT_P2 from "../assets/images/Glossy/ARABIC_BROWN_LIGHT_P2.jpeg";
import ARABIC_BROWN_LIGHT_P3 from "../assets/images/Glossy/ARABIC_BROWN_LIGHT_P3.jpeg";
import ARABIC_BROWN_LIGHT_P4 from "../assets/images/Glossy/ARABIC_BROWN_LIGHT_P4.jpeg";

import ARABIC_BROWN_P1 from "../assets/images/Glossy/ARABIC_BROWN_P1.jpeg";
import ARABIC_BROWN_P2 from "../assets/images/Glossy/ARABIC_BROWN_P2.jpeg";
import ARABIC_BROWN_P3 from "../assets/images/Glossy/ARABIC_BROWN_P3.jpeg";
import ARABIC_BROWN_P4 from "../assets/images/Glossy/ARABIC_BROWN_P4.jpeg";

import ARCTIC_WHITE_P1 from "../assets/images/Glossy/ARCTIC_WHITE_P1.jpeg";
import ARCTIC_WHITE_P2 from "../assets/images/Glossy/ARCTIC_WHITE_P2.jpeg";
import ARCTIC_WHITE_P3 from "../assets/images/Glossy/ARCTIC_WHITE_P3.jpeg";

import ARDESIA_COBALT_P1 from "../assets/images/Glossy/ARDESIA_COBALT_P1.jpeg";
import ARDESIA_COBALT_P2 from "../assets/images/Glossy/ARDESIA_COBALT_P2.jpeg";
import ARDESIA_COBALT_P3 from "../assets/images/Glossy/ARDESIA_COBALT_P3.jpeg";
import ARDESIA_COBALT_P4 from "../assets/images/Glossy/ARDESIA_COBALT_P4.jpeg";

import ARDESIA_COBALT_LIGHT_P1 from "../assets/images/Glossy/ARDESIA_COBALT_LIGHT_P1.jpeg";
import ARDESIA_COBALT_LIGHT_P2 from "../assets/images/Glossy/ARDESIA_COBALT_LIGHT_P2.jpeg";
import ARDESIA_COBALT_LIGHT_P3 from "../assets/images/Glossy/ARDESIA_COBALT_LIGHT_P3.jpeg";
import ARDESIA_COBALT_LIGHT_P4 from "../assets/images/Glossy/ARDESIA_COBALT_LIGHT_P4.jpeg";

import ARDESIA_GOLDEN_P1 from "../assets/images/Glossy/ARDESIA_GOLDEN_P1.jpeg";
import ARDESIA_GOLDEN_P2 from "../assets/images/Glossy/ARDESIA_GOLDEN_P2.jpeg";
import ARDESIA_GOLDEN_P3 from "../assets/images/Glossy/ARDESIA_GOLDEN_P3.jpeg";
import ARDESIA_GOLDEN_P4 from "../assets/images/Glossy/ARDESIA_GOLDEN_P4.jpeg";

import ARMANI_BRONZE_P1 from "../assets/images/Glossy/ARMANI_BRONZE_P1.jpeg";
import ARMANI_BRONZE_P2 from "../assets/images/Glossy/ARMANI_BRONZE_P2.jpeg";
import ARMANI_BRONZE_P3 from "../assets/images/Glossy/ARMANI_BRONZE_P3.jpeg";
import ARMANI_BRONZE_P4 from "../assets/images/Glossy/ARMANI_BRONZE_P4.jpeg";

import ARMANO_BEIGE_P1 from "../assets/images/Glossy/ARMANO_BEIGE_P1.jpeg";
import ARMANO_BEIGE_P2 from "../assets/images/Glossy/ARMANO_BEIGE_P2.jpeg";
import ARMANO_BEIGE_P3 from "../assets/images/Glossy/ARMANO_BEIGE_P3.jpeg";
import ARMANO_BEIGE_P4 from "../assets/images/Glossy/ARMANO_BEIGE_P4.jpeg";
import ARMANO_BEIGE_P5 from "../assets/images/Glossy/ARMANO_BEIGE_P5.jpeg";
import ARMANO_BEIGE_P6 from "../assets/images/Glossy/ARMANO_BEIGE_P6.jpeg";

import ARMANO_BROWN_P1 from "../assets/images/Glossy/ARMANO_BEIGE_P1.jpeg";
import ARMANO_BROWN_P2 from "../assets/images/Glossy/ARMANO_BEIGE_P2.jpeg";
import ARMANO_BROWN_P3 from "../assets/images/Glossy/ARMANO_BEIGE_P3.jpeg";
import ARMANO_BROWN_P4 from "../assets/images/Glossy/ARMANO_BEIGE_P4.jpeg";
import ARMANO_BROWN_P5 from "../assets/images/Glossy/ARMANO_BEIGE_P5.jpeg";
import ARMANO_BROWN_P6 from "../assets/images/Glossy/ARMANO_BEIGE_P6.jpeg";

import ARMANO_CREMA_P1 from "../assets/images/Glossy/ARMANO_CREMA_P1.jpeg";
import ARMANO_CREMA_P2 from "../assets/images/Glossy/ARMANO_CREMA_P2.jpeg";
import ARMANO_CREMA_P3 from "../assets/images/Glossy/ARMANO_CREMA_P3.jpeg";
import ARMANO_CREMA_P4 from "../assets/images/Glossy/ARMANO_CREMA_P4.jpeg";
import ARMANO_CREMA_P5 from "../assets/images/Glossy/ARMANO_CREMA_P5.jpeg";
import ARMANO_CREMA_P6 from "../assets/images/Glossy/ARMANO_CREMA_P6.jpeg";

import ARMANO_GREY_P1 from "../assets/images/Glossy/ARMANO_GREY_P1.jpeg";
import ARMANO_GREY_P2 from "../assets/images/Glossy/ARMANO_GREY_P2.jpeg";
import ARMANO_GREY_P3 from "../assets/images/Glossy/ARMANO_GREY_P3.jpeg";
import ARMANO_GREY_P4 from "../assets/images/Glossy/ARMANO_GREY_P4.jpeg";
import ARMANO_GREY_P5 from "../assets/images/Glossy/ARMANO_GREY_P5.jpeg";
import ARMANO_GREY_P6 from "../assets/images/Glossy/ARMANO_GREY_P6.jpeg";

import ARTICI_BEIGE from "../assets/images/Glossy/ARTICI_BEIGE.jpeg";
import ARTICI_BIANCO from "../assets/images/Glossy/ARTICI_BIANCO.jpeg";
import ARTICI_COAL from "../assets/images/Glossy/ARTICI_COAL.jpeg";

import ARTICO_ONYX_BIANCO_P1 from "../assets/images/Glossy/ARTICO_ONYX_BIANCO_P1.jpeg";
import ARTICO_ONYX_BIANCO_P2 from "../assets/images/Glossy/ARTICO_ONYX_BIANCO_P2.jpeg";
import ARTICO_ONYX_BIANCO_P3 from "../assets/images/Glossy/ARTICO_ONYX_BIANCO_P3.jpeg";
import ARTICO_ONYX_BIANCO_P4 from "../assets/images/Glossy/ARTICO_ONYX_BIANCO_P4.jpeg";

import ARTICO_ONYX_MULTI_P1 from "../assets/images/Glossy/ARTICO_ONYX_MULTI_P1.jpeg";
import ARTICO_ONYX_MULTI_P2 from "../assets/images/Glossy/ARTICO_ONYX_MULTI_P2.jpeg";
import ARTICO_ONYX_MULTI_P3 from "../assets/images/Glossy/ARTICO_ONYX_MULTI_P3.jpeg";
import ARTICO_ONYX_MULTI_P4 from "../assets/images/Glossy/ARTICO_ONYX_MULTI_P4.jpeg";

import ATHENA_BIANCO_P1 from "../assets/images/Glossy/ATHENA_BIANCO_P1.jpeg";
import ATHENA_BIANCO_P2 from "../assets/images/Glossy/ATHENA_BIANCO_P2.jpeg";
import ATHENA_BIANCO_P3 from "../assets/images/Glossy/ATHENA_BIANCO_P3.jpeg";
import ATHENA_BIANCO_P4 from "../assets/images/Glossy/ATHENA_BIANCO_P4.jpeg";

import ATHENA_BROWN_P1 from "../assets/images/Glossy/ATHENA_BROWN_P1.jpeg";
import ATHENA_BROWN_P2 from "../assets/images/Glossy/ATHENA_BROWN_P2.jpeg";
import ATHENA_BROWN_P3 from "../assets/images/Glossy/ATHENA_BROWN_P3.jpeg";
import ATHENA_BROWN_P4 from "../assets/images/Glossy/ATHENA_BROWN_P4.jpeg";

import ATHENA_COBALT_P1 from "../assets/images/Glossy/ATHENA_COBALT_P1.jpeg";
import ATHENA_COBALT_P2 from "../assets/images/Glossy/ATHENA_COBALT_P2.jpeg";
import ATHENA_COBALT_P3 from "../assets/images/Glossy/ATHENA_COBALT_P3.jpeg";
import ATHENA_COBALT_P4 from "../assets/images/Glossy/ATHENA_COBALT_P4.jpeg";

import ATLAS_CREMA_P1 from "../assets/images/Glossy/ATLAS_CREMA_P1.jpeg";
import ATLAS_CREMA_P2 from "../assets/images/Glossy/ATLAS_CREMA_P2.jpeg";
import ATLAS_CREMA_P3 from "../assets/images/Glossy/ATLAS_CREMA_P3.jpeg";
import ATLAS_CREMA_P4 from "../assets/images/Glossy/ATLAS_CREMA_P4.jpeg";
import ATLAS_CREMA_P5 from "../assets/images/Glossy/ATLAS_CREMA_P5.jpeg";

import AUK_GRESS_P1 from "../assets/images/Glossy/AUK_GRESS_P1.jpeg";
import AUK_GRESS_P2 from "../assets/images/Glossy/AUK_GRESS_P2.jpeg";
import AUK_GRESS_P3 from "../assets/images/Glossy/AUK_GRESS_P3.jpeg";
import AUK_GRESS_P4 from "../assets/images/Glossy/AUK_GRESS_P4.jpeg";

import AURA_GOLD_P1 from "../assets/images/Glossy/AURA_GOLD_P1.jpeg";
import AURA_GOLD_P2 from "../assets/images/Glossy/AURA_GOLD_P2.jpeg";
import AURA_GOLD_P3 from "../assets/images/Glossy/AURA_GOLD_P3.jpeg";
import AURA_GOLD_P4 from "../assets/images/Glossy/AURA_GOLD_P4.jpeg";

import AURA_STATURIO_P1 from "../assets/images/Glossy/AURA_STATURIO_P1.jpeg";
import AURA_STATURIO_P2 from "../assets/images/Glossy/AURA_STATURIO_P2.jpeg";
import AURA_STATURIO_P3 from "../assets/images/Glossy/AURA_STATURIO_P3.jpeg";
import AURA_STATURIO_P4 from "../assets/images/Glossy/AURA_STATURIO_P4.jpeg";

import AZUL_CREMA_P1 from "../assets/images/Glossy/AZUL_CREMA_P1.jpeg";
import AZUL_CREMA_P2 from "../assets/images/Glossy/AZUL_CREMA_P2.jpeg";
import AZUL_CREMA_P3 from "../assets/images/Glossy/AZUL_CREMA_P3.jpeg";
import AZUL_CREMA_P4 from "../assets/images/Glossy/AZUL_CREMA_P4.jpeg";
import AZUL_CREMA_P5 from "../assets/images/Glossy/AZUL_CREMA_P5.jpeg";
import AZUL_CREMA_P6 from "../assets/images/Glossy/AZUL_CREMA_P6.jpeg";

import BALI_GREY_P1 from "../assets/images/Glossy/BALI_GREY_P1.jpeg";
import BALI_GREY_P2 from "../assets/images/Glossy/BALI_GREY_P2.jpeg";
import BALI_GREY_P3 from "../assets/images/Glossy/BALI_GREY_P3.jpeg";
import BALI_GREY_P4 from "../assets/images/Glossy/BALI_GREY_P4.jpeg";

import BEIGE_OCEAN_P1 from "../assets/images/Glossy/BEIGE_OCEAN_P1.jpeg";
import BEIGE_OCEAN_P2 from "../assets/images/Glossy/BEIGE_OCEAN_P2.jpeg";
import BEIGE_OCEAN_P3 from "../assets/images/Glossy/BEIGE_OCEAN_P3.jpeg";
import BEIGE_OCEAN_P4 from "../assets/images/Glossy/BEIGE_OCEAN_P4.jpeg";

import BELMONT_BEIGE_P1 from "../assets/images/Glossy/BELMONT_BEIGE_P1.jpeg";
import BELMONT_BEIGE_P2 from "../assets/images/Glossy/BELMONT_BEIGE_P2.jpeg";
import BELMONT_BEIGE_P3 from "../assets/images/Glossy/BELMONT_BEIGE_P3.jpeg";
import BELMONT_BEIGE_P4 from "../assets/images/Glossy/BELMONT_BEIGE_P4.jpeg";

import BELMONT_BROWN_P1 from "../assets/images/Glossy/BELMONT_BROWN_P1.jpeg";
import BELMONT_BROWN_P2 from "../assets/images/Glossy/BELMONT_BROWN_P2.jpeg";
import BELMONT_BROWN_P3 from "../assets/images/Glossy/BELMONT_BROWN_P3.jpeg";
import BELMONT_BROWN_P4 from "../assets/images/Glossy/BELMONT_BROWN_P4.jpeg";

import BELMONT_GREY_P1 from "../assets/images/Glossy/BELMONT_GREY_P1.jpeg";
import BELMONT_GREY_P2 from "../assets/images/Glossy/BELMONT_GREY_P2.jpeg";
import BELMONT_GREY_P3 from "../assets/images/Glossy/BELMONT_GREY_P3.jpeg";
import BELMONT_GREY_P4 from "../assets/images/Glossy/BELMONT_GREY_P4.jpeg";

import BELMONT_WHITE_P1 from "../assets/images/Glossy/BELMONT_WHITE_P1.jpeg";
import BELMONT_WHITE_P2 from "../assets/images/Glossy/BELMONT_WHITE_P2.jpeg";
import BELMONT_WHITE_P3 from "../assets/images/Glossy/BELMONT_WHITE_P3.jpeg";
import BELMONT_WHITE_P4 from "../assets/images/Glossy/BELMONT_WHITE_P4.jpeg";

import BETON_GREY_P1 from "../assets/images/Glossy/BETON_GREY_P1.jpeg";
import BETON_GREY_P2 from "../assets/images/Glossy/BETON_GREY_P2.jpeg";
import BETON_GREY_P3 from "../assets/images/Glossy/BETON_GREY_P3.jpeg";
import BETON_GREY_P4 from "../assets/images/Glossy/BETON_GREY_P4.jpeg";

import BETTLE_TRAVENTINO_P1 from "../assets/images/Glossy/BETTLE_TRAVENTINO_P1.jpeg";
import BETTLE_TRAVENTINO_P2 from "../assets/images/Glossy/BETTLE_TRAVENTINO_P2.jpeg";
import BETTLE_TRAVENTINO_P3 from "../assets/images/Glossy/BETTLE_TRAVENTINO_P3.jpeg";
import BETTLE_TRAVENTINO_P4 from "../assets/images/Glossy/BETTLE_TRAVENTINO_P4.jpeg";

import BIANCO_OCEAN_P1 from "../assets/images/Glossy/BIANCO_OCEAN_P1.jpeg";
import BIANCO_OCEAN_P2 from "../assets/images/Glossy/BIANCO_OCEAN_P2.jpeg";
import BIANCO_OCEAN_P3 from "../assets/images/Glossy/BIANCO_OCEAN_P3.jpeg";
import BIANCO_OCEAN_P4 from "../assets/images/Glossy/BIANCO_OCEAN_P4.jpeg";

import BIGETT_BLUE_P1 from "../assets/images/Glossy/BIGETT_BLUE_P1.jpeg";
import BIGETT_BLUE_P2 from "../assets/images/Glossy/BIGETT_BLUE_P2.jpeg";
import BIGETT_BLUE_P3 from "../assets/images/Glossy/BIGETT_BLUE_P3.jpeg";

import BIGETT_BROWN_P1 from "../assets/images/Glossy/BIGETT_BROWN_P1.jpeg";
import BIGETT_BROWN_P2 from "../assets/images/Glossy/BIGETT_BROWN_P2.jpeg";
import BIGETT_BROWN_P3 from "../assets/images/Glossy/BIGETT_BROWN_P3.jpeg";

import BIGETT_CREMA_P1 from "../assets/images/Glossy/BIGETT_CREMA_P1.jpeg";
import BIGETT_CREMA_P2 from "../assets/images/Glossy/BIGETT_CREMA_P2.jpeg";
import BIGETT_CREMA_P3 from "../assets/images/Glossy/BIGETT_CREMA_P3.jpeg";

import BIGETT_GREY_P1 from "../assets/images/Glossy/BIGETT_GREY_P1.jpeg";
import BIGETT_GREY_P2 from "../assets/images/Glossy/BIGETT_GREY_P2.jpeg";
import BIGETT_GREY_P3 from "../assets/images/Glossy/BIGETT_GREY_P3.jpeg";

import BLUE_OCEAN_P1 from "../assets/images/Glossy/BLUE_OCEAN_P1.jpeg";
import BLUE_OCEAN_P2 from "../assets/images/Glossy/BLUE_OCEAN_P2.jpeg";
import BLUE_OCEAN_P3 from "../assets/images/Glossy/BLUE_OCEAN_P3.jpeg";
import BLUE_OCEAN_P4 from "../assets/images/Glossy/BLUE_OCEAN_P4.jpeg";

import BOSCO_AURORA_P1 from "../assets/images/Glossy/BOSCO_AURORA_P1.jpeg";
import BOSCO_AURORA_P2 from "../assets/images/Glossy/BOSCO_AURORA_P2.jpeg";
import BOSCO_AURORA_P3 from "../assets/images/Glossy/BOSCO_AURORA_P3.jpeg";
import BOSCO_AURORA_P4 from "../assets/images/Glossy/BOSCO_AURORA_P4.jpeg";

import BOSCO_NATURAL_P1 from "../assets/images/Glossy/BOSCO_NATURAL_P1.jpeg";
import BOSCO_NATURAL_P2 from "../assets/images/Glossy/BOSCO_NATURAL_P2.jpeg";
import BOSCO_NATURAL_P3 from "../assets/images/Glossy/BOSCO_NATURAL_P3.jpeg";
import BOSCO_NATURAL_P4 from "../assets/images/Glossy/BOSCO_NATURAL_P4.jpeg";

import BOTTO_LIGHT_P1 from "../assets/images/Glossy/BOTTO_LIGHT_P1.jpeg";
import BOTTO_LIGHT_P2 from "../assets/images/Glossy/BOTTO_LIGHT_P2.jpeg";
import BOTTO_LIGHT_P3 from "../assets/images/Glossy/BOTTO_LIGHT_P3.jpeg";
import BOTTO_LIGHT_P4 from "../assets/images/Glossy/BOTTO_LIGHT_P4.jpeg";
import BOTTO_LIGHT_P5 from "../assets/images/Glossy/BOTTO_LIGHT_P5.jpeg";

import BOTTO_MARFIL_P1 from "../assets/images/Glossy/BOTTO_MARFIL_P1.jpeg";
import BOTTO_MARFIL_P2 from "../assets/images/Glossy/BOTTO_MARFIL_P2.jpeg";
import BOTTO_MARFIL_P3 from "../assets/images/Glossy/BOTTO_MARFIL_P3.jpeg";
import BOTTO_MARFIL_P4 from "../assets/images/Glossy/BOTTO_MARFIL_P4.jpeg";

import BOTTOCHINO_BIANCO_P1 from "../assets/images/Glossy/BOTTOCHINO_BIANCO_P1.jpeg";
import BOTTOCHINO_BIANCO_P2 from "../assets/images/Glossy/BOTTOCHINO_BIANCO_P2.jpeg";
import BOTTOCHINO_BIANCO_P3 from "../assets/images/Glossy/BOTTOCHINO_BIANCO_P3.jpeg";
import BOTTOCHINO_BIANCO_P4 from "../assets/images/Glossy/BOTTOCHINO_BIANCO_P4.jpeg";

import BOTTOCHINO_CLASSICO from "../assets/images/Glossy/BOTTOCHINO_CLASSICO.jpeg";

import BOTTOCHINO_CREMA_P1 from "../assets/images/Glossy/BOTTOCHINO_CREMA_P1.jpeg";
import BOTTOCHINO_CREMA_P2 from "../assets/images/Glossy/BOTTOCHINO_CREMA_P2.jpeg";
import BOTTOCHINO_CREMA_P3 from "../assets/images/Glossy/BOTTOCHINO_CREMA_P3.jpeg";
import BOTTOCHINO_CREMA_P4 from "../assets/images/Glossy/BOTTOCHINO_CREMA_P4.jpeg";

import BRESCIA_AURORA_P1 from "../assets/images/Glossy/BRESCIA_AURORA_P1.jpeg";
import BRESCIA_AURORA_P2 from "../assets/images/Glossy/BRESCIA_AURORA_P2.jpeg";
import BRESCIA_AURORA_P3 from "../assets/images/Glossy/BRESCIA_AURORA_P3.jpeg";
import BRESCIA_AURORA_P4 from "../assets/images/Glossy/BRESCIA_AURORA_P4.jpeg";
import BRESCIA_AURORA_P5 from "../assets/images/Glossy/BRESCIA_AURORA_P5.jpeg";
import BRESCIA_AURORA_P6 from "../assets/images/Glossy/BRESCIA_AURORA_P6.jpeg";
import BRESCIA_AURORA_P7 from "../assets/images/Glossy/BRESCIA_AURORA_P7.jpeg";
import BRESCIA_AURORA_P8 from "../assets/images/Glossy/BRESCIA_AURORA_P8.jpeg";

import BRESCIA_NATURAL_P1 from "../assets/images/Glossy/BRESCIA_NATURAL_P1.jpeg";
import BRESCIA_NATURAL_P2 from "../assets/images/Glossy/BRESCIA_NATURAL_P2.jpeg";
import BRESCIA_NATURAL_P3 from "../assets/images/Glossy/BRESCIA_NATURAL_P3.jpeg";
import BRESCIA_NATURAL_P4 from "../assets/images/Glossy/BRESCIA_NATURAL_P4.jpeg";
import BRESCIA_NATURAL_P5 from "../assets/images/Glossy/BRESCIA_NATURAL_P5.jpeg";
import BRESCIA_NATURAL_P6 from "../assets/images/Glossy/BRESCIA_NATURAL_P6.jpeg";
import BRESCIA_NATURAL_P7 from "../assets/images/Glossy/BRESCIA_NATURAL_P7.jpeg";
import BRESCIA_NATURAL_P8 from "../assets/images/Glossy/BRESCIA_NATURAL_P8.jpeg";

import BRICK_ONIX_BEIGE_P1 from "../assets/images/Glossy/BRICK_ONIX_BEIGE_P1.jpeg";
import BRICK_ONIX_BEIGE_P2 from "../assets/images/Glossy/BRICK_ONIX_BEIGE_P2.jpeg";
import BRICK_ONIX_BEIGE_P3 from "../assets/images/Glossy/BRICK_ONIX_BEIGE_P3.jpeg";
import BRICK_ONIX_BEIGE_P4 from "../assets/images/Glossy/BRICK_ONIX_BEIGE_P4.jpeg";
import BRICK_ONIX_BEIGE_P5 from "../assets/images/Glossy/BRICK_ONIX_BEIGE_P5.jpeg";

import BRICK_ONIX_BIANCO_P1 from "../assets/images/Glossy/BRICK_ONIX_BIANCO_P1.jpeg";
import BRICK_ONIX_BIANCO_P2 from "../assets/images/Glossy/BRICK_ONIX_BIANCO_P2.jpeg";
import BRICK_ONIX_BIANCO_P3 from "../assets/images/Glossy/BRICK_ONIX_BIANCO_P3.jpeg";
import BRICK_ONIX_BIANCO_P4 from "../assets/images/Glossy/BRICK_ONIX_BIANCO_P4.jpeg";
import BRICK_ONIX_BIANCO_P5 from "../assets/images/Glossy/BRICK_ONIX_BIANCO_P5.jpeg";

import BRILLO_BEIGE_P1 from "../assets/images/Glossy/BRILLO_BEIGE_P1.jpeg";
import BRILLO_BEIGE_P2 from "../assets/images/Glossy/BRILLO_BEIGE_P2.jpeg";
import BRILLO_BEIGE_P3 from "../assets/images/Glossy/BRILLO_BEIGE_P3.jpeg";
import BRILLO_BEIGE_P4 from "../assets/images/Glossy/BRILLO_BEIGE_P4.jpeg";

import BRILLO_WHITE_P1 from "../assets/images/Glossy/BRILLO_WHITE_P1.jpeg";
import BRILLO_WHITE_P2 from "../assets/images/Glossy/BRILLO_WHITE_P2.jpeg";
import BRILLO_WHITE_P3 from "../assets/images/Glossy/BRILLO_WHITE_P3.jpeg";
import BRILLO_WHITE_P4 from "../assets/images/Glossy/BRILLO_WHITE_P4.jpeg";

import BRUMA_BIANCO_P1 from "../assets/images/Glossy/BRUMA_BIANCO_P1.jpeg";
import BRUMA_BIANCO_P2 from "../assets/images/Glossy/BRUMA_BIANCO_P2.jpeg";
import BRUMA_BIANCO_P3 from "../assets/images/Glossy/BRUMA_BIANCO_P3.jpeg";
import BRUMA_BIANCO_P4 from "../assets/images/Glossy/BRUMA_BIANCO_P4.jpeg";
import BRUMA_BIANCO_P5 from "../assets/images/Glossy/BRUMA_BIANCO_P5.jpeg";

import BRUMA_CREMA_P1 from "../assets/images/Glossy/BRUMA_CREMA_P1.jpeg";
import BRUMA_CREMA_P2 from "../assets/images/Glossy/BRUMA_CREMA_P2.jpeg";
import BRUMA_CREMA_P3 from "../assets/images/Glossy/BRUMA_CREMA_P3.jpeg";
import BRUMA_CREMA_P4 from "../assets/images/Glossy/BRUMA_CREMA_P4.jpeg";
import BRUMA_CREMA_P5 from "../assets/images/Glossy/BRUMA_CREMA_P5.jpeg";

import BRUMA_GREY_P1 from "../assets/images/Glossy/BRUMA_GREY_P1.jpeg";
import BRUMA_GREY_P2 from "../assets/images/Glossy/BRUMA_GREY_P2.jpeg";
import BRUMA_GREY_P3 from "../assets/images/Glossy/BRUMA_GREY_P3.jpeg";
import BRUMA_GREY_P4 from "../assets/images/Glossy/BRUMA_GREY_P4.jpeg";
import BRUMA_GREY_P5 from "../assets/images/Glossy/BRUMA_GREY_P5.jpeg";

import BURBERRY_BEIGE_P1 from "../assets/images/Glossy/BURBERRY_BEIGE_P1.jpeg";
import BURBERRY_BEIGE_P2 from "../assets/images/Glossy/BURBERRY_BEIGE_P2.jpeg";
import BURBERRY_BEIGE_P3 from "../assets/images/Glossy/BURBERRY_BEIGE_P3.jpeg";
import BURBERRY_BEIGE_P4 from "../assets/images/Glossy/BURBERRY_BEIGE_P4.jpeg";
import BURBERRY_BEIGE_P5 from "../assets/images/Glossy/BURBERRY_BEIGE_P5.jpeg";

import BURBERRY_CHOCO_P1 from "../assets/images/Glossy/BURBERRY_CHOCO_P1.jpeg";
import BURBERRY_CHOCO_P2 from "../assets/images/Glossy/BURBERRY_CHOCO_P2.jpeg";
import BURBERRY_CHOCO_P3 from "../assets/images/Glossy/BURBERRY_CHOCO_P3.jpeg";
import BURBERRY_CHOCO_P4 from "../assets/images/Glossy/BURBERRY_CHOCO_P4.jpeg";
import BURBERRY_CHOCO_P5 from "../assets/images/Glossy/BURBERRY_CHOCO_P5.jpeg";

import BURBERRY_CREMA_P1 from "../assets/images/Glossy/BURBERRY_CREMA_P1.jpeg";
import BURBERRY_CREMA_P2 from "../assets/images/Glossy/BURBERRY_CREMA_P2.jpeg";
import BURBERRY_CREMA_P3 from "../assets/images/Glossy/BURBERRY_CREMA_P3.jpeg";
import BURBERRY_CREMA_P4 from "../assets/images/Glossy/BURBERRY_CREMA_P4.jpeg";
import BURBERRY_CREMA_P5 from "../assets/images/Glossy/BURBERRY_CREMA_P5.jpeg";

import BURBERRY_GREY_P1 from "../assets/images/Glossy/BURBERRY_GREY_P1.jpeg";
import BURBERRY_GREY_P2 from "../assets/images/Glossy/BURBERRY_GREY_P2.jpeg";
import BURBERRY_GREY_P3 from "../assets/images/Glossy/BURBERRY_GREY_P3.jpeg";
import BURBERRY_GREY_P4 from "../assets/images/Glossy/BURBERRY_GREY_P4.jpeg";
import BURBERRY_GREY_P5 from "../assets/images/Glossy/BURBERRY_GREY_P5.jpeg";

import BURKINA_BEIGE_P1 from "../assets/images/Glossy/BURKINA_BEIGE_P1.jpeg";
import BURKINA_BEIGE_P2 from "../assets/images/Glossy/BURKINA_BEIGE_P2.jpeg";
import BURKINA_BEIGE_P3 from "../assets/images/Glossy/BURKINA_BEIGE_P3.jpeg";
import BURKINA_BEIGE_P4 from "../assets/images/Glossy/BURKINA_BEIGE_P4.jpeg";

import BURKINA_BLUE_P1 from "../assets/images/Glossy/BURKINA_BLUE_P1.jpeg";
import BURKINA_BLUE_P2 from "../assets/images/Glossy/BURKINA_BLUE_P1.jpeg";
import BURKINA_BLUE_P3 from "../assets/images/Glossy/BURKINA_BLUE_P1.jpeg";
import BURKINA_BLUE_P4 from "../assets/images/Glossy/BURKINA_BLUE_P1.jpeg";

import CALCUTTA_BEIGE_P1 from "../assets/images/Glossy/CALCUTTA_BEIGE_P1.jpeg";
import CALCUTTA_BEIGE_P2 from "../assets/images/Glossy/CALCUTTA_BEIGE_P2.jpeg";
import CALCUTTA_BEIGE_P3 from "../assets/images/Glossy/CALCUTTA_BEIGE_P3.jpeg";
import CALCUTTA_BEIGE_P4 from "../assets/images/Glossy/CALCUTTA_BEIGE_P4.jpeg";

import CALCUTTA_WHITE_P1 from "../assets/images/Glossy/CALCUTTA_WHITE_P1.jpeg";
import CALCUTTA_WHITE_P2 from "../assets/images/Glossy/CALCUTTA_WHITE_P2.jpeg";
import CALCUTTA_WHITE_P3 from "../assets/images/Glossy/CALCUTTA_WHITE_P3.jpeg";
import CALCUTTA_WHITE_P4 from "../assets/images/Glossy/CALCUTTA_WHITE_P4.jpeg";
import CALCUTTA_WHITE_P5 from "../assets/images/Glossy/CALCUTTA_WHITE_P5.jpeg";

import CALIPSO_BEIGE_P1 from "../assets/images/Glossy/CALIPSO_BEIGE_P1.jpeg";
import CALIPSO_BEIGE_P2 from "../assets/images/Glossy/CALIPSO_BEIGE_P2.jpeg";
import CALIPSO_BEIGE_P3 from "../assets/images/Glossy/CALIPSO_BEIGE_P3.jpeg";
import CALIPSO_BEIGE_P4 from "../assets/images/Glossy/CALIPSO_BEIGE_P4.jpeg";

import CALIPSO_CREMA_P1 from "../assets/images/Glossy/CALIPSO_CREMA_P1.jpeg";
import CALIPSO_CREMA_P2 from "../assets/images/Glossy/CALIPSO_CREMA_P2.jpeg";
import CALIPSO_CREMA_P3 from "../assets/images/Glossy/CALIPSO_CREMA_P3.jpeg";
import CALIPSO_CREMA_P4 from "../assets/images/Glossy/CALIPSO_CREMA_P4.jpeg";

import CALIPSO_GREY_P1 from "../assets/images/Glossy/CALIPSO_GREY_P1.jpeg";
import CALIPSO_GREY_P2 from "../assets/images/Glossy/CALIPSO_GREY_P2.jpeg";
import CALIPSO_GREY_P3 from "../assets/images/Glossy/CALIPSO_GREY_P3.jpeg";
import CALIPSO_GREY_P4 from "../assets/images/Glossy/CALIPSO_GREY_P4.jpeg";

import CALLIS_CREMA_P1 from "../assets/images/Glossy/CALLIS_CREMA_P1.jpeg";
import CALLIS_CREMA_P2 from "../assets/images/Glossy/CALLIS_CREMA_P2.jpeg";
import CALLIS_CREMA_P3 from "../assets/images/Glossy/CALLIS_CREMA_P3.jpeg";
import CALLIS_CREMA_P4 from "../assets/images/Glossy/CALLIS_CREMA_P4.jpeg";

import CALLIS_WHITE_P1 from "../assets/images/Glossy/CALLIS_WHITE_P1.jpeg";
import CALLIS_WHITE_P2 from "../assets/images/Glossy/CALLIS_WHITE_P2.jpeg";
import CALLIS_WHITE_P3 from "../assets/images/Glossy/CALLIS_WHITE_P3.jpeg";
import CALLIS_WHITE_P4 from "../assets/images/Glossy/CALLIS_WHITE_P4.jpeg";

import CARARA_WHITE_P1 from "../assets/images/Glossy/CARARA_WHITE_P1.jpeg";
import CARARA_WHITE_P2 from "../assets/images/Glossy/CARARA_WHITE_P2.jpeg";
import CARARA_WHITE_P3 from "../assets/images/Glossy/CARARA_WHITE_P3.jpeg";
import CARARA_WHITE_P4 from "../assets/images/Glossy/CARARA_WHITE_P4.jpeg";
import CARARA_WHITE_P5 from "../assets/images/Glossy/CARARA_WHITE_P5.jpeg";

import CARRARA_FOG_BROWN from "../assets/images/Glossy/CARRARA_FOG_BROWN.jpeg";

import CASTILIA_GOLD_P1 from "../assets/images/Glossy/CASTILIA_GOLD_P1.jpeg";
import CASTILIA_GOLD_P2 from "../assets/images/Glossy/CASTILIA_GOLD_P2.jpeg";
import CASTILIA_GOLD_P3 from "../assets/images/Glossy/CASTILIA_GOLD_P3.jpeg";
import CASTILIA_GOLD_P4 from "../assets/images/Glossy/CASTILIA_GOLD_P4.jpeg";
import CASTILIA_GOLD_P5 from "../assets/images/Glossy/CASTILIA_GOLD_P5.jpeg";

import CHOCO_OCEAN_P1 from "../assets/images/Glossy/CHOCO_OCEAN_P1.jpeg";
import CHOCO_OCEAN_P2 from "../assets/images/Glossy/CHOCO_OCEAN_P2.jpeg";
import CHOCO_OCEAN_P3 from "../assets/images/Glossy/CHOCO_OCEAN_P3.jpeg";
import CHOCO_OCEAN_P4 from "../assets/images/Glossy/CHOCO_OCEAN_P4.jpeg";

import CIERA_MARFIL_LIGHT_P1 from "../assets/images/Glossy/CIERA_MARFIL_LIGHT_P1.jpeg";
import CIERA_MARFIL_LIGHT_P2 from "../assets/images/Glossy/CIERA_MARFIL_LIGHT_P2.jpeg";
import CIERA_MARFIL_LIGHT_P3 from "../assets/images/Glossy/CIERA_MARFIL_LIGHT_P3.jpeg";
import CIERA_MARFIL_LIGHT_P4 from "../assets/images/Glossy/CIERA_MARFIL_LIGHT_P4.jpeg";

import CLASSIC_CREMA from "../assets/images/Glossy/CLASSIC_CREMA.jpeg";

import CLOUD_BLUE_P1 from "../assets/images/Glossy/CLOUD_BLUE_P1.jpeg";
import CLOUD_BLUE_P2 from "../assets/images/Glossy/CLOUD_BLUE_P2.jpeg";
import CLOUD_BLUE_P3 from "../assets/images/Glossy/CLOUD_BLUE_P3.jpeg";
import CLOUD_BLUE_P4 from "../assets/images/Glossy/CLOUD_BLUE_P4.jpeg";

import CLOUD_WHITE_P1 from "../assets/images/Glossy/CLOUD_WHITE_P1.jpeg";
import CLOUD_WHITE_P2 from "../assets/images/Glossy/CLOUD_WHITE_P2.jpeg";
import CLOUD_WHITE_P3 from "../assets/images/Glossy/CLOUD_WHITE_P3.jpeg";
import CLOUD_WHITE_P4 from "../assets/images/Glossy/CLOUD_WHITE_P4.jpeg";

import COLOUR_ONYX_P1 from "../assets/images/Glossy/COLOUR_ONYX_P1.jpeg";
import COLOUR_ONYX_P2 from "../assets/images/Glossy/COLOUR_ONYX_P2.jpeg";
import COLOUR_ONYX_P3 from "../assets/images/Glossy/COLOUR_ONYX_P3.jpeg";
import COLOUR_ONYX_P4 from "../assets/images/Glossy/COLOUR_ONYX_P4.jpeg";

import COVENALO_CREMA_P1 from "../assets/images/Glossy/COVENALO_CREMA_P1.jpeg";
import COVENALO_CREMA_P2 from "../assets/images/Glossy/COVENALO_CREMA_P2.jpeg";
import COVENALO_CREMA_P3 from "../assets/images/Glossy/COVENALO_CREMA_P3.jpeg";
import COVENALO_CREMA_P4 from "../assets/images/Glossy/COVENALO_CREMA_P4.jpeg";
import COVENALO_CREMA_P5 from "../assets/images/Glossy/COVENALO_CREMA_P4.jpeg";

import CRACKEL_STATURIO_P1 from "../assets/images/Glossy/CRACKEL_STATURIO_P1.jpeg";
import CRACKEL_STATURIO_P2 from "../assets/images/Glossy/CRACKEL_STATURIO_P2.jpeg";
import CRACKEL_STATURIO_P3 from "../assets/images/Glossy/CRACKEL_STATURIO_P3.jpeg";
import CRACKEL_STATURIO_P4 from "../assets/images/Glossy/CRACKEL_STATURIO_P4.jpeg";
import CRACKEL_STATURIO_P5 from "../assets/images/Glossy/CRACKEL_STATURIO_P4.jpeg";

import CREMA_MARFIL_UTD_P1 from "../assets/images/Glossy/CREMA_MARFIL_UTD_P1.jpeg";
import CREMA_MARFIL_UTD_P2 from "../assets/images/Glossy/CREMA_MARFIL_UTD_P2.jpeg";
import CREMA_MARFIL_UTD_P3 from "../assets/images/Glossy/CREMA_MARFIL_UTD_P3.jpeg";

import CRETONNE_BEIGE_P1 from "../assets/images/Glossy/CRETONNE_BEIGE_P1.jpeg";
import CRETONNE_BEIGE_P2 from "../assets/images/Glossy/CRETONNE_BEIGE_P2.jpeg";
import CRETONNE_BEIGE_P3 from "../assets/images/Glossy/CRETONNE_BEIGE_P3.jpeg";
import CRETONNE_BEIGE_P4 from "../assets/images/Glossy/CRETONNE_BEIGE_P4.jpeg";

import CRETONNE_CREMA_P1 from "../assets/images/Glossy/CRETONNE_CREMA_P1.jpeg";
import CRETONNE_CREMA_P2 from "../assets/images/Glossy/CRETONNE_CREMA_P2.jpeg";
import CRETONNE_CREMA_P3 from "../assets/images/Glossy/CRETONNE_CREMA_P3.jpeg";
import CRETONNE_CREMA_P4 from "../assets/images/Glossy/CRETONNE_CREMA_P4.jpeg";

import CRIST_BEIGE_P1 from "../assets/images/Glossy/CRIST_BEIGE_P1.jpeg";
import CRIST_BEIGE_P2 from "../assets/images/Glossy/CRIST_BEIGE_P2.jpeg";
import CRIST_BEIGE_P3 from "../assets/images/Glossy/CRIST_BEIGE_P3.jpeg";
import CRIST_BEIGE_P4 from "../assets/images/Glossy/CRIST_BEIGE_P4.jpeg";

import CRIST_GREY_P1 from "../assets/images/Glossy/CRIST_GREY_P1.jpeg";
import CRIST_GREY_P2 from "../assets/images/Glossy/CRIST_GREY_P2.jpeg";
import CRIST_GREY_P3 from "../assets/images/Glossy/CRIST_GREY_P3.jpeg";
import CRIST_GREY_P4 from "../assets/images/Glossy/CRIST_GREY_P4.jpeg";

import CRYSTAL_ONYX_BROWN_P1 from "../assets/images/Glossy/CRYSTAL_ONYX_BROWN_P1.jpeg";
import CRYSTAL_ONYX_BROWN_P2 from "../assets/images/Glossy/CRYSTAL_ONYX_BROWN_P2.jpeg";
import CRYSTAL_ONYX_BROWN_P3 from "../assets/images/Glossy/CRYSTAL_ONYX_BROWN_P3.jpeg";
import CRYSTAL_ONYX_BROWN_P4 from "../assets/images/Glossy/CRYSTAL_ONYX_BROWN_P4.jpeg";
import CRYSTAL_ONYX_BROWN_P5 from "../assets/images/Glossy/CRYSTAL_ONYX_BROWN_P5.jpeg";

import CRYSTALONYX_BROWN_P1 from "../assets/images/Glossy/CRYSTALONYX_BROWN_P1.jpeg";
import CRYSTALONYX_BROWN_P2 from "../assets/images/Glossy/CRYSTALONYX_BROWN_P2.jpeg";
import CRYSTALONYX_BROWN_P3 from "../assets/images/Glossy/CRYSTALONYX_BROWN_P3.jpeg";
import CRYSTALONYX_BROWN_P4 from "../assets/images/Glossy/CRYSTALONYX_BROWN_P4.jpeg";
import CRYSTALONYX_BROWN_P5 from "../assets/images/Glossy/CRYSTALONYX_BROWN_P5.jpeg";
import CRYSTALONYX_BROWN_P6 from "../assets/images/Glossy/CRYSTALONYX_BROWN_P6.jpeg";

import CUNTO_STATURIO_P1 from "../assets/images/Glossy/CUNTO_STATURIO_P1.jpeg";
import CUNTO_STATURIO_P2 from "../assets/images/Glossy/CUNTO_STATURIO_P2.jpeg";
import CUNTO_STATURIO_P3 from "../assets/images/Glossy/CUNTO_STATURIO_P3.jpeg";
import CUNTO_STATURIO_P4 from "../assets/images/Glossy/CUNTO_STATURIO_P4.jpeg";
import CUNTO_STATURIO_P5 from "../assets/images/Glossy/CUNTO_STATURIO_P5.jpeg";
import CUNTO_STATURIO_P6 from "../assets/images/Glossy/CUNTO_STATURIO_P6.jpeg";
import CUNTO_STATURIO_P7 from "../assets/images/Glossy/CUNTO_STATURIO_P7.jpeg";

import DESERT_DIANA_P1 from "../assets/images/Glossy/DESERT_DIANA_P1.jpeg";
import DESERT_DIANA_P2 from "../assets/images/Glossy/DESERT_DIANA_P2.jpeg";
import DESERT_DIANA_P3 from "../assets/images/Glossy/DESERT_DIANA_P3.jpeg";
import DESERT_DIANA_P4 from "../assets/images/Glossy/DESERT_DIANA_P4.jpeg";

import DESERT_DIANA_GREY_P1 from "../assets/images/Glossy/DESERT_DIANA_GREY_P1.jpeg";
import DESERT_DIANA_GREY_P2 from "../assets/images/Glossy/DESERT_DIANA_GREY_P2.jpeg";
import DESERT_DIANA_GREY_P3 from "../assets/images/Glossy/DESERT_DIANA_GREY_P3.jpeg";
import DESERT_DIANA_GREY_P4 from "../assets/images/Glossy/DESERT_DIANA_GREY_P4.jpeg";

import DESERT_DIANA_LIGHT_P1 from "../assets/images/Glossy/DESERT_DIANA_LIGHT_P1.jpeg";
import DESERT_DIANA_LIGHT_P2 from "../assets/images/Glossy/DESERT_DIANA_LIGHT_P2.jpeg";
import DESERT_DIANA_LIGHT_P3 from "../assets/images/Glossy/DESERT_DIANA_LIGHT_P3.jpeg";
import DESERT_DIANA_LIGHT_P4 from "../assets/images/Glossy/DESERT_DIANA_LIGHT_P4.jpeg";

import DOLLCER_ICE from "../assets/images/Glossy/DOLLCER_ICE.jpeg";

import DOLMEN_BIANCO_P1 from "../assets/images/Glossy/DOLMEN_BIANCO_P1.jpeg";
import DOLMEN_BIANCO_P2 from "../assets/images/Glossy/DOLMEN_BIANCO_P2.jpeg";
import DOLMEN_BIANCO_P3 from "../assets/images/Glossy/DOLMEN_BIANCO_P3.jpeg";
import DOLMEN_BIANCO_P4 from "../assets/images/Glossy/DOLMEN_BIANCO_P4.jpeg";
import DOLMEN_BIANCO_P5 from "../assets/images/Glossy/DOLMEN_BIANCO_P5.jpeg";

import DOLMEN_CHOCO_P1 from "../assets/images/Glossy/DOLMEN_CHOCO_P1.jpeg";
import DOLMEN_CHOCO_P2 from "../assets/images/Glossy/DOLMEN_CHOCO_P2.jpeg";
import DOLMEN_CHOCO_P3 from "../assets/images/Glossy/DOLMEN_CHOCO_P3.jpeg";
import DOLMEN_CHOCO_P4 from "../assets/images/Glossy/DOLMEN_CHOCO_P4.jpeg";
import DOLMEN_CHOCO_P5 from "../assets/images/Glossy/DOLMEN_CHOCO_P5.jpeg";

import DOMENIC_BEIGE_P1 from "../assets/images/Glossy/DOMENIC_BEIGE_P1.jpeg";
import DOMENIC_BEIGE_P2 from "../assets/images/Glossy/DOMENIC_BEIGE_P2.jpeg";
import DOMENIC_BEIGE_P3 from "../assets/images/Glossy/DOMENIC_BEIGE_P3.jpeg";
import DOMENIC_BEIGE_P4 from "../assets/images/Glossy/DOMENIC_BEIGE_P4.jpeg";
import DOMENIC_BEIGE_P5 from "../assets/images/Glossy/DOMENIC_BEIGE_P5.jpeg";

import DOMENIC_CREMA_P1 from "../assets/images/Glossy/DOMENIC_CREMA_P1.jpeg";
import DOMENIC_CREMA_P2 from "../assets/images/Glossy/DOMENIC_CREMA_P2.jpeg";
import DOMENIC_CREMA_P3 from "../assets/images/Glossy/DOMENIC_CREMA_P3.jpeg";
import DOMENIC_CREMA_P4 from "../assets/images/Glossy/DOMENIC_CREMA_P4.jpeg";
import DOMENIC_CREMA_P5 from "../assets/images/Glossy/DOMENIC_CREMA_P5.jpeg";

import DOMENIC_GREY_P1 from "../assets/images/Glossy/DOMENIC_GREY_P1.jpeg";
import DOMENIC_GREY_P2 from "../assets/images/Glossy/DOMENIC_GREY_P2.jpeg";
import DOMENIC_GREY_P3 from "../assets/images/Glossy/DOMENIC_GREY_P3.jpeg";
import DOMENIC_GREY_P4 from "../assets/images/Glossy/DOMENIC_GREY_P4.jpeg";
import DOMENIC_GREY_P5 from "../assets/images/Glossy/DOMENIC_GREY_P5.jpeg";

import DYNA_BEIGE_P1 from "../assets/images/Glossy/DYNA_BEIGE_P1.jpeg";
import DYNA_BEIGE_P2 from "../assets/images/Glossy/DYNA_BEIGE_P2.jpeg";
import DYNA_BEIGE_P3 from "../assets/images/Glossy/DYNA_BEIGE_P3.jpeg";
import DYNA_BEIGE_P4 from "../assets/images/Glossy/DYNA_BEIGE_P4.jpeg";
import DYNA_BEIGE_P5 from "../assets/images/Glossy/DYNA_BEIGE_P5.jpeg";

import DYNA_DISASTER_P1 from "../assets/images/Glossy/DYNA_DISASTER_P1.jpeg";
import DYNA_DISASTER_P2 from "../assets/images/Glossy/DYNA_DISASTER_P2.jpeg";
import DYNA_DISASTER_P3 from "../assets/images/Glossy/DYNA_DISASTER_P3.jpeg";
import DYNA_DISASTER_P4 from "../assets/images/Glossy/DYNA_DISASTER_P4.jpeg";
import DYNA_DISASTER_P5 from "../assets/images/Glossy/DYNA_DISASTER_P5.jpeg";
import DYNA_DISASTER_P6 from "../assets/images/Glossy/DYNA_DISASTER_P6.jpeg";

import DYNA_FANTCY_P1 from "../assets/images/Glossy/DYNA_FANTCY_P1.jpeg";
import DYNA_FANTCY_P2 from "../assets/images/Glossy/DYNA_FANTCY_P2.jpeg";
import DYNA_FANTCY_P3 from "../assets/images/Glossy/DYNA_FANTCY_P3.jpeg";
import DYNA_FANTCY_P4 from "../assets/images/Glossy/DYNA_FANTCY_P4.jpeg";
import DYNA_FANTCY_P5 from "../assets/images/Glossy/DYNA_FANTCY_P5.jpeg";

import DYNA_GREY_P1 from "../assets/images/Glossy/DYNA_GREY_P1.jpeg";
import DYNA_GREY_P2 from "../assets/images/Glossy/DYNA_GREY_P2.jpeg";
import DYNA_GREY_P3 from "../assets/images/Glossy/DYNA_GREY_P3.jpeg";
import DYNA_GREY_P4 from "../assets/images/Glossy/DYNA_GREY_P4.jpeg";
import DYNA_GREY_P5 from "../assets/images/Glossy/DYNA_GREY_P5.jpeg";

import DYNA_MULTY_P1 from "../assets/images/Glossy/DYNA_MULTY_P1.jpeg";
import DYNA_MULTY_P2 from "../assets/images/Glossy/DYNA_MULTY_P2.jpeg";
import DYNA_MULTY_P3 from "../assets/images/Glossy/DYNA_MULTY_P3.jpeg";
import DYNA_MULTY_P4 from "../assets/images/Glossy/DYNA_MULTY_P4.jpeg";
import DYNA_MULTY_P5 from "../assets/images/Glossy/DYNA_MULTY_P5.jpeg";

import DYNO_BEIGE_P1 from "../assets/images/Glossy/DYNO_BEIGE_P1.jpeg";
import DYNO_BEIGE_P2 from "../assets/images/Glossy/DYNO_BEIGE_P2.jpeg";
import DYNO_BEIGE_P3 from "../assets/images/Glossy/DYNO_BEIGE_P3.jpeg";
import DYNO_BEIGE_P4 from "../assets/images/Glossy/DYNO_BEIGE_P4.jpeg";
import DYNO_BEIGE_P5 from "../assets/images/Glossy/DYNO_BEIGE_P5.jpeg";
import DYNO_BEIGE_P6 from "../assets/images/Glossy/DYNO_BEIGE_P6.jpeg";

import DYNO_BIANCO_P1 from "../assets/images/Glossy/DYNO_BIANCO_P1.jpeg";
import DYNO_BIANCO_P2 from "../assets/images/Glossy/DYNO_BIANCO_P2.jpeg";
import DYNO_BIANCO_P3 from "../assets/images/Glossy/DYNO_BIANCO_P3.jpeg";
import DYNO_BIANCO_P4 from "../assets/images/Glossy/DYNO_BIANCO_P4.jpeg";
import DYNO_BIANCO_P5 from "../assets/images/Glossy/DYNO_BIANCO_P5.jpeg";
import DYNO_BIANCO_P6 from "../assets/images/Glossy/DYNO_BIANCO_P6.jpeg";

import EARTH_GLITER_ROSSO_P1 from "../assets/images/Glossy/EARTH_GLITER_ROSSO_P1.jpeg";
import EARTH_GLITER_ROSSO_P2 from "../assets/images/Glossy/EARTH_GLITER_ROSSO_P2.jpeg";
import EARTH_GLITER_ROSSO_P3 from "../assets/images/Glossy/EARTH_GLITER_ROSSO_P3.jpeg";
import EARTH_GLITER_ROSSO_P4 from "../assets/images/Glossy/EARTH_GLITER_ROSSO_P4.jpeg";

import EARTH_GLITTER_BEIGE_P1 from "../assets/images/Glossy/EARTH_GLITTER_BEIGE_P1.jpeg";
import EARTH_GLITTER_BEIGE_P2 from "../assets/images/Glossy/EARTH_GLITTER_BEIGE_P2.jpeg";
import EARTH_GLITTER_BEIGE_P3 from "../assets/images/Glossy/EARTH_GLITTER_BEIGE_P3.jpeg";
import EARTH_GLITTER_BEIGE_P4 from "../assets/images/Glossy/EARTH_GLITTER_BEIGE_P4.jpeg";

import ELECTRONICA_P1 from "../assets/images/Glossy/ELECTRONICA_P1.jpeg";
import ELECTRONICA_P2 from "../assets/images/Glossy/ELECTRONICA_P2.jpeg";
import ELECTRONICA_P3 from "../assets/images/Glossy/ELECTRONICA_P3.jpeg";
import ELECTRONICA_P4 from "../assets/images/Glossy/ELECTRONICA_P4.jpeg";

import ELEMENT_BEIGE_P1 from "../assets/images/Glossy/ELEMENT_BEIGE_P1.jpeg";
import ELEMENT_BEIGE_P2 from "../assets/images/Glossy/ELEMENT_BEIGE_P2.jpeg";
import ELEMENT_BEIGE_P3 from "../assets/images/Glossy/ELEMENT_BEIGE_P3.jpeg";
import ELEMENT_BEIGE_P4 from "../assets/images/Glossy/ELEMENT_BEIGE_P4.jpeg";
import ELEMENT_BEIGE_P5 from "../assets/images/Glossy/ELEMENT_BEIGE_P5.jpeg";

import ELEMENT_WHITE_P1 from "../assets/images/Glossy/ELEMENT_WHITE_P1.jpeg";
import ELEMENT_WHITE_P2 from "../assets/images/Glossy/ELEMENT_WHITE_P2.jpeg";
import ELEMENT_WHITE_P3 from "../assets/images/Glossy/ELEMENT_WHITE_P3.jpeg";
import ELEMENT_WHITE_P4 from "../assets/images/Glossy/ELEMENT_WHITE_P4.jpeg";
import ELEMENT_WHITE_P5 from "../assets/images/Glossy/ELEMENT_WHITE_P5.jpeg";

import ERAMOSSA_BEIGE_P1 from "../assets/images/Glossy/ERAMOSSA_BEIGE_P1.jpeg";
import ERAMOSSA_BEIGE_P2 from "../assets/images/Glossy/ERAMOSSA_BEIGE_P2.jpeg";
import ERAMOSSA_BEIGE_P3 from "../assets/images/Glossy/ERAMOSSA_BEIGE_P3.jpeg";
import ERAMOSSA_BEIGE_P4 from "../assets/images/Glossy/ERAMOSSA_BEIGE_P4.jpeg";
import ERAMOSSA_BEIGE_P5 from "../assets/images/Glossy/ERAMOSSA_BEIGE_P5.jpeg";

import ERAMOSSA_TOBACCO_P1 from "../assets/images/Glossy/ERAMOSSA_TOBACCO_P1.jpeg";
import ERAMOSSA_TOBACCO_P2 from "../assets/images/Glossy/ERAMOSSA_TOBACCO_P2.jpeg";
import ERAMOSSA_TOBACCO_P3 from "../assets/images/Glossy/ERAMOSSA_TOBACCO_P3.jpeg";
import ERAMOSSA_TOBACCO_P4 from "../assets/images/Glossy/ERAMOSSA_TOBACCO_P4.jpeg";
import ERAMOSSA_TOBACCO_P5 from "../assets/images/Glossy/ERAMOSSA_TOBACCO_P5.jpeg";

import ESPIRA_BEIGE_P1 from "../assets/images/Glossy/ESPIRA_BEIGE_P1.jpeg";
import ESPIRA_BEIGE_P2 from "../assets/images/Glossy/ESPIRA_BEIGE_P2.jpeg";
import ESPIRA_BEIGE_P3 from "../assets/images/Glossy/ESPIRA_BEIGE_P3.jpeg";
import ESPIRA_BEIGE_P4 from "../assets/images/Glossy/ESPIRA_BEIGE_P4.jpeg";
import ESPIRA_BEIGE_P5 from "../assets/images/Glossy/ESPIRA_BEIGE_P5.jpeg";

import ESPIRA_CREMA_P1 from "../assets/images/Glossy/ESPIRA_CREMA_P1.jpeg";
import ESPIRA_CREMA_P2 from "../assets/images/Glossy/ESPIRA_CREMA_P2.jpeg";
import ESPIRA_CREMA_P3 from "../assets/images/Glossy/ESPIRA_CREMA_P3.jpeg";
import ESPIRA_CREMA_P4 from "../assets/images/Glossy/ESPIRA_CREMA_P4.jpeg";
import ESPIRA_CREMA_P5 from "../assets/images/Glossy/ESPIRA_CREMA_P5.jpeg";

import ESPIRA_GREY_P1 from "../assets/images/Glossy/ESPIRA_GREY_P1.jpeg";
import ESPIRA_GREY_P2 from "../assets/images/Glossy/ESPIRA_GREY_P2.jpeg";
import ESPIRA_GREY_P3 from "../assets/images/Glossy/ESPIRA_GREY_P3.jpeg";
import ESPIRA_GREY_P4 from "../assets/images/Glossy/ESPIRA_GREY_P4.jpeg";
import ESPIRA_GREY_P5 from "../assets/images/Glossy/ESPIRA_GREY_P5.jpeg";

import EXOTIC_EMPRO_BROWN_P1 from "../assets/images/Glossy/EXOTIC_EMPRO_BROWN_P1.jpeg";
import EXOTIC_EMPRO_BROWN_P2 from "../assets/images/Glossy/EXOTIC_EMPRO_BROWN_P2.jpeg";
import EXOTIC_EMPRO_BROWN_P3 from "../assets/images/Glossy/EXOTIC_EMPRO_BROWN_P3.jpeg";
import EXOTIC_EMPRO_BROWN_P4 from "../assets/images/Glossy/EXOTIC_EMPRO_BROWN_P4.jpeg";
import EXOTIC_EMPRO_BROWN_P5 from "../assets/images/Glossy/EXOTIC_EMPRO_BROWN_P5.jpeg";

import EXOTIC_EMPRO_REAL_P1 from "../assets/images/Glossy/EXOTIC_EMPRO_REAL_P1.jpeg";
import EXOTIC_EMPRO_REAL_P2 from "../assets/images/Glossy/EXOTIC_EMPRO_REAL_P2.jpeg";
import EXOTIC_EMPRO_REAL_P3 from "../assets/images/Glossy/EXOTIC_EMPRO_REAL_P3.jpeg";
import EXOTIC_EMPRO_REAL_P4 from "../assets/images/Glossy/EXOTIC_EMPRO_REAL_P4.jpeg";
import EXOTIC_EMPRO_REAL_P5 from "../assets/images/Glossy/EXOTIC_EMPRO_REAL_P5.jpeg";

import FLORENCIA_BEIGE_P1 from "../assets/images/Glossy/FLORENCIA_BEIGE_P1.jpeg";
import FLORENCIA_BEIGE_P2 from "../assets/images/Glossy/FLORENCIA_BEIGE_P2.jpeg";
import FLORENCIA_BEIGE_P3 from "../assets/images/Glossy/FLORENCIA_BEIGE_P3.jpeg";
import FLORENCIA_BEIGE_P4 from "../assets/images/Glossy/FLORENCIA_BEIGE_P4.jpeg";
import FLORENCIA_BEIGE_P5 from "../assets/images/Glossy/FLORENCIA_BEIGE_P5.jpeg";

import FLORENCIA_BIANCO_P1 from "../assets/images/Glossy/FLORENCIA_BIANCO_P1.jpeg";
import FLORENCIA_BIANCO_P2 from "../assets/images/Glossy/FLORENCIA_BIANCO_P2.jpeg";
import FLORENCIA_BIANCO_P3 from "../assets/images/Glossy/FLORENCIA_BIANCO_P3.jpeg";
import FLORENCIA_BIANCO_P4 from "../assets/images/Glossy/FLORENCIA_BIANCO_P4.jpeg";
import FLORENCIA_BIANCO_P5 from "../assets/images/Glossy/FLORENCIA_BIANCO_P5.jpeg";

import FLORENCIA_CEREZO_P1 from "../assets/images/Glossy/FLORENCIA_CEREZO_P1.jpeg";
import FLORENCIA_CEREZO_P2 from "../assets/images/Glossy/FLORENCIA_CEREZO_P2.jpeg";
import FLORENCIA_CEREZO_P3 from "../assets/images/Glossy/FLORENCIA_CEREZO_P3.jpeg";
import FLORENCIA_CEREZO_P4 from "../assets/images/Glossy/FLORENCIA_CEREZO_P4.jpeg";
import FLORENCIA_CEREZO_P5 from "../assets/images/Glossy/FLORENCIA_CEREZO_P5.jpeg";

import FLORENCIA_CREMA_P1 from "../assets/images/Glossy/FLORENCIA_CREMA_P1.jpeg";
import FLORENCIA_CREMA_P2 from "../assets/images/Glossy/FLORENCIA_CREMA_P2.jpeg";
import FLORENCIA_CREMA_P3 from "../assets/images/Glossy/FLORENCIA_CREMA_P3.jpeg";
import FLORENCIA_CREMA_P4 from "../assets/images/Glossy/FLORENCIA_CREMA_P4.jpeg";
import FLORENCIA_CREMA_P5 from "../assets/images/Glossy/FLORENCIA_CREMA_P5.jpeg";

import FLORENCIA_GRIS_P1 from "../assets/images/Glossy/FLORENCIA_GRIS_P1.jpeg";
import FLORENCIA_GRIS_P2 from "../assets/images/Glossy/FLORENCIA_GRIS_P2.jpeg";
import FLORENCIA_GRIS_P3 from "../assets/images/Glossy/FLORENCIA_GRIS_P3.jpeg";
import FLORENCIA_GRIS_P4 from "../assets/images/Glossy/FLORENCIA_GRIS_P4.jpeg";
import FLORENCIA_GRIS_P5 from "../assets/images/Glossy/FLORENCIA_GRIS_P5.jpeg";

import FLORITO_CREMA_P1 from "../assets/images/Glossy/FLORITO_CREMA_P1.jpeg";
import FLORITO_CREMA_P2 from "../assets/images/Glossy/FLORITO_CREMA_P2.jpeg";
import FLORITO_CREMA_P3 from "../assets/images/Glossy/FLORITO_CREMA_P3.jpeg";
import FLORITO_CREMA_P4 from "../assets/images/Glossy/FLORITO_CREMA_P4.jpeg";
import FLORITO_CREMA_P5 from "../assets/images/Glossy/FLORITO_CREMA_P5.jpeg";

import FREEDOM_BEIGE_P1 from "../assets/images/Glossy/FREEDOM_BEIGE_P1.jpeg";
import FREEDOM_BEIGE_P2 from "../assets/images/Glossy/FREEDOM_BEIGE_P2.jpeg";
import FREEDOM_BEIGE_P3 from "../assets/images/Glossy/FREEDOM_BEIGE_P3.jpeg";
import FREEDOM_BEIGE_P4 from "../assets/images/Glossy/FREEDOM_BEIGE_P4.jpeg";
import FREEDOM_BEIGE_P5 from "../assets/images/Glossy/FREEDOM_BEIGE_P5.jpeg";

import FREEDOM_BIANCO_P1 from "../assets/images/Glossy/FREEDOM_BIANCO_P1.jpeg";
import FREEDOM_BIANCO_P2 from "../assets/images/Glossy/FREEDOM_BIANCO_P2.jpeg";
import FREEDOM_BIANCO_P3 from "../assets/images/Glossy/FREEDOM_BIANCO_P3.jpeg";
import FREEDOM_BIANCO_P4 from "../assets/images/Glossy/FREEDOM_BIANCO_P4.jpeg";
import FREEDOM_BIANCO_P5 from "../assets/images/Glossy/FREEDOM_BIANCO_P5.jpeg";

import FRESSIA_STATURIO_P1 from "../assets/images/Glossy/FRESSIA_STATURIO_P1.jpeg";
import FRESSIA_STATURIO_P2 from "../assets/images/Glossy/FRESSIA_STATURIO_P2.jpeg";
import FRESSIA_STATURIO_P3 from "../assets/images/Glossy/FRESSIA_STATURIO_P3.jpeg";
import FRESSIA_STATURIO_P4 from "../assets/images/Glossy/FRESSIA_STATURIO_P4.jpeg";
import FRESSIA_STATURIO_P5 from "../assets/images/Glossy/FRESSIA_STATURIO_P5.jpeg";
import FRESSIA_STATURIO_P6 from "../assets/images/Glossy/FRESSIA_STATURIO_P6.jpeg";
import FRESSIA_STATURIO_P7 from "../assets/images/Glossy/FRESSIA_STATURIO_P7.jpeg";
import FRESSIA_STATURIO_P8 from "../assets/images/Glossy/FRESSIA_STATURIO_P8.jpeg";

import FUMA_BLUE_P1 from "../assets/images/Glossy/FUMA_BLUE_P1.jpeg";
import FUMA_BLUE_P2 from "../assets/images/Glossy/FUMA_BLUE_P2.jpeg";
import FUMA_BLUE_P3 from "../assets/images/Glossy/FUMA_BLUE_P3.jpeg";
import FUMA_BLUE_P4 from "../assets/images/Glossy/FUMA_BLUE_P4.jpeg";
import FUMA_BLUE_P5 from "../assets/images/Glossy/FUMA_BLUE_P5.jpeg";

import FUMA_GOLD_P1 from "../assets/images/Glossy/FUMA_GOLD_P1.jpeg";
import FUMA_GOLD_P2 from "../assets/images/Glossy/FUMA_GOLD_P2.jpeg";
import FUMA_GOLD_P3 from "../assets/images/Glossy/FUMA_GOLD_P3.jpeg";
import FUMA_GOLD_P4 from "../assets/images/Glossy/FUMA_GOLD_P4.jpeg";
import FUMA_GOLD_P5 from "../assets/images/Glossy/FUMA_GOLD_P5.jpeg";

import GENESIS_BEIGE_P1 from "../assets/images/Glossy/GENESIS_BEIGE_P1.jpeg";
import GENESIS_BEIGE_P2 from "../assets/images/Glossy/GENESIS_BEIGE_P2.jpeg";
import GENESIS_BEIGE_P3 from "../assets/images/Glossy/GENESIS_BEIGE_P3.jpeg";
import GENESIS_BEIGE_P4 from "../assets/images/Glossy/GENESIS_BEIGE_P4.jpeg";
import GENESIS_BEIGE_P5 from "../assets/images/Glossy/GENESIS_BEIGE_P5.jpeg";

import GENESIS_BROWN_P1 from "../assets/images/Glossy/GENESIS_BROWN_P1.jpeg";
import GENESIS_BROWN_P2 from "../assets/images/Glossy/GENESIS_BROWN_P2.jpeg";
import GENESIS_BROWN_P3 from "../assets/images/Glossy/GENESIS_BROWN_P3.jpeg";
import GENESIS_BROWN_P4 from "../assets/images/Glossy/GENESIS_BROWN_P4.jpeg";
import GENESIS_BROWN_P5 from "../assets/images/Glossy/GENESIS_BROWN_P5.jpeg";

import GLACIER_BIANCO_P1 from "../assets/images/Glossy/GLACIER_BIANCO_P1.jpeg";
import GLACIER_BIANCO_P2 from "../assets/images/Glossy/GLACIER_BIANCO_P2.jpeg";
import GLACIER_BIANCO_P3 from "../assets/images/Glossy/GLACIER_BIANCO_P3.jpeg";
import GLACIER_BIANCO_P4 from "../assets/images/Glossy/GLACIER_BIANCO_P4.jpeg";
import GLACIER_BIANCO_P5 from "../assets/images/Glossy/GLACIER_BIANCO_P5.jpeg";

import GLACIER_GOLD_P1 from "../assets/images/Glossy/GLACIER_GOLD_P1.jpeg";
import GLACIER_GOLD_P2 from "../assets/images/Glossy/GLACIER_GOLD_P2.jpeg";
import GLACIER_GOLD_P3 from "../assets/images/Glossy/GLACIER_GOLD_P3.jpeg";
import GLACIER_GOLD_P4 from "../assets/images/Glossy/GLACIER_GOLD_P4.jpeg";
import GLACIER_GOLD_P5 from "../assets/images/Glossy/GLACIER_GOLD_P5.jpeg";

import GLACIER_WHITE_P1 from "../assets/images/Glossy/GLACIER_WHITE_P1.jpeg";
import GLACIER_WHITE_P2 from "../assets/images/Glossy/GLACIER_WHITE_P2.jpeg";
import GLACIER_WHITE_P3 from "../assets/images/Glossy/GLACIER_WHITE_P3.jpeg";
import GLACIER_WHITE_P4 from "../assets/images/Glossy/GLACIER_WHITE_P4.jpeg";
import GLACIER_WHITE_P5 from "../assets/images/Glossy/GLACIER_WHITE_P5.jpeg";

import GOLDCOIN_WHITE_P1 from "../assets/images/Glossy/GOLDCOIN_WHITE_P1.jpeg";
import GOLDCOIN_WHITE_P2 from "../assets/images/Glossy/GOLDCOIN_WHITE_P2.jpeg";
import GOLDCOIN_WHITE_P3 from "../assets/images/Glossy/GOLDCOIN_WHITE_P3.jpeg";
import GOLDCOIN_WHITE_P4 from "../assets/images/Glossy/GOLDCOIN_WHITE_P4.jpeg";

import GRESS_ONIX_BEIGE_P1 from "../assets/images/Glossy/GRESS_ONIX_BEIGE_P1.jpeg";
import GRESS_ONIX_BEIGE_P2 from "../assets/images/Glossy/GRESS_ONIX_BEIGE_P2.jpeg";
import GRESS_ONIX_BEIGE_P3 from "../assets/images/Glossy/GRESS_ONIX_BEIGE_P3.jpeg";
import GRESS_ONIX_BEIGE_P4 from "../assets/images/Glossy/GRESS_ONIX_BEIGE_P4.jpeg";
import GRESS_ONIX_BEIGE_P5 from "../assets/images/Glossy/GRESS_ONIX_BEIGE_P5.jpeg";

import GRIJLY_STATURIO_P1 from "../assets/images/Glossy/GRIJLY_STATURIO_P1.jpeg";
import GRIJLY_STATURIO_P2 from "../assets/images/Glossy/GRIJLY_STATURIO_P2.jpeg";
import GRIJLY_STATURIO_P3 from "../assets/images/Glossy/GRIJLY_STATURIO_P3.jpeg";
import GRIJLY_STATURIO_P4 from "../assets/images/Glossy/GRIJLY_STATURIO_P4.jpeg";

import HANSTONE_BIANCO_P1 from "../assets/images/Glossy/HANSTONE_BIANCO_P1.jpeg";
import HANSTONE_BIANCO_P2 from "../assets/images/Glossy/HANSTONE_BIANCO_P2.jpeg";
import HANSTONE_BIANCO_P3 from "../assets/images/Glossy/HANSTONE_BIANCO_P3.jpeg";
import HANSTONE_BIANCO_P4 from "../assets/images/Glossy/HANSTONE_BIANCO_P4.jpeg";
import HANSTONE_BIANCO_P5 from "../assets/images/Glossy/HANSTONE_BIANCO_P5.jpeg";

import HANSTONE_CREMA_P1 from "../assets/images/Glossy/HANSTONE_CREMA_P1.jpeg";
import HANSTONE_CREMA_P2 from "../assets/images/Glossy/HANSTONE_CREMA_P2.jpeg";
import HANSTONE_CREMA_P3 from "../assets/images/Glossy/HANSTONE_CREMA_P3.jpeg";
import HANSTONE_CREMA_P4 from "../assets/images/Glossy/HANSTONE_CREMA_P4.jpeg";
import HANSTONE_CREMA_P5 from "../assets/images/Glossy/HANSTONE_CREMA_P5.jpeg";

import HAWAI_BEIGE_P1 from "../assets/images/Glossy/HAWAI_BEIGE_P1.jpeg";
import HAWAI_BEIGE_P2 from "../assets/images/Glossy/HAWAI_BEIGE_P2.jpeg";
import HAWAI_BEIGE_P3 from "../assets/images/Glossy/HAWAI_BEIGE_P3.jpeg";
import HAWAI_BEIGE_P4 from "../assets/images/Glossy/HAWAI_BEIGE_P4.jpeg";

import HAWAI_WHITE_P1 from "../assets/images/Glossy/HAWAI_WHITE_P1.jpeg";
import HAWAI_WHITE_P2 from "../assets/images/Glossy/HAWAI_WHITE_P2.jpeg";
import HAWAI_WHITE_P3 from "../assets/images/Glossy/HAWAI_WHITE_P3.jpeg";
import HAWAI_WHITE_P4 from "../assets/images/Glossy/HAWAI_WHITE_P4.jpeg";

import IMPACT_STATURIO_P1 from "../assets/images/Glossy/IMPACT_STATURIO_P1.jpeg";
import IMPACT_STATURIO_P2 from "../assets/images/Glossy/IMPACT_STATURIO_P2.jpeg";
import IMPACT_STATURIO_P3 from "../assets/images/Glossy/IMPACT_STATURIO_P3.jpeg";
import IMPACT_STATURIO_P4 from "../assets/images/Glossy/IMPACT_STATURIO_P4.jpeg";

import IMPERIAL_BEIGE_P1 from "../assets/images/Glossy/IMPERIAL_BEIGE_P1.jpeg";
import IMPERIAL_BEIGE_P2 from "../assets/images/Glossy/IMPERIAL_BEIGE_P2.jpeg";
import IMPERIAL_BEIGE_P3 from "../assets/images/Glossy/IMPERIAL_BEIGE_P3.jpeg";
import IMPERIAL_BEIGE_P4 from "../assets/images/Glossy/IMPERIAL_BEIGE_P4.jpeg";
import IMPERIAL_BEIGE_P5 from "../assets/images/Glossy/IMPERIAL_BEIGE_P5.jpeg";

import IMPERIAL_BIANCO_P1 from "../assets/images/Glossy/IMPERIAL_BIANCO_P1.jpeg";
import IMPERIAL_BIANCO_P2 from "../assets/images/Glossy/IMPERIAL_BIANCO_P2.jpeg";
import IMPERIAL_BIANCO_P3 from "../assets/images/Glossy/IMPERIAL_BIANCO_P3.jpeg";
import IMPERIAL_BIANCO_P4 from "../assets/images/Glossy/IMPERIAL_BIANCO_P4.jpeg";
import IMPERIAL_BIANCO_P5 from "../assets/images/Glossy/IMPERIAL_BIANCO_P5.jpeg";

import IMPERIAL_CREMA_P1 from "../assets/images/Glossy/IMPERIAL_CREMA_P1.jpeg";
import IMPERIAL_CREMA_P2 from "../assets/images/Glossy/IMPERIAL_CREMA_P2.jpeg";
import IMPERIAL_CREMA_P3 from "../assets/images/Glossy/IMPERIAL_CREMA_P3.jpeg";
import IMPERIAL_CREMA_P4 from "../assets/images/Glossy/IMPERIAL_CREMA_P4.jpeg";
import IMPERIAL_CREMA_P5 from "../assets/images/Glossy/IMPERIAL_CREMA_P5.jpeg";

import IMPERIAL_GREY_P1 from "../assets/images/Glossy/IMPERIAL_GREY_P1.jpeg";
import IMPERIAL_GREY_P2 from "../assets/images/Glossy/IMPERIAL_GREY_P2.jpeg";
import IMPERIAL_GREY_P3 from "../assets/images/Glossy/IMPERIAL_GREY_P3.jpeg";
import IMPERIAL_GREY_P4 from "../assets/images/Glossy/IMPERIAL_GREY_P4.jpeg";
import IMPERIAL_GREY_P5 from "../assets/images/Glossy/IMPERIAL_GREY_P5.jpeg";

import ITALIA_BEIGE_P1 from "../assets/images/Glossy/ITALIA_BEIGE_P1.jpeg";
import ITALIA_BEIGE_P2 from "../assets/images/Glossy/ITALIA_BEIGE_P2.jpeg";
import ITALIA_BEIGE_P3 from "../assets/images/Glossy/ITALIA_BEIGE_P3.jpeg";
import ITALIA_BEIGE_P4 from "../assets/images/Glossy/ITALIA_BEIGE_P4.jpeg";
import ITALIA_BEIGE_P5 from "../assets/images/Glossy/ITALIA_BEIGE_P5.jpeg";

import ITALIA_BIANCO_P1 from "../assets/images/Glossy/ITALIA_BIANCO_P1.jpeg";
import ITALIA_BIANCO_P2 from "../assets/images/Glossy/ITALIA_BIANCO_P2.jpeg";
import ITALIA_BIANCO_P3 from "../assets/images/Glossy/ITALIA_BIANCO_P3.jpeg";
import ITALIA_BIANCO_P4 from "../assets/images/Glossy/ITALIA_BIANCO_P4.jpeg";
import ITALIA_BIANCO_P5 from "../assets/images/Glossy/ITALIA_BIANCO_P5.jpeg";

import ITALIA_BRONZ_P1 from "../assets/images/Glossy/ITALIA_BRONZ_P1.jpeg";
import ITALIA_BRONZ_P2 from "../assets/images/Glossy/ITALIA_BRONZ_P2.jpeg";
import ITALIA_BRONZ_P3 from "../assets/images/Glossy/ITALIA_BRONZ_P3.jpeg";
import ITALIA_BRONZ_P4 from "../assets/images/Glossy/ITALIA_BRONZ_P4.jpeg";
import ITALIA_BRONZ_P5 from "../assets/images/Glossy/ITALIA_BRONZ_P5.jpeg";

import ITALIA_GREY_P1 from "../assets/images/Glossy/ITALIA_GREY_P1.jpeg";
import ITALIA_GREY_P2 from "../assets/images/Glossy/ITALIA_GREY_P2.jpeg";
import ITALIA_GREY_P3 from "../assets/images/Glossy/ITALIA_GREY_P3.jpeg";
import ITALIA_GREY_P4 from "../assets/images/Glossy/ITALIA_GREY_P4.jpeg";
import ITALIA_GREY_P5 from "../assets/images/Glossy/ITALIA_GREY_P5.jpeg";

import KABLI_STATURIO_P1 from "../assets/images/Glossy/KABLI_STATURIO_P1.jpeg";
import KABLI_STATURIO_P2 from "../assets/images/Glossy/KABLI_STATURIO_P2.jpeg";
import KABLI_STATURIO_P3 from "../assets/images/Glossy/KABLI_STATURIO_P3.jpeg";
import KABLI_STATURIO_P4 from "../assets/images/Glossy/KABLI_STATURIO_P4.jpeg";
import KABLI_STATURIO_P5 from "../assets/images/Glossy/KABLI_STATURIO_P5.jpeg";

import KINGDOM_BLUE_P1 from "../assets/images/Glossy/KINGDOM_BLUE_P1.jpeg";
import KINGDOM_BLUE_P2 from "../assets/images/Glossy/KINGDOM_BLUE_P2.jpeg";

import KINGDOM_BROWN_P1 from "../assets/images/Glossy/KINGDOM_BROWN_P1.jpeg";
import KINGDOM_BROWN_P2 from "../assets/images/Glossy/KINGDOM_BROWN_P2.jpeg";

import KIYARA_GREY_P1 from "../assets/images/Glossy/KIYARA_GREY_P1.jpeg";
import KIYARA_GREY_P2 from "../assets/images/Glossy/KIYARA_GREY_P2.jpeg";
import KIYARA_GREY_P3 from "../assets/images/Glossy/KIYARA_GREY_P3.jpeg";
import KIYARA_GREY_P4 from "../assets/images/Glossy/KIYARA_GREY_P4.jpeg";

import KIYARA_LIGHT_GREY_P1 from "../assets/images/Glossy/KIYARA_LIGHT_GREY_P1.jpeg";
import KIYARA_LIGHT_GREY_P2 from "../assets/images/Glossy/KIYARA_LIGHT_GREY_P2.jpeg";
import KIYARA_LIGHT_GREY_P3 from "../assets/images/Glossy/KIYARA_LIGHT_GREY_P3.jpeg";
import KIYARA_LIGHT_GREY_P4 from "../assets/images/Glossy/KIYARA_LIGHT_GREY_P4.jpeg";

import KRAZY_BROWN from "../assets/images/Glossy/KRAZY_BROWN.jpeg";
import KRAZY_NERO from "../assets/images/Glossy/KRAZY_NERO.jpeg";

import KWID_BEIGE_P1 from "../assets/images/Glossy/KWID_BEIGE_P1.jpeg";
import KWID_BEIGE_P2 from "../assets/images/Glossy/KWID_BEIGE_P2.jpeg";
import KWID_BEIGE_P3 from "../assets/images/Glossy/KWID_BEIGE_P3.jpeg";
import KWID_BEIGE_P4 from "../assets/images/Glossy/KWID_BEIGE_P4.jpeg";
import KWID_BEIGE_P5 from "../assets/images/Glossy/KWID_BEIGE_P5.jpeg";

import KWID_GREY_P1 from "../assets/images/Glossy/KWID_GREY_P1.jpeg";
import KWID_GREY_P2 from "../assets/images/Glossy/KWID_GREY_P2.jpeg";
import KWID_GREY_P3 from "../assets/images/Glossy/KWID_GREY_P3.jpeg";
import KWID_GREY_P4 from "../assets/images/Glossy/KWID_GREY_P4.jpeg";
import KWID_GREY_P5 from "../assets/images/Glossy/KWID_GREY_P5.jpeg";

import LEGNA_ALMOUND_P1 from "../assets/images/Glossy/LEGNA_ALMOUND_P1.jpeg";
import LEGNA_ALMOUND_P2 from "../assets/images/Glossy/LEGNA_ALMOUND_P2.jpeg";
import LEGNA_ALMOUND_P3 from "../assets/images/Glossy/LEGNA_ALMOUND_P3.jpeg";
import LEGNA_ALMOUND_P4 from "../assets/images/Glossy/LEGNA_ALMOUND_P4.jpeg";
import LEGNA_ALMOUND_P5 from "../assets/images/Glossy/LEGNA_ALMOUND_P5.jpeg";

import LEVENT_BIANCO_P1 from "../assets/images/Glossy/LEVENT_BIANCO_P1.jpeg";
import LEVENT_BIANCO_P2 from "../assets/images/Glossy/LEVENT_BIANCO_P2.jpeg";
import LEVENT_BIANCO_P3 from "../assets/images/Glossy/LEVENT_BIANCO_P3.jpeg";
import LEVENT_BIANCO_P4 from "../assets/images/Glossy/LEVENT_BIANCO_P4.jpeg";

import LEVENT_BROWN_P1 from "../assets/images/Glossy/LEVENT_BROWN_P1.jpeg";
import LEVENT_BROWN_P2 from "../assets/images/Glossy/LEVENT_BROWN_P2.jpeg";
import LEVENT_BROWN_P3 from "../assets/images/Glossy/LEVENT_BROWN_P3.jpeg";
import LEVENT_BROWN_P4 from "../assets/images/Glossy/LEVENT_BROWN_P4.jpeg";

import LEVENT_GREY_P1 from "../assets/images/Glossy/LEVENT_GREY_P1.jpeg";
import LEVENT_GREY_P2 from "../assets/images/Glossy/LEVENT_GREY_P2.jpeg";
import LEVENT_GREY_P3 from "../assets/images/Glossy/LEVENT_GREY_P3.jpeg";
import LEVENT_GREY_P4 from "../assets/images/Glossy/LEVENT_GREY_P4.jpeg";

import LEVENT_NATURAL_P1 from "../assets/images/Glossy/LEVENT_NATURAL_P1.jpeg";
import LEVENT_NATURAL_P2 from "../assets/images/Glossy/LEVENT_NATURAL_P2.jpeg";
import LEVENT_NATURAL_P3 from "../assets/images/Glossy/LEVENT_NATURAL_P3.jpeg";
import LEVENT_NATURAL_P4 from "../assets/images/Glossy/LEVENT_NATURAL_P4.jpeg";

import LINER_COAL_P1 from "../assets/images/Glossy/LINER_COAL_P1.jpeg";
import LINER_COAL_P2 from "../assets/images/Glossy/LINER_COAL_P2.jpeg";
import LINER_COAL_P3 from "../assets/images/Glossy/LINER_COAL_P3.jpeg";
import LINER_COAL_P4 from "../assets/images/Glossy/LINER_COAL_P4.jpeg";
import LINER_COAL_P5 from "../assets/images/Glossy/LINER_COAL_P5.jpeg";

import LINER_GREY_P1 from "../assets/images/Glossy/LINER_GREY_P1.jpeg";
import LINER_GREY_P2 from "../assets/images/Glossy/LINER_GREY_P2.jpeg";
import LINER_GREY_P3 from "../assets/images/Glossy/LINER_GREY_P3.jpeg";
import LINER_GREY_P4 from "../assets/images/Glossy/LINER_GREY_P4.jpeg";
import LINER_GREY_P5 from "../assets/images/Glossy/LINER_GREY_P5.jpeg";

import MAGIC_SATVARIYO_P1 from "../assets/images/Glossy/MAGIC_SATVARIYO_P1.jpeg";
import MAGIC_SATVARIYO_P2 from "../assets/images/Glossy/MAGIC_SATVARIYO_P2.jpeg";
import MAGIC_SATVARIYO_P3 from "../assets/images/Glossy/MAGIC_SATVARIYO_P3.jpeg";
import MAGIC_SATVARIYO_P4 from "../assets/images/Glossy/MAGIC_SATVARIYO_P4.jpeg";

import MAJESTIC_BIANCO_P1 from "../assets/images/Glossy/MAJESTIC_BIANCO_P1.jpeg";
import MAJESTIC_BIANCO_P2 from "../assets/images/Glossy/MAJESTIC_BIANCO_P2.jpeg";
import MAJESTIC_BIANCO_P3 from "../assets/images/Glossy/MAJESTIC_BIANCO_P3.jpeg";
import MAJESTIC_BIANCO_P4 from "../assets/images/Glossy/MAJESTIC_BIANCO_P4.jpeg";
import MAJESTIC_BIANCO_P5 from "../assets/images/Glossy/MAJESTIC_BIANCO_P5.jpeg";

import MAJESTIC_BROWN_P1 from "../assets/images/Glossy/MAJESTIC_BROWN_P1.jpeg";
import MAJESTIC_BROWN_P2 from "../assets/images/Glossy/MAJESTIC_BROWN_P2.jpeg";
import MAJESTIC_BROWN_P3 from "../assets/images/Glossy/MAJESTIC_BROWN_P3.jpeg";
import MAJESTIC_BROWN_P4 from "../assets/images/Glossy/MAJESTIC_BROWN_P4.jpeg";
import MAJESTIC_BROWN_P5 from "../assets/images/Glossy/MAJESTIC_BROWN_P5.jpeg";

import MARC_ONYX_CREMA from "../assets/images/Glossy/MARC_ONYX_CREMA.jpeg";
import MARC_ONYX_GREY from "../assets/images/Glossy/MARC_ONYX_GREY.jpeg";

import MELODY_ONYX_P1 from "../assets/images/Glossy/MELODY_ONYX_P1.jpeg";
import MELODY_ONYX_P2 from "../assets/images/Glossy/MELODY_ONYX_P2.jpeg";

import MIAMI_SATVARIO_P1 from "../assets/images/Glossy/MIAMI_SATVARIO_P1.jpeg";
import MIAMI_SATVARIO_P2 from "../assets/images/Glossy/MIAMI_SATVARIO_P2.jpeg";
import MIAMI_SATVARIO_P3 from "../assets/images/Glossy/MIAMI_SATVARIO_P3.jpeg";
import MIAMI_SATVARIO_P4 from "../assets/images/Glossy/MIAMI_SATVARIO_P4.jpeg";

import MILANO_BEIGE_P1 from "../assets/images/Glossy/MILANO_BEIGE_P1.jpeg";
import MILANO_BEIGE_P2 from "../assets/images/Glossy/MILANO_BEIGE_P2.jpeg";
import MILANO_BEIGE_P3 from "../assets/images/Glossy/MILANO_BEIGE_P3.jpeg";
import MILANO_BEIGE_P4 from "../assets/images/Glossy/MILANO_BEIGE_P4.jpeg";

import MINT_BEIGE_P1 from "../assets/images/Glossy/MINT_BEIGE_P1.jpeg";
import MINT_BEIGE_P2 from "../assets/images/Glossy/MINT_BEIGE_P2.jpeg";
import MINT_BEIGE_P3 from "../assets/images/Glossy/MINT_BEIGE_P3.jpeg";
import MINT_BEIGE_P4 from "../assets/images/Glossy/MINT_BEIGE_P4.jpeg";
import MINT_BEIGE_P5 from "../assets/images/Glossy/MINT_BEIGE_P5.jpeg";

import MINT_BIANCO_P1 from "../assets/images/Glossy/MINT_BIANCO_P1.jpeg";
import MINT_BIANCO_P2 from "../assets/images/Glossy/MINT_BIANCO_P2.jpeg";
import MINT_BIANCO_P3 from "../assets/images/Glossy/MINT_BIANCO_P3.jpeg";
import MINT_BIANCO_P4 from "../assets/images/Glossy/MINT_BIANCO_P4.jpeg";
import MINT_BIANCO_P5 from "../assets/images/Glossy/MINT_BIANCO_P5.jpeg";

import MONGINIS_GREY_P1 from "../assets/images/Glossy/MONGINIS_GREY_P1.jpeg";
import MONGINIS_GREY_P2 from "../assets/images/Glossy/MONGINIS_GREY_P2.jpeg";
import MONGINIS_GREY_P3 from "../assets/images/Glossy/MONGINIS_GREY_P3.jpeg";
import MONGINIS_GREY_P4 from "../assets/images/Glossy/MONGINIS_GREY_P4.jpeg";

import MONGINIS_IVORY_P1 from "../assets/images/Glossy/MONGINIS_IVORY_P1.jpeg";
import MONGINIS_IVORY_P2 from "../assets/images/Glossy/MONGINIS_IVORY_P2.jpeg";
import MONGINIS_IVORY_P3 from "../assets/images/Glossy/MONGINIS_IVORY_P3.jpeg";
import MONGINIS_IVORY_P4 from "../assets/images/Glossy/MONGINIS_IVORY_P4.jpeg";

import MONSTER_EMPRADOR_P1 from "../assets/images/Glossy/MONSTER_EMPRADOR_P1.jpeg";
import MONSTER_EMPRADOR_P2 from "../assets/images/Glossy/MONSTER_EMPRADOR_P2.jpeg";
import MONSTER_EMPRADOR_P3 from "../assets/images/Glossy/MONSTER_EMPRADOR_P3.jpeg";
import MONSTER_EMPRADOR_P4 from "../assets/images/Glossy/MONSTER_EMPRADOR_P4.jpeg";

import MONTANA_BEIGE_P1 from "../assets/images/Glossy/MONTANA_BEIGE_P1.jpeg";
import MONTANA_BEIGE_P2 from "../assets/images/Glossy/MONTANA_BEIGE_P2.jpeg";
import MONTANA_BEIGE_P3 from "../assets/images/Glossy/MONTANA_BEIGE_P3.jpeg";
import MONTANA_BEIGE_P4 from "../assets/images/Glossy/MONTANA_BEIGE_P4.jpeg";
import MONTANA_BEIGE_P5 from "../assets/images/Glossy/MONTANA_BEIGE_P5.jpeg";

import MONTANA_GREEN_P1 from "../assets/images/Glossy/MONTANA_GREEN_P1.jpeg";
import MONTANA_GREEN_P2 from "../assets/images/Glossy/MONTANA_GREEN_P2.jpeg";
import MONTANA_GREEN_P3 from "../assets/images/Glossy/MONTANA_GREEN_P3.jpeg";
import MONTANA_GREEN_P4 from "../assets/images/Glossy/MONTANA_GREEN_P4.jpeg";
import MONTANA_GREEN_P5 from "../assets/images/Glossy/MONTANA_GREEN_P5.jpeg";

import MORACCAN_WHITE_P1 from "../assets/images/Glossy/MORACCAN_WHITE_P1.jpeg";
import MORACCAN_WHITE_P2 from "../assets/images/Glossy/MORACCAN_WHITE_P2.jpeg";
import MORACCAN_WHITE_P3 from "../assets/images/Glossy/MORACCAN_WHITE_P3.jpeg";
import MORACCAN_WHITE_P4 from "../assets/images/Glossy/MORACCAN_WHITE_P4.jpeg";

import MYSTREY_GREY_P1 from "../assets/images/Glossy/MYSTREY_GREY_P1.jpeg";
import MYSTREY_GREY_P2 from "../assets/images/Glossy/MYSTREY_GREY_P2.jpeg";
import MYSTREY_GREY_P3 from "../assets/images/Glossy/MYSTREY_GREY_P3.jpeg";
import MYSTREY_GREY_P4 from "../assets/images/Glossy/MYSTREY_GREY_P4.jpeg";

import MYSTREY_PEARL_P1 from "../assets/images/Glossy/MYSTREY_PEARL_P1.jpeg";
import MYSTREY_PEARL_P2 from "../assets/images/Glossy/MYSTREY_PEARL_P2.jpeg";
import MYSTREY_PEARL_P3 from "../assets/images/Glossy/MYSTREY_PEARL_P3.jpeg";
import MYSTREY_PEARL_P4 from "../assets/images/Glossy/MYSTREY_PEARL_P4.jpeg";

import NATURAL_SLATE_BEIGE_P1 from "../assets/images/Glossy/NATURAL_SLATE_BEIGE_P1.jpeg";
import NATURAL_SLATE_BEIGE_P2 from "../assets/images/Glossy/NATURAL_SLATE_BEIGE_P2.jpeg";
import NATURAL_SLATE_BEIGE_P3 from "../assets/images/Glossy/NATURAL_SLATE_BEIGE_P3.jpeg";
import NATURAL_SLATE_BEIGE_P4 from "../assets/images/Glossy/NATURAL_SLATE_BEIGE_P4.jpeg";
import NATURAL_SLATE_BEIGE_P5 from "../assets/images/Glossy/NATURAL_SLATE_BEIGE_P5.jpeg";

import NATURAL_SLATE_CREMA_P1 from "../assets/images/Glossy/NATURAL_SLATE_CREMA_P1.jpeg";
import NATURAL_SLATE_CREMA_P2 from "../assets/images/Glossy/NATURAL_SLATE_CREMA_P2.jpeg";
import NATURAL_SLATE_CREMA_P3 from "../assets/images/Glossy/NATURAL_SLATE_CREMA_P3.jpeg";
import NATURAL_SLATE_CREMA_P4 from "../assets/images/Glossy/NATURAL_SLATE_CREMA_P4.jpeg";
import NATURAL_SLATE_CREMA_P5 from "../assets/images/Glossy/NATURAL_SLATE_CREMA_P5.jpeg";

import NAVONA_BROWN_P1 from "../assets/images/Glossy/NAVONA_BROWN_P1.jpeg";
import NAVONA_BROWN_P2 from "../assets/images/Glossy/NAVONA_BROWN_P2.jpeg";
import NAVONA_BROWN_P3 from "../assets/images/Glossy/NAVONA_BROWN_P3.jpeg";
import NAVONA_BROWN_P4 from "../assets/images/Glossy/NAVONA_BROWN_P4.jpeg";
import NAVONA_BROWN_P5 from "../assets/images/Glossy/NAVONA_BROWN_P5.jpeg";

import NAVONA_COBALT_P1 from "../assets/images/Glossy/NAVONA_COBALT_P1.jpeg";
import NAVONA_COBALT_P2 from "../assets/images/Glossy/NAVONA_COBALT_P2.jpeg";
import NAVONA_COBALT_P3 from "../assets/images/Glossy/NAVONA_COBALT_P3.jpeg";
import NAVONA_COBALT_P4 from "../assets/images/Glossy/NAVONA_COBALT_P4.jpeg";
import NAVONA_COBALT_P5 from "../assets/images/Glossy/NAVONA_COBALT_P5.jpeg";

import NAVONA_GREY_P1 from "../assets/images/Glossy/NAVONA_GREY_P1.jpeg";
import NAVONA_GREY_P2 from "../assets/images/Glossy/NAVONA_GREY_P2.jpeg";
import NAVONA_GREY_P3 from "../assets/images/Glossy/NAVONA_GREY_P3.jpeg";
import NAVONA_GREY_P4 from "../assets/images/Glossy/NAVONA_GREY_P4.jpeg";
import NAVONA_GREY_P5 from "../assets/images/Glossy/NAVONA_GREY_P5.jpeg";

import NERO_BEIGE_P1 from "../assets/images/Glossy/NERO_BEIGE_P1.jpeg";
import NERO_BEIGE_P2 from "../assets/images/Glossy/NERO_BEIGE_P2.jpeg";
import NERO_BEIGE_P3 from "../assets/images/Glossy/NERO_BEIGE_P3.jpeg";
import NERO_BEIGE_P4 from "../assets/images/Glossy/NERO_BEIGE_P4.jpeg";
import NERO_BEIGE_P5 from "../assets/images/Glossy/NERO_BEIGE_P5.jpeg";

import NITRA_STATURIO_P1 from "../assets/images/Glossy/NITRA_STATURIO_P1.jpeg";
import NITRA_STATURIO_P2 from "../assets/images/Glossy/NITRA_STATURIO_P2.jpeg";
import NITRA_STATURIO_P3 from "../assets/images/Glossy/NITRA_STATURIO_P3.jpeg";
import NITRA_STATURIO_P4 from "../assets/images/Glossy/NITRA_STATURIO_P4.jpeg";
import NITRA_STATURIO_P5 from "../assets/images/Glossy/NITRA_STATURIO_P5.jpeg";

import NOTCH_DARK_P1 from "../assets/images/Glossy/NOTCH_DARK_P1.jpeg";
import NOTCH_DARK_P2 from "../assets/images/Glossy/NOTCH_DARK_P2.jpeg";
import NOTCH_DARK_P3 from "../assets/images/Glossy/NOTCH_DARK_P3.jpeg";
import NOTCH_DARK_P4 from "../assets/images/Glossy/NOTCH_DARK_P4.jpeg";

import NOTCH_LIGHT_P1 from "../assets/images/Glossy/NOTCH_LIGHT_P1.jpeg";
import NOTCH_LIGHT_P2 from "../assets/images/Glossy/NOTCH_LIGHT_P2.jpeg";
import NOTCH_LIGHT_P3 from "../assets/images/Glossy/NOTCH_LIGHT_P3.jpeg";
import NOTCH_LIGHT_P4 from "../assets/images/Glossy/NOTCH_LIGHT_P4.jpeg";

import NOVA_BIANCO_P1 from "../assets/images/Glossy/NOVA_BIANCO_P1.jpeg";
import NOVA_BIANCO_P2 from "../assets/images/Glossy/NOVA_BIANCO_P2.jpeg";
import NOVA_BIANCO_P3 from "../assets/images/Glossy/NOVA_BIANCO_P3.jpeg";
import NOVA_BIANCO_P4 from "../assets/images/Glossy/NOVA_BIANCO_P4.jpeg";

import NOVA_BLUE_P1 from "../assets/images/Glossy/NOVA_BLUE_P1.jpeg";
import NOVA_BLUE_P2 from "../assets/images/Glossy/NOVA_BLUE_P2.jpeg";
import NOVA_BLUE_P3 from "../assets/images/Glossy/NOVA_BLUE_P3.jpeg";
import NOVA_BLUE_P4 from "../assets/images/Glossy/NOVA_BLUE_P4.jpeg";

import NOVA_CHOCO_P1 from "../assets/images/Glossy/NOVA_CHOCO_P1.jpeg";
import NOVA_CHOCO_P2 from "../assets/images/Glossy/NOVA_CHOCO_P2.jpeg";
import NOVA_CHOCO_P3 from "../assets/images/Glossy/NOVA_CHOCO_P3.jpeg";
import NOVA_CHOCO_P4 from "../assets/images/Glossy/NOVA_CHOCO_P4.jpeg";

import NOVA_GOLD_P1 from "../assets/images/Glossy/NOVA_GOLD_P1.jpeg";
import NOVA_GOLD_P2 from "../assets/images/Glossy/NOVA_GOLD_P2.jpeg";
import NOVA_GOLD_P3 from "../assets/images/Glossy/NOVA_GOLD_P3.jpeg";
import NOVA_GOLD_P4 from "../assets/images/Glossy/NOVA_GOLD_P4.jpeg";

import OLYMPIA_GOLD_P1 from "../assets/images/Glossy/OLYMPIA_GOLD_P1.jpeg";
import OLYMPIA_GOLD_P2 from "../assets/images/Glossy/OLYMPIA_GOLD_P2.jpeg";
import OLYMPIA_GOLD_P3 from "../assets/images/Glossy/OLYMPIA_GOLD_P3.jpeg";
import OLYMPIA_GOLD_P4 from "../assets/images/Glossy/OLYMPIA_GOLD_P4.jpeg";

import OMAN_BEIGE_P1 from "../assets/images/Glossy/OMAN_BEIGE_P1.jpeg";
import OMAN_BEIGE_P2 from "../assets/images/Glossy/OMAN_BEIGE_P2.jpeg";
import OMAN_BEIGE_P3 from "../assets/images/Glossy/OMAN_BEIGE_P3.jpeg";
import OMAN_BEIGE_P4 from "../assets/images/Glossy/OMAN_BEIGE_P4.jpeg";

import OMAN_GREY_P1 from "../assets/images/Glossy/OMAN_GREY_P1.jpeg";
import OMAN_GREY_P2 from "../assets/images/Glossy/OMAN_GREY_P2.jpeg";
import OMAN_GREY_P3 from "../assets/images/Glossy/OMAN_GREY_P3.jpeg";
import OMAN_GREY_P4 from "../assets/images/Glossy/OMAN_GREY_P4.jpeg";

import OMANIA_CREMA_P1 from "../assets/images/Glossy/OMANIA_CREMA_P1.jpeg";
import OMANIA_CREMA_P2 from "../assets/images/Glossy/OMANIA_CREMA_P2.jpeg";
import OMANIA_CREMA_P3 from "../assets/images/Glossy/OMANIA_CREMA_P3.jpeg";
import OMANIA_CREMA_P4 from "../assets/images/Glossy/OMANIA_CREMA_P4.jpeg";

import ONIO_BIANCO_P1 from "../assets/images/Glossy/ONIO_BIANCO_P1.jpeg";
import ONIO_BIANCO_P2 from "../assets/images/Glossy/ONIO_BIANCO_P2.jpeg";
import ONIO_BIANCO_P3 from "../assets/images/Glossy/ONIO_BIANCO_P3.jpeg";
import ONIO_BIANCO_P4 from "../assets/images/Glossy/ONIO_BIANCO_P4.jpeg";

import ORBIT_NATURA_P1 from "../assets/images/Glossy/ORBIT_NATURA_P1.jpeg";
import ORBIT_NATURA_P2 from "../assets/images/Glossy/ORBIT_NATURA_P2.jpeg";
import ORBIT_NATURA_P3 from "../assets/images/Glossy/ORBIT_NATURA_P3.jpeg";
import ORBIT_NATURA_P4 from "../assets/images/Glossy/ORBIT_NATURA_P4.jpeg";

import OREON_BEIGE_P1 from "../assets/images/Glossy/OREON_BEIGE_P1.jpeg";
import OREON_BEIGE_P2 from "../assets/images/Glossy/OREON_BEIGE_P2.jpeg";
import OREON_BEIGE_P3 from "../assets/images/Glossy/OREON_BEIGE_P3.jpeg";
import OREON_BEIGE_P4 from "../assets/images/Glossy/OREON_BEIGE_P4.jpeg";
import OREON_BEIGE_P5 from "../assets/images/Glossy/OREON_BEIGE_P5.jpeg";

import OREON_CREMA_P1 from "../assets/images/Glossy/OREON_CREMA_P1.jpeg";
import OREON_CREMA_P2 from "../assets/images/Glossy/OREON_CREMA_P2.jpeg";
import OREON_CREMA_P3 from "../assets/images/Glossy/OREON_CREMA_P3.jpeg";
import OREON_CREMA_P4 from "../assets/images/Glossy/OREON_CREMA_P4.jpeg";
import OREON_CREMA_P5 from "../assets/images/Glossy/OREON_CREMA_P5.jpeg";

import OREON_GREEN_P1 from "../assets/images/Glossy/OREON_GREEN_P1.jpeg";
import OREON_GREEN_P2 from "../assets/images/Glossy/OREON_GREEN_P2.jpeg";
import OREON_GREEN_P3 from "../assets/images/Glossy/OREON_GREEN_P3.jpeg";
import OREON_GREEN_P4 from "../assets/images/Glossy/OREON_GREEN_P4.jpeg";
import OREON_GREEN_P5 from "../assets/images/Glossy/OREON_GREEN_P5.jpeg";

import OREON_NERO_P1 from "../assets/images/Glossy/OREON_NERO_P1.jpeg";
import OREON_NERO_P2 from "../assets/images/Glossy/OREON_NERO_P2.jpeg";
import OREON_NERO_P3 from "../assets/images/Glossy/OREON_NERO_P3.jpeg";
import OREON_NERO_P4 from "../assets/images/Glossy/OREON_NERO_P4.jpeg";
import OREON_NERO_P5 from "../assets/images/Glossy/OREON_NERO_P5.jpeg";

import OVAL_GREY_P1 from "../assets/images/Glossy/OVAL_GREY_P1.jpeg";
import OVAL_GREY_P2 from "../assets/images/Glossy/OVAL_GREY_P2.jpeg";
import OVAL_GREY_P3 from "../assets/images/Glossy/OVAL_GREY_P3.jpeg";
import OVAL_GREY_P4 from "../assets/images/Glossy/OVAL_GREY_P4.jpeg";

import OZON_WHITE_P1 from "../assets/images/Glossy/OZON_WHITE_P1.jpeg";
import OZON_WHITE_P2 from "../assets/images/Glossy/OZON_WHITE_P2.jpeg";
import OZON_WHITE_P3 from "../assets/images/Glossy/OZON_WHITE_P3.jpeg";
import OZON_WHITE_P4 from "../assets/images/Glossy/OZON_WHITE_P4.jpeg";

import PANTHON_STATURIO_P1 from "../assets/images/Glossy/PANTHON_STATURIO_P1.jpeg";
import PANTHON_STATURIO_P2 from "../assets/images/Glossy/PANTHON_STATURIO_P2.jpeg";
import PANTHON_STATURIO_P3 from "../assets/images/Glossy/PANTHON_STATURIO_P3.jpeg";
import PANTHON_STATURIO_P4 from "../assets/images/Glossy/PANTHON_STATURIO_P4.jpeg";
import PANTHON_STATURIO_P5 from "../assets/images/Glossy/PANTHON_STATURIO_P5.jpeg";

import PARANA_BIANCO_P1 from "../assets/images/Glossy/PARANA_BIANCO_P1.jpeg";
import PARANA_BIANCO_P2 from "../assets/images/Glossy/PARANA_BIANCO_P2.jpeg";
import PARANA_BIANCO_P3 from "../assets/images/Glossy/PARANA_BIANCO_P3.jpeg";
import PARANA_BIANCO_P4 from "../assets/images/Glossy/PARANA_BIANCO_P4.jpeg";
import PARANA_BIANCO_P5 from "../assets/images/Glossy/PARANA_BIANCO_P5.jpeg";

import PARANA_BRONZE_P1 from "../assets/images/Glossy/PARANA_BRONZE_P1.jpeg";
import PARANA_BRONZE_P2 from "../assets/images/Glossy/PARANA_BRONZE_P2.jpeg";
import PARANA_BRONZE_P3 from "../assets/images/Glossy/PARANA_BRONZE_P3.jpeg";
import PARANA_BRONZE_P4 from "../assets/images/Glossy/PARANA_BRONZE_P4.jpeg";
import PARANA_BRONZE_P5 from "../assets/images/Glossy/PARANA_BRONZE_P5.jpeg";

import PARANA_CREMA_P1 from "../assets/images/Glossy/PARANA_CREMA_P1.jpeg";
import PARANA_CREMA_P2 from "../assets/images/Glossy/PARANA_CREMA_P2.jpeg";
import PARANA_CREMA_P3 from "../assets/images/Glossy/PARANA_CREMA_P3.jpeg";
import PARANA_CREMA_P4 from "../assets/images/Glossy/PARANA_CREMA_P4.jpeg";
import PARANA_CREMA_P5 from "../assets/images/Glossy/PARANA_CREMA_P5.jpeg";

import PARIOS_EMPRA_BEIGE_P1 from "../assets/images/Glossy/PARIOS_EMPRA_BEIGE_P1.jpeg";
import PARIOS_EMPRA_BEIGE_P2 from "../assets/images/Glossy/PARIOS_EMPRA_BEIGE_P2.jpeg";
import PARIOS_EMPRA_BEIGE_P3 from "../assets/images/Glossy/PARIOS_EMPRA_BEIGE_P3.jpeg";
import PARIOS_EMPRA_BEIGE_P4 from "../assets/images/Glossy/PARIOS_EMPRA_BEIGE_P4.jpeg";

import PARIOS_EMPRA_CREMA_P1 from "../assets/images/Glossy/PARIOS_EMPRA_CREMA_P1.jpeg";
import PARIOS_EMPRA_CREMA_P2 from "../assets/images/Glossy/PARIOS_EMPRA_CREMA_P2.jpeg";
import PARIOS_EMPRA_CREMA_P3 from "../assets/images/Glossy/PARIOS_EMPRA_CREMA_P3.jpeg";
import PARIOS_EMPRA_CREMA_P4 from "../assets/images/Glossy/PARIOS_EMPRA_CREMA_P4.jpeg";

import PARIS_CREMA_P1 from "../assets/images/Glossy/PARIS_CREMA_P1.jpeg";
import PARIS_CREMA_P2 from "../assets/images/Glossy/PARIS_CREMA_P2.jpeg";
import PARIS_CREMA_P3 from "../assets/images/Glossy/PARIS_CREMA_P3.jpeg";
import PARIS_CREMA_P4 from "../assets/images/Glossy/PARIS_CREMA_P4.jpeg";

import PARIS_IVORY_P1 from "../assets/images/Glossy/PARIS_IVORY_P1.jpeg";
import PARIS_IVORY_P2 from "../assets/images/Glossy/PARIS_IVORY_P2.jpeg";
import PARIS_IVORY_P3 from "../assets/images/Glossy/PARIS_IVORY_P3.jpeg";
import PARIS_IVORY_P4 from "../assets/images/Glossy/PARIS_IVORY_P4.jpeg";

import PEARL_SHELL_P1 from "../assets/images/Glossy/PEARL_SHELL_P1.jpeg";
import PEARL_SHELL_P2 from "../assets/images/Glossy/PEARL_SHELL_P2.jpeg";
import PEARL_SHELL_P3 from "../assets/images/Glossy/PEARL_SHELL_P3.jpeg";
import PEARL_SHELL_P4 from "../assets/images/Glossy/PEARL_SHELL_P4.jpeg";

import PERLANO_BLUE_P1 from "../assets/images/Glossy/PERLANO_BLUE_P1.jpeg";
import PERLANO_BLUE_P2 from "../assets/images/Glossy/PERLANO_BLUE_P2.jpeg";
import PERLANO_BLUE_P3 from "../assets/images/Glossy/PERLANO_BLUE_P3.jpeg";
import PERLANO_BLUE_P4 from "../assets/images/Glossy/PERLANO_BLUE_P4.jpeg";

import POURA_BEIGE_P1 from "../assets/images/Glossy/POURA_BEIGE_P1.jpeg";
import POURA_BEIGE_P2 from "../assets/images/Glossy/POURA_BEIGE_P2.jpeg";
import POURA_BEIGE_P3 from "../assets/images/Glossy/POURA_BEIGE_P3.jpeg";
import POURA_BEIGE_P4 from "../assets/images/Glossy/POURA_BEIGE_P4.jpeg";

import POURA_CREMA_P1 from "../assets/images/Glossy/POURA_CREMA_P1.jpeg";
import POURA_CREMA_P2 from "../assets/images/Glossy/POURA_CREMA_P2.jpeg";
import POURA_CREMA_P3 from "../assets/images/Glossy/POURA_CREMA_P3.jpeg";
import POURA_CREMA_P4 from "../assets/images/Glossy/POURA_CREMA_P4.jpeg";

import PRAGA_BEIGE_P1 from "../assets/images/Glossy/PRAGA_BEIGE_P1.jpeg";
import PRAGA_BEIGE_P2 from "../assets/images/Glossy/PRAGA_BEIGE_P2.jpeg";
import PRAGA_BEIGE_P3 from "../assets/images/Glossy/PRAGA_BEIGE_P3.jpeg";
import PRAGA_BEIGE_P4 from "../assets/images/Glossy/PRAGA_BEIGE_P4.jpeg";
import PRAGA_BEIGE_P5 from "../assets/images/Glossy/PRAGA_BEIGE_P5.jpeg";

import PREDO_ONIX_BEIGE_P1 from "../assets/images/Glossy/PREDO_ONIX_BEIGE_P1.jpeg";
import PREDO_ONIX_BEIGE_P2 from "../assets/images/Glossy/PREDO_ONIX_BEIGE_P2.jpeg";
import PREDO_ONIX_BEIGE_P3 from "../assets/images/Glossy/PREDO_ONIX_BEIGE_P3.jpeg";
import PREDO_ONIX_BEIGE_P4 from "../assets/images/Glossy/PREDO_ONIX_BEIGE_P4.jpeg";
import PREDO_ONIX_BEIGE_P5 from "../assets/images/Glossy/PREDO_ONIX_BEIGE_P5.jpeg";

import PREDO_ONIX_GREY_P1 from "../assets/images/Glossy/PREDO_ONIX_GREY_P1.jpeg";
import PREDO_ONIX_GREY_P2 from "../assets/images/Glossy/PREDO_ONIX_GREY_P2.jpeg";
import PREDO_ONIX_GREY_P3 from "../assets/images/Glossy/PREDO_ONIX_GREY_P3.jpeg";
import PREDO_ONIX_GREY_P4 from "../assets/images/Glossy/PREDO_ONIX_GREY_P4.jpeg";
import PREDO_ONIX_GREY_P5 from "../assets/images/Glossy/PREDO_ONIX_GREY_P5.jpeg";

import PULPIS_BIANCO_P1 from "../assets/images/Glossy/PULPIS_BIANCO_P1.jpeg";
import PULPIS_BIANCO_P2 from "../assets/images/Glossy/PULPIS_BIANCO_P2.jpeg";
import PULPIS_BIANCO_P3 from "../assets/images/Glossy/PULPIS_BIANCO_P3.jpeg";
import PULPIS_BIANCO_P4 from "../assets/images/Glossy/PULPIS_BIANCO_P4.jpeg";

import PULPIS_GREY_P1 from "../assets/images/Glossy/PULPIS_GREY_P1.jpeg";
import PULPIS_GREY_P2 from "../assets/images/Glossy/PULPIS_GREY_P2.jpeg";
import PULPIS_GREY_P3 from "../assets/images/Glossy/PULPIS_GREY_P3.jpeg";
import PULPIS_GREY_P4 from "../assets/images/Glossy/PULPIS_GREY_P4.jpeg";

import PULPIS_GRIS_P1 from "../assets/images/Glossy/PULPIS_GRIS_P1.jpeg";
import PULPIS_GRIS_P2 from "../assets/images/Glossy/PULPIS_GRIS_P2.jpeg";
import PULPIS_GRIS_P3 from "../assets/images/Glossy/PULPIS_GRIS_P3.jpeg";
import PULPIS_GRIS_P4 from "../assets/images/Glossy/PULPIS_GRIS_P4.jpeg";

import QUANTUM_STATURIO_P1 from "../assets/images/Glossy/QUANTUM_STATURIO_P1.jpeg";
import QUANTUM_STATURIO_P2 from "../assets/images/Glossy/QUANTUM_STATURIO_P2.jpeg";
import QUANTUM_STATURIO_P3 from "../assets/images/Glossy/QUANTUM_STATURIO_P3.jpeg";
import QUANTUM_STATURIO_P4 from "../assets/images/Glossy/QUANTUM_STATURIO_P4.jpeg";
import QUANTUM_STATURIO_P5 from "../assets/images/Glossy/QUANTUM_STATURIO_P5.jpeg";
import QUANTUM_STATURIO_P6 from "../assets/images/Glossy/QUANTUM_STATURIO_P6.jpeg";
import QUANTUM_STATURIO_P7 from "../assets/images/Glossy/QUANTUM_STATURIO_P7.jpeg";
import QUANTUM_STATURIO_P8 from "../assets/images/Glossy/QUANTUM_STATURIO_P8.jpeg";

import RADIANT_BIANCO_P1 from "../assets/images/Glossy/RADIANT_BIANCO_P1.jpeg";
import RADIANT_BIANCO_P2 from "../assets/images/Glossy/RADIANT_BIANCO_P2.jpeg";
import RADIANT_BIANCO_P3 from "../assets/images/Glossy/RADIANT_BIANCO_P3.jpeg";
import RADIANT_BIANCO_P4 from "../assets/images/Glossy/RADIANT_BIANCO_P4.jpeg";

import RADIANT_CREMA_P1 from "../assets/images/Glossy/RADIANT_CREMA_P1.jpeg";
import RADIANT_CREMA_P2 from "../assets/images/Glossy/RADIANT_CREMA_P2.jpeg";
import RADIANT_CREMA_P3 from "../assets/images/Glossy/RADIANT_CREMA_P3.jpeg";
import RADIANT_CREMA_P4 from "../assets/images/Glossy/RADIANT_CREMA_P4.jpeg";

import REAL_BRESCIA_BEIGE_P1 from "../assets/images/Glossy/REAL_BRESCIA_BEIGE_P1.jpeg";
import REAL_BRESCIA_BEIGE_P2 from "../assets/images/Glossy/REAL_BRESCIA_BEIGE_P2.jpeg";
import REAL_BRESCIA_BEIGE_P3 from "../assets/images/Glossy/REAL_BRESCIA_BEIGE_P3.jpeg";

import REAL_EMPRADOR_DARK_P1 from "../assets/images/Glossy/REAL_EMPRADOR_DARK_P1.jpeg";
import REAL_EMPRADOR_DARK_P2 from "../assets/images/Glossy/REAL_EMPRADOR_DARK_P2.jpeg";
import REAL_EMPRADOR_DARK_P3 from "../assets/images/Glossy/REAL_EMPRADOR_DARK_P3.jpeg";
import REAL_EMPRADOR_DARK_P4 from "../assets/images/Glossy/REAL_EMPRADOR_DARK_P4.jpeg";

import REGALI_BLUE_P1 from "../assets/images/Glossy/REGALI_BLUE_P1.jpeg";
import REGALI_BLUE_P2 from "../assets/images/Glossy/REGALI_BLUE_P2.jpeg";
import REGALI_BLUE_P3 from "../assets/images/Glossy/REGALI_BLUE_P3.jpeg";
import REGALI_BLUE_P4 from "../assets/images/Glossy/REGALI_BLUE_P4.jpeg";

import REGALI_BROWN_P1 from "../assets/images/Glossy/REGALI_BROWN_P1.jpeg";
import REGALI_BROWN_P2 from "../assets/images/Glossy/REGALI_BROWN_P2.jpeg";
import REGALI_BROWN_P3 from "../assets/images/Glossy/REGALI_BROWN_P3.jpeg";
import REGALI_BROWN_P4 from "../assets/images/Glossy/REGALI_BROWN_P4.jpeg";

import ROCK_BROWN_P1 from "../assets/images/Glossy/ROCK_BROWN_P1.jpeg";
import ROCK_BROWN_P2 from "../assets/images/Glossy/ROCK_BROWN_P2.jpeg";
import ROCK_BROWN_P3 from "../assets/images/Glossy/ROCK_BROWN_P3.jpeg";
import ROCK_BROWN_P4 from "../assets/images/Glossy/ROCK_BROWN_P4.jpeg";
import ROCK_BROWN_P5 from "../assets/images/Glossy/ROCK_BROWN_P5.jpeg";

import ROCK_GREY_P1 from "../assets/images/Glossy/ROCK_GREY_P1.jpeg";
import ROCK_GREY_P2 from "../assets/images/Glossy/ROCK_GREY_P2.jpeg";
import ROCK_GREY_P3 from "../assets/images/Glossy/ROCK_GREY_P3.jpeg";
import ROCK_GREY_P4 from "../assets/images/Glossy/ROCK_GREY_P4.jpeg";
import ROCK_GREY_P5 from "../assets/images/Glossy/ROCK_GREY_P5.jpeg";

import ROSALINA_IVORY_P1 from "../assets/images/Glossy/ROSALINA_IVORY_P1.jpeg";
import ROSALINA_IVORY_P2 from "../assets/images/Glossy/ROSALINA_IVORY_P2.jpeg";
import ROSALINA_IVORY_P3 from "../assets/images/Glossy/ROSALINA_IVORY_P3.jpeg";
import ROSALINA_IVORY_P4 from "../assets/images/Glossy/ROSALINA_IVORY_P4.jpeg";
import ROSALINA_IVORY_P5 from "../assets/images/Glossy/ROSALINA_IVORY_P5.jpeg";

import ROYAL_BROWN_P1 from "../assets/images/Glossy/ROYAL_BROWN_P1.jpeg";
import ROYAL_BROWN_P2 from "../assets/images/Glossy/ROYAL_BROWN_P2.jpeg";
import ROYAL_BROWN_P3 from "../assets/images/Glossy/ROYAL_BROWN_P3.jpeg";
import ROYAL_BROWN_P4 from "../assets/images/Glossy/ROYAL_BROWN_P4.jpeg";

import ROYAL_CREMA_P1 from "../assets/images/Glossy/ROYAL_CREMA_P1.jpeg";
import ROYAL_CREMA_P2 from "../assets/images/Glossy/ROYAL_CREMA_P2.jpeg";
import ROYAL_CREMA_P3 from "../assets/images/Glossy/ROYAL_CREMA_P3.jpeg";
import ROYAL_CREMA_P4 from "../assets/images/Glossy/ROYAL_CREMA_P4.jpeg";

import RYNOLD_BEIGE_P1 from "../assets/images/Glossy/RYNOLD_BEIGE_P1.jpeg";
import RYNOLD_BEIGE_P2 from "../assets/images/Glossy/RYNOLD_BEIGE_P2.jpeg";
import RYNOLD_BEIGE_P3 from "../assets/images/Glossy/RYNOLD_BEIGE_P3.jpeg";
import RYNOLD_BEIGE_P4 from "../assets/images/Glossy/RYNOLD_BEIGE_P4.jpeg";
import RYNOLD_BEIGE_P5 from "../assets/images/Glossy/RYNOLD_BEIGE_P5.jpeg";

import SANDSTONE_BROWN_P1 from "../assets/images/Glossy/SANDSTONE_BROWN_P1.jpeg";
import SANDSTONE_BROWN_P2 from "../assets/images/Glossy/SANDSTONE_BROWN_P2.jpeg";
import SANDSTONE_BROWN_P3 from "../assets/images/Glossy/SANDSTONE_BROWN_P3.jpeg";
import SANDSTONE_BROWN_P4 from "../assets/images/Glossy/SANDSTONE_BROWN_P4.jpeg";
import SANDSTONE_BROWN_P5 from "../assets/images/Glossy/SANDSTONE_BROWN_P5.jpeg";
import SANDSTONE_BROWN_P6 from "../assets/images/Glossy/SANDSTONE_BROWN_P6.jpeg";

import SANTAFE_BIANCO_P1 from "../assets/images/Glossy/SANTAFE_BIANCO_P1.jpeg";
import SANTAFE_BIANCO_P2 from "../assets/images/Glossy/SANTAFE_BIANCO_P2.jpeg";
import SANTAFE_BIANCO_P3 from "../assets/images/Glossy/SANTAFE_BIANCO_P3.jpeg";
import SANTAFE_BIANCO_P4 from "../assets/images/Glossy/SANTAFE_BIANCO_P4.jpeg";
import SANTAFE_BIANCO_P5 from "../assets/images/Glossy/SANTAFE_BIANCO_P5.jpeg";

import SANTAFE_BRONZE_P1 from "../assets/images/Glossy/SANTAFE_BRONZE_P1.jpeg";
import SANTAFE_BRONZE_P2 from "../assets/images/Glossy/SANTAFE_BRONZE_P2.jpeg";
import SANTAFE_BRONZE_P3 from "../assets/images/Glossy/SANTAFE_BRONZE_P3.jpeg";
import SANTAFE_BRONZE_P4 from "../assets/images/Glossy/SANTAFE_BRONZE_P4.jpeg";
import SANTAFE_BRONZE_P5 from "../assets/images/Glossy/SANTAFE_BRONZE_P5.jpeg";

import SARENIYA_BEIGE_P1 from "../assets/images/Glossy/SARENIYA_BEIGE_P1.jpeg";
import SARENIYA_BEIGE_P2 from "../assets/images/Glossy/SARENIYA_BEIGE_P2.jpeg";
import SARENIYA_BEIGE_P3 from "../assets/images/Glossy/SARENIYA_BEIGE_P3.jpeg";
import SARENIYA_BEIGE_P4 from "../assets/images/Glossy/SARENIYA_BEIGE_P4.jpeg";

import SARENIYA_GREY_P1 from "../assets/images/Glossy/SARENIYA_GREY_P1.jpeg";
import SARENIYA_GREY_P2 from "../assets/images/Glossy/SARENIYA_GREY_P2.jpeg";
import SARENIYA_GREY_P3 from "../assets/images/Glossy/SARENIYA_GREY_P3.jpeg";
import SARENIYA_GREY_P4 from "../assets/images/Glossy/SARENIYA_GREY_P4.jpeg";

import SATAVRIO_BROWN_P1 from "../assets/images/Glossy/SATAVRIO_BROWN_P1.jpeg";
import SATAVRIO_BROWN_P2 from "../assets/images/Glossy/SATAVRIO_BROWN_P2.jpeg";
import SATAVRIO_BROWN_P3 from "../assets/images/Glossy/SATAVRIO_BROWN_P3.jpeg";
import SATAVRIO_BROWN_P4 from "../assets/images/Glossy/SATAVRIO_BROWN_P4.jpeg";

import SATVARIO_PEARL_P1 from "../assets/images/Glossy/SATVARIO_PEARL_P1.jpeg";
import SATVARIO_PEARL_P2 from "../assets/images/Glossy/SATVARIO_PEARL_P2.jpeg";
import SATVARIO_PEARL_P3 from "../assets/images/Glossy/SATVARIO_PEARL_P3.jpeg";
import SATVARIO_PEARL_P4 from "../assets/images/Glossy/SATVARIO_PEARL_P4.jpeg";

import SCREPOLATO_BROWN_P1 from "../assets/images/Glossy/SCREPOLATO_BROWN_P1.jpeg";
import SCREPOLATO_BROWN_P2 from "../assets/images/Glossy/SCREPOLATO_BROWN_P2.jpeg";
import SCREPOLATO_BROWN_P3 from "../assets/images/Glossy/SCREPOLATO_BROWN_P3.jpeg";
import SCREPOLATO_BROWN_P4 from "../assets/images/Glossy/SCREPOLATO_BROWN_P4.jpeg";

import SCREPOLATO_NERO_P1 from "../assets/images/Glossy/SCREPOLATO_NERO_P1.jpeg";
import SCREPOLATO_NERO_P2 from "../assets/images/Glossy/SCREPOLATO_NERO_P2.jpeg";
import SCREPOLATO_NERO_P3 from "../assets/images/Glossy/SCREPOLATO_NERO_P3.jpeg";
import SCREPOLATO_NERO_P4 from "../assets/images/Glossy/SCREPOLATO_NERO_P4.jpeg";

import SEA_STONE_P1 from "../assets/images/Glossy/SEA_STONE_P1.jpeg";
import SEA_STONE_P2 from "../assets/images/Glossy/SEA_STONE_P2.jpeg";

import SIBIRIAN_BEIGE from "../assets/images/Glossy/SIBIRIAN_BEIGE.jpeg";
import SIBIRIAN_BIANCO from "../assets/images/Glossy/SIBIRIAN_BIANCO.jpeg";

import SICILIA_CLASSICO_P1 from "../assets/images/Glossy/SICILIA_CLASSICO_P1.jpeg";
import SICILIA_CLASSICO_P2 from "../assets/images/Glossy/SICILIA_CLASSICO_P2.jpeg";
import SICILIA_CLASSICO_P3 from "../assets/images/Glossy/SICILIA_CLASSICO_P3.jpeg";

import SIGMA_CREMA_P1 from "../assets/images/Glossy/SIGMA_CREMA_P1.jpeg";
import SIGMA_CREMA_P2 from "../assets/images/Glossy/SIGMA_CREMA_P2.jpeg";
import SIGMA_CREMA_P3 from "../assets/images/Glossy/SIGMA_CREMA_P3.jpeg";
import SIGMA_CREMA_P4 from "../assets/images/Glossy/SIGMA_CREMA_P4.jpeg";

import SIGMA_GREY_P1 from "../assets/images/Glossy/SIGMA_GREY_P1.jpeg";
import SIGMA_GREY_P2 from "../assets/images/Glossy/SIGMA_GREY_P2.jpeg";
import SIGMA_GREY_P3 from "../assets/images/Glossy/SIGMA_GREY_P3.jpeg";
import SIGMA_GREY_P4 from "../assets/images/Glossy/SIGMA_GREY_P4.jpeg";

import SILKEN_CREMA_P1 from "../assets/images/Glossy/SILKEN_CREMA_P1.jpeg";
import SILKEN_CREMA_P2 from "../assets/images/Glossy/SILKEN_CREMA_P2.jpeg";
import SILKEN_CREMA_P3 from "../assets/images/Glossy/SILKEN_CREMA_P3.jpeg";
import SILKEN_CREMA_P4 from "../assets/images/Glossy/SILKEN_CREMA_P4.jpeg";

import SILKEN_GREY_P1 from "../assets/images/Glossy/SILKEN_GREY_P1.jpeg";
import SILKEN_GREY_P2 from "../assets/images/Glossy/SILKEN_GREY_P2.jpeg";
import SILKEN_GREY_P3 from "../assets/images/Glossy/SILKEN_GREY_P3.jpeg";
import SILKEN_GREY_P4 from "../assets/images/Glossy/SILKEN_GREY_P4.jpeg";

import SILVER_ONYX_IVORY_P1 from "../assets/images/Glossy/SILVER_ONYX_IVORY_P1.jpeg";
import SILVER_ONYX_IVORY_P2 from "../assets/images/Glossy/SILVER_ONYX_IVORY_P2.jpeg";
import SILVER_ONYX_IVORY_P3 from "../assets/images/Glossy/SILVER_ONYX_IVORY_P3.jpeg";
import SILVER_ONYX_IVORY_P4 from "../assets/images/Glossy/SILVER_ONYX_IVORY_P4.jpeg";
import SILVER_ONYX_IVORY_P5 from "../assets/images/Glossy/SILVER_ONYX_IVORY_P5.jpeg";

import SILVER_WAVE_P1 from "../assets/images/Glossy/SILVER_WAVE_P1.jpeg";
import SILVER_WAVE_P2 from "../assets/images/Glossy/SILVER_WAVE_P2.jpeg";
import SILVER_WAVE_P3 from "../assets/images/Glossy/SILVER_WAVE_P3.jpeg";
import SILVER_WAVE_P4 from "../assets/images/Glossy/SILVER_WAVE_P4.jpeg";

import SINE_WAVE_P1 from "../assets/images/Glossy/SINE_WAVE_P1.jpeg";
import SINE_WAVE_P2 from "../assets/images/Glossy/SINE_WAVE_P2.jpeg";
import SINE_WAVE_P3 from "../assets/images/Glossy/SINE_WAVE_P3.jpeg";
import SINE_WAVE_P4 from "../assets/images/Glossy/SINE_WAVE_P4.jpeg";
import SINE_WAVE_P5 from "../assets/images/Glossy/SINE_WAVE_P5.jpeg";

import SKYRIUM_ONYX_P1 from "../assets/images/Glossy/SKYRIUM_ONYX_P1.jpeg";
import SKYRIUM_ONYX_P2 from "../assets/images/Glossy/SKYRIUM_ONYX_P2.jpeg";
import SKYRIUM_ONYX_P3 from "../assets/images/Glossy/SKYRIUM_ONYX_P3.jpeg";
import SKYRIUM_ONYX_P4 from "../assets/images/Glossy/SKYRIUM_ONYX_P4.jpeg";

import SMOKE_BIANCO_P1 from "../assets/images/Glossy/SMOKE_BIANCO_P1.jpeg";
import SMOKE_BIANCO_P2 from "../assets/images/Glossy/SMOKE_BIANCO_P2.jpeg";
import SMOKE_BIANCO_P3 from "../assets/images/Glossy/SMOKE_BIANCO_P3.jpeg";
import SMOKE_BIANCO_P4 from "../assets/images/Glossy/SMOKE_BIANCO_P4.jpeg";

import SMOKE_BROWN_P1 from "../assets/images/Glossy/SMOKE_BROWN_P1.jpeg";
import SMOKE_BROWN_P2 from "../assets/images/Glossy/SMOKE_BROWN_P2.jpeg";
import SMOKE_BROWN_P3 from "../assets/images/Glossy/SMOKE_BROWN_P3.jpeg";
import SMOKE_BROWN_P4 from "../assets/images/Glossy/SMOKE_BROWN_P4.jpeg";

import SNOW_PEARL_P1 from "../assets/images/Glossy/SNOW_PEARL_P1.jpeg";
import SNOW_PEARL_P2 from "../assets/images/Glossy/SNOW_PEARL_P2.jpeg";
import SNOW_PEARL_P3 from "../assets/images/Glossy/SNOW_PEARL_P3.jpeg";
import SNOW_PEARL_P4 from "../assets/images/Glossy/SNOW_PEARL_P4.jpeg";

import SPARROW_BEIGE_P1 from "../assets/images/Glossy/SPARROW_BEIGE_P1.jpeg";
import SPARROW_BEIGE_P2 from "../assets/images/Glossy/SPARROW_BEIGE_P2.jpeg";
import SPARROW_BEIGE_P3 from "../assets/images/Glossy/SPARROW_BEIGE_P3.jpeg";
import SPARROW_BEIGE_P4 from "../assets/images/Glossy/SPARROW_BEIGE_P4.jpeg";

import SPARTA_CREMA_P1 from "../assets/images/Glossy/SPARTA_CREMA_P1.jpeg";
import SPARTA_CREMA_P2 from "../assets/images/Glossy/SPARTA_CREMA_P2.jpeg";
import SPARTA_CREMA_P3 from "../assets/images/Glossy/SPARTA_CREMA_P3.jpeg";
import SPARTA_CREMA_P4 from "../assets/images/Glossy/SPARTA_CREMA_P4.jpeg";

import SPARTA_GREY_P1 from "../assets/images/Glossy/SPARTA_GREY_P1.jpeg";
import SPARTA_GREY_P2 from "../assets/images/Glossy/SPARTA_GREY_P2.jpeg";
import SPARTA_GREY_P3 from "../assets/images/Glossy/SPARTA_GREY_P3.jpeg";
import SPARTA_GREY_P4 from "../assets/images/Glossy/SPARTA_GREY_P4.jpeg";

import SPIDER_BROWN_P1 from "../assets/images/Glossy/SPIDER_BROWN_P1.jpeg";
import SPIDER_BROWN_P2 from "../assets/images/Glossy/SPIDER_BROWN_P2.jpeg";
import SPIDER_BROWN_P3 from "../assets/images/Glossy/SPIDER_BROWN_P3.jpeg";
import SPIDER_BROWN_P4 from "../assets/images/Glossy/SPIDER_BROWN_P4.jpeg";
import SPIDER_BROWN_P5 from "../assets/images/Glossy/SPIDER_BROWN_P5.jpeg";

import SPIDER_NERO_P1 from "../assets/images/Glossy/SPIDER_NERO_P1.jpeg";
import SPIDER_NERO_P2 from "../assets/images/Glossy/SPIDER_NERO_P2.jpeg";
import SPIDER_NERO_P3 from "../assets/images/Glossy/SPIDER_NERO_P3.jpeg";
import SPIDER_NERO_P4 from "../assets/images/Glossy/SPIDER_NERO_P4.jpeg";
import SPIDER_NERO_P5 from "../assets/images/Glossy/SPIDER_NERO_P5.jpeg";

import SPLENDOR_ANTICO_P1 from "../assets/images/Glossy/SPLENDOR_ANTICO_P1.jpeg";
import SPLENDOR_ANTICO_P2 from "../assets/images/Glossy/SPLENDOR_ANTICO_P2.jpeg";
import SPLENDOR_ANTICO_P3 from "../assets/images/Glossy/SPLENDOR_ANTICO_P3.jpeg";
import SPLENDOR_ANTICO_P4 from "../assets/images/Glossy/SPLENDOR_ANTICO_P4.jpeg";
import SPLENDOR_ANTICO_P5 from "../assets/images/Glossy/SPLENDOR_ANTICO_P5.jpeg";

import STATURIO_GOLD_P1 from "../assets/images/Glossy/STATURIO_GOLD_P1.jpeg";
import STATURIO_GOLD_P2 from "../assets/images/Glossy/STATURIO_GOLD_P2.jpeg";
import STATURIO_GOLD_P3 from "../assets/images/Glossy/STATURIO_GOLD_P3.jpeg";
import STATURIO_GOLD_P4 from "../assets/images/Glossy/STATURIO_GOLD_P4.jpeg";

import STEEL_BIANCO_P1 from "../assets/images/Glossy/STEEL_BIANCO_P1.jpeg";
import STEEL_BIANCO_P2 from "../assets/images/Glossy/STEEL_BIANCO_P2.jpeg";
import STEEL_BIANCO_P3 from "../assets/images/Glossy/STEEL_BIANCO_P3.jpeg";
import STEEL_BIANCO_P4 from "../assets/images/Glossy/STEEL_BIANCO_P4.jpeg";
import STEEL_BIANCO_P5 from "../assets/images/Glossy/STEEL_BIANCO_P5.jpeg";

import STEEL_CHOCO_P1 from "../assets/images/Glossy/STEEL_CHOCO_P1.jpeg";
import STEEL_CHOCO_P2 from "../assets/images/Glossy/STEEL_CHOCO_P2.jpeg";
import STEEL_CHOCO_P3 from "../assets/images/Glossy/STEEL_CHOCO_P3.jpeg";
import STEEL_CHOCO_P4 from "../assets/images/Glossy/STEEL_CHOCO_P4.jpeg";
import STEEL_CHOCO_P5 from "../assets/images/Glossy/STEEL_CHOCO_P5.jpeg";

import STEEL_GREY_P1 from "../assets/images/Glossy/STEEL_GREY_P1.jpeg";
import STEEL_GREY_P2 from "../assets/images/Glossy/STEEL_GREY_P2.jpeg";
import STEEL_GREY_P3 from "../assets/images/Glossy/STEEL_GREY_P3.jpeg";
import STEEL_GREY_P4 from "../assets/images/Glossy/STEEL_GREY_P4.jpeg";
import STEEL_GREY_P5 from "../assets/images/Glossy/STEEL_GREY_P5.jpeg";

import STONELA_STONE from "../assets/images/Glossy/STONELA_STONE.jpeg";

import SWEDE_P1 from "../assets/images/Glossy/SWEDE_P1.jpeg";
import SWEDE_P2 from "../assets/images/Glossy/SWEDE_P2.jpeg";
import SWEDE_P3 from "../assets/images/Glossy/SWEDE_P3.jpeg";
import SWEDE_P4 from "../assets/images/Glossy/SWEDE_P4.jpeg";
import SWEDE_P5 from "../assets/images/Glossy/SWEDE_P5.jpeg";

import SWEDE_GREY_P1 from "../assets/images/Glossy/SWEDE_GREY_P1.jpeg";
import SWEDE_GREY_P2 from "../assets/images/Glossy/SWEDE_GREY_P2.jpeg";
import SWEDE_GREY_P3 from "../assets/images/Glossy/SWEDE_GREY_P3.jpeg";
import SWEDE_GREY_P4 from "../assets/images/Glossy/SWEDE_GREY_P4.jpeg";
import SWEDE_GREY_P5 from "../assets/images/Glossy/SWEDE_GREY_P5.jpeg";

import SWISS_STATURIO_P1 from "../assets/images/Glossy/SWISS_STATURIO_P1.jpeg";
import SWISS_STATURIO_P2 from "../assets/images/Glossy/SWISS_STATURIO_P2.jpeg";
import SWISS_STATURIO_P3 from "../assets/images/Glossy/SWISS_STATURIO_P3.jpeg";

import TASANT_STATURIO_P1 from "../assets/images/Glossy/TASANT_STATURIO_P1.jpeg";
import TASANT_STATURIO_P2 from "../assets/images/Glossy/TASANT_STATURIO_P2.jpeg";
import TASANT_STATURIO_P3 from "../assets/images/Glossy/TASANT_STATURIO_P3.jpeg";
import TASANT_STATURIO_P4 from "../assets/images/Glossy/TASANT_STATURIO_P4.jpeg";

import THAI_SHELL_P1 from "../assets/images/Glossy/THAI_SHELL_P1.jpeg";
import THAI_SHELL_P2 from "../assets/images/Glossy/THAI_SHELL_P2.jpeg";
import THAI_SHELL_P3 from "../assets/images/Glossy/THAI_SHELL_P3.jpeg";
import THAI_SHELL_P4 from "../assets/images/Glossy/THAI_SHELL_P4.jpeg";

import TOBACCO_BROWN_P1 from "../assets/images/Glossy/TOBACCO_BROWN_P1.jpeg";
import TOBACCO_BROWN_P2 from "../assets/images/Glossy/TOBACCO_BROWN_P2.jpeg";
import TOBACCO_BROWN_P3 from "../assets/images/Glossy/TOBACCO_BROWN_P3.jpeg";
import TOBACCO_BROWN_P4 from "../assets/images/Glossy/TOBACCO_BROWN_P4.jpeg";

import TOBACCO_CREMA_P1 from "../assets/images/Glossy/TOBACCO_CREMA_P1.jpeg";
import TOBACCO_CREMA_P2 from "../assets/images/Glossy/TOBACCO_CREMA_P2.jpeg";
import TOBACCO_CREMA_P3 from "../assets/images/Glossy/TOBACCO_CREMA_P3.jpeg";
import TOBACCO_CREMA_P4 from "../assets/images/Glossy/TOBACCO_CREMA_P4.jpeg";

import TOPAZ_SHELL_P1 from "../assets/images/Glossy/TOPAZ_SHELL_P1.jpeg";
import TOPAZ_SHELL_P2 from "../assets/images/Glossy/TOPAZ_SHELL_P2.jpeg";
import TOPAZ_SHELL_P3 from "../assets/images/Glossy/TOPAZ_SHELL_P3.jpeg";
import TOPAZ_SHELL_P4 from "../assets/images/Glossy/TOPAZ_SHELL_P4.jpeg";

import TORRENT_BEIGE_P1 from "../assets/images/Glossy/TORRENT_BEIGE_P1.jpeg";
import TORRENT_BEIGE_P2 from "../assets/images/Glossy/TORRENT_BEIGE_P2.jpeg";
import TORRENT_BEIGE_P3 from "../assets/images/Glossy/TORRENT_BEIGE_P3.jpeg";
import TORRENT_BEIGE_P4 from "../assets/images/Glossy/TORRENT_BEIGE_P4.jpeg";

import TRAVENTINE_BEIGE_P1 from "../assets/images/Glossy/TRAVENTINE_BEIGE_P1.jpeg";
import TRAVENTINE_BEIGE_P2 from "../assets/images/Glossy/TRAVENTINE_BEIGE_P2.jpeg";
import TRAVENTINE_BEIGE_P3 from "../assets/images/Glossy/TRAVENTINE_BEIGE_P3.jpeg";
import TRAVENTINE_BEIGE_P4 from "../assets/images/Glossy/TRAVENTINE_BEIGE_P4.jpeg";
import TRAVENTINE_BEIGE_P5 from "../assets/images/Glossy/TRAVENTINE_BEIGE_P4.jpeg";

import TRAVENTINE_CREMA_P1 from "../assets/images/Glossy/TRAVENTINE_CREMA_P1.jpeg";
import TRAVENTINE_CREMA_P2 from "../assets/images/Glossy/TRAVENTINE_CREMA_P2.jpeg";
import TRAVENTINE_CREMA_P3 from "../assets/images/Glossy/TRAVENTINE_CREMA_P3.jpeg";
import TRAVENTINE_CREMA_P4 from "../assets/images/Glossy/TRAVENTINE_CREMA_P4.jpeg";
import TRAVENTINE_CREMA_P5 from "../assets/images/Glossy/TRAVENTINE_CREMA_P4.jpeg";

import TRAVENTINE_GREY_P1 from "../assets/images/Glossy/TRAVENTINE_GREY_P1.jpeg";
import TRAVENTINE_GREY_P2 from "../assets/images/Glossy/TRAVENTINE_GREY_P2.jpeg";
import TRAVENTINE_GREY_P3 from "../assets/images/Glossy/TRAVENTINE_GREY_P3.jpeg";
import TRAVENTINE_GREY_P4 from "../assets/images/Glossy/TRAVENTINE_GREY_P4.jpeg";
import TRAVENTINE_GREY_P5 from "../assets/images/Glossy/TRAVENTINE_GREY_P5.jpeg";

import TRAVENTINE_NATURAL_P1 from "../assets/images/Glossy/TRAVENTINE_NATURAL_P1.jpeg";
import TRAVENTINE_NATURAL_P2 from "../assets/images/Glossy/TRAVENTINE_NATURAL_P2.jpeg";
import TRAVENTINE_NATURAL_P3 from "../assets/images/Glossy/TRAVENTINE_NATURAL_P3.jpeg";
import TRAVENTINE_NATURAL_P4 from "../assets/images/Glossy/TRAVENTINE_NATURAL_P4.jpeg";
import TRAVENTINE_NATURAL_P5 from "../assets/images/Glossy/TRAVENTINE_NATURAL_P5.jpeg";

import TRAVEX_BEIGE_P1 from "../assets/images/Glossy/TRAVEX_BEIGE_P1.jpeg";
import TRAVEX_BEIGE_P2 from "../assets/images/Glossy/TRAVEX_BEIGE_P2.jpeg";
import TRAVEX_BEIGE_P3 from "../assets/images/Glossy/TRAVEX_BEIGE_P3.jpeg";
import TRAVEX_BEIGE_P4 from "../assets/images/Glossy/TRAVEX_BEIGE_P4.jpeg";

import TRAVEX_BROWN_P1 from "../assets/images/Glossy/TRAVEX_BROWN_P1.jpeg";
import TRAVEX_BROWN_P2 from "../assets/images/Glossy/TRAVEX_BROWN_P2.jpeg";
import TRAVEX_BROWN_P3 from "../assets/images/Glossy/TRAVEX_BROWN_P3.jpeg";
import TRAVEX_BROWN_P4 from "../assets/images/Glossy/TRAVEX_BROWN_P4.jpeg";

import TREASURE_GREY_P1 from "../assets/images/Glossy/TREASURE_GREY_P1.jpeg";
import TREASURE_GREY_P2 from "../assets/images/Glossy/TREASURE_GREY_P2.jpeg";
import TREASURE_GREY_P3 from "../assets/images/Glossy/TREASURE_GREY_P3.jpeg";
import TREASURE_GREY_P4 from "../assets/images/Glossy/TREASURE_GREY_P4.jpeg";

import TRECO_GRESS_P1 from "../assets/images/Glossy/TRECO_GRESS_P1.jpeg";
import TRECO_GRESS_P2 from "../assets/images/Glossy/TRECO_GRESS_P2.jpeg";
import TRECO_GRESS_P3 from "../assets/images/Glossy/TRECO_GRESS_P3.jpeg";
import TRECO_GRESS_P4 from "../assets/images/Glossy/TRECO_GRESS_P4.jpeg";

import VALENCIA_CREMA_P1 from "../assets/images/Glossy/VALENCIA_CREMA_P1.jpeg";
import VALENCIA_CREMA_P2 from "../assets/images/Glossy/VALENCIA_CREMA_P2.jpeg";
import VALENCIA_CREMA_P3 from "../assets/images/Glossy/VALENCIA_CREMA_P3.jpeg";

import VALENTINO_P1 from "../assets/images/Glossy/VALENTINO_P1.jpeg";
import VALENTINO_P2 from "../assets/images/Glossy/VALENTINO_P2.jpeg";
import VALENTINO_P3 from "../assets/images/Glossy/VALENTINO_P3.jpeg";
import VALENTINO_P4 from "../assets/images/Glossy/VALENTINO_P4.jpeg";

import VANATO_BIANCO_P1 from "../assets/images/Glossy/VANATO_BIANCO_P1.jpeg";
import VANATO_BIANCO_P2 from "../assets/images/Glossy/VANATO_BIANCO_P2.jpeg";
import VANATO_BIANCO_P3 from "../assets/images/Glossy/VANATO_BIANCO_P3.jpeg";
import VANATO_BIANCO_P4 from "../assets/images/Glossy/VANATO_BIANCO_P4.jpeg";

import VARSOVA_BIANCO_P1 from "../assets/images/Glossy/VARSOVA_BIANCO_P1.jpeg";
import VARSOVA_BIANCO_P2 from "../assets/images/Glossy/VARSOVA_BIANCO_P2.jpeg";
import VARSOVA_BIANCO_P3 from "../assets/images/Glossy/VARSOVA_BIANCO_P3.jpeg";
import VARSOVA_BIANCO_P4 from "../assets/images/Glossy/VARSOVA_BIANCO_P4.jpeg";

import VECTOR_BIANCO_P1 from "../assets/images/Glossy/VECTOR_BIANCO_P1.jpeg";
import VECTOR_BIANCO_P2 from "../assets/images/Glossy/VECTOR_BIANCO_P2.jpeg";
import VECTOR_BIANCO_P3 from "../assets/images/Glossy/VECTOR_BIANCO_P3.jpeg";
import VECTOR_BIANCO_P4 from "../assets/images/Glossy/VECTOR_BIANCO_P4.jpeg";

import VEGAS_SMOKE_P1 from "../assets/images/Glossy/VEGAS_SMOKE_P1.jpeg";
import VEGAS_SMOKE_P2 from "../assets/images/Glossy/VEGAS_SMOKE_P2.jpeg";
import VEGAS_SMOKE_P3 from "../assets/images/Glossy/VEGAS_SMOKE_P3.jpeg";
import VEGAS_SMOKE_P4 from "../assets/images/Glossy/VEGAS_SMOKE_P4.jpeg";

import VENUS_CHOCO_P1 from "../assets/images/Glossy/VENUS_CHOCO_P1.jpeg";
import VENUS_CHOCO_P2 from "../assets/images/Glossy/VENUS_CHOCO_P2.jpeg";
import VENUS_CHOCO_P3 from "../assets/images/Glossy/VENUS_CHOCO_P3.jpeg";
import VENUS_CHOCO_P4 from "../assets/images/Glossy/VENUS_CHOCO_P4.jpeg";

import VENUS_WHITE_P1 from "../assets/images/Glossy/VENUS_WHITE_P1.jpeg";
import VENUS_WHITE_P2 from "../assets/images/Glossy/VENUS_WHITE_P2.jpeg";
import VENUS_WHITE_P3 from "../assets/images/Glossy/VENUS_WHITE_P3.jpeg";
import VENUS_WHITE_P4 from "../assets/images/Glossy/VENUS_WHITE_P4.jpeg";

import VIENTI_STATURIO_P1 from "../assets/images/Glossy/VIENTI_STATURIO_P1.jpeg";
import VIENTI_STATURIO_P2 from "../assets/images/Glossy/VIENTI_STATURIO_P2.jpeg";
import VIENTI_STATURIO_P3 from "../assets/images/Glossy/VIENTI_STATURIO_P3.jpeg";
import VIENTI_STATURIO_P4 from "../assets/images/Glossy/VIENTI_STATURIO_P4.jpeg";

import VIGO_STATURIO_P1 from "../assets/images/Glossy/VIGO_STATURIO_P1.jpeg";
import VIGO_STATURIO_P2 from "../assets/images/Glossy/VIGO_STATURIO_P2.jpeg";
import VIGO_STATURIO_P3 from "../assets/images/Glossy/VIGO_STATURIO_P3.jpeg";
import VIGO_STATURIO_P4 from "../assets/images/Glossy/VIGO_STATURIO_P4.jpeg";
import VIGO_STATURIO_P5 from "../assets/images/Glossy/VIGO_STATURIO_P5.jpeg";
import VIGO_STATURIO_P6 from "../assets/images/Glossy/VIGO_STATURIO_P6.jpeg";
import VIGO_STATURIO_P7 from "../assets/images/Glossy/VIGO_STATURIO_P7.jpeg";

import VOLGA_BROWN_P1 from "../assets/images/Glossy/VOLGA_BROWN_P1.jpeg";
import VOLGA_BROWN_P2 from "../assets/images/Glossy/VOLGA_BROWN_P2.jpeg";
import VOLGA_BROWN_P3 from "../assets/images/Glossy/VOLGA_BROWN_P3.jpeg";
import VOLGA_BROWN_P4 from "../assets/images/Glossy/VOLGA_BROWN_P4.jpeg";

import VOLGA_MAROON_P1 from "../assets/images/Glossy/VOLGA_MAROON_P1.jpeg";
import VOLGA_MAROON_P2 from "../assets/images/Glossy/VOLGA_MAROON_P2.jpeg";
import VOLGA_MAROON_P3 from "../assets/images/Glossy/VOLGA_MAROON_P3.jpeg";
import VOLGA_MAROON_P4 from "../assets/images/Glossy/VOLGA_MAROON_P4.jpeg";

import VOLTAS_BEIGE_P1 from "../assets/images/Glossy/VOLTAS_BEIGE_P1.jpeg";
import VOLTAS_BEIGE_P2 from "../assets/images/Glossy/VOLTAS_BEIGE_P2.jpeg";
import VOLTAS_BEIGE_P3 from "../assets/images/Glossy/VOLTAS_BEIGE_P3.jpeg";
import VOLTAS_BEIGE_P4 from "../assets/images/Glossy/VOLTAS_BEIGE_P4.jpeg";

import VOLTAs__BEIGE_P1 from "../assets/images/Glossy/VOLTAs__BEIGE_P1.jpeg";
import VOLTAs__BEIGE_P2 from "../assets/images/Glossy/VOLTAs__BEIGE_P2.jpeg";
import VOLTAs__BEIGE_P3 from "../assets/images/Glossy/VOLTAs__BEIGE_P3.jpeg";
import VOLTAs__BEIGE_P4 from "../assets/images/Glossy/VOLTAs__BEIGE_P4.jpeg";
import VOLTAs__BEIGE_P5 from "../assets/images/Glossy/VOLTAs__BEIGE_P5.jpeg";

import WILLIAM_GREY_EXOTIC_KRYPTON_P1 from "../assets/images/Glossy/WILLIAM_GREY_EXOTIC_KRYPTON_P1.jpeg";
import WILLIAM_GREY_EXOTIC_KRYPTON_P2 from "../assets/images/Glossy/WILLIAM_GREY_EXOTIC_KRYPTON_P2.jpeg";
import WILLIAM_GREY_EXOTIC_KRYPTON_P3 from "../assets/images/Glossy/WILLIAM_GREY_EXOTIC_KRYPTON_P3.jpeg";
import WILLIAM_GREY_EXOTIC_KRYPTON_P4 from "../assets/images/Glossy/WILLIAM_GREY_EXOTIC_KRYPTON_P4.jpeg";

import WILLIAM_LIGHT_GREY_EXOTIC_P1 from "../assets/images/Glossy/WILLIAM_LIGHT_GREY_EXOTIC_P1.jpeg";
import WILLIAM_LIGHT_GREY_EXOTIC_P2 from "../assets/images/Glossy/WILLIAM_LIGHT_GREY_EXOTIC_P2.jpeg";
import WILLIAM_LIGHT_GREY_EXOTIC_P3 from "../assets/images/Glossy/WILLIAM_LIGHT_GREY_EXOTIC_P3.jpeg";
import WILLIAM_LIGHT_GREY_EXOTIC_P4 from "../assets/images/Glossy/WILLIAM_LIGHT_GREY_EXOTIC_P4.jpeg";

import WILSON_BIANCO_P1 from "../assets/images/Glossy/WILSON_BIANCO_P1.jpeg";
import WILSON_BIANCO_P2 from "../assets/images/Glossy/WILSON_BIANCO_P2.jpeg";
import WILSON_BIANCO_P3 from "../assets/images/Glossy/WILSON_BIANCO_P3.jpeg";
import WILSON_BIANCO_P4 from "../assets/images/Glossy/WILSON_BIANCO_P4.jpeg";

import WILSON_BROWN_P1 from "../assets/images/Glossy/WILSON_BROWN_P1.jpeg";
import WILSON_BROWN_P2 from "../assets/images/Glossy/WILSON_BROWN_P2.jpeg";
import WILSON_BROWN_P3 from "../assets/images/Glossy/WILSON_BROWN_P3.jpeg";
import WILSON_BROWN_P4 from "../assets/images/Glossy/WILSON_BROWN_P4.jpeg";

import WILSON_NERO_P1 from "../assets/images/Glossy/WILSON_NERO_P1.jpeg";
import WILSON_NERO_P2 from "../assets/images/Glossy/WILSON_NERO_P2.jpeg";
import WILSON_NERO_P3 from "../assets/images/Glossy/WILSON_NERO_P3.jpeg";
import WILSON_NERO_P4 from "../assets/images/Glossy/WILSON_NERO_P4.jpeg";

import ZENIT_BEIGE_P1 from "../assets/images/Glossy/ZENIT_BEIGE_P1.jpeg";
import ZENIT_BEIGE_P2 from "../assets/images/Glossy/ZENIT_BEIGE_P2.jpeg";
import ZENIT_BEIGE_P3 from "../assets/images/Glossy/ZENIT_BEIGE_P3.jpeg";

import ZIMBA_BEIGE_P1 from "../assets/images/Glossy/ZIMBA_BEIGE_P1.jpeg";
import ZIMBA_BEIGE_P2 from "../assets/images/Glossy/ZIMBA_BEIGE_P2.jpeg";
import ZIMBA_BEIGE_P3 from "../assets/images/Glossy/ZIMBA_BEIGE_P3.jpeg";
import ZIMBA_BEIGE_P4 from "../assets/images/Glossy/ZIMBA_BEIGE_P4.jpeg";

import ZIMBA_NATURAL_P1 from "../assets/images/Glossy/ZIMBA_NATURAL_P1.jpeg";
import ZIMBA_NATURAL_P2 from "../assets/images/Glossy/ZIMBA_NATURAL_P2.jpeg";
import ZIMBA_NATURAL_P3 from "../assets/images/Glossy/ZIMBA_NATURAL_P3.jpeg";
import ZIMBA_NATURAL_P4 from "../assets/images/Glossy/ZIMBA_NATURAL_P4.jpeg";

import ZODIAC_BEIGE_P1 from "../assets/images/Glossy/ZODIAC_BEIGE_P1.jpeg";
import ZODIAC_BEIGE_P2 from "../assets/images/Glossy/ZODIAC_BEIGE_P2.jpeg";
import ZODIAC_BEIGE_P3 from "../assets/images/Glossy/ZODIAC_BEIGE_P3.jpeg";

import ZODIAC_BROWN_P1 from "../assets/images/Glossy/ZODIAC_BROWN_P1.jpeg";
import ZODIAC_BROWN_P2 from "../assets/images/Glossy/ZODIAC_BROWN_P2.jpeg";
import ZODIAC_BROWN_P3 from "../assets/images/Glossy/ZODIAC_BROWN_P3.jpeg";

import ZODIAC_STATURIO_P1 from "../assets/images/Glossy/ZODIAC_STATURIO_P1.jpeg";
import ZODIAC_STATURIO_P2 from "../assets/images/Glossy/ZODIAC_STATURIO_P2.jpeg";
import ZODIAC_STATURIO_P3 from "../assets/images/Glossy/ZODIAC_STATURIO_P3.jpeg";
import ZODIAC_STATURIO_P4 from "../assets/images/Glossy/ZODIAC_STATURIO_P4.jpeg";

import ZUVI_CREMA_P1 from "../assets/images/Glossy/ZUVI_CREMA_P1.jpeg";
import ZUVI_CREMA_P2 from "../assets/images/Glossy/ZUVI_CREMA_P2.jpeg";
import ZUVI_CREMA_P3 from "../assets/images/Glossy/ZUVI_CREMA_P3.jpeg";

import ZUVI_GREY_P1 from "../assets/images/Glossy/ZUVI_GREY_P1.jpeg";
import ZUVI_GREY_P2 from "../assets/images/Glossy/ZUVI_GREY_P2.jpeg";
import ZUVI_GREY_P3 from "../assets/images/Glossy/ZUVI_GREY_P3.jpeg";

import REGAL_STRIP_BLUE_P1 from "../assets/images/Matt/REGAL_STRIP_BLUE_P1.jpeg";
import REGAL_STRIP_BLUE_P2 from "../assets/images/Matt/REGAL_STRIP_BLUE_P2.jpeg";
import REGAL_STRIP_BLUE_P3 from "../assets/images/Matt/REGAL_STRIP_BLUE_P3.jpeg";

import REGAL_STRIP_BROWN_P1 from "../assets/images/Matt/REGAL_STRIP_BROWN_P1.jpeg";
import REGAL_STRIP_BROWN_P2 from "../assets/images/Matt/REGAL_STRIP_BROWN_P2.jpeg";
import REGAL_STRIP_BROWN_P3 from "../assets/images/Matt/REGAL_STRIP_BROWN_P3.jpeg";

import REGAL_STRIP_P1 from "../assets/images/Matt/REGAL_STRIP_P1.jpeg";
import REGAL_STRIP_P2 from "../assets/images/Matt/REGAL_STRIP_P2.jpeg";
import REGAL_STRIP_P3 from "../assets/images/Matt/REGAL_STRIP_P3.jpeg";

import TRIANGLE_STRIP_P1 from "../assets/images/Matt/TRIANGLE_STRIP_P1.jpeg";


import ZIG_ZAG_STRIP_P1 from "../assets/images/Matt/ZIG_ZAG_STRIP_P1.jpeg";

import CROSS_STRIP_P1 from "../assets/images/Matt/CROSS_STRIP_P1.jpeg";

import EIGHTEEN_SQUARE_P1 from "../assets/images/Matt/EIGHTEEN_SQUARE_P1.jpeg";

import HAWK_STRIP_P1 from "../assets/images/Matt/HAWK_STRIP_P1.jpeg";

import MULTY_STRIP_P1 from "../assets/images/Matt/MULTY_STRIP_P1.jpeg";




import ALECIA_BLUE_P1 from "../assets/images/Matt/ALECIA_BLUE_P1.jpeg";
import ALECIA_BLUE_P2 from "../assets/images/Matt/ALECIA_BLUE_P2.jpeg";
import ALECIA_BLUE_P3 from "../assets/images/Matt/ALECIA_BLUE_P3.jpeg";

import ALECIA_GRIS_P1 from "../assets/images/Matt/ALECIA_GRIS_P1.jpeg";
import ALECIA_GRIS_P2 from "../assets/images/Matt/ALECIA_GRIS_P2.jpeg";
import ALECIA_GRIS_P3 from "../assets/images/Matt/ALECIA_GRIS_P3.jpeg";

import ARBOR_GRIGIO_P1 from "../assets/images/Matt/ARBOR_GRIGIO_P1.jpeg";
import ARBOR_GRIGIO_P2 from "../assets/images/Matt/ARBOR_GRIGIO_P2.jpeg";
import ARBOR_GRIGIO_P3 from "../assets/images/Matt/ARBOR_GRIGIO_P3.jpeg";
import ARBOR_GRIGIO_P4 from "../assets/images/Matt/ARBOR_GRIGIO_P4.jpeg";

import ARMONIA_BROWN_P1 from "../assets/images/Matt/ARMONIA_BROWN_P1.jpeg";
import ARMONIA_BROWN_P2 from "../assets/images/Matt/ARMONIA_BROWN_P2.jpeg";
import ARMONIA_BROWN_P3 from "../assets/images/Matt/ARMONIA_BROWN_P3.jpeg";

import AYER_ROCK_P1 from "../assets/images/Matt/AYER_ROCK_P1.jpeg";
import AYER_ROCK_P2 from "../assets/images/Matt/AYER_ROCK_P2.jpeg";
import AYER_ROCK_P3 from "../assets/images/Matt/AYER_ROCK_P3.jpeg";
import AYER_ROCK_P4 from "../assets/images/Matt/AYER_ROCK_P4.jpeg";

import CEMENTO_CHARCOAL_P1 from "../assets/images/Matt/CEMENTO_CHARCOAL_P1.jpeg";
import CEMENTO_CHARCOAL_P2 from "../assets/images/Matt/CEMENTO_CHARCOAL_P2.jpeg";
import CEMENTO_CHARCOAL_P3 from "../assets/images/Matt/CEMENTO_CHARCOAL_P3.jpeg";
import CEMENTO_CHARCOAL_P4 from "../assets/images/Matt/CEMENTO_CHARCOAL_P4.jpeg";

import CEMENTO_COTTO_P1 from "../assets/images/Matt/CEMENTO_COTTO_P1.jpeg";
import CEMENTO_COTTO_P2 from "../assets/images/Matt/CEMENTO_COTTO_P2.jpeg";
import CEMENTO_COTTO_P3 from "../assets/images/Matt/CEMENTO_COTTO_P3.jpeg";

import CEMENTO_CREMA_P1 from "../assets/images/Matt/CEMENTO_CREMA_P1.jpeg";
import CEMENTO_CREMA_P2 from "../assets/images/Matt/CEMENTO_CREMA_P2.jpeg";
import CEMENTO_CREMA_P3 from "../assets/images/Matt/CEMENTO_CREMA_P3.jpeg";

import CONCRETE_GREY_P1 from "../assets/images/Matt/CONCRETE_GREY_P1.jpeg";
import CONCRETE_GREY_P2 from "../assets/images/Matt/CONCRETE_GREY_P2.jpeg";

import COPPER_SLATE_CREMA_P1 from "../assets/images/Matt/COPPER_SLATE_CREMA_P1.jpeg";
import COPPER_SLATE_CREMA_P2 from "../assets/images/Matt/COPPER_SLATE_CREMA_P2.jpeg";
import COPPER_SLATE_CREMA_P3 from "../assets/images/Matt/COPPER_SLATE_CREMA_P3.jpeg";

import COPPER_SLATE_GREY_P1 from "../assets/images/Matt/COPPER_SLATE_GREY_P1.jpeg";
import COPPER_SLATE_GREY_P2 from "../assets/images/Matt/COPPER_SLATE_GREY_P2.jpeg";
import COPPER_SLATE_GREY_P3 from "../assets/images/Matt/COPPER_SLATE_GREY_P3.jpeg";

import COPPER_SLATE_WHITE_P1 from "../assets/images/Matt/COPPER_SLATE_WHITE_P1.jpeg";
import COPPER_SLATE_WHITE_P2 from "../assets/images/Matt/COPPER_SLATE_WHITE_P2.jpeg";
import COPPER_SLATE_WHITE_P3 from "../assets/images/Matt/COPPER_SLATE_WHITE_P3.jpeg";

import COTTON_CREMA_P1 from "../assets/images/Matt/COTTON_CREMA_P1.jpeg";
import COTTON_CREMA_P2 from "../assets/images/Matt/COTTON_CREMA_P2.jpeg";
import COTTON_CREMA_P3 from "../assets/images/Matt/COTTON_CREMA_P3.jpeg";
import COTTON_CREMA_P4 from "../assets/images/Matt/COTTON_CREMA_P4.jpeg";

import COTTON_GREY_P1 from "../assets/images/Matt/COTTON_GREY_P1.jpeg";
import COTTON_GREY_P2 from "../assets/images/Matt/COTTON_GREY_P2.jpeg";
import COTTON_GREY_P3 from "../assets/images/Matt/COTTON_GREY_P3.jpeg";
import COTTON_GREY_P4 from "../assets/images/Matt/COTTON_GREY_P4.jpeg";

import CRONOS_GREY_P1 from "../assets/images/Matt/CRONOS_GREY_P1.jpeg";
import CRONOS_GREY_P2 from "../assets/images/Matt/CRONOS_GREY_P2.jpeg";
import CRONOS_GREY_P3 from "../assets/images/Matt/CRONOS_GREY_P3.jpeg";
import CRONOS_GREY_P4 from "../assets/images/Matt/CRONOS_GREY_P4.jpeg";

import CRONOS_ROSSA_P1 from "../assets/images/Matt/CRONOS_ROSSA_P1.jpg";
import CRONOS_ROSSA_P2 from "../assets/images/Matt/CRONOS_ROSSA_P2.jpg";
import CRONOS_ROSSA_P3 from "../assets/images/Matt/CRONOS_ROSSA_P3.jpeg";
import CRONOS_ROSSA_P4 from "../assets/images/Matt/CRONOS_ROSSA_P4.jpg";

import ESHA_BEIGE_P1 from "../assets/images/Matt/ESHA_BEIGE_P1.jpeg";
import ESHA_BEIGE_P2 from "../assets/images/Matt/ESHA_BEIGE_P2.jpeg";
import ESHA_BEIGE_P3 from "../assets/images/Matt/ESHA_BEIGE_P3.jpeg";
import ESHA_BEIGE_P4 from "../assets/images/Matt/ESHA_BEIGE_P4.jpeg";

import ESHA_CREMA_P1 from "../assets/images/Matt/ESHA_CREMA_P1.jpeg";
import ESHA_CREMA_P2 from "../assets/images/Matt/ESHA_CREMA_P2.jpeg";
import ESHA_CREMA_P3 from "../assets/images/Matt/ESHA_CREMA_P3.jpeg";
import ESHA_CREMA_P4 from "../assets/images/Matt/ESHA_CREMA_P3.jpeg";

import ESHA_GREY_P1 from "../assets/images/Matt/ESHA_GREY_P1.jpeg";
import ESHA_GREY_P2 from "../assets/images/Matt/ESHA_GREY_P2.jpeg";
import ESHA_GREY_P3 from "../assets/images/Matt/ESHA_GREY_P3.jpeg";
import ESHA_GREY_P4 from "../assets/images/Matt/ESHA_GREY_P4.jpeg";

import ESHA_STEEL_P1 from "../assets/images/Matt/ESHA_STEEL_P1.jpeg";
import ESHA_STEEL_P2 from "../assets/images/Matt/ESHA_STEEL_P2.jpeg";
import ESHA_STEEL_P3 from "../assets/images/Matt/ESHA_STEEL_P3.jpeg";
import ESHA_STEEL_P4 from "../assets/images/Matt/ESHA_STEEL_P4.jpeg";

import FERRATI_BROWN_P1 from "../assets/images/Matt/FERRATI_BROWN_P1.jpeg";
import FERRATI_BROWN_P2 from "../assets/images/Matt/FERRATI_BROWN_P2.jpeg";
import FERRATI_BROWN_P3 from "../assets/images/Matt/FERRATI_BROWN_P3.jpeg";
import FERRATI_BROWN_P4 from "../assets/images/Matt/FERRATI_BROWN_P4.jpeg";

import FERRATI_CREMA_P1 from "../assets/images/Matt/FERRATI_CREMA_P1.jpeg";
import FERRATI_CREMA_P2 from "../assets/images/Matt/FERRATI_CREMA_P2.jpeg";
import FERRATI_CREMA_P3 from "../assets/images/Matt/FERRATI_CREMA_P3.jpeg";
import FERRATI_CREMA_P4 from "../assets/images/Matt/FERRATI_CREMA_P4.jpeg";

import FERRATI_DARK_P1 from "../assets/images/Matt/FERRATI_DARK_P1.jpeg";
import FERRATI_DARK_P2 from "../assets/images/Matt/FERRATI_DARK_P2.jpeg";
import FERRATI_DARK_P3 from "../assets/images/Matt/FERRATI_DARK_P3.jpeg";
import FERRATI_DARK_P4 from "../assets/images/Matt/FERRATI_DARK_P4.jpeg";

import FERRATI_LIGHT_P1 from "../assets/images/Matt/FERRATI_LIGHT_P1.jpeg";
import FERRATI_LIGHT_P2 from "../assets/images/Matt/FERRATI_LIGHT_P2.jpeg";
import FERRATI_LIGHT_P3 from "../assets/images/Matt/FERRATI_LIGHT_P3.jpeg";
import FERRATI_LIGHT_P4 from "../assets/images/Matt/FERRATI_LIGHT_P4.jpeg";

import FERRATI_SKY_P1 from "../assets/images/Matt/FERRATI_SKY_P1.jpeg";
import FERRATI_SKY_P2 from "../assets/images/Matt/FERRATI_SKY_P2.jpeg";
import FERRATI_SKY_P3 from "../assets/images/Matt/FERRATI_SKY_P3.jpeg";
import FERRATI_SKY_P4 from "../assets/images/Matt/FERRATI_SKY_P4.jpeg";

import FRENCH_BEIGE_P1 from "../assets/images/Matt/FRENCH_BEIGE_P1.jpeg";
import FRENCH_BEIGE_P2 from "../assets/images/Matt/FRENCH_BEIGE_P2.jpeg";
import FRENCH_BEIGE_P3 from "../assets/images/Matt/FRENCH_BEIGE_P3.jpeg";

import FRENCH_BIANCO_P1 from "../assets/images/Matt/FRENCH_BIANCO_P1.jpeg";
import FRENCH_BIANCO_P2 from "../assets/images/Matt/FRENCH_BIANCO_P2.jpeg";
import FRENCH_BIANCO_P3 from "../assets/images/Matt/FRENCH_BIANCO_P3.jpeg";

import FRENCH_CREMA_P1 from "../assets/images/Matt/FRENCH_CREMA_P1.jpeg";
import FRENCH_CREMA_P2 from "../assets/images/Matt/FRENCH_CREMA_P2.jpeg";
import FRENCH_CREMA_P3 from "../assets/images/Matt/FRENCH_CREMA_P3.jpeg";

import FRENCH_GREY_P1 from "../assets/images/Matt/FRENCH_GREY_P1.jpeg";
import FRENCH_GREY_P2 from "../assets/images/Matt/FRENCH_GREY_P2.jpeg";
import FRENCH_GREY_P3 from "../assets/images/Matt/FRENCH_GREY_P3.jpeg";

import INFINITY_CREMA_P1 from "../assets/images/Matt/INFINITY_CREMA_P1.jpeg";
import INFINITY_CREMA_P2 from "../assets/images/Matt/INFINITY_CREMA_P2.jpeg";
import INFINITY_CREMA_P3 from "../assets/images/Matt/INFINITY_CREMA_P3.jpeg";
import INFINITY_CREMA_P4 from "../assets/images/Matt/INFINITY_CREMA_P4.jpeg";
import INFINITY_CREMA_P5 from "../assets/images/Matt/INFINITY_CREMA_P5.jpeg";

import INFINITY_GRAFITO_P1 from "../assets/images/Matt/INFINITY_GRAFITO_P1.jpeg";
import INFINITY_GRAFITO_P2 from "../assets/images/Matt/INFINITY_GRAFITO_P2.jpeg";
import INFINITY_GRAFITO_P3 from "../assets/images/Matt/INFINITY_GRAFITO_P3.jpeg";
import INFINITY_GRAFITO_P4 from "../assets/images/Matt/INFINITY_GRAFITO_P4.jpeg";
import INFINITY_GRAFITO_P5 from "../assets/images/Matt/INFINITY_GRAFITO_P5.jpeg";

import ISTABUL_BROWN_P1 from "../assets/images/Matt/ISTABUL_BROWN_P1.jpeg";
import ISTABUL_BROWN_P2 from "../assets/images/Matt/ISTABUL_BROWN_P2.jpeg";
import ISTABUL_BROWN_P3 from "../assets/images/Matt/ISTABUL_BROWN_P3.jpeg";
import ISTABUL_BROWN_P4 from "../assets/images/Matt/ISTABUL_BROWN_P4.jpeg";

import ISTABUL_WHITE_P1 from "../assets/images/Matt/ISTABUL_WHITE_P1.jpeg";
import ISTABUL_WHITE_P2 from "../assets/images/Matt/ISTABUL_WHITE_P2.jpeg";
import ISTABUL_WHITE_P3 from "../assets/images/Matt/ISTABUL_WHITE_P3.jpeg";
import ISTABUL_WHITE_P4 from "../assets/images/Matt/ISTABUL_WHITE_P4.jpeg";

import LACA_DARK_GREY_P1 from "../assets/images/Matt/LACA_DARK_GREY_P1.jpeg";

import LACA_LIGHT_GREY_P1 from "../assets/images/Matt/LACA_LIGHT_GREY_P1.jpeg";

import MARBIA_CHARCOAL_P1 from "../assets/images/Matt/MARBIA_CHARCOAL_P1.jpeg";
import MARBIA_CHARCOAL_P2 from "../assets/images/Matt/MARBIA_CHARCOAL_P2.jpeg";
import MARBIA_CHARCOAL_P3 from "../assets/images/Matt/MARBIA_CHARCOAL_P3.jpeg";
import MARBIA_CHARCOAL_P4 from "../assets/images/Matt/MARBIA_CHARCOAL_P4.jpeg";

import MARBIA_GREY_P1 from "../assets/images/Matt/MARBIA_GREY_P1.jpeg";
import MARBIA_GREY_P2 from "../assets/images/Matt/MARBIA_GREY_P2.jpeg";
import MARBIA_GREY_P3 from "../assets/images/Matt/MARBIA_GREY_P3.jpeg";
import MARBIA_GREY_P4 from "../assets/images/Matt/MARBIA_GREY_P4.jpeg";

import MARLIN_BEIGE_P1 from "../assets/images/Matt/MARLIN_BEIGE_P1.jpeg";

import MARLIN_WHITE_P1 from "../assets/images/Matt/MARLIN_WHITE_P1.jpeg";

import MOSAIC_CHARCOAL_P1 from "../assets/images/Matt/MOSAIC_CHARCOAL_P1.jpeg";

import MOSAIC_GREY_P1 from "../assets/images/Matt/MOSAIC_GREY_P1.jpeg";

import ORIENT_CREMA_P1 from "../assets/images/Matt/ORIENT_CREMA_P1.jpeg";
import ORIENT_CREMA_P2 from "../assets/images/Matt/ORIENT_CREMA_P2.jpeg";
import ORIENT_CREMA_P3 from "../assets/images/Matt/ORIENT_CREMA_P3.jpeg";
import ORIENT_CREMA_P4 from "../assets/images/Matt/ORIENT_CREMA_P4.jpeg";
import ORIENT_CREMA_P5 from "../assets/images/Matt/ORIENT_CREMA_P5.jpeg";

import ORIENT_GRESS_P1 from "../assets/images/Matt/ORIENT_GRESS_P1.jpeg";
import ORIENT_GRESS_P2 from "../assets/images/Matt/ORIENT_GRESS_P2.jpeg";
import ORIENT_GRESS_P3 from "../assets/images/Matt/ORIENT_GRESS_P3.jpeg";
import ORIENT_GRESS_P4 from "../assets/images/Matt/ORIENT_GRESS_P4.jpeg";
import ORIENT_GRESS_P5 from "../assets/images/Matt/ORIENT_GRESS_P5.jpeg";

import ORIENT_GREY_P1 from "../assets/images/Matt/ORIENT_GREY_P1.jpeg";
import ORIENT_GREY_P2 from "../assets/images/Matt/ORIENT_GREY_P2.jpeg";
import ORIENT_GREY_P3 from "../assets/images/Matt/ORIENT_GREY_P3.jpeg";
import ORIENT_GREY_P4 from "../assets/images/Matt/ORIENT_GREY_P4.jpeg";
import ORIENT_GREY_P5 from "../assets/images/Matt/ORIENT_GREY_P5.jpeg";

import ORIENT_SMOKE_P1 from "../assets/images/Matt/ORIENT_SMOKE_P1.jpeg";
import ORIENT_SMOKE_P2 from "../assets/images/Matt/ORIENT_SMOKE_P2.jpeg";
import ORIENT_SMOKE_P3 from "../assets/images/Matt/ORIENT_SMOKE_P3.jpeg";
import ORIENT_SMOKE_P4 from "../assets/images/Matt/ORIENT_SMOKE_P4.jpeg";
import ORIENT_SMOKE_P5 from "../assets/images/Matt/ORIENT_SMOKE_P5.jpeg";

import PLANO_BEIGE_P1 from "../assets/images/Matt/PLANO_BEIGE_P1.jpeg";
import PLANO_BEIGE_P2 from "../assets/images/Matt/PLANO_BEIGE_P2.jpeg";

import PLANO_BROWN_P1 from "../assets/images/Matt/PLANO_BROWN_P1.jpeg";
import PLANO_BROWN_P2 from "../assets/images/Matt/PLANO_BROWN_P2.jpeg";

import PLANO_GREY_P1 from "../assets/images/Matt/PLANO_GREY_P1.jpeg";
import PLANO_GREY_P2 from "../assets/images/Matt/PLANO_GREY_P2.jpeg";

import PLANO_NERO_P1 from "../assets/images/Matt/PLANO_NERO_P1.jpeg";
import PLANO_NERO_P2 from "../assets/images/Matt/PLANO_NERO_P2.jpeg";


import ARBOR_GRIGIO_P_1 from "../assets/images/woods/ARBOR_GRIGIO_P_1.jpeg";
import ARBOR_GRIGIO_P_2 from "../assets/images/woods/ARBOR_GRIGIO_P_2.jpeg";
import ARBOR_GRIGIO_P_3 from "../assets/images/woods/ARBOR_GRIGIO_P_3.jpeg";
import ARBOR_GRIGIO_P_4 from "../assets/images/woods/ARBOR_GRIGIO_P_4.jpeg";
import ARBOR_GRIGIO_P_5 from "../assets/images/woods/ARBOR_GRIGIO_P_5.jpeg";

import ARBOR_MUSCHIO_P1 from "../assets/images/woods/ARBOR_MUSCHIO_P1.jpeg";
import ARBOR_MUSCHIO_P2 from "../assets/images/woods/ARBOR_MUSCHIO_P2.jpeg";
import ARBOR_MUSCHIO_P3 from "../assets/images/woods/ARBOR_MUSCHIO_P3.jpeg";
import ARBOR_MUSCHIO_P4 from "../assets/images/woods/ARBOR_MUSCHIO_P4.jpeg";

// import ARBOR_OCRA_P1 from "../assets/images/woods/ARBOR_OCRA_P1.jpeg";
// import ARBOR_OCRA_P2 from "../assets/images/woods/ARBOR_OCRA_P2.jpeg";
// import ARBOR_OCRA_P3 from "../assets/images/woods/ARBOR_OCRA_P3.jpeg";
// import ARBOR_OCRA_P4 from "../assets/images/woods/ARBOR_OCRA_P4.jpeg";

// import ARMONIA_AQUA_P1 from "../assets/images/woods/ARMONIA_AQUA_P1.jpeg";
// import ARMONIA_AQUA_P2 from "../assets/images/woods/ARMONIA_AQUA_P2.jpeg";
// import ARMONIA_AQUA_P3 from "../assets/images/woods/ARMONIA_AQUA_P3.jpeg";
// import ARMONIA_AQUA_P4 from "../assets/images/woods/ARMONIA_AQUA_P4.jpeg";

// import ARTICWOOD_FUMO_P1 from "../assets/images/woods/ARTICWOOD_FUMO_P1.jpeg";
// import ARTICWOOD_FUMO_P2 from "../assets/images/woods/ARTICWOOD_FUMO_P2.jpeg";
// import ARTICWOOD_FUMO_P3 from "../assets/images/woods/ARTICWOOD_FUMO_P3.jpeg";
// import ARTICWOOD_FUMO_P4 from "../assets/images/woods/ARTICWOOD_FUMO_P4.jpeg";

// import ARTICWOOD_BRICHWOOD_WHITE_BLUE_P1 from "../assets/images/woods/ARTICWOOD_BRICHWOOD_WHITE_BLUE_P1.jpeg";
// import ARTICWOOD_BRICHWOOD_WHITE_BLUE_P2 from "../assets/images/woods/ARTICWOOD_BRICHWOOD_WHITE_BLUE_P2.jpeg";
// import ARTICWOOD_BRICHWOOD_WHITE_BLUE_P3 from "../assets/images/woods/ARTICWOOD_BRICHWOOD_WHITE_BLUE_P3.jpeg";
// import ARTICWOOD_BRICHWOOD_WHITE_BLUE_P4 from "../assets/images/woods/ARTICWOOD_BRICHWOOD_WHITE_BLUE_P4.jpeg";

// import BRICHWOOD_CREMA_P1 from "../assets/images/woods/BRICHWOOD_CREMA_P1.jpeg";
// import BRICHWOOD_CREMA_P2 from "../assets/images/woods/BRICHWOOD_CREMA_P2.jpeg";
// import BRICHWOOD_CREMA_P3 from "../assets/images/woods/BRICHWOOD_CREMA_P3.jpeg";
// import BRICHWOOD_CREMA_P4 from "../assets/images/woods/BRICHWOOD_CREMA_P4.jpeg";

// import CEDAR_BEIGE_P1 from "../assets/images/woods/CEDAR_BEIGE_P1.jpeg";
// import CEDAR_BEIGE_P2 from "../assets/images/woods/CEDAR_BEIGE_P2.jpeg";
// import CEDAR_BEIGE_P3 from "../assets/images/woods/CEDAR_BEIGE_P3.jpeg";
// import CEDAR_BEIGE_P4 from "../assets/images/woods/CEDAR_BEIGE_P4.jpeg";

// import CEDAR_MOKA_P1 from "../assets/images/woods/CEDAR_MOKA_P1.jpeg";
// import CEDAR_MOKA_P2 from "../assets/images/woods/CEDAR_MOKA_P2.jpeg";
// import CEDAR_MOKA_P3 from "../assets/images/woods/CEDAR_MOKA_P3.jpeg";
// import CEDAR_MOKA_P4 from "../assets/images/woods/CEDAR_MOKA_P4.jpeg";

// import CEDAR_PINE_P1 from "../assets/images/woods/CEDAR_PINE_P1.jpeg";
// import CEDAR_PINE_P2 from "../assets/images/woods/CEDAR_PINE_P2.jpeg";
// import CEDAR_PINE_P3 from "../assets/images/woods/CEDAR_PINE_P3.jpeg";
// import CEDAR_PINE_P4 from "../assets/images/woods/CEDAR_PINE_P4.jpeg";

// import CEDAR_WHITE_P1 from "../assets/images/woods/CEDAR_WHITE_P1.jpeg";
// import CEDAR_WHITE_P2 from "../assets/images/woods/CEDAR_WHITE_P2.jpeg";
// import CEDAR_WHITE_P3 from "../assets/images/woods/CEDAR_WHITE_P3.jpeg";
// import CEDAR_WHITE_P4 from "../assets/images/woods/CEDAR_WHITE_P4.jpeg";

// import CLARA_WOOD_BEIGE_P1 from "../assets/images/woods/CLARA_WOOD_BEIGE_P1.jpeg";
// import CLARA_WOOD_BEIGE_P2 from "../assets/images/woods/CLARA_WOOD_BEIGE_P2.jpeg";
// import CLARA_WOOD_BEIGE_P3 from "../assets/images/woods/CLARA_WOOD_BEIGE_P3.jpeg";
// import CLARA_WOOD_BEIGE_P4 from "../assets/images/woods/CLARA_WOOD_BEIGE_P4.jpeg";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ADONIS1,
  ADONIS2,

  AIZA_COBALT_P1,
  AIZA_COBALT_P2,
  AIZA_COBALT_P3,
  AIZA_COBALT_P4,

  AIZA_ONYX_P1,
  AIZA_ONYX_P2,
  AIZA_ONYX_P3,
  AIZA_ONYX_P4,

  ALBASTO_BIANCO_P1,
  ALBASTO_BIANCO_P2,
  ALBASTO_BIANCO_P3,
  ALBASTO_BIANCO_P4,

  ALBASTO_BROWN_P1,
  ALBASTO_BROWN_P2,
  ALBASTO_BROWN_P3,
  ALBASTO_BROWN_P4,
  ALBASTO_BROWN_P5,

  ALPINE_STATURIO_P1,
  ALPINE_STATURIO_P2,
  ALPINE_STATURIO_P3,
  ALPINE_STATURIO_P4,
  ALPINE_STATURIO_P5,

  AMAZON_CREMA_P1,
  AMAZON_CREMA_P2,
  AMAZON_CREMA_P3,
  AMAZON_CREMA_P4,

  AMAZON_GREY_P1,
  AMAZON_GREY_P2,
  AMAZON_GREY_P3,
  AMAZON_GREY_P4,

  AMBROSIA_BIANCO_P1,
  AMBROSIA_BIANCO_P2,
  AMBROSIA_BIANCO_P3,
  AMBROSIA_BIANCO_P4,

  AMBROSIA_BROWN_P1,
  AMBROSIA_BROWN_P2,
  AMBROSIA_BROWN_P3,
  AMBROSIA_BROWN_P4,

  ANGEL_SATVARIYO_P1,
  ANGEL_SATVARIYO_P2,
  ANGEL_SATVARIYO_P3,
  ANGEL_SATVARIYO_P4,

  ANTI_QUITY_BEIGE_P1,
  ANTI_QUITY_BEIGE_P2,
  ANTI_QUITY_BEIGE_P3,
  ANTI_QUITY_BEIGE_P4,


  ANTICA_COBALT_P1,
  ANTICA_COBALT_P2,

  ANTICA_MAROON_P1,
  ANTICA_MAROON_P2,

  ANTRACITA_ICE_P1,
  ANTRACITA_ICE_P2,
  ANTRACITA_ICE_P3,
  ANTRACITA_ICE_P4,

  APUANE_SATUARIO_P1,
  APUANE_SATUARIO_P2,
  APUANE_SATUARIO_P3,
  APUANE_SATUARIO_P4,

  ARABIC_BROWN_LIGHT_P1,
  ARABIC_BROWN_LIGHT_P2,
  ARABIC_BROWN_LIGHT_P3,
  ARABIC_BROWN_LIGHT_P4,

  ARABIC_BROWN_P1,
  ARABIC_BROWN_P2,
  ARABIC_BROWN_P3,
  ARABIC_BROWN_P4,

  ARCTIC_WHITE_P1,
  ARCTIC_WHITE_P2,
  ARCTIC_WHITE_P3,

  ARDESIA_COBALT_P1,
  ARDESIA_COBALT_P2,
  ARDESIA_COBALT_P3,
  ARDESIA_COBALT_P4,

  ARDESIA_COBALT_LIGHT_P1,
  ARDESIA_COBALT_LIGHT_P2,
  ARDESIA_COBALT_LIGHT_P3,
  ARDESIA_COBALT_LIGHT_P4,

  ARDESIA_GOLDEN_P1,
  ARDESIA_GOLDEN_P2,
  ARDESIA_GOLDEN_P3,
  ARDESIA_GOLDEN_P4,

  ARMANI_BRONZE_P1,
  ARMANI_BRONZE_P2,
  ARMANI_BRONZE_P3,
  ARMANI_BRONZE_P4,

  ARMANO_BEIGE_P1,
  ARMANO_BEIGE_P2,
  ARMANO_BEIGE_P3,
  ARMANO_BEIGE_P4,
  ARMANO_BEIGE_P5,
  ARMANO_BEIGE_P6,

  ARMANO_BROWN_P1,
  ARMANO_BROWN_P2,
  ARMANO_BROWN_P3,
  ARMANO_BROWN_P4,
  ARMANO_BROWN_P5,
  ARMANO_BROWN_P6,


  ARMANO_CREMA_P1,
  ARMANO_CREMA_P2,
  ARMANO_CREMA_P3,
  ARMANO_CREMA_P4,
  ARMANO_CREMA_P5,
  ARMANO_CREMA_P6,

  ARMANO_GREY_P1,
  ARMANO_GREY_P2,
  ARMANO_GREY_P3,
  ARMANO_GREY_P4,
  ARMANO_GREY_P5,
  ARMANO_GREY_P6,

  ARTICI_BEIGE,
  ARTICI_BIANCO,
  ARTICI_COAL,

  ARTICO_ONYX_BIANCO_P1,
  ARTICO_ONYX_BIANCO_P2,
  ARTICO_ONYX_BIANCO_P3,
  ARTICO_ONYX_BIANCO_P4,

  ARTICO_ONYX_MULTI_P1,
  ARTICO_ONYX_MULTI_P2,
  ARTICO_ONYX_MULTI_P3,
  ARTICO_ONYX_MULTI_P4,

  ATHENA_BIANCO_P1,
  ATHENA_BIANCO_P2,
  ATHENA_BIANCO_P3,
  ATHENA_BIANCO_P4,

  ATHENA_BROWN_P1,
  ATHENA_BROWN_P2,
  ATHENA_BROWN_P3,
  ATHENA_BROWN_P4,

  ATHENA_COBALT_P1,
  ATHENA_COBALT_P2,
  ATHENA_COBALT_P3,
  ATHENA_COBALT_P4,

  ATLAS_CREMA_P1,
  ATLAS_CREMA_P2,
  ATLAS_CREMA_P3,
  ATLAS_CREMA_P4,
  ATLAS_CREMA_P5,

  AUK_GRESS_P1,
  AUK_GRESS_P2,
  AUK_GRESS_P3,
  AUK_GRESS_P4,

  AURA_GOLD_P1,
  AURA_GOLD_P2,
  AURA_GOLD_P3,
  AURA_GOLD_P4,

  AURA_STATURIO_P1,
  AURA_STATURIO_P2,
  AURA_STATURIO_P3,
  AURA_STATURIO_P4,

  AZUL_CREMA_P1,
  AZUL_CREMA_P2,
  AZUL_CREMA_P3,
  AZUL_CREMA_P4,
  AZUL_CREMA_P5,
  AZUL_CREMA_P6,

  BALI_GREY_P1,
  BALI_GREY_P2,
  BALI_GREY_P3,
  BALI_GREY_P4,

  BEIGE_OCEAN_P1,
  BEIGE_OCEAN_P2,
  BEIGE_OCEAN_P3,
  BEIGE_OCEAN_P4,

  BELMONT_BEIGE_P1,
  BELMONT_BEIGE_P2,
  BELMONT_BEIGE_P3,
  BELMONT_BEIGE_P4,

  BELMONT_BROWN_P1,
  BELMONT_BROWN_P2,
  BELMONT_BROWN_P3,
  BELMONT_BROWN_P4,

  BELMONT_GREY_P1,
  BELMONT_GREY_P2,
  BELMONT_GREY_P3,
  BELMONT_GREY_P4,

  BELMONT_WHITE_P1,
  BELMONT_WHITE_P2,
  BELMONT_WHITE_P3,
  BELMONT_WHITE_P4,

  BETON_GREY_P1,
  BETON_GREY_P2,
  BETON_GREY_P3,
  BETON_GREY_P4,

  BETTLE_TRAVENTINO_P1,
  BETTLE_TRAVENTINO_P2,
  BETTLE_TRAVENTINO_P3,
  BETTLE_TRAVENTINO_P4,

  BIANCO_OCEAN_P1,
  BIANCO_OCEAN_P2,
  BIANCO_OCEAN_P3,
  BIANCO_OCEAN_P4,

  BIGETT_BLUE_P1,
  BIGETT_BLUE_P2,
  BIGETT_BLUE_P3,

  BIGETT_BROWN_P1,
  BIGETT_BROWN_P2,
  BIGETT_BROWN_P3,

  BIGETT_CREMA_P1,
  BIGETT_CREMA_P2,
  BIGETT_CREMA_P3,

  BIGETT_GREY_P1,
  BIGETT_GREY_P2,
  BIGETT_GREY_P3,

  BLUE_OCEAN_P1,
  BLUE_OCEAN_P2,
  BLUE_OCEAN_P3,
  BLUE_OCEAN_P4,

  BOSCO_AURORA_P1,
  BOSCO_AURORA_P2,
  BOSCO_AURORA_P3,
  BOSCO_AURORA_P4,

  BOSCO_NATURAL_P1,
  BOSCO_NATURAL_P2,
  BOSCO_NATURAL_P3,
  BOSCO_NATURAL_P4,

  BOTTO_LIGHT_P1,
  BOTTO_LIGHT_P2,
  BOTTO_LIGHT_P3,
  BOTTO_LIGHT_P4,
  BOTTO_LIGHT_P5,

  BOTTO_MARFIL_P1,
  BOTTO_MARFIL_P2,
  BOTTO_MARFIL_P3,
  BOTTO_MARFIL_P4,

  BOTTOCHINO_BIANCO_P1,
  BOTTOCHINO_BIANCO_P2,
  BOTTOCHINO_BIANCO_P3,
  BOTTOCHINO_BIANCO_P4,

  BOTTOCHINO_CLASSICO,

  BOTTOCHINO_CREMA_P1,
  BOTTOCHINO_CREMA_P2,
  BOTTOCHINO_CREMA_P3,
  BOTTOCHINO_CREMA_P4,

  BRESCIA_AURORA_P1,
  BRESCIA_AURORA_P2,
  BRESCIA_AURORA_P3,
  BRESCIA_AURORA_P4,
  BRESCIA_AURORA_P5,
  BRESCIA_AURORA_P6,
  BRESCIA_AURORA_P7,
  BRESCIA_AURORA_P8,

  BRESCIA_NATURAL_P1,
  BRESCIA_NATURAL_P2,
  BRESCIA_NATURAL_P3,
  BRESCIA_NATURAL_P4,
  BRESCIA_NATURAL_P5,
  BRESCIA_NATURAL_P6,
  BRESCIA_NATURAL_P7,
  BRESCIA_NATURAL_P8,

  BRICK_ONIX_BEIGE_P1,
  BRICK_ONIX_BEIGE_P2,
  BRICK_ONIX_BEIGE_P3,
  BRICK_ONIX_BEIGE_P4,
  BRICK_ONIX_BEIGE_P5,

  BRICK_ONIX_BIANCO_P1,
  BRICK_ONIX_BIANCO_P2,
  BRICK_ONIX_BIANCO_P3,
  BRICK_ONIX_BIANCO_P4,
  BRICK_ONIX_BIANCO_P5,

  BRILLO_BEIGE_P1,
  BRILLO_BEIGE_P2,
  BRILLO_BEIGE_P3,
  BRILLO_BEIGE_P4,

  BRILLO_WHITE_P1,
  BRILLO_WHITE_P2,
  BRILLO_WHITE_P3,
  BRILLO_WHITE_P4,

  BRUMA_BIANCO_P1,
  BRUMA_BIANCO_P2,
  BRUMA_BIANCO_P3,
  BRUMA_BIANCO_P4,
  BRUMA_BIANCO_P5,

  BRUMA_CREMA_P1,
  BRUMA_CREMA_P2,
  BRUMA_CREMA_P3,
  BRUMA_CREMA_P4,
  BRUMA_CREMA_P5,

  BRUMA_GREY_P1,
  BRUMA_GREY_P2,
  BRUMA_GREY_P3,
  BRUMA_GREY_P4,
  BRUMA_GREY_P5,

  BURBERRY_BEIGE_P1,
  BURBERRY_BEIGE_P2,
  BURBERRY_BEIGE_P3,
  BURBERRY_BEIGE_P4,
  BURBERRY_BEIGE_P5,

  BURBERRY_CHOCO_P1,
  BURBERRY_CHOCO_P2,
  BURBERRY_CHOCO_P3,
  BURBERRY_CHOCO_P4,
  BURBERRY_CHOCO_P5,

  BURBERRY_CREMA_P1,
  BURBERRY_CREMA_P2,
  BURBERRY_CREMA_P3,
  BURBERRY_CREMA_P4,
  BURBERRY_CREMA_P5,

  BURBERRY_GREY_P1,
  BURBERRY_GREY_P2,
  BURBERRY_GREY_P3,
  BURBERRY_GREY_P4,
  BURBERRY_GREY_P5,

  BURKINA_BEIGE_P1,
  BURKINA_BEIGE_P2,
  BURKINA_BEIGE_P3,
  BURKINA_BEIGE_P4,

  BURKINA_BLUE_P1,
  BURKINA_BLUE_P2,
  BURKINA_BLUE_P3,
  BURKINA_BLUE_P4,

  CALCUTTA_BEIGE_P1,
  CALCUTTA_BEIGE_P2,
  CALCUTTA_BEIGE_P3,
  CALCUTTA_BEIGE_P4,

  CALCUTTA_WHITE_P1,
  CALCUTTA_WHITE_P2,
  CALCUTTA_WHITE_P3,
  CALCUTTA_WHITE_P4,
  CALCUTTA_WHITE_P5,

  CALIPSO_BEIGE_P1,
  CALIPSO_BEIGE_P2,
  CALIPSO_BEIGE_P3,
  CALIPSO_BEIGE_P4,

  CALIPSO_CREMA_P1,
  CALIPSO_CREMA_P2,
  CALIPSO_CREMA_P3,
  CALIPSO_CREMA_P4,

  CALIPSO_GREY_P1,
  CALIPSO_GREY_P2,
  CALIPSO_GREY_P3,
  CALIPSO_GREY_P4,

  CALLIS_CREMA_P1,
  CALLIS_CREMA_P2,
  CALLIS_CREMA_P3,
  CALLIS_CREMA_P4,

  CALLIS_WHITE_P1,
  CALLIS_WHITE_P2,
  CALLIS_WHITE_P3,
  CALLIS_WHITE_P4,

  CARARA_WHITE_P1,
  CARARA_WHITE_P2,
  CARARA_WHITE_P3,
  CARARA_WHITE_P4,
  CARARA_WHITE_P5,

  CARRARA_FOG_BROWN,

  CASTILIA_GOLD_P1,
  CASTILIA_GOLD_P2,
  CASTILIA_GOLD_P3,
  CASTILIA_GOLD_P4,
  CASTILIA_GOLD_P5,


  CHOCO_OCEAN_P1,
  CHOCO_OCEAN_P2,
  CHOCO_OCEAN_P3,
  CHOCO_OCEAN_P4,

  CIERA_MARFIL_LIGHT_P1,
  CIERA_MARFIL_LIGHT_P2,
  CIERA_MARFIL_LIGHT_P3,
  CIERA_MARFIL_LIGHT_P4,

  CLASSIC_CREMA,

  CLOUD_BLUE_P1,
  CLOUD_BLUE_P2,
  CLOUD_BLUE_P3,
  CLOUD_BLUE_P4,

  CLOUD_WHITE_P1,
  CLOUD_WHITE_P2,
  CLOUD_WHITE_P3,
  CLOUD_WHITE_P4,

  COLOUR_ONYX_P1,
  COLOUR_ONYX_P2,
  COLOUR_ONYX_P3,
  COLOUR_ONYX_P4,

  COVENALO_CREMA_P1,
  COVENALO_CREMA_P2,
  COVENALO_CREMA_P3,
  COVENALO_CREMA_P4,
  COVENALO_CREMA_P5,

  CRACKEL_STATURIO_P1,
  CRACKEL_STATURIO_P2,
  CRACKEL_STATURIO_P3,
  CRACKEL_STATURIO_P4,
  CRACKEL_STATURIO_P5,

  CREMA_MARFIL_UTD_P1,
  CREMA_MARFIL_UTD_P2,
  CREMA_MARFIL_UTD_P3,

  CRETONNE_BEIGE_P1,
  CRETONNE_BEIGE_P2,
  CRETONNE_BEIGE_P3,
  CRETONNE_BEIGE_P4,

  CRETONNE_CREMA_P1,
  CRETONNE_CREMA_P2,
  CRETONNE_CREMA_P3,
  CRETONNE_CREMA_P4,

  CRIST_BEIGE_P1,
  CRIST_BEIGE_P2,
  CRIST_BEIGE_P3,
  CRIST_BEIGE_P4,

  CRIST_GREY_P1,
  CRIST_GREY_P2,
  CRIST_GREY_P3,
  CRIST_GREY_P4,

  CRYSTAL_ONYX_BROWN_P1,
  CRYSTAL_ONYX_BROWN_P2,
  CRYSTAL_ONYX_BROWN_P3,
  CRYSTAL_ONYX_BROWN_P4,
  CRYSTAL_ONYX_BROWN_P5,

  CRYSTALONYX_BROWN_P1,
  CRYSTALONYX_BROWN_P2,
  CRYSTALONYX_BROWN_P3,
  CRYSTALONYX_BROWN_P4,
  CRYSTALONYX_BROWN_P5,
  CRYSTALONYX_BROWN_P6,

  CUNTO_STATURIO_P1,
  CUNTO_STATURIO_P2,
  CUNTO_STATURIO_P3,
  CUNTO_STATURIO_P4,
  CUNTO_STATURIO_P5,
  CUNTO_STATURIO_P6,
  CUNTO_STATURIO_P7,

  DESERT_DIANA_P1,
  DESERT_DIANA_P2,
  DESERT_DIANA_P3,
  DESERT_DIANA_P4,

  DESERT_DIANA_GREY_P1,
  DESERT_DIANA_GREY_P2,
  DESERT_DIANA_GREY_P3,
  DESERT_DIANA_GREY_P4,

  DESERT_DIANA_LIGHT_P1,
  DESERT_DIANA_LIGHT_P2,
  DESERT_DIANA_LIGHT_P3,
  DESERT_DIANA_LIGHT_P4,

  DOLLCER_ICE,

  DOLMEN_BIANCO_P1,
  DOLMEN_BIANCO_P2,
  DOLMEN_BIANCO_P3,
  DOLMEN_BIANCO_P4,
  DOLMEN_BIANCO_P5,

  DOLMEN_CHOCO_P1,
  DOLMEN_CHOCO_P2,
  DOLMEN_CHOCO_P3,
  DOLMEN_CHOCO_P4,
  DOLMEN_CHOCO_P5,

  DOMENIC_BEIGE_P1,
  DOMENIC_BEIGE_P2,
  DOMENIC_BEIGE_P3,
  DOMENIC_BEIGE_P4,
  DOMENIC_BEIGE_P5,

  DOMENIC_CREMA_P1,
  DOMENIC_CREMA_P2,
  DOMENIC_CREMA_P3,
  DOMENIC_CREMA_P4,
  DOMENIC_CREMA_P5,

  DOMENIC_GREY_P1,
  DOMENIC_GREY_P2,
  DOMENIC_GREY_P3,
  DOMENIC_GREY_P4,
  DOMENIC_GREY_P5,

  DYNA_BEIGE_P1,
  DYNA_BEIGE_P2,
  DYNA_BEIGE_P3,
  DYNA_BEIGE_P4,
  DYNA_BEIGE_P5,

  DYNA_DISASTER_P1,
  DYNA_DISASTER_P2,
  DYNA_DISASTER_P3,
  DYNA_DISASTER_P4,
  DYNA_DISASTER_P5,
  DYNA_DISASTER_P6,

  DYNA_FANTCY_P1,
  DYNA_FANTCY_P2,
  DYNA_FANTCY_P3,
  DYNA_FANTCY_P4,
  DYNA_FANTCY_P5,

  DYNA_GREY_P1,
  DYNA_GREY_P2,
  DYNA_GREY_P3,
  DYNA_GREY_P4,
  DYNA_GREY_P5,

  DYNA_MULTY_P1,
  DYNA_MULTY_P2,
  DYNA_MULTY_P3,
  DYNA_MULTY_P4,
  DYNA_MULTY_P5,

  DYNO_BEIGE_P1,
  DYNO_BEIGE_P2,
  DYNO_BEIGE_P3,
  DYNO_BEIGE_P4,
  DYNO_BEIGE_P5,
  DYNO_BEIGE_P6,

  DYNO_BIANCO_P1,
  DYNO_BIANCO_P2,
  DYNO_BIANCO_P3,
  DYNO_BIANCO_P4,
  DYNO_BIANCO_P5,
  DYNO_BIANCO_P6,

  EARTH_GLITER_ROSSO_P1,
  EARTH_GLITER_ROSSO_P2,
  EARTH_GLITER_ROSSO_P3,
  EARTH_GLITER_ROSSO_P4,

  EARTH_GLITTER_BEIGE_P1,
  EARTH_GLITTER_BEIGE_P2,
  EARTH_GLITTER_BEIGE_P3,
  EARTH_GLITTER_BEIGE_P4,

  ELECTRONICA_P1,
  ELECTRONICA_P2,
  ELECTRONICA_P3,
  ELECTRONICA_P4,

  ELEMENT_BEIGE_P1,
  ELEMENT_BEIGE_P2,
  ELEMENT_BEIGE_P3,
  ELEMENT_BEIGE_P4,
  ELEMENT_BEIGE_P5,

  ELEMENT_WHITE_P1,
  ELEMENT_WHITE_P2,
  ELEMENT_WHITE_P3,
  ELEMENT_WHITE_P4,
  ELEMENT_WHITE_P5,

  ERAMOSSA_BEIGE_P1,
  ERAMOSSA_BEIGE_P2,
  ERAMOSSA_BEIGE_P3,
  ERAMOSSA_BEIGE_P4,
  ERAMOSSA_BEIGE_P5,

  ERAMOSSA_TOBACCO_P1,
  ERAMOSSA_TOBACCO_P2,
  ERAMOSSA_TOBACCO_P3,
  ERAMOSSA_TOBACCO_P4,
  ERAMOSSA_TOBACCO_P5,

  ESPIRA_BEIGE_P1,
  ESPIRA_BEIGE_P2,
  ESPIRA_BEIGE_P3,
  ESPIRA_BEIGE_P4,
  ESPIRA_BEIGE_P5,

  ESPIRA_CREMA_P1,
  ESPIRA_CREMA_P2,
  ESPIRA_CREMA_P3,
  ESPIRA_CREMA_P4,
  ESPIRA_CREMA_P5,

  ESPIRA_GREY_P1,
  ESPIRA_GREY_P2,
  ESPIRA_GREY_P3,
  ESPIRA_GREY_P4,
  ESPIRA_GREY_P5,

  EXOTIC_EMPRO_BROWN_P1,
  EXOTIC_EMPRO_BROWN_P2,
  EXOTIC_EMPRO_BROWN_P3,
  EXOTIC_EMPRO_BROWN_P4,
  EXOTIC_EMPRO_BROWN_P5,

  EXOTIC_EMPRO_REAL_P1,
  EXOTIC_EMPRO_REAL_P2,
  EXOTIC_EMPRO_REAL_P3,
  EXOTIC_EMPRO_REAL_P4,
  EXOTIC_EMPRO_REAL_P5,

  FLORENCIA_BEIGE_P1,
  FLORENCIA_BEIGE_P2,
  FLORENCIA_BEIGE_P3,
  FLORENCIA_BEIGE_P4,
  FLORENCIA_BEIGE_P5,

  FLORENCIA_BIANCO_P1,
  FLORENCIA_BIANCO_P2,
  FLORENCIA_BIANCO_P3,
  FLORENCIA_BIANCO_P4,
  FLORENCIA_BIANCO_P5,

  FLORENCIA_CEREZO_P1,
  FLORENCIA_CEREZO_P2,
  FLORENCIA_CEREZO_P3,
  FLORENCIA_CEREZO_P4,
  FLORENCIA_CEREZO_P5,

  FLORENCIA_CREMA_P1,
  FLORENCIA_CREMA_P2,
  FLORENCIA_CREMA_P3,
  FLORENCIA_CREMA_P4,
  FLORENCIA_CREMA_P5,

  FLORENCIA_GRIS_P1,
  FLORENCIA_GRIS_P2,
  FLORENCIA_GRIS_P3,
  FLORENCIA_GRIS_P4,
  FLORENCIA_GRIS_P5,

  FLORITO_CREMA_P1,
  FLORITO_CREMA_P2,
  FLORITO_CREMA_P3,
  FLORITO_CREMA_P4,
  FLORITO_CREMA_P5,

  FREEDOM_BEIGE_P1,
  FREEDOM_BEIGE_P2,
  FREEDOM_BEIGE_P3,
  FREEDOM_BEIGE_P4,
  FREEDOM_BEIGE_P5,

  FREEDOM_BIANCO_P1,
  FREEDOM_BIANCO_P2,
  FREEDOM_BIANCO_P3,
  FREEDOM_BIANCO_P4,
  FREEDOM_BIANCO_P5,

  FRESSIA_STATURIO_P1,
  FRESSIA_STATURIO_P2,
  FRESSIA_STATURIO_P3,
  FRESSIA_STATURIO_P4,
  FRESSIA_STATURIO_P5,
  FRESSIA_STATURIO_P6,
  FRESSIA_STATURIO_P7,
  FRESSIA_STATURIO_P8,

  FUMA_BLUE_P1,
  FUMA_BLUE_P2,
  FUMA_BLUE_P3,
  FUMA_BLUE_P4,
  FUMA_BLUE_P5,

  FUMA_GOLD_P1,
  FUMA_GOLD_P2,
  FUMA_GOLD_P3,
  FUMA_GOLD_P4,
  FUMA_GOLD_P5,

  GENESIS_BEIGE_P1,
  GENESIS_BEIGE_P2,
  GENESIS_BEIGE_P3,
  GENESIS_BEIGE_P4,
  GENESIS_BEIGE_P5,

  GENESIS_BROWN_P1,
  GENESIS_BROWN_P2,
  GENESIS_BROWN_P3,
  GENESIS_BROWN_P4,
  GENESIS_BROWN_P5,

  GLACIER_BIANCO_P1,
  GLACIER_BIANCO_P2,
  GLACIER_BIANCO_P3,
  GLACIER_BIANCO_P4,
  GLACIER_BIANCO_P5,

  GLACIER_GOLD_P1,
  GLACIER_GOLD_P2,
  GLACIER_GOLD_P3,
  GLACIER_GOLD_P4,
  GLACIER_GOLD_P5,

  GLACIER_WHITE_P1,
  GLACIER_WHITE_P2,
  GLACIER_WHITE_P3,
  GLACIER_WHITE_P4,
  GLACIER_WHITE_P5,

  GOLDCOIN_WHITE_P1,
  GOLDCOIN_WHITE_P2,
  GOLDCOIN_WHITE_P3,
  GOLDCOIN_WHITE_P4,

  GRESS_ONIX_BEIGE_P1,
  GRESS_ONIX_BEIGE_P2,
  GRESS_ONIX_BEIGE_P3,
  GRESS_ONIX_BEIGE_P4,
  GRESS_ONIX_BEIGE_P5,

  GRIJLY_STATURIO_P1,
  GRIJLY_STATURIO_P2,
  GRIJLY_STATURIO_P3,
  GRIJLY_STATURIO_P4,

  HANSTONE_BIANCO_P1,
  HANSTONE_BIANCO_P2,
  HANSTONE_BIANCO_P3,
  HANSTONE_BIANCO_P4,
  HANSTONE_BIANCO_P5,

  HANSTONE_CREMA_P1,
  HANSTONE_CREMA_P2,
  HANSTONE_CREMA_P3,
  HANSTONE_CREMA_P4,
  HANSTONE_CREMA_P5,

  HAWAI_BEIGE_P1,
  HAWAI_BEIGE_P2,
  HAWAI_BEIGE_P3,
  HAWAI_BEIGE_P4,

  HAWAI_WHITE_P1,
  HAWAI_WHITE_P2,
  HAWAI_WHITE_P3,
  HAWAI_WHITE_P4,

  IMPACT_STATURIO_P1,
  IMPACT_STATURIO_P2,
  IMPACT_STATURIO_P3,
  IMPACT_STATURIO_P4,

  IMPERIAL_BEIGE_P1,
  IMPERIAL_BEIGE_P2,
  IMPERIAL_BEIGE_P3,
  IMPERIAL_BEIGE_P4,
  IMPERIAL_BEIGE_P5,

  IMPERIAL_BIANCO_P1,
  IMPERIAL_BIANCO_P2,
  IMPERIAL_BIANCO_P3,
  IMPERIAL_BIANCO_P4,
  IMPERIAL_BIANCO_P5,

  IMPERIAL_CREMA_P1,
  IMPERIAL_CREMA_P2,
  IMPERIAL_CREMA_P3,
  IMPERIAL_CREMA_P4,
  IMPERIAL_CREMA_P5,

  IMPERIAL_GREY_P1,
  IMPERIAL_GREY_P2,
  IMPERIAL_GREY_P3,
  IMPERIAL_GREY_P4,
  IMPERIAL_GREY_P5,

  ITALIA_BEIGE_P1,
  ITALIA_BEIGE_P2,
  ITALIA_BEIGE_P3,
  ITALIA_BEIGE_P4,
  ITALIA_BEIGE_P5,

  ITALIA_BIANCO_P1,
  ITALIA_BIANCO_P2,
  ITALIA_BIANCO_P3,
  ITALIA_BIANCO_P4,
  ITALIA_BIANCO_P5,

  ITALIA_BRONZ_P1,
  ITALIA_BRONZ_P2,
  ITALIA_BRONZ_P3,
  ITALIA_BRONZ_P4,
  ITALIA_BRONZ_P5,

  ITALIA_GREY_P1,
  ITALIA_GREY_P2,
  ITALIA_GREY_P3,
  ITALIA_GREY_P4,
  ITALIA_GREY_P5,

  KABLI_STATURIO_P1,
  KABLI_STATURIO_P2,
  KABLI_STATURIO_P3,
  KABLI_STATURIO_P4,
  KABLI_STATURIO_P5,

  KINGDOM_BLUE_P1,
  KINGDOM_BLUE_P2,

  KINGDOM_BROWN_P1,
  KINGDOM_BROWN_P2,

  KIYARA_GREY_P1,
  KIYARA_GREY_P2,
  KIYARA_GREY_P3,
  KIYARA_GREY_P4,

  KIYARA_LIGHT_GREY_P1,
  KIYARA_LIGHT_GREY_P2,
  KIYARA_LIGHT_GREY_P3,
  KIYARA_LIGHT_GREY_P4,

  KRAZY_BROWN,

  KRAZY_NERO,

  KWID_BEIGE_P1,
  KWID_BEIGE_P2,
  KWID_BEIGE_P3,
  KWID_BEIGE_P4,
  KWID_BEIGE_P5,

  KWID_GREY_P1,
  KWID_GREY_P2,
  KWID_GREY_P3,
  KWID_GREY_P4,
  KWID_GREY_P5,

  LEGNA_ALMOUND_P1,
  LEGNA_ALMOUND_P2,
  LEGNA_ALMOUND_P3,
  LEGNA_ALMOUND_P4,
  LEGNA_ALMOUND_P5,

  LEVENT_BIANCO_P1,
  LEVENT_BIANCO_P2,
  LEVENT_BIANCO_P3,
  LEVENT_BIANCO_P4,

  LEVENT_BROWN_P1,
  LEVENT_BROWN_P2,
  LEVENT_BROWN_P3,
  LEVENT_BROWN_P4,

  LEVENT_GREY_P1,
  LEVENT_GREY_P2,
  LEVENT_GREY_P3,
  LEVENT_GREY_P4,

  LEVENT_NATURAL_P1,
  LEVENT_NATURAL_P2,
  LEVENT_NATURAL_P3,
  LEVENT_NATURAL_P4,

  LINER_COAL_P1,
  LINER_COAL_P2,
  LINER_COAL_P3,
  LINER_COAL_P4,
  LINER_COAL_P5,

  LINER_GREY_P1,
  LINER_GREY_P2,
  LINER_GREY_P3,
  LINER_GREY_P4,
  LINER_GREY_P5,

  MAGIC_SATVARIYO_P1,
  MAGIC_SATVARIYO_P2,
  MAGIC_SATVARIYO_P3,
  MAGIC_SATVARIYO_P4,

  MAJESTIC_BIANCO_P1,
  MAJESTIC_BIANCO_P2,
  MAJESTIC_BIANCO_P3,
  MAJESTIC_BIANCO_P4,
  MAJESTIC_BIANCO_P5,

  MAJESTIC_BROWN_P1,
  MAJESTIC_BROWN_P2,
  MAJESTIC_BROWN_P3,
  MAJESTIC_BROWN_P4,
  MAJESTIC_BROWN_P5,

  MARC_ONYX_CREMA,
  MARC_ONYX_GREY,

  MELODY_ONYX_P1,
  MELODY_ONYX_P2,

  MIAMI_SATVARIO_P1,
  MIAMI_SATVARIO_P2,
  MIAMI_SATVARIO_P3,
  MIAMI_SATVARIO_P4,

  MILANO_BEIGE_P1,
  MILANO_BEIGE_P2,
  MILANO_BEIGE_P3,
  MILANO_BEIGE_P4,

  MINT_BEIGE_P1,
  MINT_BEIGE_P2,
  MINT_BEIGE_P3,
  MINT_BEIGE_P4,
  MINT_BEIGE_P5,

  MINT_BIANCO_P1,
  MINT_BIANCO_P2,
  MINT_BIANCO_P3,
  MINT_BIANCO_P4,
  MINT_BIANCO_P5,

  MONGINIS_GREY_P1,
  MONGINIS_GREY_P2,
  MONGINIS_GREY_P3,
  MONGINIS_GREY_P4,

  MONGINIS_IVORY_P1,
  MONGINIS_IVORY_P2,
  MONGINIS_IVORY_P3,
  MONGINIS_IVORY_P4,

  MONSTER_EMPRADOR_P1,
  MONSTER_EMPRADOR_P2,
  MONSTER_EMPRADOR_P3,
  MONSTER_EMPRADOR_P4,

  MONTANA_BEIGE_P1,
  MONTANA_BEIGE_P2,
  MONTANA_BEIGE_P3,
  MONTANA_BEIGE_P4,
  MONTANA_BEIGE_P5,

  MONTANA_GREEN_P1,
  MONTANA_GREEN_P2,
  MONTANA_GREEN_P3,
  MONTANA_GREEN_P4,
  MONTANA_GREEN_P5,

  MORACCAN_WHITE_P1,
  MORACCAN_WHITE_P2,
  MORACCAN_WHITE_P3,
  MORACCAN_WHITE_P4,

  MYSTREY_GREY_P1,
  MYSTREY_GREY_P2,
  MYSTREY_GREY_P3,
  MYSTREY_GREY_P4,

  MYSTREY_PEARL_P1,
  MYSTREY_PEARL_P2,
  MYSTREY_PEARL_P3,
  MYSTREY_PEARL_P4,

  NATURAL_SLATE_BEIGE_P1,
  NATURAL_SLATE_BEIGE_P2,
  NATURAL_SLATE_BEIGE_P3,
  NATURAL_SLATE_BEIGE_P4,
  NATURAL_SLATE_BEIGE_P5,

  NATURAL_SLATE_CREMA_P1,
  NATURAL_SLATE_CREMA_P2,
  NATURAL_SLATE_CREMA_P3,
  NATURAL_SLATE_CREMA_P4,
  NATURAL_SLATE_CREMA_P5,

  NAVONA_BROWN_P1,
  NAVONA_BROWN_P2,
  NAVONA_BROWN_P3,
  NAVONA_BROWN_P4,
  NAVONA_BROWN_P5,

  NAVONA_COBALT_P1,
  NAVONA_COBALT_P2,
  NAVONA_COBALT_P3,
  NAVONA_COBALT_P4,
  NAVONA_COBALT_P5,

  NAVONA_GREY_P1,
  NAVONA_GREY_P2,
  NAVONA_GREY_P3,
  NAVONA_GREY_P4,
  NAVONA_GREY_P5,

  NERO_BEIGE_P1,
  NERO_BEIGE_P2,
  NERO_BEIGE_P3,
  NERO_BEIGE_P4,
  NERO_BEIGE_P5,

  NITRA_STATURIO_P1,
  NITRA_STATURIO_P2,
  NITRA_STATURIO_P3,
  NITRA_STATURIO_P4,
  NITRA_STATURIO_P5,

  NOTCH_DARK_P1,
  NOTCH_DARK_P2,
  NOTCH_DARK_P3,
  NOTCH_DARK_P4,

  NOTCH_LIGHT_P1,
  NOTCH_LIGHT_P2,
  NOTCH_LIGHT_P3,
  NOTCH_LIGHT_P4,

  NOVA_BIANCO_P1,
  NOVA_BIANCO_P2,
  NOVA_BIANCO_P3,
  NOVA_BIANCO_P4,

  NOVA_BLUE_P1,
  NOVA_BLUE_P2,
  NOVA_BLUE_P3,
  NOVA_BLUE_P4,

  NOVA_CHOCO_P1,
  NOVA_CHOCO_P2,
  NOVA_CHOCO_P3,
  NOVA_CHOCO_P4,

  NOVA_GOLD_P1,
  NOVA_GOLD_P2,
  NOVA_GOLD_P3,
  NOVA_GOLD_P4,

  OLYMPIA_GOLD_P1,
  OLYMPIA_GOLD_P2,
  OLYMPIA_GOLD_P3,
  OLYMPIA_GOLD_P4,

  OMAN_BEIGE_P1,
  OMAN_BEIGE_P2,
  OMAN_BEIGE_P3,
  OMAN_BEIGE_P4,

  OMAN_GREY_P1,
  OMAN_GREY_P2,
  OMAN_GREY_P3,
  OMAN_GREY_P4,

  OMANIA_CREMA_P1,
  OMANIA_CREMA_P2,
  OMANIA_CREMA_P3,
  OMANIA_CREMA_P4,

  ONIO_BIANCO_P1,
  ONIO_BIANCO_P2,
  ONIO_BIANCO_P3,
  ONIO_BIANCO_P4,

  ORBIT_NATURA_P1,
  ORBIT_NATURA_P2,
  ORBIT_NATURA_P3,
  ORBIT_NATURA_P4,

  OREON_BEIGE_P1,
  OREON_BEIGE_P2,
  OREON_BEIGE_P3,
  OREON_BEIGE_P4,
  OREON_BEIGE_P5,

  OREON_CREMA_P1,
  OREON_CREMA_P2,
  OREON_CREMA_P3,
  OREON_CREMA_P4,
  OREON_CREMA_P5,

  OREON_GREEN_P1,
  OREON_GREEN_P2,
  OREON_GREEN_P3,
  OREON_GREEN_P4,
  OREON_GREEN_P5,

  OREON_NERO_P1,
  OREON_NERO_P2,
  OREON_NERO_P3,
  OREON_NERO_P4,
  OREON_NERO_P5,

  OVAL_GREY_P1,
  OVAL_GREY_P2,
  OVAL_GREY_P3,
  OVAL_GREY_P4,

  OZON_WHITE_P1,
  OZON_WHITE_P2,
  OZON_WHITE_P3,
  OZON_WHITE_P4,

  PANTHON_STATURIO_P1,
  PANTHON_STATURIO_P2,
  PANTHON_STATURIO_P3,
  PANTHON_STATURIO_P4,
  PANTHON_STATURIO_P5,

  PARANA_BIANCO_P1,
  PARANA_BIANCO_P2,
  PARANA_BIANCO_P3,
  PARANA_BIANCO_P4,
  PARANA_BIANCO_P5,

  PARANA_BRONZE_P1,
  PARANA_BRONZE_P2,
  PARANA_BRONZE_P3,
  PARANA_BRONZE_P4,
  PARANA_BRONZE_P5,

  PARANA_CREMA_P1,
  PARANA_CREMA_P2,
  PARANA_CREMA_P3,
  PARANA_CREMA_P4,
  PARANA_CREMA_P5,

  PARIOS_EMPRA_BEIGE_P1,
  PARIOS_EMPRA_BEIGE_P2,
  PARIOS_EMPRA_BEIGE_P3,
  PARIOS_EMPRA_BEIGE_P4,

  PARIOS_EMPRA_CREMA_P1,
  PARIOS_EMPRA_CREMA_P2,
  PARIOS_EMPRA_CREMA_P3,
  PARIOS_EMPRA_CREMA_P4,

  PARIS_CREMA_P1,
  PARIS_CREMA_P2,
  PARIS_CREMA_P3,
  PARIS_CREMA_P4,

  PARIS_IVORY_P1,
  PARIS_IVORY_P2,
  PARIS_IVORY_P3,
  PARIS_IVORY_P4,

  PEARL_SHELL_P1,
  PEARL_SHELL_P2,
  PEARL_SHELL_P3,
  PEARL_SHELL_P4,

  PERLANO_BLUE_P1,
  PERLANO_BLUE_P2,
  PERLANO_BLUE_P3,
  PERLANO_BLUE_P4,

  POURA_BEIGE_P1,
  POURA_BEIGE_P2,
  POURA_BEIGE_P3,
  POURA_BEIGE_P4,

  POURA_CREMA_P1,
  POURA_CREMA_P2,
  POURA_CREMA_P3,
  POURA_CREMA_P4,

  PRAGA_BEIGE_P1,
  PRAGA_BEIGE_P2,
  PRAGA_BEIGE_P3,
  PRAGA_BEIGE_P4,
  PRAGA_BEIGE_P5,

  PREDO_ONIX_BEIGE_P1,
  PREDO_ONIX_BEIGE_P2,
  PREDO_ONIX_BEIGE_P3,
  PREDO_ONIX_BEIGE_P4,
  PREDO_ONIX_BEIGE_P5,

  PREDO_ONIX_GREY_P1,
  PREDO_ONIX_GREY_P2,
  PREDO_ONIX_GREY_P3,
  PREDO_ONIX_GREY_P4,
  PREDO_ONIX_GREY_P5,

  PULPIS_BIANCO_P1,
  PULPIS_BIANCO_P2,
  PULPIS_BIANCO_P3,
  PULPIS_BIANCO_P4,

  PULPIS_GREY_P1,
  PULPIS_GREY_P2,
  PULPIS_GREY_P3,
  PULPIS_GREY_P4,

  PULPIS_GRIS_P1,
  PULPIS_GRIS_P2,
  PULPIS_GRIS_P3,
  PULPIS_GRIS_P4,

  QUANTUM_STATURIO_P1,
  QUANTUM_STATURIO_P2,
  QUANTUM_STATURIO_P3,
  QUANTUM_STATURIO_P4,
  QUANTUM_STATURIO_P5,
  QUANTUM_STATURIO_P6,
  QUANTUM_STATURIO_P7,
  QUANTUM_STATURIO_P8,

  RADIANT_BIANCO_P1,
  RADIANT_BIANCO_P2,
  RADIANT_BIANCO_P3,
  RADIANT_BIANCO_P4,

  RADIANT_CREMA_P1,
  RADIANT_CREMA_P2,
  RADIANT_CREMA_P3,
  RADIANT_CREMA_P4,

  REAL_BRESCIA_BEIGE_P1,
  REAL_BRESCIA_BEIGE_P2,
  REAL_BRESCIA_BEIGE_P3,

  REAL_EMPRADOR_DARK_P1,
  REAL_EMPRADOR_DARK_P2,
  REAL_EMPRADOR_DARK_P3,
  REAL_EMPRADOR_DARK_P4,

  REGALI_BLUE_P1,
  REGALI_BLUE_P2,
  REGALI_BLUE_P3,
  REGALI_BLUE_P4,

  REGALI_BROWN_P1,
  REGALI_BROWN_P2,
  REGALI_BROWN_P3,
  REGALI_BROWN_P4,

  ROCK_BROWN_P1,
  ROCK_BROWN_P2,
  ROCK_BROWN_P3,
  ROCK_BROWN_P4,
  ROCK_BROWN_P5,

  ROCK_GREY_P1,
  ROCK_GREY_P2,
  ROCK_GREY_P3,
  ROCK_GREY_P4,
  ROCK_GREY_P5,

  ROSALINA_IVORY_P1,
  ROSALINA_IVORY_P2,
  ROSALINA_IVORY_P3,
  ROSALINA_IVORY_P4,
  ROSALINA_IVORY_P5,

  ROYAL_BROWN_P1,
  ROYAL_BROWN_P2,
  ROYAL_BROWN_P3,
  ROYAL_BROWN_P4,

  ROYAL_CREMA_P1,
  ROYAL_CREMA_P2,
  ROYAL_CREMA_P3,
  ROYAL_CREMA_P4,

  RYNOLD_BEIGE_P1,
  RYNOLD_BEIGE_P2,
  RYNOLD_BEIGE_P3,
  RYNOLD_BEIGE_P4,
  RYNOLD_BEIGE_P5,

  SANDSTONE_BROWN_P1,
  SANDSTONE_BROWN_P2,
  SANDSTONE_BROWN_P3,
  SANDSTONE_BROWN_P4,
  SANDSTONE_BROWN_P5,
  SANDSTONE_BROWN_P6,

  SANTAFE_BIANCO_P1,
  SANTAFE_BIANCO_P2,
  SANTAFE_BIANCO_P3,
  SANTAFE_BIANCO_P4,
  SANTAFE_BIANCO_P5,

  SANTAFE_BRONZE_P1,
  SANTAFE_BRONZE_P2,
  SANTAFE_BRONZE_P3,
  SANTAFE_BRONZE_P4,
  SANTAFE_BRONZE_P5,

  SARENIYA_BEIGE_P1,
  SARENIYA_BEIGE_P2,
  SARENIYA_BEIGE_P3,
  SARENIYA_BEIGE_P4,

  SARENIYA_GREY_P1,
  SARENIYA_GREY_P2,
  SARENIYA_GREY_P3,
  SARENIYA_GREY_P4,

  SATAVRIO_BROWN_P1,
  SATAVRIO_BROWN_P2,
  SATAVRIO_BROWN_P3,
  SATAVRIO_BROWN_P4,

  SATVARIO_PEARL_P1,
  SATVARIO_PEARL_P2,
  SATVARIO_PEARL_P3,
  SATVARIO_PEARL_P4,

  SCREPOLATO_BROWN_P1,
  SCREPOLATO_BROWN_P2,
  SCREPOLATO_BROWN_P3,
  SCREPOLATO_BROWN_P4,

  SCREPOLATO_NERO_P1,
  SCREPOLATO_NERO_P2,
  SCREPOLATO_NERO_P3,
  SCREPOLATO_NERO_P4,

  SEA_STONE_P1,
  SEA_STONE_P2,

  SIBIRIAN_BEIGE,
  SIBIRIAN_BIANCO,

  SICILIA_CLASSICO_P1,
  SICILIA_CLASSICO_P2,
  SICILIA_CLASSICO_P3,

  SIGMA_CREMA_P1,
  SIGMA_CREMA_P2,
  SIGMA_CREMA_P3,
  SIGMA_CREMA_P4,

  SIGMA_GREY_P1,
  SIGMA_GREY_P2,
  SIGMA_GREY_P3,
  SIGMA_GREY_P4,

  SILKEN_CREMA_P1,
  SILKEN_CREMA_P2,
  SILKEN_CREMA_P3,
  SILKEN_CREMA_P4,

  SILKEN_GREY_P1,
  SILKEN_GREY_P2,
  SILKEN_GREY_P3,
  SILKEN_GREY_P4,

  SILVER_ONYX_IVORY_P1,
  SILVER_ONYX_IVORY_P2,
  SILVER_ONYX_IVORY_P3,
  SILVER_ONYX_IVORY_P4,
  SILVER_ONYX_IVORY_P5,

  SILVER_WAVE_P1,
  SILVER_WAVE_P2,
  SILVER_WAVE_P3,
  SILVER_WAVE_P4,

  SINE_WAVE_P1,
  SINE_WAVE_P2,
  SINE_WAVE_P3,
  SINE_WAVE_P4,
  SINE_WAVE_P5,

  SKYRIUM_ONYX_P1,
  SKYRIUM_ONYX_P2,
  SKYRIUM_ONYX_P3,
  SKYRIUM_ONYX_P4,

  SMOKE_BIANCO_P1,
  SMOKE_BIANCO_P2,
  SMOKE_BIANCO_P3,
  SMOKE_BIANCO_P4,

  SMOKE_BROWN_P1,
  SMOKE_BROWN_P2,
  SMOKE_BROWN_P3,
  SMOKE_BROWN_P4,

  SNOW_PEARL_P1,
  SNOW_PEARL_P2,
  SNOW_PEARL_P3,
  SNOW_PEARL_P4,

  SPARROW_BEIGE_P1,
  SPARROW_BEIGE_P2,
  SPARROW_BEIGE_P3,
  SPARROW_BEIGE_P4,

  SPARTA_CREMA_P1,
  SPARTA_CREMA_P2,
  SPARTA_CREMA_P3,
  SPARTA_CREMA_P4,

  SPARTA_GREY_P1,
  SPARTA_GREY_P2,
  SPARTA_GREY_P3,
  SPARTA_GREY_P4,

  SPIDER_BROWN_P1,
  SPIDER_BROWN_P2,
  SPIDER_BROWN_P3,
  SPIDER_BROWN_P4,
  SPIDER_BROWN_P5,

  SPIDER_NERO_P1,
  SPIDER_NERO_P2,
  SPIDER_NERO_P3,
  SPIDER_NERO_P4,
  SPIDER_NERO_P5,

  SPLENDOR_ANTICO_P1,
  SPLENDOR_ANTICO_P2,
  SPLENDOR_ANTICO_P3,
  SPLENDOR_ANTICO_P4,
  SPLENDOR_ANTICO_P5,

  STATURIO_GOLD_P1,
  STATURIO_GOLD_P2,
  STATURIO_GOLD_P3,
  STATURIO_GOLD_P4,

  STEEL_BIANCO_P1,
  STEEL_BIANCO_P2,
  STEEL_BIANCO_P3,
  STEEL_BIANCO_P4,
  STEEL_BIANCO_P5,

  STEEL_CHOCO_P1,
  STEEL_CHOCO_P2,
  STEEL_CHOCO_P3,
  STEEL_CHOCO_P4,
  STEEL_CHOCO_P5,

  STEEL_GREY_P1,
  STEEL_GREY_P2,
  STEEL_GREY_P3,
  STEEL_GREY_P4,
  STEEL_GREY_P5,

  STONELA_STONE,

  SWEDE_P1,
  SWEDE_P2,
  SWEDE_P3,
  SWEDE_P4,
  SWEDE_P5,

  SWEDE_GREY_P1,
  SWEDE_GREY_P2,
  SWEDE_GREY_P3,
  SWEDE_GREY_P4,
  SWEDE_GREY_P5,

  SWISS_STATURIO_P1,
  SWISS_STATURIO_P2,
  SWISS_STATURIO_P3,

  TASANT_STATURIO_P1,
  TASANT_STATURIO_P2,
  TASANT_STATURIO_P3,
  TASANT_STATURIO_P4,

  THAI_SHELL_P1,
  THAI_SHELL_P2,
  THAI_SHELL_P3,
  THAI_SHELL_P4,

  TOBACCO_BROWN_P1,
  TOBACCO_BROWN_P2,
  TOBACCO_BROWN_P3,
  TOBACCO_BROWN_P4,

  TOBACCO_CREMA_P1,
  TOBACCO_CREMA_P2,
  TOBACCO_CREMA_P3,
  TOBACCO_CREMA_P4,

  TOPAZ_SHELL_P1,
  TOPAZ_SHELL_P2,
  TOPAZ_SHELL_P3,
  TOPAZ_SHELL_P4,

  TORRENT_BEIGE_P1,
  TORRENT_BEIGE_P2,
  TORRENT_BEIGE_P3,
  TORRENT_BEIGE_P4,

  TRAVENTINE_BEIGE_P1,
  TRAVENTINE_BEIGE_P2,
  TRAVENTINE_BEIGE_P3,
  TRAVENTINE_BEIGE_P4,
  TRAVENTINE_BEIGE_P5,

  TRAVENTINE_CREMA_P1,
  TRAVENTINE_CREMA_P2,
  TRAVENTINE_CREMA_P3,
  TRAVENTINE_CREMA_P4,
  TRAVENTINE_CREMA_P5,

  TRAVENTINE_GREY_P1,
  TRAVENTINE_GREY_P2,
  TRAVENTINE_GREY_P3,
  TRAVENTINE_GREY_P4,
  TRAVENTINE_GREY_P5,

  TRAVENTINE_NATURAL_P1,
  TRAVENTINE_NATURAL_P2,
  TRAVENTINE_NATURAL_P3,
  TRAVENTINE_NATURAL_P4,
  TRAVENTINE_NATURAL_P5,

  TRAVEX_BEIGE_P1,
  TRAVEX_BEIGE_P2,
  TRAVEX_BEIGE_P3,
  TRAVEX_BEIGE_P4,

  TRAVEX_BROWN_P1,
  TRAVEX_BROWN_P2,
  TRAVEX_BROWN_P3,
  TRAVEX_BROWN_P4,

  TREASURE_GREY_P1,
  TREASURE_GREY_P2,
  TREASURE_GREY_P3,
  TREASURE_GREY_P4,

  TRECO_GRESS_P1,
  TRECO_GRESS_P2,
  TRECO_GRESS_P3,
  TRECO_GRESS_P4,

  VALENCIA_CREMA_P1,
  VALENCIA_CREMA_P2,
  VALENCIA_CREMA_P3,

  VALENTINO_P1,
  VALENTINO_P2,
  VALENTINO_P3,
  VALENTINO_P4,

  VANATO_BIANCO_P1,
  VANATO_BIANCO_P2,
  VANATO_BIANCO_P3,
  VANATO_BIANCO_P4,

  VARSOVA_BIANCO_P1,
  VARSOVA_BIANCO_P2,
  VARSOVA_BIANCO_P3,
  VARSOVA_BIANCO_P4,

  VECTOR_BIANCO_P1,
  VECTOR_BIANCO_P2,
  VECTOR_BIANCO_P3,
  VECTOR_BIANCO_P4,

  VEGAS_SMOKE_P1,
  VEGAS_SMOKE_P2,
  VEGAS_SMOKE_P3,
  VEGAS_SMOKE_P4,

  VENUS_CHOCO_P1,
  VENUS_CHOCO_P2,
  VENUS_CHOCO_P3,
  VENUS_CHOCO_P4,

  VENUS_WHITE_P1,
  VENUS_WHITE_P2,
  VENUS_WHITE_P3,
  VENUS_WHITE_P4,

  VIENTI_STATURIO_P1,
  VIENTI_STATURIO_P2,
  VIENTI_STATURIO_P3,
  VIENTI_STATURIO_P4,

  VIGO_STATURIO_P1,
  VIGO_STATURIO_P2,
  VIGO_STATURIO_P3,
  VIGO_STATURIO_P4,
  VIGO_STATURIO_P5,
  VIGO_STATURIO_P6,
  VIGO_STATURIO_P7,

  VOLGA_BROWN_P1,
  VOLGA_BROWN_P2,
  VOLGA_BROWN_P3,
  VOLGA_BROWN_P4,

  VOLGA_MAROON_P1,
  VOLGA_MAROON_P2,
  VOLGA_MAROON_P3,
  VOLGA_MAROON_P4,

  VOLTAS_BEIGE_P1,
  VOLTAS_BEIGE_P2,
  VOLTAS_BEIGE_P3,
  VOLTAS_BEIGE_P4,

  VOLTAs__BEIGE_P1,
  VOLTAs__BEIGE_P2,
  VOLTAs__BEIGE_P3,
  VOLTAs__BEIGE_P4,
  VOLTAs__BEIGE_P5,

  WILLIAM_GREY_EXOTIC_KRYPTON_P1,
  WILLIAM_GREY_EXOTIC_KRYPTON_P2,
  WILLIAM_GREY_EXOTIC_KRYPTON_P3,
  WILLIAM_GREY_EXOTIC_KRYPTON_P4,

  WILLIAM_LIGHT_GREY_EXOTIC_P1,
  WILLIAM_LIGHT_GREY_EXOTIC_P2,
  WILLIAM_LIGHT_GREY_EXOTIC_P3,
  WILLIAM_LIGHT_GREY_EXOTIC_P4,

  WILSON_BIANCO_P1,
  WILSON_BIANCO_P2,
  WILSON_BIANCO_P3,
  WILSON_BIANCO_P4,

  WILSON_BROWN_P1,
  WILSON_BROWN_P2,
  WILSON_BROWN_P3,
  WILSON_BROWN_P4,

  WILSON_NERO_P1,
  WILSON_NERO_P2,
  WILSON_NERO_P3,
  WILSON_NERO_P4,

  ZENIT_BEIGE_P1,
  ZENIT_BEIGE_P2,
  ZENIT_BEIGE_P3,

  ZIMBA_BEIGE_P1,
  ZIMBA_BEIGE_P2,
  ZIMBA_BEIGE_P3,
  ZIMBA_BEIGE_P4,

  ZIMBA_NATURAL_P1,
  ZIMBA_NATURAL_P2,
  ZIMBA_NATURAL_P3,
  ZIMBA_NATURAL_P4,

  ZODIAC_BEIGE_P1,
  ZODIAC_BEIGE_P2,
  ZODIAC_BEIGE_P3,

  ZODIAC_BROWN_P1,
  ZODIAC_BROWN_P2,
  ZODIAC_BROWN_P3,

  ZODIAC_STATURIO_P1,
  ZODIAC_STATURIO_P2,
  ZODIAC_STATURIO_P3,
  ZODIAC_STATURIO_P4,

  ZUVI_CREMA_P1,
  ZUVI_CREMA_P2,
  ZUVI_CREMA_P3,

  ZUVI_GREY_P1,
  ZUVI_GREY_P2,
  ZUVI_GREY_P3,

  REGAL_STRIP_BLUE_P1,
  REGAL_STRIP_BLUE_P2,
  REGAL_STRIP_BLUE_P3,


  REGAL_STRIP_BROWN_P1,
  REGAL_STRIP_BROWN_P2,
  REGAL_STRIP_BROWN_P3,

  REGAL_STRIP_P1,
  REGAL_STRIP_P2,
  REGAL_STRIP_P3,

  TRIANGLE_STRIP_P1,

  ZIG_ZAG_STRIP_P1,

  CROSS_STRIP_P1,

  EIGHTEEN_SQUARE_P1,

  HAWK_STRIP_P1,

  MULTY_STRIP_P1,

  ALECIA_BLUE_P1,
  ALECIA_BLUE_P2,
  ALECIA_BLUE_P3,

  ALECIA_GRIS_P1,
  ALECIA_GRIS_P2,
  ALECIA_GRIS_P3,

  ARBOR_GRIGIO_P1,
  ARBOR_GRIGIO_P2,
  ARBOR_GRIGIO_P3,
  ARBOR_GRIGIO_P4,

  ARMONIA_BROWN_P1,
  ARMONIA_BROWN_P2,
  ARMONIA_BROWN_P3,

  AYER_ROCK_P1,
  AYER_ROCK_P2,
  AYER_ROCK_P3,
  AYER_ROCK_P4,

  CEMENTO_CHARCOAL_P1,
  CEMENTO_CHARCOAL_P2,
  CEMENTO_CHARCOAL_P3,
  CEMENTO_CHARCOAL_P4,

  CEMENTO_COTTO_P1,
  CEMENTO_COTTO_P2,
  CEMENTO_COTTO_P3,

  CEMENTO_CREMA_P1,
  CEMENTO_CREMA_P2,
  CEMENTO_CREMA_P3,

  CONCRETE_GREY_P1,
  CONCRETE_GREY_P2,

  COPPER_SLATE_CREMA_P1,
  COPPER_SLATE_CREMA_P2,
  COPPER_SLATE_CREMA_P3,

  COPPER_SLATE_GREY_P1,
  COPPER_SLATE_GREY_P2,
  COPPER_SLATE_GREY_P3,

  COPPER_SLATE_CREMA_P1,
  COPPER_SLATE_CREMA_P2,
  COPPER_SLATE_CREMA_P3,

  COPPER_SLATE_WHITE_P1,
  COPPER_SLATE_WHITE_P2,
  COPPER_SLATE_WHITE_P3,

  COTTON_CREMA_P1,
  COTTON_CREMA_P2,
  COTTON_CREMA_P3,
  COTTON_CREMA_P4,

  COTTON_GREY_P1,
  COTTON_GREY_P2,
  COTTON_GREY_P3,
  COTTON_GREY_P4,

  CRONOS_GREY_P1,
  CRONOS_GREY_P2,
  CRONOS_GREY_P3,
  CRONOS_GREY_P4,

  CRONOS_ROSSA_P1,
  CRONOS_ROSSA_P2,
  CRONOS_ROSSA_P3,
  CRONOS_ROSSA_P4,

  ESHA_BEIGE_P1,
  ESHA_BEIGE_P2,
  ESHA_BEIGE_P3,
  ESHA_BEIGE_P4,

  ESHA_CREMA_P1,
  ESHA_CREMA_P2,
  ESHA_CREMA_P3,
  ESHA_CREMA_P4,

  ESHA_GREY_P1,
  ESHA_GREY_P2,
  ESHA_GREY_P3,
  ESHA_GREY_P4,

  ESHA_STEEL_P1,
  ESHA_STEEL_P2,
  ESHA_STEEL_P3,
  ESHA_STEEL_P4,

  FERRATI_BROWN_P1,
  FERRATI_BROWN_P2,
  FERRATI_BROWN_P3,
  FERRATI_BROWN_P4,

  FERRATI_CREMA_P1,
  FERRATI_CREMA_P2,
  FERRATI_CREMA_P3,
  FERRATI_CREMA_P4,

  FERRATI_DARK_P1,
  FERRATI_DARK_P2,
  FERRATI_DARK_P3,
  FERRATI_DARK_P4,

  FERRATI_LIGHT_P1,
  FERRATI_LIGHT_P2,
  FERRATI_LIGHT_P3,
  FERRATI_LIGHT_P4,

  FERRATI_SKY_P1,
  FERRATI_SKY_P2,
  FERRATI_SKY_P3,
  FERRATI_SKY_P4,

  FRENCH_BEIGE_P1,
  FRENCH_BEIGE_P2,
  FRENCH_BEIGE_P3,

  FRENCH_BIANCO_P1,
  FRENCH_BIANCO_P2,
  FRENCH_BIANCO_P3,

  FRENCH_CREMA_P1,
  FRENCH_CREMA_P2,
  FRENCH_CREMA_P3,

  FRENCH_GREY_P1,
  FRENCH_GREY_P2,
  FRENCH_GREY_P3,

  INFINITY_CREMA_P1,
  INFINITY_CREMA_P2,
  INFINITY_CREMA_P3,
  INFINITY_CREMA_P4,
  INFINITY_CREMA_P5,

  INFINITY_GRAFITO_P1,
  INFINITY_GRAFITO_P2,
  INFINITY_GRAFITO_P3,
  INFINITY_GRAFITO_P4,
  INFINITY_GRAFITO_P5,

  ISTABUL_BROWN_P1,
  ISTABUL_BROWN_P2,
  ISTABUL_BROWN_P3,
  ISTABUL_BROWN_P4,

  ISTABUL_WHITE_P1,
  ISTABUL_WHITE_P2,
  ISTABUL_WHITE_P3,
  ISTABUL_WHITE_P4,

  LACA_DARK_GREY_P1,

  LACA_LIGHT_GREY_P1,

  MARBIA_CHARCOAL_P1,
  MARBIA_CHARCOAL_P2,
  MARBIA_CHARCOAL_P3,
  MARBIA_CHARCOAL_P4,

  MARBIA_GREY_P1,
  MARBIA_GREY_P2,
  MARBIA_GREY_P3,
  MARBIA_GREY_P4,

  MARLIN_BEIGE_P1,

  MARLIN_WHITE_P1,

  MOSAIC_CHARCOAL_P1,

  MOSAIC_GREY_P1,

  ORIENT_CREMA_P1,
  ORIENT_CREMA_P2,
  ORIENT_CREMA_P3,
  ORIENT_CREMA_P4,
  ORIENT_CREMA_P5,

  ORIENT_GRESS_P1,
  ORIENT_GRESS_P2,
  ORIENT_GRESS_P3,
  ORIENT_GRESS_P4,
  ORIENT_GRESS_P5,

  ORIENT_GREY_P1,
  ORIENT_GREY_P2,
  ORIENT_GREY_P3,
  ORIENT_GREY_P4,
  ORIENT_GREY_P5,

  ORIENT_SMOKE_P1,
  ORIENT_SMOKE_P2,
  ORIENT_SMOKE_P3,
  ORIENT_SMOKE_P4,
  ORIENT_SMOKE_P5,

  PLANO_BEIGE_P1,
  PLANO_BEIGE_P2,

  PLANO_BROWN_P1,
  PLANO_BROWN_P2,

  PLANO_GREY_P1,
  PLANO_GREY_P2,

  PLANO_NERO_P1,
  PLANO_NERO_P2,

  ARBOR_GRIGIO_P_1,
  ARBOR_GRIGIO_P_2,
  ARBOR_GRIGIO_P_3,
  ARBOR_GRIGIO_P_4,
  ARBOR_GRIGIO_P_5,

  ARBOR_MUSCHIO_P1,
  ARBOR_MUSCHIO_P2,
  ARBOR_MUSCHIO_P3,
  ARBOR_MUSCHIO_P4,

  // ARBOR_OCRA_P1,
  // ARBOR_OCRA_P2,
  // ARBOR_OCRA_P3,
  // ARBOR_OCRA_P4,

  // ARMONIA_AQUA_P1,
  // ARMONIA_AQUA_P2,
  // ARMONIA_AQUA_P3,
  // ARMONIA_AQUA_P4,

  // ARTICWOOD_FUMO_P1,
  // ARTICWOOD_FUMO_P2,
  // ARTICWOOD_FUMO_P3,
  // ARTICWOOD_FUMO_P4,

  // ARTICWOOD_BRICHWOOD_WHITE_BLUE_P1,
  // ARTICWOOD_BRICHWOOD_WHITE_BLUE_P2,
  // ARTICWOOD_BRICHWOOD_WHITE_BLUE_P3,
  // ARTICWOOD_BRICHWOOD_WHITE_BLUE_P4,

  // BRICHWOOD_CREMA_P1,
  // BRICHWOOD_CREMA_P2,
  // BRICHWOOD_CREMA_P3,
  // BRICHWOOD_CREMA_P4,

  // CEDAR_BEIGE_P1,
  // CEDAR_BEIGE_P2,
  // CEDAR_BEIGE_P3,
  // CEDAR_BEIGE_P4,

  // CEDAR_MOKA_P1,
  // CEDAR_MOKA_P2,
  // CEDAR_MOKA_P3,
  // CEDAR_MOKA_P4,

  // CEDAR_PINE_P1,
  // CEDAR_PINE_P2,
  // CEDAR_PINE_P3,
  // CEDAR_PINE_P4,

  // CEDAR_WHITE_P1,
  // CEDAR_WHITE_P2,
  // CEDAR_WHITE_P3,
  // CEDAR_WHITE_P4,

  // CLARA_WOOD_BEIGE_P1,
  // CLARA_WOOD_BEIGE_P2,
  // CLARA_WOOD_BEIGE_P3,
  // CLARA_WOOD_BEIGE_P4,



  teknika_product,
  teknika_bg,
  map_pin,
  map_pin_white,

  // logos
  logo,
  btn_img,
  play_btn,
  ose_logo_1,
  ose_logo_2,
  ose_logo_3,
  ose_logo_4,
  ose_logo_5,

  email_icon_white,
  location_icon_white,
  phone_icon_white,

  right_arrow,

  email_icon,
  location_icon,
  phone_icon,
  news_send_icon,

  home_advantage_logo_1,
  home_advantage_logo_2,
  home_advantage_logo_3,
  home_advantage_logo_4,
  home_advantage_logo_5,
  home_advantage_logo_6,

  tech_partner_logo_1,
  tech_partner_logo_2,
  tech_partner_logo_3,
  tech_partner_logo_4,
  tech_partner_logo_5,

  build_logo,

  // ---------- images ----------------------

  // home
  home_slider_img1,
  home_slider_img2,
  home_slider_img3,
  home_slider_img4,
  home_slider_img5,
  home_slider_img6,

  home_slider_1,
  about_image,

  home_img_1,
  home_img_2,
  home_img_3,
  home_img_4,

  // about
  about_hero_img,
  about_weare_img,

  img1,
  img2,
  img3,

  // tech

  tech_img,

  //product
  product_hero_img,
  roccia_gris,
  roccia_metal,
  roccia_nero,
  roccia_taupe,
  norway_cerma,
  norway_cloud,
  norway_gris,
  norway_nero,

  insta_img3,
  insta_img4,
  insta_img5,

  about_timeline,
  about_timeline2,
  about_timeline3,
  techinnovation_hero_img_hero_img,

  contact_hero_img,

  blog_hero_img,
  blog_img1,
  blog_img2,
  blog_img3,

  blog_detail_1,
  blog_detail_2,
  // ---------- videos -------------------------

  video1,
  video2,
  teknikaa,

  // ---------- XL Slab -------------------------


  CALACUTTA_BORGHINI_BM,
  CALACUTTA_BORGHINI_BM_2,
  CALACUTTA_LUXE01,
  CALACUTTA_LUXE02,
  CARRARA_ULTIMO,
  CELIO01,
  CELIO02,
  DIOR_GOLD,
  DOLCE_GREY,
  EVEREST_WHITE_1,
  SAINT_LAURENT_2,
  SILVER_RIVER_Calacutta_Bianco_Oro,
  SILVER_RIVER,
  STATUARIO_NORWELL_1,
  STATUARIO_NORWELL_2,
  STATUARIO_NORWELL_3,
  STATUARIO_PLATINA_1,
  STATUARIO_PLATINA_2,
  STATUARIO_VENETIAN_1,
  STATUARIO_VENETIAN_2,
  STATUARIO_VENETIAN_BM,
  STRATUM_PORTORO_1,
  STRATUM_PORTORO_2,
  STRATUM_PORTORO,
};
