import React, { useEffect, useState } from "react";
import { HeroSection } from "../common";
import images from "../constants/images";
import { BlogCard } from "../components";
import axios from "axios";

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState(6);
  const [showAll, setShowAll] = useState(false);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });

    axios.get("https://gokulnair.com/teknika/vadmin/api/get_all_blog")
      .then(response => {
        console.log("API response:", response.data);

        if (response.data.status === 0) {
          setNoData(true);
          return;
        }

        const blogsData = response.data.blog_data.map(blog => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(blog.blog_details, 'text/html');
          const textContent = doc.body.textContent || "";

          return {
            ...blog,
            blog_details: truncateText(textContent, 16)
          };
        });

        setBlogs(blogsData);
      })
      .catch(error => {
        console.error("There was an error fetching the blog data!", error);
      });
  }, []);

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '..';
    }
    return text;
  };

  const handleToggleBlogs = () => {
    setShowAll(!showAll);
    setVisibleBlogs(showAll ? 6 : blogs.length);
  };

  return (
    <div style={{ width: "100%" }}>
      <HeroSection img={images.blog_hero_img} title="Blog" />
      {noData ? (
        <div className="no_data_available">
          <div>
            <h2>Coming Soon</h2>
          </div>
        </div>
      ) : (
        <>
          <div className="blogs_cards_wrapp">
            {blogs.slice(0, visibleBlogs).map((blog, index) => (
              <BlogCard
                key={index}
                imgSrc={blog.blog_image[0]?.image || images.about_timeline2}
                blogName={blog.blog_title}
                date={blog.date}
                blog_details={blog.blog_details}
                slug={blog.slug}
                blogKeywords={blog.blog_key_words}
                main_blog_details={blog.main_blog_details}
              />
            ))}
          </div>
          <div
            style={{
              margin: "3rem auto 1rem auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <button className="custom-button" onClick={handleToggleBlogs}>
              {showAll ? "SHOW LESS" : "SHOW MORE"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BlogsPage;
