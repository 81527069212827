import React, { useState } from "react";
import "./HomeContectUs.css";
import images from "../../constants/images";
import { GoogleMap, LoadScript, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";

const containerStyle = {
  width: "100%",
  height: "422px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const HomeContectUs = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCa9w9dFBG-vpBWdS_bBt-Qa9AJCLl18QQ",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);



  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "full_name":
        if (!value.trim()) {
          error = "Full Name is required";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid";
        }
        break;
      case "message":
        if (!value.trim()) {
          error = "Message is required";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.full_name.trim()) {
      newErrors.full_name = "Full Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); // Set loading state to true on form submission
      try {
        const response = await axios.post("https://gokulnair.com/teknika/vadmin/api/contact_us", formData);
        console.log("Response:", response.data);
        setFormData({
          full_name: "",
          email: "",
          message: "",
        });
        setShowSuccessModal(true); // Show the success modal after successful submission
      } catch (error) {
        console.error("There was an error sending the form data!", error);
      }
      setLoading(false); // Set loading state to false after form submission
    }
  };

  const closeModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div className="section_main_wrapp">
      {loading && <div className="loading-overlay"></div>}
      <div className="section_inner_wrapp">
        <div className="home_contectus_main_wrapp">
          <h3 className="sec_heading mb-2">WE LOVE TO HEAR FROM YOU.</h3>

          <div className="home_contectus_inner_wrapp">
            <form className="home_contectus_inner_sec_1" onSubmit={handleSubmit}>

              <input
                type="text"
                name="full_name"
                placeholder="Full Name"
                value={formData.full_name}
                onChange={handleChange}
                className="input-box"
              />
              {errors.full_name && <p className="error">{errors.full_name}</p>}
              <input type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange} className="input-box" />
              {errors.email && <p className="error">{errors.email}</p>}
              <textarea
                name="message"
                placeholder="Message"
                id=""
                cols="30"
                rows="8"
                value={formData.message}
                onChange={handleChange}
                className="input-box"
              ></textarea>
              {errors.message && <p className="error">{errors.message}</p>}
              <button type="submit" className="home_contectus_btn">Submit</button>
            </form>
            <div className="home_contectus_inner_sec_2">
              <p>Factory Address</p>
              <div>
                <img src={images.map_pin} alt="" />
                <span>
                  8A national highway, Halvad road,
                  <br /> morbi-2 , Gujarat, India,
                  <br />Pin - 363642
                </span>
              </div>
              <p>Corporate Address</p>
              <div>
                <img src={images.map_pin} alt="" />
                <span>
                  Block no. 4,  Ground floor, Gokuldham main road,
                  <br />near swaminarayan Nagar,
                  <br /> Rajkot, Gujarat, India , <br />Pin- 360004
                </span>
              </div>

              <div style={{ alignItems: "center", marginTop: "10px" }}>
                <img src={images.email_icon} className="home_icon_mail" alt="" />
                <div className="mail_home_cont">
                  <a href="mailto:export@teknikaceramiche.com" className="">export@teknikaceramiche.com</a>
                  <a href="mailto:Sales@teknikaceramiche.com" className="">Sales@teknikaceramiche.com</a>
                </div>
              </div>
              <div style={{ alignItems: "center", marginTop: "1rem" }}>
                <img src={images.phone_icon} className="home_icon_mail" alt="" />
                <div className="mail_home_cont">
                  <a href="tel:+91 82382 03331" className="">+91 82382 03331</a>
                  <a href="tel:+91 82382 03338" className="">+91 82382 03338</a>
                </div>
              </div>
            </div>
            <div className="home_contectus_inner_sec_3">
              {/* {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <></>
                </GoogleMap>
              ) : null} */}

              <iframe
                width="100%"
                height="433"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                style={{ filter: " grayscale(100%)" }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.7616589561726!2d70.90250807786279!3d22.774224772325425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598e0006f23a4f%3A0x83707a7c713d19f1!2s8A%2C%20National%20Highway%2C%20Rafaleshwar%2C%20Jambudiya%2C%20Gujarat%20363642!5e0!3m2!1sen!2sin!4v1717145523175!5m2!1sen!2sin"
              >
                <a href="https://www.gps.ie/">gps trackers</a>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContectUs;
