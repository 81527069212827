import React, { useEffect } from "react";
import { HeroSection } from "../common";
import images from "../constants/images";
import {
  AboutSlider,
  AboutVideo,
  AboutWeAre,
  ContectUsTimeline,
} from "../container";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <HeroSection img={images.home_slider_img6} title="About Us" />
      <AboutWeAre />
      <AboutVideo />
      {/* <AboutSlider /> */}
      <ContectUsTimeline />
    </>
  );
};

export default AboutUsPage;
