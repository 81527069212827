import React from "react";
import "./BlogCard.css";
import { Link } from "react-router-dom";

const BlogCard = ({ imgSrc, blogName, date, blog_details, slug }) => {
 

  return (
    <Link to={`/blog-detail/${slug}`} className="blog_card_main_wrapp">
      <img src={imgSrc} alt="" />
      <div>
        {/* <span>{slug}</span> */}
        <p>{date}</p>
        <h4>{blogName}</h4>
        <p>{blog_details}</p>
      </div>
    </Link>
  );
};

export default BlogCard;