import React, { useEffect, useState } from "react";
import axios from "axios";
import images from "../../constants/images";
import { BlogCard } from "../../components";

const Recentblog = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' 
      });
  
      axios.get("https://gokulnair.com/teknika/vadmin/api/get_all_blog")
        .then(response => {
          console.log("API response:", response.data);
  
          const blogsData = response.data.blog_data.map(blog => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(blog.blog_details, 'text/html');
            const textContent = doc.body.textContent || "";
  
            return {
              ...blog,
              blog_details: truncateText(textContent, 16)
            };
          });
  
          // Get the latest 3 blogs
          const latestBlogs = blogsData.reverse().slice(0, 3);
  
          setBlogs(latestBlogs);
        })
        .catch(error => {
          console.error("There was an error fetching the blog data!", error);
        });
    }, []);
  
    const truncateText = (text, wordLimit) => {
      const words = text.split(' ');
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '..';
      }
      return text;
    };
  
  return (
    <div>
      <div className="blogs_cards_wrapp">
        {blogs.map((blog, index) => (
          <BlogCard
            key={index}
            imgSrc={blog.blog_image[0].image || images.about_timeline2}
            blogName={blog.blog_title}
            date={blog.date}
            blog_details={blog.blog_details}
            slug={blog.slug}
          />
        ))}
      </div>
    </div>
  );
};

export default Recentblog;
