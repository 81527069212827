import React, { useEffect, useState } from "react";
import { BlogDetail, BlogDetailBanner } from "../container";
import { useParams } from "react-router-dom";
import axios from "axios";

const BlogDetailPage = () => {
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    // Log when the component mounts and when the slug changes
    console.log("Component mounted or slug changed:", slug);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });

    const fetchBlogDetails = async () => {
      try {
        console.log("Fetching blog details for slug:", slug);

        const response = await axios.post("https://gokulnair.com/teknika/vadmin/api/get_blog_details", {
          slug: slug,
        });

        console.log("Response received:", response);

        if (response.status === 200 && response.data.status === 1) {
          console.log("Blog details fetched successfully:", response.data.blog_data);
          setBlogData(response.data.blog_data);
        } else {
          console.error("Failed to fetch blog details, status code or response data status is not as expected");
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogDetails();
  }, [slug]);

  // Log the blogData state whenever it changes
  useEffect(() => {
    console.log("blogData state updated:", blogData);
  }, [blogData]);

  return (
    <div>
      <BlogDetailBanner blogData={blogData} />
      <BlogDetail blogData={blogData} />
    </div>
  );
};

export default BlogDetailPage;
