import React from "react";
import "./ContectUsMap.css";

const ContectUsMap = () => {
  return (
    <div className="section_main_wrapp map_sec">
      <div className="c_u_map_wrapp">
        {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBu-916DdpKAjTmJNIgngS6HL_kDIKU0aU&callback=myMap"></script> */}
        <div>
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            style={{ filter: " grayscale(100%)", height: "60vh" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.7616589561726!2d70.90250807786279!3d22.774224772325425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598e0006f23a4f%3A0x83707a7c713d19f1!2s8A%2C%20National%20Highway%2C%20Rafaleshwar%2C%20Jambudiya%2C%20Gujarat%20363642!5e0!3m2!1sen!2sin!4v1717145523175!5m2!1sen!2sin"
          >
            <a href="https://www.gps.ie/">gps trackers</a>
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default ContectUsMap;
