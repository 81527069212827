import React, { useEffect } from "react";
import {
  HomeAbout,
  HomeAdvantage,
  HomeContectUs,
  HomeHero,
  HomeInsta,
  HomeOurExpert,
  HomeSeamless,
  HomeTopProducts,
} from "../container";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' 
    });
  }, []);
  return (
    <div>
      <HomeHero />
      <HomeAbout />
      <HomeSeamless />
      <HomeTopProducts />
      <HomeAdvantage />
      {/* <HomeOurExpert /> */}
      <HomeContectUs />
      <HomeInsta />
    </div>
  );
};

export default HomePage;
