import React, { useRef, useState } from "react";
import "./AboutVideo.css";
import images from "../../constants/images";

const AboutVideo = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false); // New state for overlay visibility

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setShowOverlay(true); // Show overlay on pause
    } else {
      videoRef.current.play();
      setShowOverlay(false); // Hide overlay on play
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  return (
    <div className="section_main_wrapp">
      <div className="a_video_main_wrapp">
        <div className="a_video_wrapp">
          <div className="a_video_inner">
            <video
              ref={videoRef}
              // onTimeUpdate={handleTimeUpdate}
              // onLoadedMetadata={handleLoadedMetadata}
              src={images.teknikaa}
              autoPlay={true}
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
              muted={true}
            >
              Your browser does not support the video tag.
            </video>
            {/* <button className="video_play_btn" onClick={togglePlay}>
              {isPlaying ? null : (
                <div className="video_overly">
                  <img src={images.play_btn} alt="" />
                </div>
              )}
            </button> */}
          </div>
        </div>
        <div className="a_video_text_wrapp">
          <div className="spec1div">
            <h3>10</h3>
            <p>YEARS</p>
          </div>
          <div className="spec1div">
            <h3>02</h3>
            <p>OFFICES</p>
          </div>
          <div className="spec1div">
            <h3>90+</h3>
            <p>DISTRIBUTORS</p>
          </div>
          <div className="spec1div">
            <h3>50</h3>
            <p>PRODUCTS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutVideo;
