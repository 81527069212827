import React, { useState, useEffect } from "react";
import "./ContectUsTimeline.css";
import images from "../../constants/images";

const ContectUsTimeline = () => {
  const [year, setYear] = useState("2011");

  const years = ["2011", "2016", "2024"];
  const yearDetails = {
    "2011": {
      description: "Teknika's journey began in 2011 with a focus on industrial machines and technology under the name Techno Group. Their expertise in machinery laid a strong foundation for future endeavors.",
      image: images.about_timeline
    },
    "2016": {
      description: "In 2016, a pivotal shift occurred. Recognizing the potential of the Indian ceramic industry, Teknika Group strategically entered this sector by acquiring a stake in a well-established manufacturing unit.  This bold move marked a turning point, propelling them towards a new path focused on innovation within the ceramic landscape.",
      image: images.about_timeline
    },
    "2024": {
      description: "From Techno Group to Teknika Group, the brand name evolved to reflect their changing identity. Today, Teknika Group stands tall with partnerships forged with four major corporations, solidifying their position as a prominent player in India's ceramic industry. Their commitment to 'Make in India, Made for the World' continues to be a driving force in their ongoing journey.",
      image: images.about_timeline
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setYear(prevYear => {
        const currentIndex = years.indexOf(prevYear);
        const nextIndex = (currentIndex + 1) % years.length;
        return years[nextIndex];
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="section_main_wrapp">
      <div className="c_u_timeline_sec_wrapp mt-2">
        <h4 className="sec_heading together">Together we build success</h4>
        <div className="c_u_timeline_sec_inner_wrapp">
          <img
            src={yearDetails[year].image}
            alt={`Timeline ${year}`}
            className="c_u_timeline_img"
          />
          <div className="c_u_timeline_sec_blue_box">
            <div className="c_u_timeline_row">
              <div className="c_u_timeline_times_wrapp">
                {years.map((y) => (
                  <button
                    key={y}
                    onClick={() => setYear(y)}
                    className="c_u_single_timeline"
                  >
                    <p
                      style={{
                        color: year === y ? "black" : "var(--color-ocher)",
                        fontSize: year === y ? "22px" : "16px",
                      }}
                    >
                      {y}
                    </p>
                    <div
                      style={{
                        background: year === y ? "black" : "var(--color-ocher)",
                      }}
                    ></div>
                  </button>
                ))}
              </div>
            </div>
            <div className="c_u_timeline_details">
              <h4>Teknika's journey</h4>
              <p>{yearDetails[year].description}</p>
            </div>
          </div>
        </div>
        <img src={images.build_logo} alt="Build logo" className="about-build-logo" />
      </div>
    </div>
  );
};

export default ContectUsTimeline;
