import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AboutUsPage,
  BlogDetailPage,
  BlogsPage,
  ContectUsPage,
  HomePage,
  ProductsPage,
  TechPage,
} from "./pages";
import { Footer, Navbar } from "./common";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/tech-innovation" element={<TechPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/blog-detail/:slug" element={<BlogDetailPage />} />
        {/* <Route path="/blog-detail" element={<BlogDetailPage />} /> */}
        <Route path="/contect-us" element={<ContectUsPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
