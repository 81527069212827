import React, { useState } from "react";
import "./Footer.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest } from "react-icons/fa6";
import axios from "axios";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async () => {
    if (validateEmail(email)) {
      setLoading(true);
      setErrorMessage("");
      try {
        const response = await axios.post("https://gokulnair.com/teknika/vadmin/api/newsletter_add", { email });
        console.log("Response:", response.data);
        setShowSuccessModal(true);
      } catch (error) {
        console.error("There was an error sending the email!", error);
        setErrorMessage("There was an error subscribing. Please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("Please enter a valid email address.");
    }
  };

  const closeModal = () => {
    setShowSuccessModal(false);
    setEmail("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  return (
    <div>
      <div className="main_wrapp footer_main_wrapp">
        <div>
          <div className="footer_first_wrapp">
            <div className="footer_first_wrapp_sec1">
              <Link to={"/"}><img src={images.logo} alt="" /></Link>
              <p>
                Teknika Industries is constantly pushing boundaries in the tile
                industry. We invest in research and development to explore new
                materials, textures, and finishes.
              </p>
            </div>
            <div className="footer_first_wrapp_sec2">
              <Link to={"/"}>Home</Link>
              <Link to={"/about"}>About Us</Link>
              <Link to={"/products"}>Products</Link>
              <Link to={"/tech-innovation"}>Tech Innovate</Link>
              <Link to={"/blogs"}>Blog</Link>
              <Link to={"/contect-us"}>Contact Us</Link>
            </div>

            <div className="footer_first_wrapp_sec3">
              <Link to={"https://www.facebook.com/profile.php?id=61560521545376"} target="_blank">
                <FaFacebook size={16} color=" var(--color-gray)" className="icon_fot" />
                <p>Facebook</p>
              </Link>
              <Link to={"https://www.instagram.com/teknikaceramiche/"} target="_blank">
                <FaInstagram size={16} color=" var(--color-gray)" className="icon_fot" />
                <p>Instagram</p>
              </Link>
              <Link to={"https://www.linkedin.com/company/teknika-ceramiche"} target="_blank">
                <FaLinkedin size={16} color=" var(--color-gray)" className="icon_fot" />
                <p>Linkedin</p>
              </Link>
              <Link to={"https://in.pinterest.com/teknikaceramiche/"} target="_blank">
                <FaPinterest size={16} color=" var(--color-gray)" className="icon_fot" />
                <p>Pinterest</p>
              </Link>
            </div>

            <div className="footer_first_wrapp_sec4">
              <p className="footer_first_wrapp_sec4_heading">Newsletter</p>
              <div className="footer_first_wrapp_sec4_input_wrapp">
                <input
                  type="email"
                  className="footer_first_wrapp_sec4_input_box"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <button onClick={handleSubscribe}>
                  <img src={images.news_send_icon} alt="" />
                </button>
              </div>
              {errorMessage && <p className="error_message">{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="footer_third_wrapp">
        <div className="footer_third_content">
          <p>Copyright&#xA9; 2024 Teknika. / All Rights Reserved</p>
          <p>
            <Link to={"https://binarygeckos.com/"} target="_blank">
              Designed By: Binary Geckos
            </Link>
          </p>
        </div>
      </div>
      {loading && <div className="loading-overlay"></div>}
      {showSuccessModal && (
        <div className="modal_contact">
          <div className="modal_content">
            <p>Your message has been sent successfully!</p>
            <button onClick={closeModal} className="custom_button">Okay</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
