import React from "react";
import "./TechPartners.css";
import images from "../../constants/images";

const TechPartners = () => {
  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp tech_partner_main_wrapp">
        <h2 className="sec_heading mb-2">TECHNOLOGY PARTNERS</h2>
        <div className="tech_partner_logos_wrapp">
          <div>
            <img src={images.tech_partner_logo_1} alt="" />
          </div>
          <div>
            <img src={images.tech_partner_logo_2} alt="" />
          </div>
          <div>
            <img src={images.tech_partner_logo_3} alt="" />
          </div>
          <div>
            <img src={images.tech_partner_logo_4} alt="" />
          </div>
          <div>
            <img src={images.tech_partner_logo_5} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechPartners;
