import React, { useEffect, useState } from "react";
import "./Navbar.css";
import images from "../../constants/images";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { RiMenu3Line } from "react-icons/ri";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest } from "react-icons/fa";


const Navbar = () => {
  const location = useLocation();

  const [getsidebarOpen, setSidebarOpen] = useState(false);

  const handleChange = () => {
    setSidebarOpen(!getsidebarOpen);
  };

  return (
    <>
      <nav className="nav_main_wrapp">
        <div className="container_2">
          <div className="nav_base_wrapp">
            <Link to={"/"}>
              <img src={images.logo} alt="logo" className="nav_logo" />
            </Link>

            <div className="nav_links">
              <Link
                to={"/"}
                style={{
                  color:
                    location.pathname === "/" ? "var(--color-ocher)" : "black",
                  fontWeight: location.pathname === "/" ? "600" : "600",

                  borderTop:
                    location.pathname === "/"
                      ? "4px solid var(--color-ocher)"
                      : "",

                  fontFamily: "var(--font-base)",
                  padding: "18px 0px",
                }}
              >
                <p
                  style={{
                    marginBottom: location.pathname === "/" ? "4px" : "0px",
                  }}
                >
                  Home
                </p>
              </Link>
              <Link
                to={"/about"}
                style={{
                  color:
                    location.pathname === "/about"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/about" ? "700" : "600",
                  borderTop:
                    location.pathname === "/about"
                      ? "4px solid var(--color-ocher)"
                      : "",
                  fontFamily: "var(--font-base)",
                  padding: "18px 0px",
                }}
              >
                <p
                  style={{
                    marginBottom: location.pathname === "/" ? "4px" : "0px",
                  }}
                >
                  About Us
                </p>
              </Link>
              <Link
                to={"/products"}
                style={{
                  color:
                    location.pathname === "/products"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/products" ? "700" : "600",
                  borderTop:
                    location.pathname === "/products"
                      ? "4px solid var(--color-ocher)"
                      : "",

                  fontFamily: "var(--font-base)",
                  padding: "18px 0px",
                }}
              >
                <p
                  style={{
                    marginBottom: location.pathname === "/" ? "4px" : "0px",
                  }}
                >
                  Products
                </p>
              </Link>
              <Link
                to={"/tech-innovation"}
                style={{
                  color:
                    location.pathname === "/tech-innovation"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight:
                    location.pathname === "/tech-innovation" ? "700" : "600",
                  borderTop:
                    location.pathname === "/tech-innovation"
                      ? "4px solid var(--color-ocher)"
                      : "",

                  fontFamily: "var(--font-base)",
                  padding: "18px 0px",
                }}
              >
                <p
                  style={{
                    marginBottom: location.pathname === "/" ? "4px" : "0px",
                  }}
                >
                  Tech Innovate
                </p>
              </Link>
              <Link
                to={"/blogs"}
                style={{
                  color:
                    location.pathname === "/blogs"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/blogs" ? "700" : "600",
                  borderTop:
                    location.pathname === "/blogs"
                      ? "4px solid var(--color-ocher)"
                      : "",

                  fontFamily: "var(--font-base)",
                  padding: "18px 0px",
                }}
              >
                <p
                  style={{
                    marginBottom: location.pathname === "/" ? "4px" : "0px",
                  }}
                >
                  Blogs
                </p>
              </Link>
              <Link
                to={"/contect-us"}
                style={{
                  color:
                    location.pathname === "/contect-us"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight:
                    location.pathname === "/contect-us" ? "700" : "600",
                  borderTop:
                    location.pathname === "/contect-us"
                      ? "4px solid var(--color-ocher)"
                      : "",

                  fontFamily: "var(--font-base)",
                  padding: "18px 0px",
                }}
              >
                <p
                  style={{
                    marginBottom: location.pathname === "/" ? "4px" : "0px",
                  }}
                >
                  Contact Us
                </p>
              </Link>
            </div>

            <div className="nav_social_links">
              <Link
                to={"https://www.facebook.com/profile.php?id=61560521545376"} target="_blank"
                style={{
                  color: "black",
                  fontWeight: "600",
                  // fontWeight: location.pathname === "/" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
              >
                <FaFacebook size={25} />
              </Link>
              <Link
                to={"https://www.instagram.com/teknikaceramiche/"} target="_blank"
                style={{
                  color: "black",
                  fontWeight: "600",
                  // fontWeight: location.pathname === "/" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
              >
                <FaInstagram size={25} />
              </Link>
              <Link
                to={"https://www.linkedin.com/company/teknika-ceramiche"} target="_blank"
                style={{
                  color: "black",
                  fontWeight: "600",
                  // fontWeight: location.pathname === "/" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
              >
                <FaLinkedin size={25} />
              </Link>
              <Link
                to={"https://in.pinterest.com/teknikaceramiche/"} target="_blank"
                style={{
                  color: "black",
                  fontWeight: "600",
                  // fontWeight: location.pathname === "/" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
              >
                <FaPinterest size={25} />
              </Link>
            </div>

            <input
              id="checkbox"
              type="checkbox"
              checked={getsidebarOpen}
              onClick={() => {
                setSidebarOpen(!getsidebarOpen);
              }}
            />
            <label class="toggle" for="checkbox">
              <div id="bar1" class="bars"></div>
              <div id="bar2" class="bars"></div>
              <div id="bar3" class="bars"></div>
            </label>
          </div>

          {/* ----------- sidemenu start ------------ */}
          <div
            className={`nav_sidebar_wrapp`}
            style={{
              width: getsidebarOpen ? "100vw" : "0px",
              height: getsidebarOpen ? "90vh" : "0px",
              transition: "height 0.5s ease",
              overflow: "hidden",
            }}
          >
            <div className="navbar_inner_wrapp">
              <Link
                to={"/"}
                style={{
                  color:
                    location.pathname === "/" ? "var(--color-ocher)" : "black",
                  fontWeight: location.pathname === "/" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                Home
              </Link>
              <Link
                to={"/about"}
                style={{
                  color:
                    location.pathname === "/about"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/about" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                About Us
              </Link>
              <Link
                to={"/products"}
                style={{
                  color:
                    location.pathname === "/products"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/programs" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                Products
              </Link>
              <Link
                to={"/tech-innovation"}
                style={{
                  color:
                    location.pathname === "/tech-innovation"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/programs" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                Tech Innovate
              </Link>
              <Link
                to={"/blogs"}
                style={{
                  color:
                    location.pathname === "/blogs"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/programs" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                Blogs
              </Link>
              <Link
                to={"/contect-us"}
                style={{
                  color:
                    location.pathname === "/contect-us"
                      ? "var(--color-ocher)"
                      : "black",
                  fontWeight: location.pathname === "/programs" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                Contact Us
              </Link>
              <div className="nav_social_links_side">
                <Link
                  to={"https://www.facebook.com/profile.php?id=61560521545376"} target="_blank"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    // fontWeight: location.pathname === "/" ? "700" : "600",
                    fontFamily: "var(--font-base)",
                  }}
                >
                  <FaFacebook size={25} />
                </Link>
                <Link
                  to={"https://www.instagram.com/teknikaceramiche/"} target="_blank"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    // fontWeight: location.pathname === "/" ? "700" : "600",
                    fontFamily: "var(--font-base)",
                  }}
                >
                  <FaInstagram size={25} />
                </Link>
                <Link
                  to={"https://www.linkedin.com/company/teknika-ceramiche"} target="_blank"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    // fontWeight: location.pathname === "/" ? "700" : "600",
                    fontFamily: "var(--font-base)",
                  }}
                >
                  <FaLinkedin size={25} />
                </Link>
                <Link
                to={"https://in.pinterest.com/teknikaceramiche/"} target="_blank"
                style={{
                  color: "black",
                  fontWeight: "600",
                  // fontWeight: location.pathname === "/" ? "700" : "600",
                  fontFamily: "var(--font-base)",
                }}
              >
                <FaPinterest size={25} />
              </Link>
              </div>
            </div>
          </div>
          {/* ----------- sidemenu end ------------ */}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
