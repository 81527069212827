import React from "react";
import "./HomeInsta.css";
import images from "../../constants/images";
import { FaInstagram } from "react-icons/fa6";
import { InstagramEmbed } from 'react-social-media-embed';

const HomeInsta = () => {
  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp">
        <div className="home_insta_main_wrapp">
          <br />
          {/* <p className="home_insta_text"></p> */}
          <h3 className="sec_heading mb-2">Instagram</h3>
          <div className="home_insta_cards_wrapp">
            <InstagramEmbed
              url="https://www.instagram.com/p/CwST7cPILeo/?utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA=="
              width={540}
              captioned
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/C5iOYX7tFPW/?utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA=="
              width={540}
              captioned
            />
            <InstagramEmbed
              url="https://www.instagram.com/p/C5iOjC0NOr8/?utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA=="
              width={540}
              captioned
            />
          </div>
          {/* <div className="home_insta_cards_wrapp">
            <div className="image_container">
              <img src={images.home_img_2} alt="" />
              <div className="overlay"><FaInstagram size={30} color=" var(--color-white)"/></div>
            </div>
            <div className="image_container">
              <img src={images.about_image} alt="" />
              <div className="overlay"><FaInstagram size={30} color=" var(--color-white)"/></div>
            </div>
            <div className="image_container">
              <img src={images.insta_img3} alt="" />
              <div className="overlay"><FaInstagram size={30} color=" var(--color-white)"/></div>
            </div>
            <div className="image_container">
              <img src={images.insta_img4} alt="" />
              <div className="overlay"><FaInstagram size={30} color=" var(--color-white)"/></div>
            </div>
            <div className="image_container">
              <img src={images.insta_img5} alt="" />
              <div className="overlay"><FaInstagram size={30} color=" var(--color-white)"/></div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomeInsta;
