import React, { useEffect } from "react";
import { HeroSection } from "../common";
import images from "../constants/images";
import { TechInnovation, TechPartners } from "../container";

const TechPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' 
    });
  }, []);
  return (
    <div>
      <HeroSection img={images.techinnovation_hero_img_hero_img} title="Technology & Innovation" />
      <TechInnovation />
      <TechPartners />
    </div>
  );
};

export default TechPage;
