import React, { useState } from "react";
import axios from "axios";
import "./ContectUsForm.css";
import images from "../../constants/images";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa6";

const ContectUsForm = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "full_name":
        if (!value.trim()) {
          error = "Full Name is required";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid";
        }
        break;
      case "message":
        if (!value.trim()) {
          error = "Message is required";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.full_name.trim()) {
      newErrors.full_name = "Full Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); // Set loading state to true on form submission
      try {
        const response = await axios.post("https://gokulnair.com/teknika/vadmin/api/contact_us", formData);
        console.log("Response:", response.data);
        setFormData({
          full_name: "",
          email: "",
          message: "",
        });
        setShowSuccessModal(true); // Show the success modal after successful submission
      } catch (error) {
        console.error("There was an error sending the form data!", error);
      }
      setLoading(false); // Set loading state to false after form submission
    }
  };

  const closeModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div className="c_u_f_main_wrapp">
      {loading && <div className="loading-overlay"></div>}
      <div className="contect_form_wrapp">
        <p className="contect_form_l_title">CONTACT FORM</p>
        <h3 className="sec_heading">Send us your query here</h3>
        <form className="contect_form_inputs_wrapp" onSubmit={handleSubmit}>
          <input
            type="text"
            name="full_name"
            placeholder="Full Name"
            value={formData.full_name}
            onChange={handleChange}
          />
          {errors.full_name && <p className="error">{errors.full_name}</p>}
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <p className="error">{errors.email}</p>}
          <textarea
            name="message"
            rows="6"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          {errors.message && <p className="error">{errors.message}</p>}
          <button type="submit" className="custom-button mb-2">SEND MESSAGE</button>
        </form>
        {showSuccessModal && (
          <div className="modal_contact">
            <div className="modal_content">
              <p>Your message has been sent successfully!</p>
              <button onClick={closeModal} className="custom_button">Okay</button>
            </div>
          </div>
        )}
      </div>
      <div className="contect_info_wrapp">
        <div className="contect_info_inner_wrapp">
          <p className="contect_info_l_title">CONTACT INFO</p>
          <h4 className="contect_info_heading">
            For further reach out, please contact through below
          </h4>
          <p className="contect_info_des">
            Give us a call or drop by anytime, we endeavour to answer all
            enquiries within 24 hours on business days. We will be happy to
            answer your questions.
          </p>
          <div className="contect_info_address_wrapp">
            <img src={images.map_pin_white} alt="" />
            <div>
              <h5>FACTORY ADDRESS:</h5>
              <p>
                8A national highway, Halvad road., <br /> morbi-2 , Gujarat,
                India. <br /> Pin - 363642
              </p>
            </div>
          </div>
          <div className="contect_info_address_wrapp">
            <img src={images.map_pin_white} alt="" />
            <div>
              <h5>CORPORATE ADDRESS:</h5>
              <p>
                Block no. 4, Ground floor, Gokuldham main road, near swaminarayan
                Nagar, Rajkot, Gujarat, India <br /> Pin- 360004
              </p>
            </div>
          </div>
          <div className="contect_info_address_wrapp">
            <img src={images.email_icon_white} alt="" />
            <div>
              <h5>OUR MAILBOX:</h5>
              <a href="mailto:export@teknikaceramiche.com" className="email_contact">export@teknikaceramiche.com</a>
              <a href="mailto:Sales@teknikaceramiche.com" className="email_contact">Sales@teknikaceramiche.com</a>
            </div>
          </div>
          <div className="contect_info_address_wrapp">
            <img src={images.phone_icon_white} alt="" />
            <div>
              <h5>OUR PHONE:</h5>
              <a href="tel:+91 82382 03331" className="email_contact">+91 82382 03331</a>
              <a href="tel:+91 82382 03338" className="email_contact">+91 82382 03338</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContectUsForm;