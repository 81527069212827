import React, { useEffect, useRef, useState } from "react";
import "./HomeAdvantage.css";
import images from "../../constants/images";
import { Parallax } from "react-parallax";

const HomeAdvantage = () => {
  const parallaxRef = useRef(null);
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    if (parallaxRef.current) {
      const parallax = parallaxRef.current;
      const offset = window.pageYOffset;
      setOffsetY(offset);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className="section_main_wrapp"
      style={{ flexDirection: "column", marginBottom: "2rem" }}
    >
      <div className="section_inner_wrapp">
        <div className="home_advantage_main_wrapp">
          <p className="home_advantage_heading mb-1">The Teknika Advantage</p>
          <div className="home_advantage_cards_wrapp">
            <div className="home_advantage_single_card">
              <img src={images.home_advantage_logo_1} alt="" />
              <h3>Unique Design portfolio</h3>
              <p>
                Unleash your creativity with our vast selection of one-of-a-kind
                designs.
              </p>
            </div>
            <div className="home_advantage_single_card">
              <img src={images.home_advantage_logo_2} alt="" />
              <h3>European Standard</h3>
              <p>
                Experience unparalleled quality, crafted to meet the strictest
                European standards.
              </p>
            </div>
            <div className="home_advantage_single_card">
              <img src={images.home_advantage_logo_3} alt="" />
              <h3>Highely Durable Packaging</h3>
              <p>
                Rest assured, your tiles will arrive safely with our secure
                packaging.{" "}
              </p>
            </div>

            <div className="home_advantage_single_card">
              <img src={images.home_advantage_logo_4} alt="" />
              <h3>Innovative Surfaces</h3>
              <p>
                Explore our collection of groundbreaking surfaces that redefine
                design possibilities.{" "}
              </p>
            </div>

            <div className="home_advantage_single_card">
              <img src={images.home_advantage_logo_5} alt="" />
              <h3>TECHNOLOGY</h3>
              <p>
                Discover the power of technology in our manufacturing process,
                ensuring precision and consistency.
              </p>
            </div>

            <div className="home_advantage_single_card">
              <img src={images.home_advantage_logo_6} alt="" />
              <h3>Adaptability</h3>
              <p>
                Our tiles offer exceptional versatility, perfect for bringing
                your design vision to life.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home_advantage_peralx_main_wrapp">
        <Parallax
          bgImage={images.home_img_4} // Path to the background image
          strength={300} // Control the depth effect
          bgImageAlt="Parallax Background"
          style={{ height: "100%" }} // Height of the parallax section
        >
          <div className="home_advantage_parallax_shadow"></div>
        </Parallax>
      </div>
    </div>
  );
};

export default HomeAdvantage;
