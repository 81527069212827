import React, { useEffect, useState } from "react";
import { HeroSection } from "../common";
import images from "../constants/images";
import { ProductFilter, ProductsSec } from "../container";
import CategoryData from "../constants/data";
import "../container/productssec/ProductsSec.css";
import { IoClose } from "react-icons/io5";

const ProductsPage = () => {
  const [categoryFilter, setCategoryFilter] = useState("All Categories");
  const [sizeFilter, setSizeFilter] = useState("All Sizes");
  const [finishFilter, setFinishFilter] = useState("All Finishes");
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCount, setVisibleCount] = useState(8);
  const [modalOpened, setModalOpened] = useState(false);
  const [showImg, setShowImg] = useState("");
  const [subImages, setSubImages] = useState([]);

  const getAllProducts = () => {
    return CategoryData.reduce((acc, category) => {
      return acc.concat(category.products);
    }, []);
  };

  const [currentCategory, setCurrentCategory] = useState({
    name: "All Categories",
    products: getAllProducts(),
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    console.log("modalOpened", modalOpened);
  }, [modalOpened]);

  const handleModalOpened = (img, subImgs) => {
    setShowImg(img);
    setSubImages(subImgs);
    setModalOpened(true);
  };

  const handleCategoryChange = (category) => {
    setCategoryFilter(category);
    setSizeFilter("All Sizes");
    setFinishFilter("All Finishes");
    if (category === "All Categories") {
      setCurrentCategory({
        name: "All Categories",
        products: getAllProducts(),
      });
    } else {
      setCurrentCategory(CategoryData.find((cat) => cat.name === category));
    }
  };

  const handleSizeChange = (size) => {
    setSizeFilter(size);
    setFinishFilter("All Finishes"); // Reset finish filter
  };

  const handleFinishChange = (finish) => {
    setFinishFilter(finish);
  };

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };

  let filteredProducts = currentCategory.products;

  if (sizeFilter && sizeFilter !== "All Sizes") {
    filteredProducts = filteredProducts.filter((product) =>
      product.size.includes(sizeFilter)
    );
  }

  if (finishFilter && finishFilter !== "All Finishes") {
    filteredProducts = filteredProducts.filter(
      (product) => product.finish === finishFilter
    );
  }

  if (searchTerm) {
    filteredProducts = filteredProducts.filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.size.some((size) =>
          size.toLowerCase().includes(searchTerm.toLowerCase())
        ) ||
        product.finish.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const availableSizes = [
    "All Sizes",
    ...new Set(filteredProducts.flatMap((product) => product.size)),
  ];

  const availableFinishes = [
    "All Finishes",
    ...new Set(filteredProducts.map((product) => product.finish)),
  ];

  return (
    <div>
      <HeroSection img={images.product_hero_img} title="Products" />
      <ProductFilter
        data={CategoryData}
        currentCategory={currentCategory}
        categoryFilter={categoryFilter}
        sizeFilter={sizeFilter}
        finishFilter={finishFilter}
        searchTerm={searchTerm}
        handleCategoryChange={handleCategoryChange}
        handleSizeChange={handleSizeChange}
        handleFinishChange={handleFinishChange}
        handleSearchChange={handleSearchChange}
        isSizeDisabled={
          categoryFilter === "All Categories" ||
          currentCategory.products.every((product) => !product.size)
        }
        isFinishDisabled={
          categoryFilter === "All Categories" ||
          currentCategory.products.every((product) => !product.finish)
        }
        availableSizes={availableSizes}
        availableFinishes={availableFinishes}
      />
      <ProductsSec
        products={filteredProducts.slice(0, visibleCount)}
        handleModalOpened={handleModalOpened}
      />
      {visibleCount < filteredProducts.length && (
        <div
          style={{
            margin: "1rem auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <button className="custom-button" onClick={handleShowMore}>
            SHOW MORE
          </button>
        </div>
      )}

      {modalOpened && (
        <div className="product_img_open_mv">
          <div className="product_img_open_iv">
            <img src={showImg} alt="" />
            <div className="sub_images">
              {subImages.map((subImg, index) => (
                <img
                  key={index}
                  src={subImg}
                  alt={`Sub img ${index + 1}`}
                  onClick={() => setShowImg(subImg)}
                  className="sub_image"
                />
              ))}
            </div>
            <button
              className="product_img_close_btn"
              onClick={() => {
                setModalOpened(false);
              }}
            >
              <IoClose size={30} color="var(--color-ocher)" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
