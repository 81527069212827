import React, { useRef, useState } from "react";
import "./HomeTopProducts.css";
import images from "../../constants/images";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

const slides = [
  { src: images.home_slider_1, alt: "Slide 1" },
  { src: images.home_img_2, alt: "Slide 2" },
  { src: images.about_weare_img, alt: "Slide 3" },
  { src: images.roccia_taupe, alt: "Slide 4" },
  { src: images.insta_img4, alt: "Slide 5" },
  { src: images.norway_nero, alt: "Slide 6" },
];

const PointsDisplay = ({ slideIndex }) => {
  // Define the words array
  const words = [
    "marble look",
    "stone look",
    "concrete",
    "wood look",
    "Porcelain Counter Tops",
    "XL slabs",
  ];

  return (
    <div className="home_top_prod_sec_2_flot_wrapp">
      {words.map((word, index) => (
        <div
          key={index}
          // className="home_top_prod_sec_2_flot_single_part"
          className={`home_top_prod_sec_2_flot_single_part ${
            slideIndex === index ? "flot_single_part_highlighted" : ""
          }`}
        >
          <div>{index + 1}</div>
          <p
            className={`flot_single_part_word ${
              slideIndex === index ? "flot_single_part_highlighted" : ""
            }`}
          >
            {word}
          </p>
        </div>
      ))}
    </div>
  );
};

const HomeTopProducts = () => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    afterChange: (index) => setCurrentSlide(index),
  };

  // Custom button handlers
  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp">
        <div className="home_top_prod_main_wrapp">
          <div className="home_top_prod_sec_1">
            {/* custom slider */}
            <Slider ref={sliderRef} {...settings}>
              {slides.map((slide, index) => (
                <div key={index} className="slide">
                  <img
                    src={slide.src}
                    alt={slide.alt}
                    className="slide-image-top-products"
                  />
                </div>
              ))}
            </Slider>

            {/* custom slide count */}

            <div className="hero-slider-counter">
              <p>0{currentSlide + 1}</p>
            </div>

            {/* Custom buttons */}
            <div className="custom-buttons">
              <button className="prev-button" onClick={handlePrevious}>
                <FaAngleLeft size={24} color="#000" />
              </button>
              <button className="next-button" onClick={handleNext}>
                <FaAngleRight size={24} color="#000" />
              </button>
            </div>
          </div>
          <div className="home_top_prod_sec_2">
            <p className="home_top_prod_sec2_title">Top Products</p>
            <p className="home_top_prod_sec2_des">
              Our premium wooden tiles, available in 20x120 and 20x90 sizes.
              Crafted for timeless elegance and durability, these tiles offer
              versatile solutions for any space.
            </p>
            <Link to="/products" className="home_top_prod_sec2_btn">
              VIEW COLLECTION
            </Link>

            {/* <div className="home_top_prod_sec_2_flot_wrapp"></div> */}
            <PointsDisplay slideIndex={currentSlide} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTopProducts;
