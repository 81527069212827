import React from "react";
import "./ProductCard.css";
import { IoEyeOutline } from "react-icons/io5";

const ProductCard = ({ imgSrc, productName, handleModalOpened, sizeproduct, subImages }) => {
  return (
    <div className="prod_card_m_w">
      <div className="prod_card_shadow">
        <div
          className="prod_card_shadow_eye_wrapp"
          onClick={() => {
            handleModalOpened(imgSrc, subImages);
          }}
        >
          <IoEyeOutline size={24} color="var(--color-ocher)" />
        </div>
      </div>
      <img src={imgSrc} alt={productName} />
      <p>{productName}</p>
      {/* <span>{sizeproduct}</span> */}
    </div>
  );
};

export default ProductCard;
