import images from "./images";

const CategoryData = [
  {
    name: 'Porcelain Tiles',
    products: [
      // ***************  Glossy start  *************** //
      {
        id: 1,
        name: "ADONIS DARK",
        img: images.ADONIS1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ADONIS1,
          images.ADONIS2,
        ]
      },
      {
        id: 2,
        name: "AIZA COBALT",
        img: images.AIZA_COBALT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AIZA_COBALT_P1,
          images.AIZA_COBALT_P2,
          images.AIZA_COBALT_P3,
          images.AIZA_COBALT_P4,
        ]
      },
      {
        id: 3,
        name: "AIZA ONYX",
        img: images.AIZA_ONYX_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AIZA_ONYX_P1,
          images.AIZA_ONYX_P2,
          images.AIZA_ONYX_P3,
          images.AIZA_ONYX_P4,
        ]
      },
      {
        id: 4,
        name: "ALBASTO BIANCO",
        img: images.ALBASTO_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ALBASTO_BIANCO_P1,
          images.ALBASTO_BIANCO_P2,
          images.ALBASTO_BIANCO_P3,
          images.ALBASTO_BIANCO_P4,
        ]
      },
      {
        id: 5,
        name: "ALBASTO BROWN",
        img: images.ALBASTO_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ALBASTO_BROWN_P1,
          images.ALBASTO_BROWN_P2,
          images.ALBASTO_BROWN_P3,
          images.ALBASTO_BROWN_P4,
          images.ALBASTO_BROWN_P5,
        ]
      },
      {
        id: 6,
        name: "ALPINE STATURIO",
        img: images.ALPINE_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ALPINE_STATURIO_P1,
          images.ALPINE_STATURIO_P2,
          images.ALPINE_STATURIO_P3,
          images.ALPINE_STATURIO_P4,
          images.ALPINE_STATURIO_P5,
        ]
      },
      {
        id: 7,
        name: "AMAZON CREMA",
        img: images.AMAZON_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AMAZON_CREMA_P1,
          images.AMAZON_CREMA_P2,
          images.AMAZON_CREMA_P3,
          images.AMAZON_CREMA_P4,
        ]
      },
      {
        id: 8,
        name: "AMAZON GREY",
        img: images.AMAZON_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AMAZON_GREY_P1,
          images.AMAZON_GREY_P2,
          images.AMAZON_GREY_P3,
          images.AMAZON_GREY_P4,
        ]
      },
      {
        id: 9,
        name: "AMBROSIA BIANCO",
        img: images.AMBROSIA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AMBROSIA_BIANCO_P1,
          images.AMBROSIA_BIANCO_P2,
          images.AMBROSIA_BIANCO_P3,
          images.AMBROSIA_BIANCO_P4,
        ]
      },
      {
        id: 10,
        name: "AMBROSIA BROWN",
        img: images.AMBROSIA_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AMBROSIA_BROWN_P1,
          images.AMBROSIA_BROWN_P2,
          images.AMBROSIA_BROWN_P3,
          images.AMBROSIA_BROWN_P4,
        ]
      },
      {
        id: 11,
        name: "ANGEL SATVARIYO",
        img: images.ANGEL_SATVARIYO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ANGEL_SATVARIYO_P1,
          images.ANGEL_SATVARIYO_P2,
          images.ANGEL_SATVARIYO_P3,
          images.ANGEL_SATVARIYO_P4,
        ]
      },
      {
        id: 12,
        name: "ANTI QUITY BEIGE",
        img: images.ANTI_QUITY_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ANTI_QUITY_BEIGE_P1,
          images.ANTI_QUITY_BEIGE_P2,
          images.ANTI_QUITY_BEIGE_P3,
          images.ANTI_QUITY_BEIGE_P4,
        ]
      },
      {
        id: 13,
        name: "ANTICA COBALT",
        img: images.ANTICA_COBALT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ANTICA_COBALT_P1,
          images.ANTICA_COBALT_P2,
        ]
      },
      {
        id: 14,
        name: "ANTICA MAROON",
        img: images.ANTICA_MAROON_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ANTICA_MAROON_P1,
          images.ANTICA_MAROON_P2,
        ]
      },
      {
        id: 15,
        name: "ANTRACITA ICE",
        img: images.ANTRACITA_ICE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ANTRACITA_ICE_P1,
          images.ANTRACITA_ICE_P2,
          images.ANTRACITA_ICE_P3,
          images.ANTRACITA_ICE_P4,
        ]
      },
      {
        id: 16,
        name: "APUANE SATUARIO",
        img: images.APUANE_SATUARIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.APUANE_SATUARIO_P1,
          images.APUANE_SATUARIO_P2,
          images.APUANE_SATUARIO_P3,
          images.APUANE_SATUARIO_P4,
        ]
      },
      {
        id: 17,
        name: "ARABIC BROWN",
        img: images.ARABIC_BROWN_LIGHT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARABIC_BROWN_LIGHT_P1,
          images.ARABIC_BROWN_LIGHT_P2,
          images.ARABIC_BROWN_LIGHT_P3,
          images.ARABIC_BROWN_LIGHT_P4,
        ]
      },
      {
        id: 18,
        name: "ARABIC BROWN LIGHT",
        img: images.ARABIC_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARABIC_BROWN_P1,
          images.ARABIC_BROWN_P2,
          images.ARABIC_BROWN_P3,
          images.ARABIC_BROWN_P4,
        ]
      },
      {
        id: 19,
        name: "ARCTIC WHITE",
        img: images.ARCTIC_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARCTIC_WHITE_P1,
          images.ARCTIC_WHITE_P2,
          images.ARCTIC_WHITE_P3,
        ]
      },
      {
        id: 20,
        name: "ARDESIA COBALT",
        img: images.ARDESIA_COBALT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARDESIA_COBALT_P1,
          images.ARDESIA_COBALT_P2,
          images.ARDESIA_COBALT_P3,
          images.ARDESIA_COBALT_P4,
        ]
      },
      {
        id: 21,
        name: "ARDESIA COBALT LIGHT",
        img: images.ARDESIA_COBALT_LIGHT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARDESIA_COBALT_LIGHT_P1,
          images.ARDESIA_COBALT_LIGHT_P2,
          images.ARDESIA_COBALT_LIGHT_P3,
          images.ARDESIA_COBALT_LIGHT_P4,
        ]
      },
      {
        id: 22,
        name: "ARDESIA GOLDEN",
        img: images.ARDESIA_GOLDEN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARDESIA_GOLDEN_P1,
          images.ARDESIA_GOLDEN_P2,
          images.ARDESIA_GOLDEN_P3,
          images.ARDESIA_GOLDEN_P4,
        ]
      },
      {
        id: 23,
        name: "ARMANI BRONZE",
        img: images.ARMANI_BRONZE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARMANI_BRONZE_P1,
          images.ARMANI_BRONZE_P2,
          images.ARMANI_BRONZE_P3,
          images.ARMANI_BRONZE_P4,
        ]
      },
      {
        id: 24,
        name: "ARMANO BEIGE",
        img: images.ARMANO_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARMANO_BEIGE_P1,
          images.ARMANO_BEIGE_P2,
          images.ARMANO_BEIGE_P3,
          images.ARMANO_BEIGE_P4,
          images.ARMANO_BEIGE_P5,
          images.ARMANO_BEIGE_P6,
        ]
      },
      {
        id: 25,
        name: "ARMANO BROWN",
        img: images.ARMANO_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARMANO_BROWN_P1,
          images.ARMANO_BROWN_P2,
          images.ARMANO_BROWN_P3,
          images.ARMANO_BROWN_P4,
          images.ARMANO_BROWN_P5,
          images.ARMANO_BROWN_P6,
        ]
      },
      {
        id: 26,
        name: "ARMANO CREMA",
        img: images.ARMANO_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARMANO_CREMA_P1,
          images.ARMANO_CREMA_P2,
          images.ARMANO_CREMA_P3,
          images.ARMANO_CREMA_P4,
          images.ARMANO_CREMA_P5,
          images.ARMANO_CREMA_P6,
        ]
      },
      {
        id: 27,
        name: "ARMANO GREY",
        img: images.ARMANO_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARMANO_GREY_P1,
          images.ARMANO_GREY_P2,
          images.ARMANO_GREY_P3,
          images.ARMANO_GREY_P4,
          images.ARMANO_GREY_P5,
          images.ARMANO_GREY_P6,
        ]
      },
      {
        id: 28,
        name: "ARTICI BEIGE",
        img: images.ARTICI_BEIGE,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARTICI_BEIGE,
        ]
      },
      {
        id: 29,
        name: "ARTICI BIANCO",
        img: images.ARTICI_BIANCO,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARTICI_BIANCO,
        ]
      },
      {
        id: 30,
        name: "ARTICI COAL",
        img: images.ARTICI_COAL,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARTICI_COAL,
        ]
      },
      {
        id: 31,
        name: "ARTICO ONYX BIANCO",
        img: images.ARTICO_ONYX_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARTICO_ONYX_BIANCO_P1,
          images.ARTICO_ONYX_BIANCO_P2,
          images.ARTICO_ONYX_BIANCO_P3,
          images.ARTICO_ONYX_BIANCO_P4,
        ]
      },
      {
        id: 32,
        name: "ARTICO ONYX MULTI",
        img: images.ARTICO_ONYX_MULTI_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ARTICO_ONYX_MULTI_P1,
          images.ARTICO_ONYX_MULTI_P2,
          images.ARTICO_ONYX_MULTI_P3,
          images.ARTICO_ONYX_MULTI_P4,
        ]
      },
      {
        id: 33,
        name: "ATHENA BIANCO",
        img: images.ATHENA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ATHENA_BIANCO_P1,
          images.ATHENA_BIANCO_P2,
          images.ATHENA_BIANCO_P3,
          images.ATHENA_BIANCO_P4,
        ]
      },
      {
        id: 34,
        name: "ATHENA BROWN",
        img: images.ATHENA_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ATHENA_BROWN_P1,
          images.ATHENA_BROWN_P2,
          images.ATHENA_BROWN_P3,
          images.ATHENA_BROWN_P4,
        ]
      },
      {
        id: 35,
        name: "ATHENA COBALT",
        img: images.ATHENA_COBALT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ATHENA_COBALT_P1,
          images.ATHENA_COBALT_P2,
          images.ATHENA_COBALT_P3,
          images.ATHENA_COBALT_P4,
        ]
      },
      {
        id: 36,
        name: "ATLAS CREMA",
        img: images.ATLAS_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ATLAS_CREMA_P1,
          images.ATLAS_CREMA_P2,
          images.ATLAS_CREMA_P3,
          images.ATLAS_CREMA_P4,
          images.ATLAS_CREMA_P5,
        ]
      },
      {
        id: 37,
        name: "AUK GRESS",
        img: images.AUK_GRESS_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AUK_GRESS_P1,
          images.AUK_GRESS_P2,
          images.AUK_GRESS_P3,
          images.AUK_GRESS_P4,
        ]
      },
      {
        id: 38,
        name: "AURA GOLD",
        img: images.AURA_GOLD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AURA_GOLD_P1,
          images.AURA_GOLD_P2,
          images.AURA_GOLD_P3,
          images.AURA_GOLD_P4,
        ]
      },
      {
        id: 39,
        name: "AURA STATURIO",
        img: images.AURA_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AURA_STATURIO_P1,
          images.AURA_STATURIO_P2,
          images.AURA_STATURIO_P3,
          images.AURA_STATURIO_P4,
        ]
      },
      {
        id: 40,
        name: "AZUL CREMA",
        img: images.AZUL_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.AZUL_CREMA_P1,
          images.AZUL_CREMA_P2,
          images.AZUL_CREMA_P3,
          images.AZUL_CREMA_P4,
          images.AZUL_CREMA_P5,
          images.AZUL_CREMA_P6,
        ]
      },
      {
        id: 41,
        name: "BALI GREY",
        img: images.BALI_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BALI_GREY_P1,
          images.BALI_GREY_P2,
          images.BALI_GREY_P3,
          images.BALI_GREY_P4,
        ]
      },
      {
        id: 42,
        name: "BEIGE OCEAN",
        img: images.BEIGE_OCEAN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BEIGE_OCEAN_P1,
          images.BEIGE_OCEAN_P2,
          images.BEIGE_OCEAN_P3,
          images.BEIGE_OCEAN_P4,
        ]
      },
      {
        id: 43,
        name: "BELMONT BEIGE",
        img: images.BELMONT_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BELMONT_BEIGE_P1,
          images.BELMONT_BEIGE_P2,
          images.BELMONT_BEIGE_P3,
          images.BELMONT_BEIGE_P4,
        ]
      },
      {
        id: 44,
        name: "BELMONT BROWN",
        img: images.BELMONT_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BELMONT_BROWN_P1,
          images.BELMONT_BROWN_P2,
          images.BELMONT_BROWN_P3,
          images.BELMONT_BROWN_P4,
        ]
      },
      {
        id: 45,
        name: "BELMONT GREY",
        img: images.BELMONT_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BELMONT_GREY_P1,
          images.BELMONT_GREY_P2,
          images.BELMONT_GREY_P3,
          images.BELMONT_GREY_P4,
        ]
      },
      {
        id: 46,
        name: "BELMONT WHITE",
        img: images.BELMONT_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BELMONT_WHITE_P1,
          images.BELMONT_WHITE_P2,
          images.BELMONT_WHITE_P3,
          images.BELMONT_WHITE_P4,
        ]
      },
      {
        id: 47,
        name: "BETON GREY",
        img: images.BETON_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BETON_GREY_P1,
          images.BETON_GREY_P2,
          images.BETON_GREY_P3,
          images.BETON_GREY_P4,
        ]
      },
      {
        id: 48,
        name: "BETTLE TRAVENTINO",
        img: images.BETTLE_TRAVENTINO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BETTLE_TRAVENTINO_P1,
          images.BETTLE_TRAVENTINO_P2,
          images.BETTLE_TRAVENTINO_P3,
          images.BETTLE_TRAVENTINO_P4,
        ]
      },
      {
        id: 49,
        name: "BIANCO OCEAN",
        img: images.BIANCO_OCEAN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BIANCO_OCEAN_P1,
          images.BIANCO_OCEAN_P2,
          images.BIANCO_OCEAN_P3,
          images.BIANCO_OCEAN_P4,
        ]
      },
      {
        id: 50,
        name: "BIGETT BLUE",
        img: images.BIGETT_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BIGETT_BLUE_P1,
          images.BIGETT_BLUE_P2,
          images.BIGETT_BLUE_P3,
        ]
      },
      {
        id: 51,
        name: "BIGETT BROWN",
        img: images.BIGETT_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BIGETT_BROWN_P1,
          images.BIGETT_BROWN_P2,
          images.BIGETT_BROWN_P3,
        ]
      },
      {
        id: 52,
        name: "BIGETT CREMA",
        img: images.BIGETT_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BIGETT_CREMA_P1,
          images.BIGETT_CREMA_P2,
          images.BIGETT_CREMA_P3,
        ]
      },
      {
        id: 53,
        name: "BIGETT GREY",
        img: images.BIGETT_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BIGETT_GREY_P1,
          images.BIGETT_GREY_P2,
          images.BIGETT_GREY_P3,
        ]
      },
      {
        id: 54,
        name: "BLUE OCEAN",
        img: images.BLUE_OCEAN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BLUE_OCEAN_P1,
          images.BLUE_OCEAN_P2,
          images.BLUE_OCEAN_P3,
          images.BLUE_OCEAN_P4,
        ]
      },
      {
        id: 55,
        name: "BOSCO AURORA",
        img: images.BOSCO_AURORA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BOSCO_AURORA_P1,
          images.BOSCO_AURORA_P2,
          images.BOSCO_AURORA_P3,
          images.BOSCO_AURORA_P4,
        ]
      },
      {
        id: 56,
        name: "BOSCO NATURAL",
        img: images.BOSCO_NATURAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BOSCO_NATURAL_P1,
          images.BOSCO_NATURAL_P2,
          images.BOSCO_NATURAL_P3,
          images.BOSCO_NATURAL_P4,
        ]
      },
      {
        id: 57,
        name: "BOTTO LIGHT",
        img: images.BOTTO_LIGHT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BOTTO_LIGHT_P1,
          images.BOTTO_LIGHT_P2,
          images.BOTTO_LIGHT_P3,
          images.BOTTO_LIGHT_P4,
          images.BOTTO_LIGHT_P5,
        ]
      },
      {
        id: 58,
        name: "BOTTO MARFIL",
        img: images.BOTTO_MARFIL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BOTTO_MARFIL_P1,
          images.BOTTO_MARFIL_P2,
          images.BOTTO_MARFIL_P3,
          images.BOTTO_MARFIL_P4,
        ]
      },
      {
        id: 59,
        name: "BOTTOCHINO BIANCO",
        img: images.BOTTOCHINO_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BOTTOCHINO_BIANCO_P1,
          images.BOTTOCHINO_BIANCO_P2,
          images.BOTTOCHINO_BIANCO_P3,
          images.BOTTOCHINO_BIANCO_P4,
        ]
      },
      {
        id: 60,
        name: "BOTTOCHINO CLASSICO",
        img: images.BOTTOCHINO_CLASSICO,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BOTTOCHINO_CLASSICO,
        ]
      },
      {
        id: 61,
        name: "BOTTOCHINO CREMA",
        img: images.BOTTOCHINO_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BOTTOCHINO_CREMA_P1,
          images.BOTTOCHINO_CREMA_P2,
          images.BOTTOCHINO_CREMA_P3,
          images.BOTTOCHINO_CREMA_P4,
        ]
      },
      {
        id: 62,
        name: "BRESCIA AURORA",
        img: images.BRESCIA_AURORA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRESCIA_AURORA_P1,
          images.BRESCIA_AURORA_P2,
          images.BRESCIA_AURORA_P3,
          images.BRESCIA_AURORA_P4,
          images.BRESCIA_AURORA_P5,
          images.BRESCIA_AURORA_P6,
          images.BRESCIA_AURORA_P7,
          images.BRESCIA_AURORA_P8,
        ]
      },
      {
        id: 63,
        name: "BRESCIA NATURAL",
        img: images.BRESCIA_NATURAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRESCIA_NATURAL_P1,
          images.BRESCIA_NATURAL_P2,
          images.BRESCIA_NATURAL_P3,
          images.BRESCIA_NATURAL_P4,
          images.BRESCIA_NATURAL_P5,
          images.BRESCIA_NATURAL_P6,
          images.BRESCIA_NATURAL_P7,
          images.BRESCIA_NATURAL_P8,
        ]
      },
      {
        id: 64,
        name: "BRICK ONIX BEIGE",
        img: images.BRICK_ONIX_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRICK_ONIX_BEIGE_P1,
          images.BRICK_ONIX_BEIGE_P2,
          images.BRICK_ONIX_BEIGE_P3,
          images.BRICK_ONIX_BEIGE_P4,
          images.BRICK_ONIX_BEIGE_P5,
        ]
      },
      {
        id: 65,
        name: "BRICK ONIX BIANCO",
        img: images.BRICK_ONIX_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRICK_ONIX_BIANCO_P1,
          images.BRICK_ONIX_BIANCO_P2,
          images.BRICK_ONIX_BIANCO_P3,
          images.BRICK_ONIX_BIANCO_P4,
          images.BRICK_ONIX_BIANCO_P5,
        ]
      },
      {
        id: 66,
        name: "BRILLO BEIGE",
        img: images.BRILLO_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRILLO_BEIGE_P1,
          images.BRILLO_BEIGE_P2,
          images.BRILLO_BEIGE_P3,
          images.BRILLO_BEIGE_P4,
        ]
      },
      {
        id: 67,
        name: "BRILLO WHITE",
        img: images.BRILLO_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRILLO_WHITE_P1,
          images.BRILLO_WHITE_P2,
          images.BRILLO_WHITE_P3,
          images.BRILLO_WHITE_P4,
        ]
      },
      {
        id: 68,
        name: "BRUMA BIANCO",
        img: images.BRUMA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRUMA_BIANCO_P1,
          images.BRUMA_BIANCO_P2,
          images.BRUMA_BIANCO_P3,
          images.BRUMA_BIANCO_P4,
          images.BRUMA_BIANCO_P5,
        ]
      },
      {
        id: 69,
        name: "BRUMA CREMA",
        img: images.BRUMA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRUMA_CREMA_P1,
          images.BRUMA_CREMA_P2,
          images.BRUMA_CREMA_P3,
          images.BRUMA_CREMA_P4,
          images.BRUMA_CREMA_P5,
        ]
      },
      {
        id: 70,
        name: "BRUMA GREY",
        img: images.BRUMA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BRUMA_GREY_P1,
          images.BRUMA_GREY_P2,
          images.BRUMA_GREY_P3,
          images.BRUMA_GREY_P4,
          images.BRUMA_GREY_P5,
        ]
      },
      {
        id: 71,
        name: "BURBERRY BEIGE",
        img: images.BURBERRY_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BURBERRY_BEIGE_P1,
          images.BURBERRY_BEIGE_P2,
          images.BURBERRY_BEIGE_P3,
          images.BURBERRY_BEIGE_P4,
          images.BURBERRY_BEIGE_P5,
        ]
      },
      {
        id: 72,
        name: "BURBERRY CHOCO",
        img: images.BURBERRY_CHOCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BURBERRY_CHOCO_P1,
          images.BURBERRY_CHOCO_P2,
          images.BURBERRY_CHOCO_P3,
          images.BURBERRY_CHOCO_P4,
          images.BURBERRY_CHOCO_P5,
        ]
      },
      {
        id: 73,
        name: "BURBERRY CREMA",
        img: images.BURBERRY_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BURBERRY_CREMA_P1,
          images.BURBERRY_CREMA_P2,
          images.BURBERRY_CREMA_P3,
          images.BURBERRY_CREMA_P4,
          images.BURBERRY_CREMA_P5,
        ]
      },
      {
        id: 74,
        name: "BURBERRY GREY",
        img: images.BURBERRY_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BURBERRY_GREY_P1,
          images.BURBERRY_GREY_P2,
          images.BURBERRY_GREY_P3,
          images.BURBERRY_GREY_P4,
          images.BURBERRY_GREY_P5,
        ]
      },
      {
        id: 75,
        name: "BURKINA BEIGE",
        img: images.BURKINA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BURKINA_BEIGE_P1,
          images.BURKINA_BEIGE_P2,
          images.BURKINA_BEIGE_P3,
          images.BURKINA_BEIGE_P4,
        ]
      },
      {
        id: 76,
        name: "BURKINA BLUE",
        img: images.BURKINA_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.BURKINA_BLUE_P1,
          images.BURKINA_BLUE_P2,
          images.BURKINA_BLUE_P3,
          images.BURKINA_BLUE_P4,
        ]
      },
      {
        id: 77,
        name: "CALCUTTA BEIGE",
        img: images.CALCUTTA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CALCUTTA_BEIGE_P1,
          images.CALCUTTA_BEIGE_P2,
          images.CALCUTTA_BEIGE_P3,
          images.CALCUTTA_BEIGE_P4,
        ]
      },
      {
        id: 78,
        name: "CALCUTTA WHITE",
        img: images.CALCUTTA_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CALCUTTA_WHITE_P1,
          images.CALCUTTA_WHITE_P2,
          images.CALCUTTA_WHITE_P3,
          images.CALCUTTA_WHITE_P4,
          images.CALCUTTA_WHITE_P5,
        ]
      },
      {
        id: 79,
        name: "CALIPSO BEIGE",
        img: images.CALIPSO_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CALIPSO_BEIGE_P1,
          images.CALIPSO_BEIGE_P2,
          images.CALIPSO_BEIGE_P3,
          images.CALIPSO_BEIGE_P4,
        ]
      },
      {
        id: 80,
        name: "CALIPSO CREMA",
        img: images.CALIPSO_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CALIPSO_CREMA_P1,
          images.CALIPSO_CREMA_P2,
          images.CALIPSO_CREMA_P3,
          images.CALIPSO_CREMA_P4,
        ]
      },
      {
        id: 81,
        name: "CALIPSO GREY",
        img: images.CALIPSO_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CALIPSO_GREY_P1,
          images.CALIPSO_GREY_P2,
          images.CALIPSO_GREY_P3,
          images.CALIPSO_GREY_P4,
        ]
      },
      {
        id: 82,
        name: "CALLIS CREMA",
        img: images.CALLIS_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CALLIS_CREMA_P1,
          images.CALLIS_CREMA_P2,
          images.CALLIS_CREMA_P3,
          images.CALLIS_CREMA_P4,
        ]
      },
      {
        id: 83,
        name: "CALLIS WHITE",
        img: images.CALLIS_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CALLIS_WHITE_P1,
          images.CALLIS_WHITE_P2,
          images.CALLIS_WHITE_P3,
          images.CALLIS_WHITE_P4,
        ]
      },
      {
        id: 84,
        name: "CARARA WHITE",
        img: images.CARARA_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CARARA_WHITE_P1,
          images.CARARA_WHITE_P2,
          images.CARARA_WHITE_P3,
          images.CARARA_WHITE_P4,
          images.CARARA_WHITE_P5,
        ]
      },
      {
        id: 85,
        name: "CARRARA FOG BROWN",
        img: images.CARRARA_FOG_BROWN,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CARRARA_FOG_BROWN,
        ]
      },
      {
        id: 86,
        name: "CASTILIA GOLD",
        img: images.CASTILIA_GOLD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CASTILIA_GOLD_P1,
          images.CASTILIA_GOLD_P2,
          images.CASTILIA_GOLD_P3,
          images.CASTILIA_GOLD_P4,
          images.CASTILIA_GOLD_P5,
        ]
      },
      {
        id: 87,
        name: "CHOCO OCEAN",
        img: images.CHOCO_OCEAN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CHOCO_OCEAN_P1,
          images.CHOCO_OCEAN_P2,
          images.CHOCO_OCEAN_P3,
          images.CHOCO_OCEAN_P4,
        ]
      },
      {
        id: 88,
        name: "CIERA MARFIL LIGHT",
        img: images.CIERA_MARFIL_LIGHT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CIERA_MARFIL_LIGHT_P1,
          images.CIERA_MARFIL_LIGHT_P2,
          images.CIERA_MARFIL_LIGHT_P3,
          images.CIERA_MARFIL_LIGHT_P4,
        ]
      },
      {
        id: 89,
        name: "CLASSIC CREMA",
        img: images.CLASSIC_CREMA,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CLASSIC_CREMA,
        ]
      },
      {
        id: 90,
        name: "CLOUD BLUE",
        img: images.CLOUD_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CLOUD_BLUE_P1,
          images.CLOUD_BLUE_P2,
          images.CLOUD_BLUE_P3,
          images.CLOUD_BLUE_P4,
        ]
      },
      {
        id: 91,
        name: "CLOUD WHITE",
        img: images.CLOUD_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CLOUD_WHITE_P1,
          images.CLOUD_WHITE_P2,
          images.CLOUD_WHITE_P3,
          images.CLOUD_WHITE_P4,
        ]
      },
      {
        id: 92,
        name: "COLOUR ONYX",
        img: images.COLOUR_ONYX_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.COLOUR_ONYX_P1,
          images.COLOUR_ONYX_P2,
          images.COLOUR_ONYX_P3,
          images.COLOUR_ONYX_P4,
        ]
      },
      {
        id: 93,
        name: "COVENALO CREMA",
        img: images.COVENALO_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.COVENALO_CREMA_P1,
          images.COVENALO_CREMA_P2,
          images.COVENALO_CREMA_P3,
          images.COVENALO_CREMA_P4,
          images.COVENALO_CREMA_P5,
        ]
      },
      {
        id: 94,
        name: "CRACKEL STATURIO",
        img: images.CRACKEL_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CRACKEL_STATURIO_P1,
          images.CRACKEL_STATURIO_P2,
          images.CRACKEL_STATURIO_P3,
          images.CRACKEL_STATURIO_P4,
          images.CRACKEL_STATURIO_P5,
        ]
      },
      {
        id: 95,
        name: "CREMA MARFIL UTD",
        img: images.CREMA_MARFIL_UTD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CREMA_MARFIL_UTD_P1,
          images.CREMA_MARFIL_UTD_P2,
          images.CREMA_MARFIL_UTD_P3,
        ]
      },
      {
        id: 96,
        name: "CRETONNE BEIGE",
        img: images.CRETONNE_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CRETONNE_BEIGE_P1,
          images.CRETONNE_BEIGE_P2,
          images.CRETONNE_BEIGE_P3,
          images.CRETONNE_BEIGE_P4,
        ]
      },
      {
        id: 97,
        name: "CRETONNE CREMA",
        img: images.CRETONNE_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CRETONNE_CREMA_P1,
          images.CRETONNE_CREMA_P2,
          images.CRETONNE_CREMA_P3,
          images.CRETONNE_CREMA_P4,
        ]
      },
      {
        id: 98,
        name: "CRIST BEIGE",
        img: images.CRIST_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CRIST_BEIGE_P1,
          images.CRIST_BEIGE_P2,
          images.CRIST_BEIGE_P3,
          images.CRIST_BEIGE_P4,
        ]
      },
      {
        id: 99,
        name: "CRIST GREY",
        img: images.CRIST_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CRIST_GREY_P1,
          images.CRIST_GREY_P2,
          images.CRIST_GREY_P3,
          images.CRIST_GREY_P4,
        ]
      },
      {
        id: 100, name: "CRYSTAL ONYX BROWN",
        img: images.CRYSTAL_ONYX_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CRYSTAL_ONYX_BROWN_P1,
          images.CRYSTAL_ONYX_BROWN_P2,
          images.CRYSTAL_ONYX_BROWN_P3,
          images.CRYSTAL_ONYX_BROWN_P4,
          images.CRYSTAL_ONYX_BROWN_P5,
        ]
      },
      {
        id: 101, name: "CRYSTAL ONYX BROWN",
        img: images.CRYSTALONYX_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CRYSTALONYX_BROWN_P1,
          images.CRYSTALONYX_BROWN_P2,
          images.CRYSTALONYX_BROWN_P3,
          images.CRYSTALONYX_BROWN_P4,
          images.CRYSTALONYX_BROWN_P5,
          images.CRYSTALONYX_BROWN_P6,
        ]
      },
      {
        id: 102, name: "CUNTO STATURIO",
        img: images.CUNTO_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.CUNTO_STATURIO_P1,
          images.CUNTO_STATURIO_P2,
          images.CUNTO_STATURIO_P3,
          images.CUNTO_STATURIO_P4,
          images.CUNTO_STATURIO_P5,
          images.CUNTO_STATURIO_P6,
          images.CUNTO_STATURIO_P7,
        ]
      },
      {
        id: 103, name: "DESERT DIANA",
        img: images.DESERT_DIANA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DESERT_DIANA_P1,
          images.DESERT_DIANA_P2,
          images.DESERT_DIANA_P3,
          images.DESERT_DIANA_P4,
        ]
      },
      {
        id: 104, name: "DESERT DIANA GREY",
        img: images.DESERT_DIANA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DESERT_DIANA_GREY_P1,
          images.DESERT_DIANA_GREY_P2,
          images.DESERT_DIANA_GREY_P3,
          images.DESERT_DIANA_GREY_P4,
        ]
      },
      {
        id: 105, name: "DESERT DIANA LIGHT",
        img: images.DESERT_DIANA_LIGHT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DESERT_DIANA_LIGHT_P1,
          images.DESERT_DIANA_LIGHT_P2,
          images.DESERT_DIANA_LIGHT_P3,
          images.DESERT_DIANA_LIGHT_P4,
        ]
      },
      {
        id: 106, name: "DOLLCER ICE",
        img: images.DOLLCER_ICE,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DOLLCER_ICE,
        ]
      },
      {
        id: 107, name: "DOLMEN BIANCO",
        img: images.DOLMEN_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DOLMEN_BIANCO_P1,
          images.DOLMEN_BIANCO_P2,
          images.DOLMEN_BIANCO_P3,
          images.DOLMEN_BIANCO_P4,
          images.DOLMEN_BIANCO_P5,
        ]
      },
      {
        id: 108, name: "DOLMEN CHOCO",
        img: images.DOLMEN_CHOCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DOLMEN_CHOCO_P1,
          images.DOLMEN_CHOCO_P2,
          images.DOLMEN_CHOCO_P3,
          images.DOLMEN_CHOCO_P4,
          images.DOLMEN_CHOCO_P5,
        ]
      },
      {
        id: 109, name: "DOMENIC BEIGE",
        img: images.DOMENIC_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DOMENIC_BEIGE_P1,
          images.DOMENIC_BEIGE_P2,
          images.DOMENIC_BEIGE_P3,
          images.DOMENIC_BEIGE_P4,
          images.DOMENIC_BEIGE_P5,
        ]
      },
      {
        id: 110, name: "DOMENIC CREMA",
        img: images.DOMENIC_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DOMENIC_CREMA_P1,
          images.DOMENIC_CREMA_P2,
          images.DOMENIC_CREMA_P3,
          images.DOMENIC_CREMA_P4,
          images.DOMENIC_CREMA_P5,
        ]
      },
      {
        id: 111, name: "DOMENIC GREY",
        img: images.DOMENIC_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DOMENIC_GREY_P1,
          images.DOMENIC_GREY_P2,
          images.DOMENIC_GREY_P3,
          images.DOMENIC_GREY_P4,
          images.DOMENIC_GREY_P5,
        ]
      },
      {
        id: 112, name: "DYNA BEIGE",
        img: images.DYNA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DYNA_BEIGE_P1,
          images.DYNA_BEIGE_P2,
          images.DYNA_BEIGE_P3,
          images.DYNA_BEIGE_P4,
          images.DYNA_BEIGE_P5,
        ]
      },
      {
        id: 113, name: "DYNA DISASTER",
        img: images.DYNA_DISASTER_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DYNA_DISASTER_P1,
          images.DYNA_DISASTER_P2,
          images.DYNA_DISASTER_P3,
          images.DYNA_DISASTER_P4,
          images.DYNA_DISASTER_P5,
          images.DYNA_DISASTER_P6,
        ]
      },
      {
        id: 114, name: "DYNA FANTCY",
        img: images.DYNA_FANTCY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DYNA_FANTCY_P1,
          images.DYNA_FANTCY_P2,
          images.DYNA_FANTCY_P3,
          images.DYNA_FANTCY_P4,
          images.DYNA_FANTCY_P5,
        ]
      },
      {
        id: 115, name: "DYNA GREY",
        img: images.DYNA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DYNA_GREY_P1,
          images.DYNA_GREY_P2,
          images.DYNA_GREY_P3,
          images.DYNA_GREY_P4,
          images.DYNA_GREY_P5,
        ]
      },
      {
        id: 116, name: "DYNA MULTY",
        img: images.DYNA_MULTY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DYNA_MULTY_P1,
          images.DYNA_MULTY_P2,
          images.DYNA_MULTY_P3,
          images.DYNA_MULTY_P4,
          images.DYNA_MULTY_P5,
        ]
      },
      {
        id: 117, name: "DYNO BEIGE",
        img: images.DYNO_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DYNO_BEIGE_P1,
          images.DYNO_BEIGE_P2,
          images.DYNO_BEIGE_P3,
          images.DYNO_BEIGE_P4,
          images.DYNO_BEIGE_P5,
          images.DYNO_BEIGE_P6,
        ]
      },
      {
        id: 118, name: "DYNO BIANCO",
        img: images.DYNO_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.DYNO_BIANCO_P1,
          images.DYNO_BIANCO_P2,
          images.DYNO_BIANCO_P3,
          images.DYNO_BIANCO_P4,
          images.DYNO_BIANCO_P5,
          images.DYNO_BIANCO_P6,
        ]
      },
      {
        id: 119, name: "EARTH GLITER ROSSO",
        img: images.EARTH_GLITER_ROSSO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.EARTH_GLITER_ROSSO_P1,
          images.EARTH_GLITER_ROSSO_P2,
          images.EARTH_GLITER_ROSSO_P3,
          images.EARTH_GLITER_ROSSO_P4,
        ]
      },
      {
        id: 120, name: "EARTH GLITTER BEIGE",
        img: images.EARTH_GLITTER_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.EARTH_GLITTER_BEIGE_P1,
          images.EARTH_GLITTER_BEIGE_P2,
          images.EARTH_GLITTER_BEIGE_P3,
          images.EARTH_GLITTER_BEIGE_P4,
        ]
      },
      {
        id: 121, name: "ELECTRONICA",
        img: images.ELECTRONICA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ELECTRONICA_P1,
          images.ELECTRONICA_P2,
          images.ELECTRONICA_P3,
          images.ELECTRONICA_P4,
        ]
      },
      {
        id: 122, name: "ELEMENT BEIGE",
        img: images.ELEMENT_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ELEMENT_BEIGE_P1,
          images.ELEMENT_BEIGE_P2,
          images.ELEMENT_BEIGE_P3,
          images.ELEMENT_BEIGE_P4,
          images.ELEMENT_BEIGE_P5,
        ]
      },
      {
        id: 123, name: "ELEMENT WHITE",
        img: images.ELEMENT_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ELEMENT_WHITE_P1,
          images.ELEMENT_WHITE_P2,
          images.ELEMENT_WHITE_P3,
          images.ELEMENT_WHITE_P4,
          images.ELEMENT_WHITE_P5,
        ]
      },
      {
        id: 124, name: "ERAMOSSA BEIGE",
        img: images.ERAMOSSA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ERAMOSSA_BEIGE_P1,
          images.ERAMOSSA_BEIGE_P2,
          images.ERAMOSSA_BEIGE_P3,
          images.ERAMOSSA_BEIGE_P4,
          images.ERAMOSSA_BEIGE_P5,
        ]
      },
      {
        id: 125, name: "ERAMOSSA TOBACCO",
        img: images.ERAMOSSA_TOBACCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ERAMOSSA_TOBACCO_P1,
          images.ERAMOSSA_TOBACCO_P2,
          images.ERAMOSSA_TOBACCO_P3,
          images.ERAMOSSA_TOBACCO_P4,
          images.ERAMOSSA_TOBACCO_P5,
        ]
      },
      {
        id: 126, name: "ESPIRA BEIGE",
        img: images.ESPIRA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ESPIRA_BEIGE_P1,
          images.ESPIRA_BEIGE_P2,
          images.ESPIRA_BEIGE_P3,
          images.ESPIRA_BEIGE_P4,
          images.ESPIRA_BEIGE_P5,
        ]
      },
      {
        id: 127, name: "ESPIRA CREMA",
        img: images.ESPIRA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ESPIRA_CREMA_P1,
          images.ESPIRA_CREMA_P2,
          images.ESPIRA_CREMA_P3,
          images.ESPIRA_CREMA_P4,
          images.ESPIRA_CREMA_P5,
        ]
      },
      {
        id: 128, name: "ESPIRA GREY",
        img: images.ESPIRA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ESPIRA_GREY_P1,
          images.ESPIRA_GREY_P2,
          images.ESPIRA_GREY_P3,
          images.ESPIRA_GREY_P4,
          images.ESPIRA_GREY_P5,
        ]
      },
      {
        id: 129, name: "EXOTIC EMPRO BROWN",
        img: images.EXOTIC_EMPRO_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.EXOTIC_EMPRO_BROWN_P1,
          images.EXOTIC_EMPRO_BROWN_P2,
          images.EXOTIC_EMPRO_BROWN_P3,
          images.EXOTIC_EMPRO_BROWN_P4,
          images.EXOTIC_EMPRO_BROWN_P5,
        ]
      },
      {
        id: 130, name: "EXOTIC EMPRO REAL",
        img: images.EXOTIC_EMPRO_REAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.EXOTIC_EMPRO_REAL_P1,
          images.EXOTIC_EMPRO_REAL_P2,
          images.EXOTIC_EMPRO_REAL_P3,
          images.EXOTIC_EMPRO_REAL_P4,
          images.EXOTIC_EMPRO_REAL_P5,
        ]
      },
      {
        id: 131, name: "FLORENCIA BEIGE",
        img: images.FLORENCIA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FLORENCIA_BEIGE_P1,
          images.FLORENCIA_BEIGE_P2,
          images.FLORENCIA_BEIGE_P3,
          images.FLORENCIA_BEIGE_P4,
          images.FLORENCIA_BEIGE_P5,
        ]
      },
      {
        id: 132, name: "FLORENCIA BIANCO",
        img: images.FLORENCIA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FLORENCIA_BIANCO_P1,
          images.FLORENCIA_BIANCO_P2,
          images.FLORENCIA_BIANCO_P3,
          images.FLORENCIA_BIANCO_P4,
          images.FLORENCIA_BIANCO_P5,
        ]
      },
      {
        id: 133, name: "FLORENCIA CEREZO",
        img: images.FLORENCIA_CEREZO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FLORENCIA_CEREZO_P1,
          images.FLORENCIA_CEREZO_P2,
          images.FLORENCIA_CEREZO_P3,
          images.FLORENCIA_CEREZO_P4,
          images.FLORENCIA_CEREZO_P5,
        ]
      },
      {
        id: 134, name: "FLORENCIA CREMA",
        img: images.FLORENCIA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FLORENCIA_CREMA_P1,
          images.FLORENCIA_CREMA_P2,
          images.FLORENCIA_CREMA_P3,
          images.FLORENCIA_CREMA_P4,
          images.FLORENCIA_CREMA_P5,
        ]
      },
      {
        id: 135, name: "FLORENCIA GRIS",
        img: images.FLORENCIA_GRIS_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FLORENCIA_GRIS_P1,
          images.FLORENCIA_GRIS_P2,
          images.FLORENCIA_GRIS_P3,
          images.FLORENCIA_GRIS_P4,
          images.FLORENCIA_GRIS_P5,
        ]
      },
      {
        id: 136, name: "FLORITO CREMA",
        img: images.FLORITO_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FLORITO_CREMA_P1,
          images.FLORITO_CREMA_P2,
          images.FLORITO_CREMA_P3,
          images.FLORITO_CREMA_P4,
          images.FLORITO_CREMA_P5,
        ]
      },
      {
        id: 137, name: "FREEDOM BEIGE",
        img: images.FREEDOM_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FREEDOM_BEIGE_P1,
          images.FREEDOM_BEIGE_P2,
          images.FREEDOM_BEIGE_P3,
          images.FREEDOM_BEIGE_P4,
          images.FREEDOM_BEIGE_P5,
        ]
      },
      {
        id: 138, name: "FREEDOM BIANCO",
        img: images.FREEDOM_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FREEDOM_BIANCO_P1,
          images.FREEDOM_BIANCO_P2,
          images.FREEDOM_BIANCO_P3,
          images.FREEDOM_BIANCO_P4,
          images.FREEDOM_BIANCO_P5,
        ]
      },
      {
        id: 139, name: "FRESSIA STATURIO",
        img: images.FRESSIA_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FRESSIA_STATURIO_P1,
          images.FRESSIA_STATURIO_P2,
          images.FRESSIA_STATURIO_P3,
          images.FRESSIA_STATURIO_P4,
          images.FRESSIA_STATURIO_P5,
          images.FRESSIA_STATURIO_P6,
          images.FRESSIA_STATURIO_P7,
          images.FRESSIA_STATURIO_P8,
        ]
      },
      {
        id: 140, name: "FUMA BLUE",
        img: images.FFUMA_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FFUMA_BLUE_P1,
          images.FFUMA_BLUE_P2,
          images.FFUMA_BLUE_P3,
          images.FFUMA_BLUE_P4,
          images.FFUMA_BLUE_P5,
        ]
      },
      {
        id: 141, name: "FUMA GOLD",
        img: images.FUMA_GOLD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.FUMA_GOLD_P1,
          images.FUMA_GOLD_P2,
          images.FUMA_GOLD_P3,
          images.FUMA_GOLD_P4,
          images.FUMA_GOLD_P5,
        ]
      },
      {
        id: 142, name: "GENESIS BEIGE",
        img: images.GENESIS_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GENESIS_BEIGE_P1,
          images.GENESIS_BEIGE_P2,
          images.GENESIS_BEIGE_P3,
          images.GENESIS_BEIGE_P4,
          images.GENESIS_BEIGE_P5,
        ]
      },
      {
        id: 143, name: "GENESIS BROWN",
        img: images.GENESIS_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GENESIS_BROWN_P1,
          images.GENESIS_BROWN_P2,
          images.GENESIS_BROWN_P3,
          images.GENESIS_BROWN_P4,
          images.GENESIS_BROWN_P5,
        ]
      },
      {
        id: 144, name: "GLACIER BIANCO",
        img: images.GLACIER_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GLACIER_BIANCO_P1,
          images.GLACIER_BIANCO_P2,
          images.GLACIER_BIANCO_P3,
          images.GLACIER_BIANCO_P4,
          images.GLACIER_BIANCO_P5,
        ]
      },
      {
        id: 145, name: "GLACIER GOLD",
        img: images.GLACIER_GOLD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GLACIER_GOLD_P1,
          images.GLACIER_GOLD_P2,
          images.GLACIER_GOLD_P3,
          images.GLACIER_GOLD_P3,
          images.GLACIER_GOLD_P5,
        ]
      },
      {
        id: 146, name: "GLACIER WHITE",
        img: images.GLACIER_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GLACIER_WHITE_P1,
          images.GLACIER_WHITE_P2,
          images.GLACIER_WHITE_P3,
          images.GLACIER_WHITE_P4,
          images.GLACIER_WHITE_P5,
        ]
      },
      {
        id: 147, name: "GOLDCOIN WHITE",
        img: images.GOLDCOIN_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GOLDCOIN_WHITE_P1,
          images.GOLDCOIN_WHITE_P2,
          images.GOLDCOIN_WHITE_P3,
          images.GOLDCOIN_WHITE_P4,
        ]
      },
      {
        id: 148, name: "GRESS ONIX BEIGE",
        img: images.GRESS_ONIX_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GRESS_ONIX_BEIGE_P1,
          images.GRESS_ONIX_BEIGE_P2,
          images.GRESS_ONIX_BEIGE_P3,
          images.GRESS_ONIX_BEIGE_P4,
          images.GRESS_ONIX_BEIGE_P5,
        ]
      },
      {
        id: 149, name: "GRIJLY STATURIO",
        img: images.GRIJLY_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.GRIJLY_STATURIO_P1,
          images.GRIJLY_STATURIO_P2,
          images.GRIJLY_STATURIO_P3,
          images.GRIJLY_STATURIO_P4,
        ]
      },
      {
        id: 150, name: "HANSTONE BIANCO",
        img: images.HANSTONE_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.HANSTONE_BIANCO_P1,
          images.HANSTONE_BIANCO_P1,
          images.HANSTONE_BIANCO_P1,
          images.HANSTONE_BIANCO_P1,
          images.HANSTONE_BIANCO_P1,
        ]
      },
      {
        id: 151, name: "HANSTONE CREMA",
        img: images.HANSTONE_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.HANSTONE_CREMA_P1,
          images.HANSTONE_CREMA_P2,
          images.HANSTONE_CREMA_P3,
          images.HANSTONE_CREMA_P4,
          images.HANSTONE_CREMA_P5,
        ]
      },
      {
        id: 152, name: "HAWAI BEIGE",
        img: images.HAWAI_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.HAWAI_BEIGE_P1,
          images.HAWAI_BEIGE_P2,
          images.HAWAI_BEIGE_P3,
          images.HAWAI_BEIGE_P4,
        ]
      },
      {
        id: 153, name: "HAWAI WHITE",
        img: images.HAWAI_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.HAWAI_WHITE_P1,
          images.HAWAI_WHITE_P2,
          images.HAWAI_WHITE_P3,
          images.HAWAI_WHITE_P4,
        ]
      },
      {
        id: 154, name: "IMPACT STATURIO",
        img: images.IMPACT_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.IMPACT_STATURIO_P1,
          images.IMPACT_STATURIO_P2,
          images.IMPACT_STATURIO_P3,
          images.IMPACT_STATURIO_P4,
        ]
      },
      {
        id: 155, name: "IMPERIAL BEIGE",
        img: images.IMPERIAL_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.IMPERIAL_BEIGE_P1,
          images.IMPERIAL_BEIGE_P2,
          images.IMPERIAL_BEIGE_P3,
          images.IMPERIAL_BEIGE_P4,
          images.IMPERIAL_BEIGE_P5,
        ]
      },
      {
        id: 156, name: "IMPERIAL BIANCO",
        img: images.IMPERIAL_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.IMPERIAL_BIANCO_P1,
          images.IMPERIAL_BIANCO_P2,
          images.IMPERIAL_BIANCO_P3,
          images.IMPERIAL_BIANCO_P4,
          images.IMPERIAL_BIANCO_P5,
        ]
      },
      {
        id: 157, name: "IMPERIAL CREMA",
        img: images.IMPERIAL_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.IMPERIAL_CREMA_P1,
          images.IMPERIAL_CREMA_P2,
          images.IMPERIAL_CREMA_P3,
          images.IMPERIAL_CREMA_P4,
          images.IMPERIAL_CREMA_P5,
        ]
      },
      {
        id: 158, name: "IMPERIAL GREY",
        img: images.IMPERIAL_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.IMPERIAL_GREY_P1,
          images.IMPERIAL_GREY_P2,
          images.IMPERIAL_GREY_P3,
          images.IMPERIAL_GREY_P4,
          images.IMPERIAL_GREY_P5,
        ]
      },
      {
        id: 159, name: "ITALIA BEIGE",
        img: images.ITALIA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ITALIA_BEIGE_P1,
          images.ITALIA_BEIGE_P2,
          images.ITALIA_BEIGE_P3,
          images.ITALIA_BEIGE_P4,
          images.ITALIA_BEIGE_P5,
        ]
      },
      {
        id: 160, name: "ITALIA BIANCO",
        img: images.ITALIA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ITALIA_BIANCO_P1,
          images.ITALIA_BIANCO_P2,
          images.ITALIA_BIANCO_P3,
          images.ITALIA_BIANCO_P4,
          images.ITALIA_BIANCO_P5,
        ]
      },
      {
        id: 161, name: "ITALIA BRONZ",
        img: images.ITALIA_BRONZ_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ITALIA_BRONZ_P1,
          images.ITALIA_BRONZ_P2,
          images.ITALIA_BRONZ_P3,
          images.ITALIA_BRONZ_P4,
          images.ITALIA_BRONZ_P5,
        ]
      },
      {
        id: 162, name: "ITALIA GREY",
        img: images.ITALIA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ITALIA_GREY_P1,
          images.ITALIA_GREY_P2,
          images.ITALIA_GREY_P3,
          images.ITALIA_GREY_P4,
          images.ITALIA_GREY_P5,
        ]
      },
      {
        id: 163, name: "KABLI STATURIO",
        img: images.KABLI_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KABLI_STATURIO_P1,
          images.KABLI_STATURIO_P2,
          images.KABLI_STATURIO_P3,
          images.KABLI_STATURIO_P4,
          images.KABLI_STATURIO_P5,
        ]
      },
      {
        id: 164, name: "KINGDOM BLUE",
        img: images.KINGDOM_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KINGDOM_BLUE_P1,
          images.KINGDOM_BLUE_P2,
        ]
      },
      {
        id: 165, name: "KINGDOM BROWN",
        img: images.KINGDOM_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KINGDOM_BROWN_P1,
          images.KINGDOM_BROWN_P2,
        ]
      },
      {
        id: 166, name: "KIYARA GREY",
        img: images.KIYARA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KIYARA_GREY_P1,
          images.KIYARA_GREY_P2,
          images.KIYARA_GREY_P3,
          images.KIYARA_GREY_P4,
        ]
      },
      {
        id: 167, name: "KIYARA LIGHT GREY",
        img: images.KIYARA_LIGHT_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KIYARA_LIGHT_GREY_P1,
          images.KIYARA_LIGHT_GREY_P2,
          images.KIYARA_LIGHT_GREY_P3,
          images.KIYARA_LIGHT_GREY_P4,
        ]
      },
      {
        id: 168, name: "KRAZY BROWN",
        img: images.KRAZY_BROWN,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KRAZY_BROWN,
        ]
      },
      {
        id: 169, name: "KRAZY NERO",
        img: images.KRAZY_NERO,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KRAZY_NERO,
        ]
      },
      {
        id: 170, name: "KWID BEIGE",
        img: images.KWID_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KWID_BEIGE_P1,
          images.KWID_BEIGE_P2,
          images.KWID_BEIGE_P3,
          images.KWID_BEIGE_P4,
          images.KWID_BEIGE_P5,
        ]
      },
      {
        id: 171, name: "KWID GREY",
        img: images.KWID_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.KWID_GREY_P1,
          images.KWID_GREY_P2,
          images.KWID_GREY_P3,
          images.KWID_GREY_P4,
          images.KWID_GREY_P5,
        ]
      },
      {
        id: 172, name: "LEGNA ALMOUND",
        img: images.LEGNA_ALMOUND_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.LEGNA_ALMOUND_P1,
          images.LEGNA_ALMOUND_P2,
          images.LEGNA_ALMOUND_P3,
          images.LEGNA_ALMOUND_P4,
          images.LEGNA_ALMOUND_P5,
        ]
      },
      {
        id: 173, name: "LEVENT BIANCO",
        img: images.LEVENT_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.LEVENT_BIANCO_P1,
          images.LEVENT_BIANCO_P2,
          images.LEVENT_BIANCO_P3,
          images.LEVENT_BIANCO_P4,
        ]
      },
      {
        id: 174, name: "LEVENT BROWN",
        img: images.LEVENT_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.LEVENT_BROWN_P1,
          images.LEVENT_BROWN_P2,
          images.LEVENT_BROWN_P3,
          images.LEVENT_BROWN_P4,
        ]
      },
      {
        id: 175, name: "LEVENT GREY",
        img: images.LEVENT_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.LEVENT_GREY_P1,
          images.LEVENT_GREY_P2,
          images.LEVENT_GREY_P3,
          images.LEVENT_GREY_P4,
        ]
      },
      {
        id: 176, name: "LEVENT NATURAL",
        img: images.LEVENT_NATURAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.LEVENT_NATURAL_P1,
          images.LEVENT_NATURAL_P2,
          images.LEVENT_NATURAL_P3,
          images.LEVENT_NATURAL_P4,
        ]
      },
      {
        id: 177, name: "LINER COAL",
        img: images.LINER_COAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.LINER_COAL_P1,
          images.LINER_COAL_P2,
          images.LINER_COAL_P3,
          images.LINER_COAL_P4,
          images.LINER_COAL_P5,
        ]
      },
      {
        id: 178, name: "LINER GREY",
        img: images.LINER_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.LINER_GREY_P1,
          images.LINER_GREY_P2,
          images.LINER_GREY_P3,
          images.LINER_GREY_P4,
          images.LINER_GREY_P5,
        ]
      },
      {
        id: 179, name: "MAGIC SATVARIYO",
        img: images.MAGIC_SATVARIYO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MAGIC_SATVARIYO_P1,
          images.MAGIC_SATVARIYO_P2,
          images.MAGIC_SATVARIYO_P3,
          images.MAGIC_SATVARIYO_P4,
        ]
      },
      {
        id: 180, name: "MAJESTIC BIANCO",
        img: images.MAJESTIC_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MAJESTIC_BIANCO_P1,
          images.MAJESTIC_BIANCO_P2,
          images.MAJESTIC_BIANCO_P3,
          images.MAJESTIC_BIANCO_P4,
          images.MAJESTIC_BIANCO_P5,
        ]
      },
      {
        id: 181, name: "MAJESTIC BROWN",
        img: images.MAJESTIC_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MAJESTIC_BROWN_P1,
          images.MAJESTIC_BROWN_P2,
          images.MAJESTIC_BROWN_P3,
          images.MAJESTIC_BROWN_P4,
          images.MAJESTIC_BROWN_P5,
        ]
      },
      {
        id: 182, name: "MARC ONYX CREMA",
        img: images.MARC_ONYX_CREMA,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MARC_ONYX_CREMA,
        ]
      },
      {
        id: 183, name: "MARC ONYX GREY",
        img: images.MARC_ONYX_GREY,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MARC_ONYX_GREY,
        ]
      },
      {
        id: 184, name: "MELODY ONYX",
        img: images.MELODY_ONYX_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MELODY_ONYX_P1,
          images.MELODY_ONYX_P2,
        ]
      },
      {
        id: 185, name: "MIAMI SATVARIO",
        img: images.MIAMI_SATVARIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MIAMI_SATVARIO_P1,
          images.MIAMI_SATVARIO_P2,
          images.MIAMI_SATVARIO_P3,
          images.MIAMI_SATVARIO_P4,
        ]
      },
      {
        id: 186, name: "MILANO BEIGE",
        img: images.MILANO_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MILANO_BEIGE_P1,
          images.MILANO_BEIGE_P2,
          images.MILANO_BEIGE_P3,
          images.MILANO_BEIGE_P4,
        ]
      },
      {
        id: 187, name: "MINT BEIGE",
        img: images.MINT_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MINT_BEIGE_P1,
          images.MINT_BEIGE_P2,
          images.MINT_BEIGE_P3,
          images.MINT_BEIGE_P4,
          images.MINT_BEIGE_P5,
        ]
      },
      {
        id: 188, name: "MINT BIANCO",
        img: images.MINT_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MINT_BIANCO_P1,
          images.MINT_BIANCO_P2,
          images.MINT_BIANCO_P3,
          images.MINT_BIANCO_P4,
          images.MINT_BIANCO_P5,
        ]
      },
      {
        id: 189, name: "MONGINIS GREY",
        img: images.MONGINIS_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MONGINIS_GREY_P1,
          images.MONGINIS_GREY_P2,
          images.MONGINIS_GREY_P3,
          images.MONGINIS_GREY_P4,
        ]
      },
      {
        id: 190, name: "MONGINIS IVORY",
        img: images.MONGINIS_IVORY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MONGINIS_IVORY_P1,
          images.MONGINIS_IVORY_P2,
          images.MONGINIS_IVORY_P3,
          images.MONGINIS_IVORY_P4,
        ]
      },
      {
        id: 191, name: "MONSTER EMPRADOR",
        img: images.MONSTER_EMPRADOR_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MONSTER_EMPRADOR_P1,
          images.MONSTER_EMPRADOR_P2,
          images.MONSTER_EMPRADOR_P3,
          images.MONSTER_EMPRADOR_P4,
        ]
      },
      {
        id: 192, name: "MONTANA BEIGE",
        img: images.MONTANA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MONTANA_BEIGE_P1,
          images.MONTANA_BEIGE_P2,
          images.MONTANA_BEIGE_P3,
          images.MONTANA_BEIGE_P4,
          images.MONTANA_BEIGE_P5,
        ]
      },
      {
        id: 193, name: "MONTANA GREEN",
        img: images.MONTANA_GREEN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MONTANA_GREEN_P1,
          images.MONTANA_GREEN_P2,
          images.MONTANA_GREEN_P3,
          images.MONTANA_GREEN_P4,
          images.MONTANA_GREEN_P5,
        ]
      },
      {
        id: 194, name: "MORACCAN WHITE",
        img: images.MORACCAN_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MORACCAN_WHITE_P1,
          images.MORACCAN_WHITE_P2,
          images.MORACCAN_WHITE_P3,
          images.MORACCAN_WHITE_P4,
        ]
      },
      {
        id: 195, name: "MYSTREY GREY",
        img: images.MYSTREY_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MYSTREY_GREY_P1,
          images.MYSTREY_GREY_P2,
          images.MYSTREY_GREY_P3,
          images.MYSTREY_GREY_P4,
        ]
      },
      {
        id: 196, name: "MYSTREY PEARL",
        img: images.MYSTREY_PEARL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.MYSTREY_PEARL_P1,
          images.MYSTREY_PEARL_P2,
          images.MYSTREY_PEARL_P3,
          images.MYSTREY_PEARL_P4,
        ]
      },
      {
        id: 197, name: "NATURAL SLATE BEIGE",
        img: images.NATURAL_SLATE_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NATURAL_SLATE_BEIGE_P1,
          images.NATURAL_SLATE_BEIGE_P2,
          images.NATURAL_SLATE_BEIGE_P3,
          images.NATURAL_SLATE_BEIGE_P4,
          images.NATURAL_SLATE_BEIGE_P5,
        ]
      },
      {
        id: 198, name: "NATURAL SLATE CREMA",
        img: images.NATURAL_SLATE_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NATURAL_SLATE_CREMA_P1,
          images.NATURAL_SLATE_CREMA_P2,
          images.NATURAL_SLATE_CREMA_P3,
          images.NATURAL_SLATE_CREMA_P4,
          images.NATURAL_SLATE_CREMA_P5,
        ]
      },
      {
        id: 199, name: "NAVONA BROWN",
        img: images.NAVONA_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NAVONA_BROWN_P1,
          images.NAVONA_BROWN_P2,
          images.NAVONA_BROWN_P3,
          images.NAVONA_BROWN_P4,
          images.NAVONA_BROWN_P5,
        ]
      },
      {
        id: 200, name: "NAVONA COBALT",
        img: images.NAVONA_COBALT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NAVONA_COBALT_P1,
          images.NAVONA_COBALT_P2,
          images.NAVONA_COBALT_P3,
          images.NAVONA_COBALT_P4,
          images.NAVONA_COBALT_P5,
        ]
      },
      {
        id: 201, name: "NAVONA GREY",
        img: images.NAVONA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NAVONA_GREY_P1,
          images.NAVONA_GREY_P2,
          images.NAVONA_GREY_P3,
          images.NAVONA_GREY_P4,
          images.NAVONA_GREY_P5,
        ]
      },
      {
        id: 202, name: "NERO BEIGE",
        img: images.NERO_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NERO_BEIGE_P1,
          images.NERO_BEIGE_P2,
          images.NERO_BEIGE_P3,
          images.NERO_BEIGE_P4,
          images.NERO_BEIGE_P5,
        ]
      },
      {
        id: 203, name: "NITRA STATURIO",
        img: images.NITRA_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NITRA_STATURIO_P1,
          images.NITRA_STATURIO_P2,
          images.NITRA_STATURIO_P3,
          images.NITRA_STATURIO_P4,
          images.NITRA_STATURIO_P5,
        ]
      },
      {
        id: 204, name: "NOTCH DARK",
        img: images.NOTCH_DARK_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NOTCH_DARK_P1,
          images.NOTCH_DARK_P2,
          images.NOTCH_DARK_P3,
          images.NOTCH_DARK_P4,
        ]
      },
      {
        id: 205, name: "NOTCH LIGHT",
        img: images.NOTCH_LIGHT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NOTCH_LIGHT_P1,
          images.NOTCH_LIGHT_P2,
          images.NOTCH_LIGHT_P3,
          images.NOTCH_LIGHT_P4,
        ]
      },
      {
        id: 206, name: "NOVA BIANCO",
        img: images.NOVA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NOVA_BIANCO_P1,
          images.NOVA_BIANCO_P2,
          images.NOVA_BIANCO_P3,
          images.NOVA_BIANCO_P4,
        ]
      },
      {
        id: 207, name: "NOVA BLUE",
        img: images.NOVA_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NOVA_BLUE_P1,
          images.NOVA_BLUE_P2,
          images.NOVA_BLUE_P3,
          images.NOVA_BLUE_P4,
        ]
      },
      {
        id: 208, name: "NOVA CHOCO",
        img: images.NOVA_CHOCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NOVA_CHOCO_P1,
          images.NOVA_CHOCO_P2,
          images.NOVA_CHOCO_P3,
          images.NOVA_CHOCO_P4,
        ]
      },
      {
        id: 209, name: "NOVA GOLD",
        img: images.NOVA_GOLD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.NOVA_GOLD_P1,
          images.NOVA_GOLD_P2,
          images.NOVA_GOLD_P3,
          images.NOVA_GOLD_P4,
        ]
      },
      {
        id: 210, name: "OLYMPIA GOLD",
        img: images.OLYMPIA_GOLD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OLYMPIA_GOLD_P1,
          images.OLYMPIA_GOLD_P2,
          images.OLYMPIA_GOLD_P3,
          images.OLYMPIA_GOLD_P4,
        ]
      },
      {
        id: 211, name: "OMAN BEIGE",
        img: images.OMAN_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OMAN_BEIGE_P1,
          images.OMAN_BEIGE_P2,
          images.OMAN_BEIGE_P3,
          images.OMAN_BEIGE_P4,
        ]
      },
      {
        id: 212, name: "OMAN GREY",
        img: images.OMAN_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OMAN_GREY_P1,
          images.OMAN_GREY_P2,
          images.OMAN_GREY_P3,
          images.OMAN_GREY_P4,
        ]
      },
      {
        id: 213, name: "OMANIA CREMA",
        img: images.OMANIA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OMANIA_CREMA_P1,
          images.OMANIA_CREMA_P2,
          images.OMANIA_CREMA_P3,
          images.OMANIA_CREMA_P4,
        ]
      },
      {
        id: 214, name: "ONIO BIANCO",
        img: images.ONIO_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ONIO_BIANCO_P1,
          images.ONIO_BIANCO_P2,
          images.ONIO_BIANCO_P3,
          images.ONIO_BIANCO_P4,
          images.ONIO_BIANCO_P5,
        ]
      },
      {
        id: 215, name: "ORBIT NATURA",
        img: images.ORBIT_NATURA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ORBIT_NATURA_P1,
          images.ORBIT_NATURA_P2,
          images.ORBIT_NATURA_P3,
          images.ORBIT_NATURA_P4,
        ]
      },
      {
        id: 216, name: "OREON BEIGE",
        img: images.OREON_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OREON_BEIGE_P1,
          images.OREON_BEIGE_P2,
          images.OREON_BEIGE_P3,
          images.OREON_BEIGE_P4,
          images.OREON_BEIGE_P5,
        ]
      },
      {
        id: 217, name: "OREON CREMA",
        img: images.OREON_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OREON_CREMA_P1,
          images.OREON_CREMA_P2,
          images.OREON_CREMA_P3,
          images.OREON_CREMA_P4,
          images.OREON_CREMA_P5,
        ]
      },
      {
        id: 218, name: "OREON GREEN",
        img: images.OREON_GREEN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OREON_GREEN_P1,
          images.OREON_GREEN_P2,
          images.OREON_GREEN_P3,
          images.OREON_GREEN_P4,
          images.OREON_GREEN_P5,
        ]
      },
      {
        id: 219, name: "OREON NERO",
        img: images.OREON_NERO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OREON_NERO_P1,
          images.OREON_NERO_P2,
          images.OREON_NERO_P3,
          images.OREON_NERO_P4,
          images.OREON_NERO_P5,
        ]
      },
      {
        id: 220, name: "OVAL GREY",
        img: images.OVAL_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OVAL_GREY_P1,
          images.OVAL_GREY_P2,
          images.OVAL_GREY_P3,
          images.OVAL_GREY_P4,
        ]
      },
      {
        id: 221, name: "OZON WHITE",
        img: images.OZON_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.OZON_WHITE_P1,
          images.OZON_WHITE_P2,
          images.OZON_WHITE_P3,
          images.OZON_WHITE_P4,
        ]
      },
      {
        id: 222, name: "PANTHON STATURIO",
        img: images.PANTHON_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PANTHON_STATURIO_P1,
          images.PANTHON_STATURIO_P2,
          images.PANTHON_STATURIO_P3,
          images.PANTHON_STATURIO_P4,
          images.PANTHON_STATURIO_P5,
        ]
      },
      {
        id: 223, name: "PARANA BIANCO",
        img: images.PARANA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PARANA_BIANCO_P1,
          images.PARANA_BIANCO_P2,
          images.PARANA_BIANCO_P3,
          images.PARANA_BIANCO_P4,
          images.PARANA_BIANCO_P5,
        ]
      },
      {
        id: 224, name: "PARANA BRONZE",
        img: images.PARANA_BRONZE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PARANA_BRONZE_P1,
          images.PARANA_BRONZE_P2,
          images.PARANA_BRONZE_P3,
          images.PARANA_BRONZE_P4,
          images.PARANA_BRONZE_P5,
        ]
      },
      {
        id: 225, name: "PARANA CREMA",
        img: images.PARANA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PARANA_CREMA_P1,
          images.PARANA_CREMA_P2,
          images.PARANA_CREMA_P3,
          images.PARANA_CREMA_P4,
          images.PARANA_CREMA_P5,
        ]
      },
      {
        id: 226, name: "PARIOS EMPRA BEIGE",
        img: images.PARIOS_EMPRA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PARIOS_EMPRA_BEIGE_P1,
          images.PARIOS_EMPRA_BEIGE_P2,
          images.PARIOS_EMPRA_BEIGE_P3,
          images.PARIOS_EMPRA_BEIGE_P4,
        ]
      },
      {
        id: 227, name: "PARIOS EMPRA CREMA",
        img: images.PARIOS_EMPRA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PARIOS_EMPRA_CREMA_P1,
          images.PARIOS_EMPRA_CREMA_P2,
          images.PARIOS_EMPRA_CREMA_P3,
          images.PARIOS_EMPRA_CREMA_P4,
        ]
      },
      {
        id: 228, name: "PARIS CREMA",
        img: images.PARIS_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PARIS_CREMA_P1,
          images.PARIS_CREMA_P2,
          images.PARIS_CREMA_P3,
          images.PARIS_CREMA_P4,
        ]
      },
      {
        id: 229, name: "PARIS IVORY",
        img: images.PARIS_IVORY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PARIS_IVORY_P1,
          images.PARIS_IVORY_P2,
          images.PARIS_IVORY_P3,
          images.PARIS_IVORY_P4,
        ]
      },
      {
        id: 230, name: "PEARL SHELL",
        img: images.PEARL_SHELL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PEARL_SHELL_P1,
          images.PEARL_SHELL_P2,
          images.PEARL_SHELL_P3,
          images.PEARL_SHELL_P4,
        ]
      },
      {
        id: 231, name: "PERLANO BLUE",
        img: images.PERLANO_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PERLANO_BLUE_P1,
          images.PERLANO_BLUE_P2,
          images.PERLANO_BLUE_P3,
          images.PERLANO_BLUE_P4,
        ]
      },
      {
        id: 232, name: "POURA BEIGE",
        img: images.POURA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.POURA_BEIGE_P1,
          images.POURA_BEIGE_P2,
          images.POURA_BEIGE_P3,
          images.POURA_BEIGE_P4,
        ]
      },
      {
        id: 233, name: "POURA CREMA",
        img: images.POURA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.POURA_CREMA_P1,
          images.POURA_CREMA_P2,
          images.POURA_CREMA_P3,
          images.POURA_CREMA_P4,
        ]
      },
      {
        id: 234, name: "PRAGA BEIGE",
        img: images.PRAGA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PRAGA_BEIGE_P1,
          images.PRAGA_BEIGE_P2,
          images.PRAGA_BEIGE_P3,
          images.PRAGA_BEIGE_P4,
          images.PRAGA_BEIGE_P5,
        ]
      },
      {
        id: 235, name: "PREDO ONIX BEIGE",
        img: images.PREDO_ONIX_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PREDO_ONIX_BEIGE_P1,
          images.PREDO_ONIX_BEIGE_P2,
          images.PREDO_ONIX_BEIGE_P3,
          images.PREDO_ONIX_BEIGE_P4,
          images.PREDO_ONIX_BEIGE_P5,
        ]
      },
      {
        id: 236, name: "PREDO ONIX GREY",
        img: images.PREDO_ONIX_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PREDO_ONIX_GREY_P1,
          images.PREDO_ONIX_GREY_P2,
          images.PREDO_ONIX_GREY_P3,
          images.PREDO_ONIX_GREY_P4,
          images.PREDO_ONIX_GREY_P5,
        ]
      },
      {
        id: 237, name: "PULPIS BIANCO",
        img: images.PULPIS_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PULPIS_BIANCO_P1,
          images.PULPIS_BIANCO_P2,
          images.PULPIS_BIANCO_P3,
          images.PULPIS_BIANCO_P4,
        ]
      },
      {
        id: 238, name: "PULPIS GREY",
        img: images.PULPIS_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PULPIS_GREY_P1,
          images.PULPIS_GREY_P2,
          images.PULPIS_GREY_P3,
          images.PULPIS_GREY_P4,
        ]
      },
      {
        id: 239, name: "PULPIS GRIS",
        img: images.PULPIS_GRIS_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.PULPIS_GRIS_P1,
          images.PULPIS_GRIS_P2,
          images.PULPIS_GRIS_P3,
          images.PULPIS_GRIS_P4,
        ]
      },
      {
        id: 240, name: "QUANTUM STATURIO",
        img: images.QUANTUM_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.QUANTUM_STATURIO_P1,
          images.QUANTUM_STATURIO_P2,
          images.QUANTUM_STATURIO_P3,
          images.QUANTUM_STATURIO_P4,
          images.QUANTUM_STATURIO_P5,
          images.QUANTUM_STATURIO_P6,
          images.QUANTUM_STATURIO_P7,
          images.QUANTUM_STATURIO_P8,
        ]
      },
      {
        id: 241, name: "RADIANT BIANCO",
        img: images.RADIANT_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.RADIANT_BIANCO_P1,
          images.RADIANT_BIANCO_P2,
          images.RADIANT_BIANCO_P3,
          images.RADIANT_BIANCO_P4,
        ]
      },
      {
        id: 242, name: "RADIANT CREMA",
        img: images.RADIANT_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.RADIANT_CREMA_P1,
          images.RADIANT_CREMA_P2,
          images.RADIANT_CREMA_P3,
          images.RADIANT_CREMA_P4,
        ]
      },
      {
        id: 243, name: "REAL BRESCIA BEIGE",
        img: images.REAL_BRESCIA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.REAL_BRESCIA_BEIGE_P1,
          images.REAL_BRESCIA_BEIGE_P2,
          images.REAL_BRESCIA_BEIGE_P3,
        ]
      },
      {
        id: 244, name: "REAL EMPRADOR DARK",
        img: images.REAL_EMPRADOR_DARK_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.REAL_EMPRADOR_DARK_P1,
          images.REAL_EMPRADOR_DARK_P2,
          images.REAL_EMPRADOR_DARK_P3,
          images.REAL_EMPRADOR_DARK_P4,
        ]
      },
      {
        id: 245, name: "REGALI BLUE",
        img: images.REGALI_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.REGALI_BLUE_P1,
          images.REGALI_BLUE_P2,
          images.REGALI_BLUE_P3,
          images.REGALI_BLUE_P4,
        ]
      },
      {
        id: 246, name: "REGALI BROWN",
        img: images.REGALI_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.REGALI_BROWN_P1,
          images.REGALI_BROWN_P2,
          images.REGALI_BROWN_P3,
          images.REGALI_BROWN_P4,
        ]
      },
      {
        id: 247, name: "ROCK BROWN",
        img: images.ROCK_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ROCK_BROWN_P1,
          images.ROCK_BROWN_P2,
          images.ROCK_BROWN_P3,
          images.ROCK_BROWN_P4,
          images.ROCK_BROWN_P5,
        ]
      },
      {
        id: 248, name: "ROCK GREY",
        img: images.ROCK_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ROCK_GREY_P1,
          images.ROCK_GREY_P2,
          images.ROCK_GREY_P3,
          images.ROCK_GREY_P4,
          images.ROCK_GREY_P5,
        ]
      },
      {
        id: 249, name: "ROSALINA IVORY",
        img: images.ROSALINA_IVORY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ROSALINA_IVORY_P1,
          images.ROSALINA_IVORY_P2,
          images.ROSALINA_IVORY_P3,
          images.ROSALINA_IVORY_P4,
          images.ROSALINA_IVORY_P5,
        ]
      },
      {
        id: 250, name: "ROYAL BROWN",
        img: images.ROYAL_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ROYAL_BROWN_P1,
          images.ROYAL_BROWN_P2,
          images.ROYAL_BROWN_P3,
          images.ROYAL_BROWN_P4,
        ]
      },
      {
        id: 251, name: "ROYAL CREMA",
        img: images.ROYAL_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ROYAL_CREMA_P1,
          images.ROYAL_CREMA_P2,
          images.ROYAL_CREMA_P3,
          images.ROYAL_CREMA_P4,
        ]
      },
      {
        id: 252, name: "RYNOLD BEIGE",
        img: images.RYNOLD_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.RYNOLD_BEIGE_P1,
          images.RYNOLD_BEIGE_P2,
          images.RYNOLD_BEIGE_P3,
          images.RYNOLD_BEIGE_P4,
          images.RYNOLD_BEIGE_P5,
        ]
      },
      {
        id: 253, name: "SANDSTONE BROWN",
        img: images.SANDSTONE_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SANDSTONE_BROWN_P1,
          images.SANDSTONE_BROWN_P2,
          images.SANDSTONE_BROWN_P3,
          images.SANDSTONE_BROWN_P4,
          images.SANDSTONE_BROWN_P5,
          images.SANDSTONE_BROWN_P6,
        ]
      },
      {
        id: 254, name: "SANTAFE BIANCO",
        img: images.SANTAFE_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SANTAFE_BIANCO_P1,
          images.SANTAFE_BIANCO_P2,
          images.SANTAFE_BIANCO_P3,
          images.SANTAFE_BIANCO_P4,
          images.SANTAFE_BIANCO_P5,
        ]
      },
      {
        id: 255, name: "SANTAFE BRONZE",
        img: images.SANTAFE_BRONZE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SANTAFE_BRONZE_P1,
          images.SANTAFE_BRONZE_P2,
          images.SANTAFE_BRONZE_P3,
          images.SANTAFE_BRONZE_P4,
          images.SANTAFE_BRONZE_P5,
        ]
      },
      {
        id: 256, name: "SARENIYA BEIGE",
        img: images.SARENIYA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SARENIYA_BEIGE_P1,
          images.SARENIYA_BEIGE_P2,
          images.SARENIYA_BEIGE_P3,
          images.SARENIYA_BEIGE_P4,
        ]
      },
      {
        id: 257, name: "SARENIYA GREY",
        img: images.SARENIYA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SARENIYA_GREY_P1,
          images.SARENIYA_GREY_P2,
          images.SARENIYA_GREY_P3,
          images.SARENIYA_GREY_P4,
        ]
      },
      {
        id: 258, name: "SATAVRIO BROWN",
        img: images.SATAVRIO_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SATAVRIO_BROWN_P1,
          images.SATAVRIO_BROWN_P2,
          images.SATAVRIO_BROWN_P3,
          images.SATAVRIO_BROWN_P4,
        ]
      },
      {
        id: 259, name: "SATVARIO PEARL",
        img: images.SATVARIO_PEARL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SATVARIO_PEARL_P1,
          images.SATVARIO_PEARL_P2,
          images.SATVARIO_PEARL_P3,
          images.SATVARIO_PEARL_P4,
        ]
      },
      {
        id: 260, name: "SCREPOLATO BROWN",
        img: images.SCREPOLATO_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SCREPOLATO_BROWN_P1,
          images.SCREPOLATO_BROWN_P2,
          images.SCREPOLATO_BROWN_P3,
          images.SCREPOLATO_BROWN_P4,
        ]
      },
      {
        id: 261, name: "SCREPOLATO NERO",
        img: images.SCREPOLATO_NERO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SCREPOLATO_NERO_P1,
          images.SCREPOLATO_NERO_P2,
          images.SCREPOLATO_NERO_P3,
          images.SCREPOLATO_NERO_P4,
        ]
      },
      {
        id: 262, name: "SEA STONE",
        img: images.SEA_STONE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SEA_STONE_P1,
          images.SEA_STONE_P2,
        ]
      },
      {
        id: 263, name: "SIBIRIAN BEIGE",
        img: images.SIBIRIAN_BEIGE,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SIBIRIAN_BEIGE,
        ]
      },
      {
        id: 264, name: "SIBIRIAN BIANCO",
        img: images.SIBIRIAN_BIANCO,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SIBIRIAN_BIANCO,
        ]
      },
      {
        id: 265, name: "SICILIA CLASSICO",
        img: images.SICILIA_CLASSICO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SICILIA_CLASSICO_P1,
          images.SICILIA_CLASSICO_P2,
          images.SICILIA_CLASSICO_P3,
        ]
      },

      {
        id: 267, name: "SIGMA CREMA",
        img: images.SIGMA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SIGMA_CREMA_P1,
          images.SIGMA_CREMA_P2,
          images.SIGMA_CREMA_P3,
          images.SIGMA_CREMA_P4,
        ]
      },
      {
        id: 268, name: "SIGMA GREY",
        img: images.SIGMA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SIGMA_GREY_P1,
          images.SIGMA_GREY_P2,
          images.SIGMA_GREY_P3,
          images.SIGMA_GREY_P4,
        ]
      },
      {
        id: 269, name: "SILKEN CREMA",
        img: images.SILKEN_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SILKEN_CREMA_P1,
          images.SILKEN_CREMA_P2,
          images.SILKEN_CREMA_P3,
          images.SILKEN_CREMA_P4,
        ]
      },
      {
        id: 270, name: "SILKEN GREY",
        img: images.SILKEN_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SILKEN_GREY_P1,
          images.SILKEN_GREY_P2,
          images.SILKEN_GREY_P3,
          images.SILKEN_GREY_P4,
        ]
      },
      {
        id: 271, name: "SILVER ONYX IVORY",
        img: images.SILVER_ONYX_IVORY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SILVER_ONYX_IVORY_P1,
          images.SILVER_ONYX_IVORY_P27,
          images.SILVER_ONYX_IVORY_P3,
          images.SILVER_ONYX_IVORY_P4,
          images.SILVER_ONYX_IVORY_P5,
        ]
      },
      {
        id: 272, name: "SILVER WAVE",
        img: images.SILVER_WAVE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SILVER_WAVE_P1,
          images.SILVER_WAVE_P2,
          images.SILVER_WAVE_P3,
          images.SILVER_WAVE_P4,
        ]
      },
      {
        id: 273, name: "SINE WAVE",
        img: images.SINE_WAVE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SINE_WAVE_P1,
          images.SINE_WAVE_P2,
          images.SINE_WAVE_P3,
          images.SINE_WAVE_P4,
          images.SINE_WAVE_P5,
        ]
      },
      {
        id: 274, name: "SKYRIUM ONYX",
        img: images.SKYRIUM_ONYX_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SKYRIUM_ONYX_P1,
          images.SKYRIUM_ONYX_P2,
          images.SKYRIUM_ONYX_P3,
          images.SKYRIUM_ONYX_P4,
        ]
      },
      {
        id: 275, name: "SMOKE BIANCO",
        img: images.SMOKE_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SMOKE_BIANCO_P1,
          images.SMOKE_BIANCO_P2,
          images.SMOKE_BIANCO_P3,
          images.SMOKE_BIANCO_P4,
        ]
      },
      {
        id: 276, name: "SMOKE BROWN",
        img: images.SMOKE_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SMOKE_BROWN_P1,
          images.SMOKE_BROWN_P2,
          images.SMOKE_BROWN_P3,
          images.SMOKE_BROWN_P4,
        ]
      },
      {
        id: 277, name: "SNOW PEARL",
        img: images.SNOW_PEARL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SNOW_PEARL_P1,
          images.SNOW_PEARL_P2,
          images.SNOW_PEARL_P3,
          images.SNOW_PEARL_P4,
        ]
      },
      {
        id: 278, name: "SPARROW BEIGE",
        img: images.SPARROW_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SPARROW_BEIGE_P1,
          images.SPARROW_BEIGE_P2,
          images.SPARROW_BEIGE_P3,
          images.SPARROW_BEIGE_P4,
        ]
      },
      {
        id: 279, name: "SPARTA CREMA",
        img: images.SPARTA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SPARTA_CREMA_P1,
          images.SPARTA_CREMA_P2,
          images.SPARTA_CREMA_P3,
          images.SPARTA_CREMA_P4,
        ]
      },
      {
        id: 280, name: "SPARTA GREY",
        img: images.SPARTA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SPARTA_GREY_P1,
          images.SPARTA_GREY_P2,
          images.SPARTA_GREY_P3,
          images.SPARTA_GREY_P4,
        ]
      },
      {
        id: 281, name: "SPIDER BROWN",
        img: images.SPIDER_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SPIDER_BROWN_P1,
          images.SPIDER_BROWN_P2,
          images.SPIDER_BROWN_P3,
          images.SPIDER_BROWN_P4,
          images.SPIDER_BROWN_P5,
        ]
      },
      {
        id: 282, name: "SPIDER NERO",
        img: images.SPIDER_NERO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SPIDER_NERO_P1,
          images.SPIDER_NERO_P2,
          images.SPIDER_NERO_P3,
          images.SPIDER_NERO_P4,
          images.SPIDER_NERO_P5,
        ]
      },
      {
        id: 283, name: "SPLENDOR ANTICO",
        img: images.SPLENDOR_ANTICO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SPLENDOR_ANTICO_P1,
          images.SPLENDOR_ANTICO_P2,
          images.SPLENDOR_ANTICO_P3,
          images.SPLENDOR_ANTICO_P4,
          images.SPLENDOR_ANTICO_P5,
        ]
      },
      {
        id: 284, name: "STATURIO GOLD",
        img: images.STATURIO_GOLD_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.STATURIO_GOLD_P1,
          images.STATURIO_GOLD_P2,
          images.STATURIO_GOLD_P3,
          images.STATURIO_GOLD_P4,
        ]
      },
      {
        id: 285, name: "STEEL BIANCO",
        img: images.STEEL_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.STEEL_BIANCO_P1,
          images.STEEL_BIANCO_P2,
          images.STEEL_BIANCO_P3,
          images.STEEL_BIANCO_P4,
          images.STEEL_BIANCO_P5,
        ]
      },
      {
        id: 286, name: "STEEL CHOCO",
        img: images.STEEL_CHOCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.STEEL_CHOCO_P1,
          images.STEEL_CHOCO_P2,
          images.STEEL_CHOCO_P3,
          images.STEEL_CHOCO_P4,
          images.STEEL_CHOCO_P5,
        ]
      },
      {
        id: 287, name: "STEEL GREY",
        img: images.STEEL_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.STEEL_GREY_P1,
          images.STEEL_GREY_P2,
          images.STEEL_GREY_P3,
          images.STEEL_GREY_P4,
          images.STEEL_GREY_P5,
        ]
      },
      {
        id: 288, name: "STONELA STONE",
        img: images.STONELA_STONE,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.STONELA_STONE,
        ]
      },
      {
        id: 289, name: "SWEDE",
        img: images.SWEDE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SWEDE_P1,
          images.SWEDE_P2,
          images.SWEDE_P3,
          images.SWEDE_P4,
          images.SWEDE_P5,
        ]
      },
      {
        id: 290, name: "SWEDE GREY",
        img: images.SWEDE_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SWEDE_GREY_P1,
          images.SWEDE_GREY_P2,
          images.SWEDE_GREY_P3,
          images.SWEDE_GREY_P4,
          images.SWEDE_GREY_P5,
        ]
      },
      {
        id: 291, name: "SWISS STATURIO",
        img: images.SWISS_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.SWISS_STATURIO_P1,
          images.SWISS_STATURIO_P2,
          images.SWISS_STATURIO_P3,
        ]
      },
      {
        id: 292, name: "TASANT STATURIO",
        img: images.TASANT_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TASANT_STATURIO_P1,
          images.TASANT_STATURIO_P2,
          images.TASANT_STATURIO_P3,
          images.TASANT_STATURIO_P4,
        ]
      },
      {
        id: 293, name: "THAI SHELL",
        img: images.THAI_SHELL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.THAI_SHELL_P1,
          images.THAI_SHELL_P2,
          images.THAI_SHELL_P3,
          images.THAI_SHELL_P4,
        ]
      },
      {
        id: 294, name: "TOBACCO BROWN",
        img: images.TOBACCO_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TOBACCO_BROWN_P1,
          images.TOBACCO_BROWN_P2,
          images.TOBACCO_BROWN_P3,
          images.TOBACCO_BROWN_P4,
        ]
      },
      {
        id: 295, name: "TOBACCO CREMA",
        img: images.TOBACCO_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TOBACCO_CREMA_P1,
          images.TOBACCO_CREMA_P2,
          images.TOBACCO_CREMA_P3,
          images.TOBACCO_CREMA_P4,
        ]
      },
      {
        id: 296, name: "TOPAZ SHELL",
        img: images.TOPAZ_SHELL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TOPAZ_SHELL_P1,
          images.TOPAZ_SHELL_P2,
          images.TOPAZ_SHELL_P3,
          images.TOPAZ_SHELL_P4,
        ]
      },
      {
        id: 297, name: "TORRENT BEIGE",
        img: images.TORRENT_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TORRENT_BEIGE_P1,
          images.TORRENT_BEIGE_P2,
          images.TORRENT_BEIGE_P3,
          images.TORRENT_BEIGE_P4,
        ]
      },
      {
        id: 298, name: "TRAVENTINE BEIGE",
        img: images.TRAVENTINE_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TRAVENTINE_BEIGE_P1,
          images.TRAVENTINE_BEIGE_P2,
          images.TRAVENTINE_BEIGE_P3,
          images.TRAVENTINE_BEIGE_P4,
          images.TRAVENTINE_BEIGE_P5,
        ]
      },
      {
        id: 299, name: "TRAVENTINE CREMA",
        img: images.TRAVENTINE_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TRAVENTINE_CREMA_P1,
          images.TRAVENTINE_CREMA_P2,
          images.TRAVENTINE_CREMA_P3,
          images.TRAVENTINE_CREMA_P4,
          images.TRAVENTINE_CREMA_P5,
        ]
      },
      {
        id: 300, name: "TRAVENTINE GREY",
        img: images.TRAVENTINE_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TRAVENTINE_GREY_P1,
          images.TRAVENTINE_GREY_P2,
          images.TRAVENTINE_GREY_P3,
          images.TRAVENTINE_GREY_P4,
          images.TRAVENTINE_GREY_P5,
        ]
      },
      {
        id: 301, name: "TRAVENTINE NATURAL",
        img: images.TRAVENTINE_NATURAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TRAVENTINE_NATURAL_P1,
          images.TRAVENTINE_NATURAL_P2,
          images.TRAVENTINE_NATURAL_P3,
          images.TRAVENTINE_NATURAL_P4,
          images.TRAVENTINE_NATURAL_P5,
        ]
      },
      {
        id: 302, name: "TRAVEX BEIGE",
        img: images.TRAVEX_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TRAVEX_BEIGE_P1,
          images.TRAVEX_BEIGE_P2,
          images.TRAVEX_BEIGE_P3,
          images.TRAVEX_BEIGE_P4,
        ]
      },
      {
        id: 303, name: "TRAVEX BROWN",
        img: images.TRAVEX_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TRAVEX_BROWN_P1,
          images.TRAVEX_BROWN_P2,
          images.TRAVEX_BROWN_P3,
          images.TRAVEX_BROWN_P4,
        ]
      },
      {
        id: 304, name: "TREASURE GREY",
        img: images.TREASURE_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TREASURE_GREY_P1,
          images.TREASURE_GREY_P2,
          images.TREASURE_GREY_P3,
          images.TREASURE_GREY_P4,
        ]
      },
      {
        id: 305, name: "TRECO GRESS",
        img: images.TRECO_GRESS_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.TRECO_GRESS_P1,
          images.TRECO_GRESS_P2,
          images.TRECO_GRESS_P3,
          images.TRECO_GRESS_P4,
        ]
      },
      {
        id: 306, name: "VALENCIA CREMA",
        img: images.VALENCIA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VALENCIA_CREMA_P1,
          images.VALENCIA_CREMA_P2,
          images.VALENCIA_CREMA_P3,
        ]
      },
      {
        id: 307, name: "VALENTINO",
        img: images.VALENTINO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VALENTINO_P1,
          images.VALENTINO_P2,
          images.VALENTINO_P3,
          images.VALENTINO_P4,
        ]
      },
      {
        id: 308, name: "VANATO BIANCO",
        img: images.VANATO_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VANATO_BIANCO_P1,
          images.VANATO_BIANCO_P2,
          images.VANATO_BIANCO_P3,
          images.VANATO_BIANCO_P4,
        ]
      },
      {
        id: 309, name: "VARSOVA BIANCO",
        img: images.VARSOVA_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VARSOVA_BIANCO_P1,
          images.VARSOVA_BIANCO_P2,
          images.VARSOVA_BIANCO_P3,
          images.VARSOVA_BIANCO_P4,
        ]
      },
      {
        id: 310, name: "VECTOR BIANCO",
        img: images.VECTOR_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VECTOR_BIANCO_P1,
          images.VECTOR_BIANCO_P2,
          images.VECTOR_BIANCO_P3,
          images.VECTOR_BIANCO_P4,
        ]
      },
      {
        id: 311, name: "VEGAS SMOKE",
        img: images.VEGAS_SMOKE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VEGAS_SMOKE_P1,
          images.VEGAS_SMOKE_P2,
          images.VEGAS_SMOKE_P3,
          images.VEGAS_SMOKE_P4,
        ]
      },
      {
        id: 312, name: "VENUS CHOCO",
        img: images.VENUS_CHOCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VENUS_CHOCO_P1,
          images.VENUS_CHOCO_P2,
          images.VENUS_CHOCO_P3,
          images.VENUS_CHOCO_P4,
        ]
      },
      {
        id: 313, name: "VENUS WHITE",
        img: images.VENUS_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VENUS_WHITE_P1,
          images.VENUS_WHITE_P2,
          images.VENUS_WHITE_P3,
          images.VENUS_WHITE_P4,
        ]
      },
      {
        id: 314, name: "VIENTI STATURIO",
        img: images.VIENTI_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VIENTI_STATURIO_P1,
          images.VIENTI_STATURIO_P2,
          images.VIENTI_STATURIO_P3,
          images.VIENTI_STATURIO_P4,
        ]
      },
      {
        id: 315, name: "VIGO STATURIO",
        img: images.VIGO_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VIGO_STATURIO_P1,
          images.VIGO_STATURIO_P2,
          images.VIGO_STATURIO_P3,
          images.VIGO_STATURIO_P4,
          images.VIGO_STATURIO_P5,
          images.VIGO_STATURIO_P6,
          images.VIGO_STATURIO_P7,
        ]
      },
      {
        id: 316, name: "VOLGA BROWN",
        img: images.VOLGA_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VOLGA_BROWN_P1,
          images.VOLGA_BROWN_P2,
          images.VOLGA_BROWN_P3,
          images.VOLGA_BROWN_P4,
        ]
      },
      {
        id: 317, name: "VOLGA MAROON",
        img: images.VOLGA_MAROON_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VOLGA_MAROON_P1,
          images.VOLGA_MAROON_P2,
          images.VOLGA_MAROON_P3,
          images.VOLGA_MAROON_P4,
        ]
      },
      {
        id: 318, name: "VOLTAS BEIGE",
        img: images.VOLTAS_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VOLTAS_BEIGE_P1,
          images.VOLTAS_BEIGE_P2,
          images.VOLTAS_BEIGE_P3,
          images.VOLTAS_BEIGE_P4,
        ]
      },
      {
        id: 319, name: "VOLTAS BEIGE",
        img: images.VOLTAs__BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.VOLTAs__BEIGE_P1,
          images.VOLTAs__BEIGE_P2,
          images.VOLTAs__BEIGE_P3,
          images.VOLTAs__BEIGE_P4,
          images.VOLTAs__BEIGE_P5,
        ]
      },
      {
        id: 320, name: "WILLIAM GREY EXOTIC",
        img: images.WILLIAM_GREY_EXOTIC_KRYPTON_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.WILLIAM_GREY_EXOTIC_KRYPTON_P1,
          images.WILLIAM_GREY_EXOTIC_KRYPTON_P2,
          images.WILLIAM_GREY_EXOTIC_KRYPTON_P3,
          images.WILLIAM_GREY_EXOTIC_KRYPTON_P4,
        ]
      },
      {
        id: 321, name: "WILLIAM LIGHT GREY EXOTIC",
        img: images.WILLIAM_LIGHT_GREY_EXOTIC_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.WILLIAM_LIGHT_GREY_EXOTIC_P1,
          images.WILLIAM_LIGHT_GREY_EXOTIC_P2,
          images.WILLIAM_LIGHT_GREY_EXOTIC_P3,
          images.WILLIAM_LIGHT_GREY_EXOTIC_P4,
        ]
      },
      {
        id: 322, name: "WILSON BIANCO",
        img: images.WILSON_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.WILSON_BIANCO_P1,
          images.WILSON_BIANCO_P2,
          images.WILSON_BIANCO_P3,
          images.WILSON_BIANCO_P4,
        ]
      },
      {
        id: 323, name: "WILSON BROWN",
        img: images.WILSON_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.WILSON_BROWN_P1,
          images.WILSON_BROWN_P2,
          images.WILSON_BROWN_P3,
          images.WILSON_BROWN_P4,
        ]
      },
      {
        id: 324, name: "WILSON NERO",
        img: images.WILSON_NERO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.WILSON_NERO_P1,
          images.WILSON_NERO_P2,
          images.WILSON_NERO_P3,
          images.WILSON_NERO_P4,
        ]
      },
      {
        id: 325, name: "ZENIT BEIGE",
        img: images.ZENIT_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZENIT_BEIGE_P1,
          images.ZENIT_BEIGE_P2,
          images.ZENIT_BEIGE_P3,
        ]
      },
      {
        id: 326, name: "ZIMBA BEIGE",
        img: images.ZIMBA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZIMBA_BEIGE_P1,
          images.ZIMBA_BEIGE_P2,
          images.ZIMBA_BEIGE_P3,
          images.ZIMBA_BEIGE_P4,
        ]
      },
      {
        id: 327, name: "ZIMBA NATURAL",
        img: images.ZIMBA_NATURAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZIMBA_NATURAL_P1,
          images.ZIMBA_NATURAL_P2,
          images.ZIMBA_NATURAL_P3,
          images.ZIMBA_NATURAL_P4,
        ]
      },
      {
        id: 328, name: "ZODIAC BEIGE",
        img: images.ZODIAC_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZODIAC_BEIGE_P1,
          images.ZODIAC_BEIGE_P2,
          images.ZODIAC_BEIGE_P3,
        ]
      },
      {
        id: 329, name: "ZODIAC BROWN",
        img: images.ZODIAC_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZODIAC_BROWN_P1,
          images.ZODIAC_BROWN_P2,
          images.ZODIAC_BROWN_P3,
        ]
      },
      {
        id: 330, name: "ZODIAC STATURIO",
        img: images.ZODIAC_STATURIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZODIAC_STATURIO_P1,
          images.ZODIAC_STATURIO_P2,
          images.ZODIAC_STATURIO_P3,
          images.ZODIAC_STATURIO_P4,
        ]
      },
      {
        id: 331, name: "ZUVI CREMA",
        img: images.ZUVI_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZUVI_CREMA_P1,
          images.ZUVI_CREMA_P2,
          images.ZUVI_CREMA_P3,
        ]
      },
      {
        id: 332, name: "ZUVI GREY",
        img: images.ZUVI_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Glossy",
        subImages: [
          images.ZUVI_GREY_P1,
          images.ZUVI_GREY_P2,
          images.ZUVI_GREY_P3,
        ]
      },
      // ***************  Matt start  *************** 

      {
        id: 333, name: "REGAL STRIP BLUE",
        img: images.REGAL_STRIP_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.REGAL_STRIP_BLUE_P1,
          images.REGAL_STRIP_BLUE_P2,
          images.REGAL_STRIP_BLUE_P3,
        ]
      },
      {
        id: 334, name: "REGAL STRIP BROWN",
        img: images.REGAL_STRIP_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.REGAL_STRIP_BROWN_P1,
          images.REGAL_STRIP_BROWN_P2,
          images.REGAL_STRIP_BROWN_P3,
        ]
      },
      {
        id: 335, name: "REGAL STRIP",
        img: images.REGAL_STRIP_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.REGAL_STRIP_P1,
          images.REGAL_STRIP_P2,
          images.REGAL_STRIP_P3,
        ]
      },
      {
        id: 336, name: "TRIANGLE STRIP",
        img: images.TRIANGLE_STRIP_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.TRIANGLE_STRIP_P1,
        ]
      },
      {
        id: 337, name: "ZIG ZAG STRIP",
        img: images.ZIG_ZAG_STRIP_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ZIG_ZAG_STRIP_P1,
        ]
      },
      {
        id: 338, name: "CROSS STRIP",
        img: images.CROSS_STRIP_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.CROSS_STRIP_P1,
        ]
      },
      {
        id: 339, name: "EIGHTEEN SQUARE",
        img: images.EIGHTEEN_SQUARE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.EIGHTEEN_SQUARE_P1,
        ]
      },
      {
        id: 340, name: "HAWK STRIP",
        img: images.HAWK_STRIP_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.HAWK_STRIP_P1,
        ]
      },
      {
        id: 341, name: "MULTY STRIP",
        img: images.MULTY_STRIP_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.MULTY_STRIP_P1,
        ]
      },

      {
        id: 342, name: "ALECIA BLUE",
        img: images.ALECIA_BLUE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ALECIA_BLUE_P1,
          images.ALECIA_BLUE_P2,
          images.ALECIA_BLUE_P3,
        ]
      },
      {
        id: 343, name: "ALECIA GRIS",
        img: images.ALECIA_GRIS_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ALECIA_GRIS_P1,
          images.ALECIA_GRIS_P2,
          images.ALECIA_GRIS_P3,
        ]
      },
      {
        id: 344, name: "ARBOR GRIGIO",
        img: images.ARBOR_GRIGIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ARBOR_GRIGIO_P1,
          images.ARBOR_GRIGIO_P2,
          images.ARBOR_GRIGIO_P3,
          images.ARBOR_GRIGIO_P4,

        ]
      },
      {
        id: 345, name: "ARMONIA BROWN",
        img: images.ARMONIA_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ARMONIA_BROWN_P1,
          images.ARMONIA_BROWN_P2,
          images.ARMONIA_BROWN_P3,
        ]
      },
      {
        id: 346, name: "AYER ROCK",
        img: images.AYER_ROCK_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.AYER_ROCK_P1,
          images.AYER_ROCK_P2,
          images.AYER_ROCK_P3,
          images.AYER_ROCK_P4,
        ]
      },
      {
        id: 348, name: "CEMENTO CHARCOAL",
        img: images.CEMENTO_CHARCOAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.CEMENTO_CHARCOAL_P1,
          images.CEMENTO_CHARCOAL_P2,
          images.CEMENTO_CHARCOAL_P3,
          images.CEMENTO_CHARCOAL_P4,
        ]
      },
      {
        id: 349, name: "CEMENTO COTTO",
        img: images.CEMENTO_COTTO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.CEMENTO_COTTO_P1,
          images.CEMENTO_COTTO_P2,
          images.CEMENTO_COTTO_P3,
        ]
      },
      {
        id: 350, name: "CEMENTO CREMA",
        img: images.CEMENTO_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.CEMENTO_CREMA_P1,
          images.CEMENTO_CREMA_P2,
          images.CEMENTO_CREMA_P3,
        ]
      },
      {
        id: 351, name: "CONCRETE GREY",
        img: images.CONCRETE_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.CONCRETE_GREY_P1,
          images.CONCRETE_GREY_P2,
        ]
      },
      {
        id: 352, name: "COPPER SLATE CREMA",
        img: images.COPPER_SLATE_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.COPPER_SLATE_CREMA_P1,
          images.COPPER_SLATE_CREMA_P2,
          images.COPPER_SLATE_CREMA_P3,
        ]
      },
      {
        id: 353, name: "COPPER SLATE GREY",
        img: images.COPPER_SLATE_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.COPPER_SLATE_GREY_P1,
          images.COPPER_SLATE_GREY_P2,
          images.COPPER_SLATE_GREY_P3,
        ]
      },
      {
        id: 354,
        name: "COPPER SLATE CREMA",
        img: images.COPPER_SLATE_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.COPPER_SLATE_WHITE_P1,
          images.COPPER_SLATE_WHITE_P2,
          images.COPPER_SLATE_WHITE_P3,
        ]
      },
      {
        id: 356, name: "COTTON CREMA",
        img: images.COTTON_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.COTTON_CREMA_P1,
          images.COTTON_CREMA_P2,
          images.COTTON_CREMA_P3,
          images.COTTON_CREMA_P4,
        ]
      },
      {
        id: 357, name: "COTTON GREY",
        img: images.COTTON_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.COTTON_GREY_P1,
          images.COTTON_GREY_P2,
          images.COTTON_GREY_P3,
          images.COTTON_GREY_P4,
        ]
      },
      {
        id: 358, name: "CRONOS GREY",
        img: images.CRONOS_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.CRONOS_GREY_P1,
          images.CRONOS_GREY_P2,
          images.CRONOS_GREY_P3,
          images.CRONOS_GREY_P4,
        ]
      },
      {
        id: 359, name: "CRONOS ROSSA",
        img: images.CRONOS_ROSSA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.CRONOS_ROSSA_P1,
          images.CRONOS_ROSSA_P2,
          images.CRONOS_ROSSA_P3,
          images.CRONOS_ROSSA_P4,
        ]
      },
      {
        id: 360, name: "ESHA BEIGE",
        img: images.ESHA_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ESHA_BEIGE_P1,
          images.ESHA_BEIGE_P2,
          images.ESHA_BEIGE_P3,
          images.ESHA_BEIGE_P4,
        ]
      },
      {
        id: 361, name: "ESHA CREMA",
        img: images.ESHA_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ESHA_CREMA_P1,
          images.ESHA_CREMA_P2,
          images.ESHA_CREMA_P3,
          images.ESHA_CREMA_P4,
        ]
      },
      {
        id: 362, name: "ESHA GREY",
        img: images.ESHA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ESHA_GREY_P1,
          images.ESHA_GREY_P2,
          images.ESHA_GREY_P3,
          images.ESHA_GREY_P4,
        ]
      },
      {
        id: 363, name: "ESHA STEEL",
        img: images.ESHA_STEEL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ESHA_STEEL_P1,
          images.ESHA_STEEL_P2,
          images.ESHA_STEEL_P3,
          images.ESHA_STEEL_P4,
        ]
      },
      {
        id: 364, name: "FERRATI BROWN",
        img: images.FERRATI_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FERRATI_BROWN_P1,
          images.FERRATI_BROWN_P2,
          images.FERRATI_BROWN_P3,
          images.FERRATI_BROWN_P4,
        ]
      },
      {
        id: 365, name: "FERRATI CREMA",
        img: images.FERRATI_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FERRATI_CREMA_P1,
          images.FERRATI_CREMA_P2,
          images.FERRATI_CREMA_P3,
          images.FERRATI_CREMA_P4,
        ]
      },
      {
        id: 366, name: "FERRATI DARK",
        img: images.FERRATI_DARK_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FERRATI_DARK_P1,
          images.FERRATI_DARK_P2,
          images.FERRATI_DARK_P3,
          images.FERRATI_DARK_P4,
        ]
      },
      {
        id: 367, name: "FERRATI LIGHT",
        img: images.FERRATI_LIGHT_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FERRATI_LIGHT_P1,
          images.FERRATI_LIGHT_P2,
          images.FERRATI_LIGHT_P3,
          images.FERRATI_LIGHT_P4,
        ]
      },
      {
        id: 368, name: "FERRATI SKY",
        img: images.FERRATI_SKY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FERRATI_SKY_P1,
          images.FERRATI_SKY_P2,
          images.FERRATI_SKY_P3,
          images.FERRATI_SKY_P4,
        ]
      },
      {
        id: 369, name: "FRENCH BEIGE",
        img: images.FRENCH_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FRENCH_BEIGE_P1,
          images.FRENCH_BEIGE_P2,
          images.FRENCH_BEIGE_P3,
        ]
      },
      {
        id: 370, name: "FRENCH BIANCO",
        img: images.FRENCH_BIANCO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FRENCH_BIANCO_P1,
          images.FRENCH_BIANCO_P2,
          images.FRENCH_BIANCO_P3,
        ]
      },
      {
        id: 371, name: "FRENCH CREMA",
        img: images.FRENCH_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FRENCH_CREMA_P1,
          images.FRENCH_CREMA_P2,
          images.FRENCH_CREMA_P3,
        ]
      },
      {
        id: 372, name: "FRENCH GREY",
        img: images.FRENCH_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.FRENCH_GREY_P1,
          images.FRENCH_GREY_P2,
          images.FRENCH_GREY_P3,
        ]
      },
      {
        id: 373, name: "INFINITY CREMA",
        img: images.INFINITY_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.INFINITY_CREMA_P1,
          images.INFINITY_CREMA_P2,
          images.INFINITY_CREMA_P3,
          images.INFINITY_CREMA_P4,
          images.INFINITY_CREMA_P5,
        ]
      },
      {
        id: 374, name: "INFINITY GRAFITO",
        img: images.INFINITY_GRAFITO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.INFINITY_GRAFITO_P1,
          images.INFINITY_GRAFITO_P2,
          images.INFINITY_GRAFITO_P3,
          images.INFINITY_GRAFITO_P4,
          images.INFINITY_GRAFITO_P5,
        ]
      },
      {
        id: 375, name: "ISTABUL BROWN",
        img: images.ISTABUL_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ISTABUL_BROWN_P1,
          images.ISTABUL_BROWN_P2,
          images.ISTABUL_BROWN_P3,
          images.ISTABUL_BROWN_P4,
        ]
      },
      {
        id: 376, name: "ISTABUL WHITE",
        img: images.ISTABUL_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ISTABUL_WHITE_P1,
          images.ISTABUL_WHITE_P2,
          images.ISTABUL_WHITE_P3,
          images.ISTABUL_WHITE_P4,
        ]
      },
      {
        id: 377, name: "LACA DARK GREY",
        img: images.LACA_DARK_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.LACA_DARK_GREY_P1,
        ]
      },
      {
        id: 378, name: "LACA LIGHT GREY",
        img: images.LACA_LIGHT_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.LACA_LIGHT_GREY_P1,
        ]
      },
      {
        id: 379, name: "MARBIA CHARCOAL",
        img: images.MARBIA_CHARCOAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.MARBIA_CHARCOAL_P1,
          images.MARBIA_CHARCOAL_P2,
          images.MARBIA_CHARCOAL_P3,
          images.MARBIA_CHARCOAL_P4,
        ]
      },
      {
        id: 380, name: "MARBIA GREY",
        img: images.MARBIA_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.MARBIA_GREY_P1,
          images.MARBIA_GREY_P2,
          images.MARBIA_GREY_P3,
          images.MARBIA_GREY_P4,
        ]
      },
      {
        id: 381, name: "MARLIN BEIGE",
        img: images.MARLIN_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.MARLIN_BEIGE_P1,
        ]
      },
      {
        id: 382, name: "MARLIN WHITE",
        img: images.MARLIN_WHITE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.MARLIN_WHITE_P1,
        ]
      },
      {
        id: 383, name: "MOSAIC CHARCOAL",
        img: images.MOSAIC_CHARCOAL_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.MOSAIC_CHARCOAL_P1,
        ]
      },
      {
        id: 384, name: "MOSAIC GREY",
        img: images.MOSAIC_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.MOSAIC_GREY_P1,
        ]
      },
      {
        id: 385, name: "ORIENT CREMA",
        img: images.ORIENT_CREMA_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ORIENT_CREMA_P1,
          images.ORIENT_CREMA_P2,
          images.ORIENT_CREMA_P3,
          images.ORIENT_CREMA_P4,
          images.ORIENT_CREMA_P5,
        ]
      },
      {
        id: 386, name: "ORIENT GRESS",
        img: images.ORIENT_GRESS_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ORIENT_GRESS_P1,
          images.ORIENT_GRESS_P2,
          images.ORIENT_GRESS_P3,
          images.ORIENT_GRESS_P4,
          images.ORIENT_GRESS_P5,
        ]
      },
      {
        id: 387, name: "ORIENT GREY",
        img: images.ORIENT_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ORIENT_GREY_P1,
          images.ORIENT_GREY_P2,
          images.ORIENT_GREY_P3,
          images.ORIENT_GREY_P4,
          images.ORIENT_GREY_P5,
        ]
      },
      {
        id: 388, name: "ORIENT SMOKE",
        img: images.ORIENT_SMOKE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ORIENT_SMOKE_P1,
          images.ORIENT_SMOKE_P2,
          images.ORIENT_SMOKE_P3,
          images.ORIENT_SMOKE_P4,
          images.ORIENT_SMOKE_P5,
        ]
      },
      {
        id: 389, name: "PLANO BEIGE",
        img: images.PLANO_BEIGE_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.PLANO_BEIGE_P1,
          images.PLANO_BEIGE_P2,
        ]
      },
      {
        id: 390, name: "PLANO BROWN",
        img: images.PLANO_BROWN_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.PLANO_BROWN_P1,
          images.PLANO_BROWN_P2,
        ]
      },
      {
        id: 391, name: "PLANO GREY",
        img: images.PLANO_GREY_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.PLANO_GREY_P1,
          images.PLANO_GREY_P2,
        ]
      },
      {
        id: 392, name: "PLANO NERO",
        img: images.PLANO_NERO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.PLANO_NERO_P1,
          images.PLANO_NERO_P2,
        ]
      },
      {
        id: 393,
        name: "ARBOR GRIGIO",
        img: images.ARBOR_GRIGIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ARBOR_GRIGIO_P1,
          images.ARBOR_GRIGIO_P2,
          images.ARBOR_GRIGIO_P3,
          images.ARBOR_GRIGIO_P4,
        ]
      },
      {
        id: 394,
        name: "ARBOR MUSCHIO",
        img: images.ARBOR_MUSCHIO_P1,
        size: ["600x600 mm", "600x1200 mm"],
        finish: "Matt",
        subImages: [
          images.ARBOR_MUSCHIO_P1,
          images.ARBOR_MUSCHIO_P2,
          images.ARBOR_MUSCHIO_P3,
          images.ARBOR_MUSCHIO_P4,
        ]
      },
      // {
      //   id: 395,
      //   name: "ARBOR OCRA",
      //   img: images.ARBOR_OCRA_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.ARBOR_OCRA_P1,
      //     images.ARBOR_OCRA_P2,
      //   ]
      // },
      // {
      //   id: 396,
      //   name: "ARMONIA AQUA",
      //   img: images.ARMONIA_AQUA_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.ARMONIA_AQUA_P1,
      //     images.ARMONIA_AQUA_P2,
      //   ]
      // },
      // {
      //   id: 397,
      //   name: "ARTICWOOD FUMO",
      //   img: images.ARTICWOOD_FUMO_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.ARTICWOOD_FUMO_P1,
      //     images.ARTICWOOD_FUMO_P2,
      //   ]
      // },
      // {
      //   id: 398,
      //   name: "ARTICWOOD BRICHWOOD WHITE BLUE",
      //   img: images.ARTICWOOD_BRICHWOOD_WHITE_BLUE_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.ARTICWOOD_BRICHWOOD_WHITE_BLUE_P1,
      //     images.ARTICWOOD_BRICHWOOD_WHITE_BLUE_P2,
      //   ]
      // },
      // {
      //   id: 399,
      //   name: "BRICHWOOD CREMA",
      //   img: images.BRICHWOOD_CREMA_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.BRICHWOOD_CREMA_P1,
      //     images.BRICHWOOD_CREMA_P2,
      //   ]
      // },
      // {
      //   id: 400,
      //   name: "CEDAR BEIGE",
      //   img: images.CEDAR_BEIGE_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.CEDAR_BEIGE_P1,
      //     images.CEDAR_BEIGE_P2,
      //   ]
      // },
      // {
      //   id: 401,
      //   name: "CEDAR MOKA",
      //   img: images.CEDAR_MOKA_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.CEDAR_MOKA_P1,
      //     images.CEDAR_MOKA_P2,
      //   ]
      // },
      // {
      //   id: 402,
      //   name: "CEDAR PINE",
      //   img: images.CEDAR_PINE_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.CEDAR_PINE_P1,
      //     images.CEDAR_PINE_P2,
      //   ]
      // },
      // {
      //   id: 403,
      //   name: "CEDAR WHITE",
      //   img: images.CEDAR_WHITE_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.CEDAR_WHITE_P1,
      //     images.CEDAR_WHITE_P2,
      //   ]
      // },
      // {
      //   id: 404,
      //   name: "CLARA WOOD BEIGE",
      //   img: images.CLARA_WOOD_BEIGE_P1,
      //   size: ["600x600 mm", "600x1200 mm"],
      //   finish: "Matt",
      //   subImages: [
      //     images.CLARA_WOOD_BEIGE_P1,
      //     images.CLARA_WOOD_BEIGE_P2,
      //   ]
      // },


      // ***************  Glossy end  *************** //


      // ***************  Matt end  *************** //

      // ***************   High Gloss start  *************** //

      // ***************   High Gloss end  *************** //

      // ***************  Carving start  *************** //

      // ***************  Carving end  *************** //

      // ***************  Endless start  *************** //

      // ***************  Endless end  *************** //
    ]
  },
];

export default CategoryData;
