import React, { useRef, useState, useEffect } from "react";
import "./HomeHero.css";
import Slider from "react-slick";
import images from "../../constants/images";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const slides = [
  { src: images.home_slider_img1, alt: "Slide 1" },
  { src: images.home_slider_img2, alt: "Slide 2" },
  { src: images.home_slider_img3, alt: "Slide 3" },
  { src: images.home_slider_img4, alt: "Slide 4" },
  { src: images.home_slider_img5, alt: "Slide 5" },
  { src: images.home_slider_img6, alt: "Slide 6" },
];

const HomeHero = () => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const imagesToLoad = slides.map((slide) => {
      const img = new Image();
      img.src = slide.src;
      return img;
    });

    Promise.all(imagesToLoad.map((img) => {
      return new Promise((resolve) => {
        img.onload = resolve;
      });
    })).then(() => {
      setLoading(false);
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    afterChange: (index) => setCurrentSlide(index),
  };

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="image-slider-container">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-text">
            {"Teknika".split("").map((letter, index) => (
              <span key={index} className={`loading-letter loading-letter-${index}`}>
                {letter}
              </span>
            ))}
          </div>
        </div>
      )}

      <div className={`hero-slider-content ${loading ? 'loading' : ''}`}>
        <div className="hero-slider-wrapp-shadow">
          <div className="hero-slider-wrapp-shadow-inner">
            <div className="hero-slider-wrapp-shadow-vl"></div>
            <div className="hero-slider-wrapp-shadow-text-wrapp">
              <p>
                Revolutionize,
                <br /> Design, <br /> Tailor
              </p>
            </div>
          </div>
        </div>

        <Slider ref={sliderRef} {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className="slide">
              <img src={slide.src} alt={slide.alt} className="slide-image" />
            </div>
          ))}
        </Slider>

        <div className="hero-slider-counter">
          <p>0{currentSlide + 1}</p>
        </div>

        <div className="custom-buttons">
          <button className="prev-button" onClick={handlePrevious}>
            <FaAngleLeft size={24} color="#000" />
          </button>
          <button className="next-button" onClick={handleNext}>
            <FaAngleRight size={24} color="#000" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
