import React, { useEffect, useState } from "react";
import "./BlogDetail.css";
import images from "../../constants/images";
import Recentblog from "../recentblog/Recentblog";

const BlogDetail = ({ blogData }) => {
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    if (blogData && blogData.blog_details) {
      // Extract headings from blog_details
      const headingMatches = blogData.blog_details.match(/<(h[1-3])[^>]*>(.*?)<\/\1>/g);
      if (headingMatches) {
        const extractedHeadings = headingMatches.map((heading, index) => {
          const tagMatch = heading.match(/<(h[1-3])[^>]*>/);
          const tag = tagMatch ? tagMatch[1] : "";
          const cleanHeading = heading.replace(/<[^>]+>/g, "").trim();
          return { id: `heading-${index}`, text: cleanHeading, tag };
        });

        setHeadings(extractedHeadings);

        // Add IDs to the blog_details content
        let updatedBlogDetails = blogData.blog_details;
        headingMatches.forEach((heading, index) => {
          const id = `heading-${index}`;
          const headingWithId = heading.replace(/<(h[1-3])/, `<$1 id="${id}"`);
          updatedBlogDetails = updatedBlogDetails.replace(heading, headingWithId);
        });

        blogData.blog_details = updatedBlogDetails;
      }
    }
  }, [blogData]);

  const renderTOC = (headings) => {
    let currentLevel = 1;
    const toc = [];

    headings.forEach((heading) => {
      const level = parseInt(heading.tag[1]);
      if (level > currentLevel) {
        toc.push('<ul class="nested">');
      } else if (level < currentLevel) {
        toc.push('</ul>');
      }
      toc.push(`<li><a href="#${heading.id}">${heading.text}</a></li>`);
      currentLevel = level;
    });

    while (currentLevel > 1) {
      toc.push('</ul>');
      currentLevel--;
    }

    return toc.join('');
  };

  if (!blogData) {
    return null;
  }

  return (
    <div className="section_main_wrapp">
      <div className="section_inner_wrapp">
        <div className="blog_detail_main_wrapp">
          <p className="b_d_des mb-2">{blogData.main_blog_details}</p>
          <div className="b_d_img_wrapp">
            {blogData.blog_image.map((image, index) => (
              <img key={index} src={image.image} alt={`Blog Image ${index + 1}`} />
            ))}
          </div>
          <div className="tablecontents">
            <h1>Table Of Contents</h1>
            <div
              className="ul_table"
              dangerouslySetInnerHTML={{ __html: renderTOC(headings) }}
            />
          </div>
          <div className="blog_details_sub">
            <div className="b_d_des mb-2" dangerouslySetInnerHTML={{ __html: blogData.blog_details }}></div>
          </div>
          <br />
          <div className="keyword_div">
            <p>Post Tags:</p>
            <div className="tag_main_key">
              {blogData.blog_key_words.map((blog_key_words, index) => (
                <div className="tag_key" key={index}>
                  {blog_key_words.blog_key_words}
                </div>
              ))}
            </div>
          </div>
          <div className="recent_blog">
            <h1>Recent BLOG</h1>
            <Recentblog />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
